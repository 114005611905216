import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { connect } from 'react-redux';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as actions from '../../../services/actions';
import { jsDictionaryEquals } from '../../../ressources/functionJS/jsDictionaryEquals';
import * as S from './FormulaireSelectionCapteur.module.scss';
import { AiOutlineClear } from 'react-icons/ai';

class FormulaireSelectionCapteur extends Component {
  state = {
    // inputValue:"",
    capteurId: '',
    preconfigurerCapteurId: null,
    affichageIdName: 'selectionne par Id ou Nom',
    buttonVisible: true,
  };

  componentDidMount() {
    if (this.props.preconfigurerCapteurId !== undefined) {
      this.setState({
        ...this.state,
        capteurId: this.props.preconfigurerCapteurId,
      });
      for (let capteur of this.props.listeCapteurs) {
        if (capteur.sensor_id === this.props.preconfigurerCapteurId) {
          this.setState({ ...this.state, preconfigurerCapteur: capteur });
        }
      }
    }
    if (this.props.preconfigurerCapteurId !== undefined) {
      this.setState({
        ...this.state,
        capteurId: this.props.preconfigurerCapteurId,
      });
    }
    this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !isNaN(this.props.preconfigurerCapteurId) &&
      this.props.preconfigurerCapteurId !== prevProps.preconfigurerCapteurId
    ) {
      const capteur =
        this.props.listeCapteurs[
          this.props.listeCapteurs.findIndex(
            (capteur) => capteur.sensor_id === this.props.preconfigurerCapteurId
          )
        ];
      let capteurName;
      if (capteur !== undefined) {
        capteurName = capteur.sensor_name;
      } else {
        capteurName = null;
      }
      this.setState({
        ...this.state,
        capteurId: this.props.preconfigurerCapteurId,
        affichageIdName: capteurName,
      });
    }
    if (
      this.props.listeCapteurs.length > 0 &&
      this.state.affichageIdName === null
    ) {
      const capteur =
        this.props.listeCapteurs[
          this.props.listeCapteurs.findIndex(
            (capteur) => capteur.sensor_id === this.props.preconfigurerCapteurId
          )
        ];
      let capteurName;
      if (capteur !== undefined) {
        capteurName = capteur.sensor_name;
      } else {
        capteurName = '';
      }
      this.setState({ ...this.state, affichageIdName: capteurName });
    }
    if (
      !jsDictionaryEquals(
        prevProps.filtre_projects_list_id,
        this.props.filtre_projects_list_id
      )
    ) {
      this.props.recupererListeCapteurs(this.props.filtre_projects_list_id);
    }
  }

  // gererCapteurId = (event) => {
  //     /**
  //      * gère id du parent en fonction de l'option sélectionnée
  //      */
  //     let newCapteurId = parseInt(event.target.value);
  //     let newListeComposantes = [];
  //     if (newCapteurId === -1 || newCapteurId === null || newCapteurId==="") {
  //         this.setState({...this.state, capteurId: "", affichageIdName: checkMot("selectionne_un_capteur")});
  //     } else if (isNaN(newCapteurId)) {
  //         this.setState({...this.state, capteurId: "", affichageIdName: checkMot("selectionne_un_capteur")});
  //     } else {
  //         const capteur = this.props.listeCapteurs[this.props.listeCapteurs.findIndex((capteur) => capteur.sensor_id===newCapteurId)]
  //         let capteurName = "";
  //         if(capteur !== undefined){
  //             capteurName = capteur.sensor_name
  //             newListeComposantes=capteur.component_list
  //         }

  //         this.setState({...this.state, capteurId: newCapteurId, affichageIdName: capteurName});
  //     }
  //     this.props.gererCapteurId(newCapteurId, newListeComposantes);
  // }
  gererCapteurId = (event) => {
    /**
     * gère id du parent en fonction de l'option sélectionnée
     */
    let newCapteurId = event.target.value;
    let newListeComposantes = [];
    if (newCapteurId === '') {
      this.setState({
        ...this.state,
        capteurId: '',
        affichageIdName: checkMot('selectionne_un_capteur'),
      });
    } else {
      if (!isNaN(newCapteurId) && newCapteurId !== '') {
        newCapteurId = parseInt(newCapteurId);
      }
      const capteur = this.props.listeCapteurs.find(
        (capteur) => capteur.sensor_id === newCapteurId
      );
      let capteurName = '';
      if (capteur !== undefined) {
        capteurName = capteur.sensor_name;
        newListeComposantes = capteur.component_list;
      }
      this.setState({
        ...this.state,
        capteurId: newCapteurId,
        affichageIdName: `${newCapteurId}  ${capteurName}`,
      });
    }
    this.props.gererCapteurId(newCapteurId, newListeComposantes);
  };

  limparSelecao() {
    this.setState({ capteurId: '', affichageIdName: '' });
  }
  render() {
    // récupérer liste des capteurs avec this.props.listeCapteurs
    // créer un formulaire pour ajouter un capteur à l'objet vue sous forme de liste déroulante via objet datalist
    return (
      <div className={[s.col_12, S.style].join(' ')}>
        {/* <span className={[S.stil2,].join(" ")}>{checkMot("sensor_id")}</span> */}
        <div className={[s.input_group, s.mb_2].join(' ')}>
          <div className={[s.input_group_prepend, S.CaseInput].join(' ')}>
            <div
              className={[s.input_group_text, s.form_control, S.Input].join(
                ' '
              )}
            >
              {this.state.affichageIdName}
            </div>
          </div>

          <input
            list="brow"
            className={[s.form_control].join(' ')}
            onChange={(event) => this.gererCapteurId(event)}
            value={this.state.capteurId}
            //    disabled={this.props.affichageProfil === true}
          />
          <button
            type="button"
            className={S.btx}
            onClick={() => this.limparSelecao()}
          >
            {this.state.buttonVisible ? <AiOutlineClear size={18} /> : ''}
          </button>
          <datalist
            id="brow"
            className={[S.datalist].join(' ')}
            // onChange={(event) => this.gererCapteurId(event)}
          >
            <option disabled={true} selected={true}>
              {checkMot('selectionne_un_capteur')}
            </option>
            {Object.keys(this.props.listeCapteurs).map((indListeCapteurs) => {
              return (
                <option
                  key={this.props.listeCapteurs[indListeCapteurs].sensor_id}
                  value={this.props.listeCapteurs[indListeCapteurs].sensor_id}
                  selected={
                    this.props.preconfigurerCapteurId ===
                    this.props.listeCapteurs[indListeCapteurs].sensor_id
                  }
                >
                  {this.props.listeCapteurs[indListeCapteurs].sensor_name}
                </option>
              );
            })}
          </datalist>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeCapteurs: state.capteur.capteursListe,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recupererListeCapteurs: (listeIdProjets) =>
      dispatch(actions.reqRecupererListeCapteurs(listeIdProjets)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormulaireSelectionCapteur);

import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  alarms_list: [], //todo liste vide de base
  modificationListe: [],

  list_emails_groups: [],
  list_templates: [],
  list_alarms_xy: [],
};

const enregistrerListeAlarmes = (state, action) => {
  return { ...state, alarms_list: action.alarms_list };
};
const enregistrerListeEmailsGroupes = (state, action) => {
  return { ...state, list_emails_groups: action.list_emails_groups };
};
const enregistrerListeGabarits = (state, action) => {
  return { ...state, list_templates: action.list_templates };
};
const enregistrerListeAlarmesXY = (state, action) => {
  return { ...state, list_alarms_xy: action.list_alarms_xy };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_LISTE_ALARMES:
      return enregistrerListeAlarmes(state, action);
    case actionTypes.ENREGISTRER_LISTE_EMAILS_GROUPS:
      return enregistrerListeEmailsGroupes(state, action);
    case actionTypes.ENREGISTRER_LISTE_GABARITS:
      return enregistrerListeGabarits(state, action);
    case actionTypes.ENREGISTRER_LISTE_ALARMES_XY:
      return enregistrerListeAlarmesXY(state, action);
    default:
      return state;
  }
};

export default reducer;

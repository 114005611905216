import * as actionTypes from '../actions/actionTypes';

const debutAttente = (state, action) => {
  return { ...state, ['ATTENTE_REQUETE' + action.requestType]: true };
};
const finAttente = (state, action) => {
  return { ...state, ['ATTENTE_REQUETE' + action.requestType]: false };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DEBUT_ATTENTE:
      return debutAttente(state, action);
    case actionTypes.FIN_ATTENTE:
      return finAttente(state, action);
    default:
      return state;
  }
};

export default reducer;

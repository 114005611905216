import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import { getPrivileges } from '../api';
import { affichageRetourErreurs } from '../utils/utils';

export const reqListePrivilegesParCategorie = () => {
  return async (dispatch) => {
    const requestId =
      requestType.liste_des_privileges_associes_a_une_categorie_d_utilisateur;
    dispatch(actions.debutAttente(requestId));
    try {
      const response = await getPrivileges();
      if (response.data.state) {
        dispatch(enregistrerListePrivilegesParCategorie(response.data));
      } else {
        affichageRetourErreurs(
          dispatch,
          `Erreur dans la requête ${requestId}`,
          response
        );
      }
    } catch (err) {
      console.error('Error API', requestId, ':', err);
    } finally {
      dispatch(actions.finAttente(requestId));
    }
  };
};

const enregistrerListePrivilegesParCategorie = (data) => {
  /**
   * Enregistre les informations de privilèges par catégories d'utilisateur todo:après la connexion?
   */
  return {
    type: actionTypes.ENREGISTRER_LISTE_PRIVILEGES_PAR_CATEGORIE,
    list_cat_functions: data.list_cat_functions,
  };
};

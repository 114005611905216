import * as requestType from '../ressources/glossaires/glossaire_requetes';
import {
  ProjectFailureRateResponse,
  SensorFailureRateResponse,
  SensorTypeReponse,
} from '../types/types';
import { _post, _postAllHeaders, _postFormData } from './axios';

//AUTHENTIFICATION (auth.js dans les actions redux)
export const authentication = (payload: {
  login_name: string;
  login_password: string;
}) => {
  return _postAllHeaders({
    request_type: requestType.connexion,
    login_name: payload.login_name,
    login_password: payload.login_password,
  });
};

export const logout = () => {
  return _post({
    request_type: requestType.deconnexion,
  });
};

export const askForPasswordReset = (payload: { email: string }) => {
  return _postAllHeaders({
    email: payload.email,
    request_type: requestType.demande_de_reinitialisation_du_mot_de_passe,
  });
};

export const resetPassword = () => {
  return _postAllHeaders({
    request_type: requestType.reinitialisation_du_mot_de_passe,
  });
};

export const validateCodeVerification = (payload: {
  codeVerification: string;
}) => {
  return _postAllHeaders({
    requestType: requestType.validation_du_code_de_verification,
    verification_code: payload.codeVerification,
  });
};

export const checkConnexion = () => {
  return _post({ requestType: requestType.information_de_connexion });
};

export const requestPasswordChange = (payload: any) => {
  return _postAllHeaders({
    request_type: requestType.changer_son_mot_de_passe,
    ...payload,
  });
};

//ALARME (alarme.js dans les actions redux)
export const getAllAlarmsWithIdProject = (payload: {
  list_project_id: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_alarmes,
    list_project_id: payload.list_project_id,
  });
};

export const getConfirmationAlarm = (payload: any) => {
  return _post({
    request_type: requestType.confirmer_une_alarme,
    ...payload,
  });
};

export const getListMailGroupsWithIdProject = (payload: {
  list_project_id: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_groupes_d_email,
    list_project_id: payload.list_project_id,
  });
};

export const createGroupMail = (payload: any) => {
  return _post({
    request_type: requestType.creer_un_groupe_d_email,
    ...payload,
  });
};

export const updateGroupMail = (payload: any) => {
  return _post({
    request_type: requestType.modifier_un_groupe_d_email,
    ...payload,
  });
};

export const deleteGroupMail = (payload: { group_email_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_groupe_d_email,
    group_email_id: payload.group_email_id,
  });
};

export const createAlarmTemplate = (payload: any) => {
  return _post({
    request_type: requestType.creer_un_gabarit,
    ...payload,
  });
};

export const updateAlarmTemplate = (payload: any) => {
  return _post({
    request_type: requestType.modifier_un_gabarit,
    ...payload,
  });
};

export const deleteAlarmTemplate = (payload: { template_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_gabarit,
    template_id: payload.template_id,
  });
};

export const getAllAlarmTemplatesWithIdProject = (payload: {
  list_project_id: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_gabarits,
    list_project_id: payload.list_project_id,
  });
};

export const createAlarmXY = (payload: any) => {
  return _post({
    request_type: requestType.configurer_des_alarmes_xy,
    ...payload,
  });
};

export const updateAlarmXY = (payload: any) => {
  return _post({
    request_type: requestType.modifier_des_alarmes_xy,
    ...payload,
  });
};

export const deleteAlarmXY = (payload: { alarm_xy_id: string }) => {
  return _post({
    request_type: requestType.supprimer_une_configuration_d_alarme_xy,
    alarm_xy_id: payload.alarm_xy_id,
  });
};

export const getAllAlarmsXYWithIdProject = (payload: {
  list_project_id: any;
}) => {
  return _post({
    request_type:
      requestType.recuperer_la_liste_des_configurations_des_alarmes_xy,
    list_project_id: payload.list_project_id,
  });
};

//CAPTEUR (capteur.js dans les actions redux)

export const createCapteur = (payload: any) => {
  return _post({
    request_type: requestType.creation_d_un_capteur,
    ...payload,
  });
};

export const updateCapteur = (payload: any) => {
  return _post({
    request_type: requestType.modification_d_un_capteur,
    ...payload,
  });
};

export const deleteCapteur = (payload: { sensor_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_capteur,
    sensor_id: payload.sensor_id,
  });
};

export const getSensorTypesList = (): Promise<SensorTypeReponse> => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_sensor_type,
  });
};

export const createSensorType = (payload: {
  sensor_type_name: string;
  sensor_type_display: string;
}) => {
  return _post({
    request_type: requestType.creation_d_un_sensor_type,
    sensor_type_name: payload.sensor_type_name,
    sensor_type_display: payload.sensor_type_display,
  });
};

export const updateSensorType = (payload: any) => {
  return _post({
    request_type: requestType.modification_d_un_sensor_type,
    ...payload,
  });
};

export const deleteSensorType = (payload: { sensor_type_id: string }) => {
  return _post({
    request_type: requestType.suppression_d_un_sensor_type,
    sensor_type_id: payload.sensor_type_id,
  });
};

export const getAllCapteurByProjectId = (payload: { projects_id: any }) => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_capteurs,
    projects_id: payload.projects_id,
  });
};

export const getAllImportFilters = () => {
  return _post({
    request_type:
      requestType.recuperation_de_la_liste_des_filtres_d_importation,
  });
};

export const getAllEmailGroups = () => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_groupes_d_emails,
  });
};

export const createOrReplaceImageForSensorType = (payload: any) => {
  return _post({
    request_type: requestType.ajouter_une_image_de_type_de_capteur,
    ...payload,
  });
};

export const getSensors = (payload: any) => {
  return _post({
    request_type: requestType.importation_des_capteurs_en_lot,
    ...payload,
  });
};

// GRAPHES (graphe.js dans les actions)
export const getAllGraphs = (payload: { projects_id: any }) => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_graphes,
    projects_id: payload.projects_id,
  });
};

export const deleteGraph = (payload: { graph_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_graphe,
    graph_id: payload.graph_id,
  });
};

export const getGraph = (payload: { graph_id: string }) => {
  return _post({
    request_type: requestType.recuperer_un_graphe,
    graph_id: payload.graph_id,
  });
};

export const getGraphData = (payload: {
  graph_id: string;
  time_period?: any;
  measures_num?: any;
  select_time?: any;
  time_interval?: any[];
}) => {
  const { graph_id, time_period, measures_num, select_time, time_interval } =
    payload;
  return _post({
    request_type: requestType.recuperation_des_donnees_d_un_graphe,
    graph_id,
    time_period: time_period === undefined ? null : time_period,
    measures_num: measures_num === undefined ? null : measures_num,
    select_time: select_time === undefined ? [] : select_time,
    time_interval: time_interval === undefined ? [null, null] : time_interval,
  });
};

export const getAutoGraphDataXT = (payload: {
  sensor_id: string;
  position_in_sensor: number;
  time_period?: any;
  measures_num?: any;
  select_time?: any;
  time_interval?: any[];
}) => {
  const {
    sensor_id,
    position_in_sensor,
    time_period,
    measures_num,
    select_time,
    time_interval,
  } = payload;
  return _post({
    request_type:
      requestType.recuperation_des_donnees_d_un_graphe_automatique_xt,
    sensor_id,
    position_in_sensor,
    time_period: time_period === undefined ? null : time_period,
    measures_num: measures_num === undefined ? null : measures_num,
    select_time: select_time === undefined ? [] : select_time,
    time_interval: time_interval === undefined ? [null, null] : time_interval,
  });
};

export const createGraph = (payload: { config: any }) => {
  return _post({
    request_type: requestType.creer_un_graphe,
    config: payload.config,
  });
};

export const updateGraph = (payload: { config: any }) => {
  return _post({
    request_type: requestType.modifier_un_graphe,
    config: payload.config,
  });
};

export const getDateFormat = () => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_formats_de_dates,
  });
};

//PRIVILEGE (privilege.js dans les actions redux)
export const getPrivileges = () => {
  return _post({
    request_type:
      requestType.liste_des_privileges_associes_a_une_categorie_d_utilisateur,
  });
};

//PROJETS (projet.js dans les actions redux)
export const getProjects = () => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_projets,
  });
};

export const createProject = (payload: any) => {
  return _post({
    request_type: requestType.creation_d_un_projet,
    ...payload,
  });
};
export const updateProject = (payload: any) => {
  return _post({
    request_type: requestType.modification_d_un_projet,
    ...payload,
  });
};
export const deleteProject = (payload: { project_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_projet,
    project_id: payload.project_id,
  });
};

export const addImageToProject = (payload: {
  project_id: string;
  image: string;
}) => {
  return _post({
    request_type: requestType.ajouter_une_image_de_projet,
    project_id: payload.project_id,
    image: payload.image,
  });
};

export const removeImageFromProject = (payload: { project_id: string }) => {
  return _post({
    request_type: requestType.ajouter_une_image_de_projet,
    project_id: payload.project_id,
  });
};

export const addProjectToFavorite = (payload: { project_id: string }) => {
  return _post({
    request_type: requestType.rajouter_un_projet_en_favori,
    project_id: payload.project_id,
  });
};

export const removeProjectfromFavorite = (payload: { project_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_projet_des_favoris,
    project_id: payload.project_id,
  });
};

export const getFavortiesProject = () => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_projets_des_favoris,
  });
};

//PROJETS (rapport.js dans les actions redux)

export const getPublishedReports = (payload: { list_project_id: any }) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_rapports_publies,
    list_project_id: payload.list_project_id,
  });
};

export const getPublishedTemplateReports = (payload: {
  list_project_id: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_rapports_gabarits,
    list_project_id: payload.list_project_id,
  });
};

export const createTemplateReport = (payload: any) => {
  return _post({
    request_type: requestType.creer_un_rapport_gabarit,
    ...payload,
  });
};

export const updateTemplateReport = (payload: any) => {
  return _post({
    request_type: requestType.modifier_un_rapport_gabarit,
    ...payload,
  });
};

export const deleteTemplateReport = (payload: {
  template_report_id: string;
}) => {
  return _post({
    request_type: requestType.modifier_un_rapport_gabarit,
    template_report_id: payload.template_report_id,
  });
};

export const createReport = (payload: {
  template_report_id: string;
  start_report: any;
  end_report: any;
}) => {
  return _post({
    request_type: requestType.generer_un_rapport,
    template_report_id: payload.template_report_id,
    start_report: payload.start_report,
    end_report: payload.end_report,
  });
};

export const getAllReports = (payload: {
  list_project_id: any;
  start_list_generate_report: any;
  end_list_generate_report: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_rapports_generes,
    list_project_id: payload.list_project_id,
    start_list_generate_report: payload.start_list_generate_report,
    end_list_generate_report: payload.end_list_generate_report,
  });
};

export const publishReports = (payload: { generate_report_id: any }) => {
  return _post({
    request_type: requestType.publier_un_rapport_genere,
    generate_report_id: payload.generate_report_id,
  });
};

export const loadReports = (payload: any) => {
  return _postFormData({
    request_type: requestType.charger_le_document_rapport,
    ...payload,
  });
};

export const deleteReports = (payload: { generate_report_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_rapport_genere,
    generate_report_id: payload.generate_report_id,
  });
};

// UTILISATEURS (utilisateur.js dans les actions redux)

export const createUser = (payload: any) => {
  return _post({
    request_type: requestType.creation_d_un_utilisateur,
    ...payload,
  });
};

export const updateUser = (payload: any) => {
  return _post({
    request_type: requestType.modification_d_un_utilisateur,
    ...payload,
  });
};

export const deleteUser = (payload: { user_id: string }) => {
  return _post({
    request_type: requestType.modification_d_un_utilisateur,
    user_id: payload.user_id,
  });
};

export const getAllUsers = () => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_utilisateurs,
  });
};

export const getUser = (payload: { user_id: string }) => {
  return _post({
    request_type: requestType.recuperation_d_un_utilisateur,
    user_id: payload.user_id,
  });
};

//VUES (vue.js dans les actions redux)

export const getViewByProjectIds = (payload: { list_project_id: any }) => {
  return _post({
    request_type: requestType.recuperation_de_la_liste_des_vues,
    list_project_id: payload.list_project_id,
  });
};

export const getView = (payload: { view_id: string }) => {
  return _post({
    request_type: requestType.recuperer_une_vue,
    view_id: payload.view_id,
  });
};

export const createView = (payload: { view_id: string; layer: any }) => {
  return _post({
    request_type: requestType.creer_une_vue,
    ...payload,
  });
};

export const addViewLayer = (payload: any) => {
  return _post({
    request_type: requestType.ajouter_une_couche_de_vue,
    ...payload,
  });
};

export const getViewLayers = (payload: { view_id: string }) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_couches_de_vue,
    view_id: payload.view_id,
  });
};

export const getFavoriteDashboards = () => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_tableaux_de_bord_favoris,
  });
};

export const updateUserFavoriteDashboards = (payload: {
  user_id: string;
  favorite_views_list: any;
}) => {
  return _post({
    request_type:
      requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur,
    user_id: payload.user_id,
    favorite_views_list: payload.favorite_views_list,
  });
};

export const getUserDashboards = (payload: { user_id: string }) => {
  return _post({
    request_type:
      requestType.recuperer_la_liste_des_tableaux_de_bord_utilisateur,
    user_id: payload.user_id,
  });
};

export const updateLayersOrder = (payload: {
  view_id: string;
  layers_position_order_list: any;
}) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_tableaux_de_bord_favoris,
    view_id: payload.view_id,
    layers_position_order_list: payload.layers_position_order_list,
  });
};

export const deleteLayerFromView = (payload: { layer_id: string }) => {
  return _post({
    request_type: requestType.supprimer_une_couche_de_vue,
    layer_id: payload.layer_id,
  });
};

export const updateView = (payload: any) => {
  return _post({
    request_type: requestType.modifier_une_vue,
    ...payload,
  });
};

export const deleteView = (payload: { view_id: string }) => {
  return _post({
    request_type: requestType.supprimer_une_vue,
    view_id: payload.view_id,
  });
};

export const getAllObjectsFromView = (payload: { view_id: string }) => {
  return _post({
    request_type: requestType.recuperer_la_liste_des_objets_associe_a_la_vue,
    view_id: payload.view_id,
  });
};

export const addObjectToView = (payload: {
  view_id: string;
  viewobj_id: string;
  viewobj: any;
}) => {
  return _post({
    request_type: requestType.ajouter_un_objet_associe_a_la_vue,
    view_id: payload.view_id,
    viewobj_id: payload.viewobj_id,
    viewobj: payload.viewobj,
  });
};

export const removeObjectFromView = (payload: { viewobj_id: string }) => {
  return _post({
    request_type: requestType.supprimer_un_objet_associe_a_la_vue,
    viewobj_id: payload.viewobj_id,
  });
};

export const getAllObjectsToDisplayFromView = (payload: {
  view_id: string;
}) => {
  return _post({
    request_type:
      requestType.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue,
    view_id: payload.view_id,
  });
};

export const getAdditonalDataOfObject = (payload: {
  view_id: string;
  viewobj_id: string;
}) => {
  return _post({
    request_type:
      requestType.recuperer_les_informations_complementaires_d_un_objet,
    view_id: payload.view_id,
    viewobj_id: payload.viewobj_id,
  });
};

//MAINTENANCE
export const getFailureRatePerProject = (
  startInterval: number,
  endInterval: number,
  typesIdList: number[],
  criticityIdList: number[],
  filteredProjects: number[]
): Promise<ProjectFailureRateResponse> => {
  return _post({
    request_type: requestType.recuperer_objet_projets_taux_de_pannes,
    start_interval: startInterval,
    end_interval: endInterval,
    types_id_list: typesIdList,
    criticity_id_list: criticityIdList,
    projects_id: filteredProjects,
  });
};

export const getDailyFailureRate = (
  startInterval: number,
  endInterval: number,
  typesIdList: number[],
  criticityIdList: number[],
  filteredProjects: number[]
): Promise<ProjectFailureRateResponse> => {
  return _post({
    request_type: requestType.graphe_taux_de_pannes,
    start_interval: startInterval,
    end_interval: endInterval,
    types_id_list: typesIdList,
    criticity_id_list: criticityIdList,
    projects_id: filteredProjects,
  });
};

export const getSensorFailureRatePerProject = (
  startInterval: number,
  endInterval: number,
  typesIdList: number[],
  criticityIdList: number[],
  filteredProjects: number[]
): Promise<SensorFailureRateResponse> => {
  return _post({
    request_type: requestType.liste_des_capteurs_defaillants_par_projet,
    start_interval: startInterval,
    end_interval: endInterval,
    types_id_list: typesIdList,
    criticity_id_list: criticityIdList,
    projects_id: filteredProjects,
  });
};

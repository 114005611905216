import axios from 'axios';

export const _post = async (body: any): Promise<any> => {
  return await axios.post('', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
export const _postFormData = async (body: any): Promise<any> => {
  return await axios.post('', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const _postAllHeaders = async (payload: any): Promise<any> => {
  return await axios.post('', payload, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    },
  });
};

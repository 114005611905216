import React, { Component } from 'react';

import { connect } from 'react-redux';
import Connexion from '../../composant/Connexion/Connexion';
import * as actions from '../../services/actions';
import ConteneurCentral from '../ConteneurCentral/ConteneurCentral';
import Menu from '../Menu/Menu';
class MiseEnPage extends Component {
  state = {
    montrerMenu: true, // affiche si le menu est visible ou non
  };

  commutateurMenu = () => {
    /**
     * permet de switch entre un menu ouvert ou fermer
     * */

    this.setState((prevState) => {
      if (!prevState.montrerMenu) {
        //    menu montrer
        this.props.ouvertureMenuGauche();
      } else if (prevState.montrerMenu) {
        //    menu caché
        this.props.fermetureMenuGauche();
      }
      return { ...this.state, montrerMenu: !prevState.montrerMenu };
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    return (
      <>
        <Menu
          ouvert={this.state.montrerMenu} //passe l'information d'ouverture/fermeture du menu
          commutateur={this.commutateurMenu}
        />
        {/*Faire passer l'information de si le menu et/ou entête sont ouverts ou non */}
        <ConteneurCentral montrerMenu={this.state.montrerMenu} />
        <Connexion />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fermetureMenuGauche: () => dispatch(actions.fermetureMenuGauche()),
    ouvertureMenuGauche: () => dispatch(actions.ouvertureMenuGauche()),
    fermetureEnTete: () => dispatch(actions.fermetureEnTete()),
    ouvertureEnTete: () => dispatch(actions.ouvertureEnTete()),
  };
};

export default connect(null, mapDispatchToProps)(MiseEnPage);

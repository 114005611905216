import React from 'react';
import { setUrlPathFromDomain } from '../../../services/utils/utils';
function AffichageImage(props) {
  let cheminURL = setUrlPathFromDomain(props.src, '../../.' + props.src);
  return (
    <div className={props.ajoutClass}>
      <img className={props.ajoutClass} src={cheminURL} alt={props.alt} />
    </div>
  );
}

export default AffichageImage;

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  tempsDeDefilement: 5,
  project_id_url: parseInt(window.location.href.split('/')[3].split('-')[0]),
  arborescence_projets: {},
  type_objet_affichage: parseInt(
    window.location.href.split('/')[3].split('-')[1]
  ),
  ID_objet_affichage: parseInt(
    window.location.href.split('/')[3].split('-')[2]
  ),
  conteurVueMarkers: 0,
};

const enregistrerProjectIdUrl = (state, action) => {
  return { ...state, project_id_url: action.project_id_url };
};
const enregistrerTypeObjetAffichage = (state, action) => {
  //todo: filtrer l'information si le type n'appartient pas au glossaire de type d'objet à afficher
  return { ...state, type_objet_affichage: action.type_objet_affichage };
};
const enregistrerIDObjetAffichage = (state, action) => {
  return { ...state, ID_objet_affichage: action.ID_objet_affichage };
};
const enregistrerTempsDeDefilement = (state, action) => {
  return { ...state, tempsDeDefilement: action.tempsDeDefilement };
};
const ajoutConteurVueMarkers = (state, action) => {
  return {
    ...state,
    conteurVueMarkers: state.conteurVueMarkers + action.nbMarkers,
  };
};

const enregistrerArborescenceProjets = (state, action) => {
  return { ...state, arborescence_projets: action.arborescence_projets };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_PROJECT_ID_URL:
      return enregistrerProjectIdUrl(state, action);
    case actionTypes.ENREGISTRER_TYPE_OBJET_AFFICHAGE:
      return enregistrerTypeObjetAffichage(state, action);
    case actionTypes.ENREGISTRER_ID_OBJET_AFFICHAGE:
      return enregistrerIDObjetAffichage(state, action);
    case actionTypes.ENREGISTRER_TEMPS_DE_DEFILEMENT:
      return enregistrerTempsDeDefilement(state, action);
    case actionTypes.ENREGISTRER_ARBORESCENCE_PROJECTS:
      return enregistrerArborescenceProjets(state, action);
    case actionTypes.AJOUT_CONTEUR_VUE_MARKERS:
      return ajoutConteurVueMarkers(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from 'react';
import BibliothequePlotly from '../BibliothequePlotly/BibliothequePlotly';
import { couleurAlarme } from '../../../../ressources/glossaires/glossaire_niveau_alarme';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { isJsonString } from '../../../../ressources/functionJS/isJsonString';

class GrapheXY extends Component {
  /**
   *
   * @return {JSX.Element}
   */
  state = {
    afficherX2: true,
  };

  render() {
    const jsDictionaryEquals = (dictionary1, dictionary2) =>
      JSON.stringify(dictionary1) === JSON.stringify(dictionary2);

    // converti uts en date pour la bibliothèque plotly
    const utsToDate = (unix) => {
      if (!isNaN(unix)) {
        let unixToconvert = Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }).format(unix * 1000);
        unixToconvert = unixToconvert.replaceAll('/', '-').replace(',', '');
        const time = unixToconvert.split(' ')[1];
        let splitDate = unixToconvert.split(' ')[0].split('-');
        let date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0];
        return date + ' ' + time;
      } else {
        return null;
      }
    };
    const emptyArray = (arr) =>
      jsDictionaryEquals(arr, []) || arr.length === 0 || !Boolean(arr);

    //vérifie si l'entrée est une chaîne de caractère vide
    const emptyString = (str) =>
      !Boolean(str) || str === '' || str.length === 0;

    //initialisation des données d'affichage
    let layout = {}; //mise en forme du graphe
    let data = []; //liste des données à afficher

    //s'il y a eu un retour de l'api pour la récupération des données
    if (this.props.configuration !== undefined) {
      layout = {
        title: {
          text:
            this.props.configuration.title +
            '<br>' +
            this.props.configuration.subtitle,
          font: {
            // size: 25,
            color: '#3c3935',
          },
        },
        barmode: this.props.configuration.barmode,
        xaxis: {
          ...this.props.configuration.xaxis,
          showgrid: this.props.configuration.showgrid,
          autoscale: !jsDictionaryEquals(this.props.configuration.xaxis.range, [
            null,
            null,
          ]),
        },
        yaxis: {
          ...this.props.configuration.yaxis,
          showgrid: this.props.configuration.showgrid,
          autoscale: !jsDictionaryEquals(this.props.configuration.yaxis.range, [
            null,
            null,
          ]),
        },
        yaxis2: {
          ...this.props.configuration.yaxis2,
          showgrid: this.props.configuration.showgrid,
          autoscale: !jsDictionaryEquals(
            this.props.configuration.yaxis2.range,
            [null, null]
          ),
          overlaying: 'y',
          side: 'right',
        },
        ...(this.props.viewobj_json_content === undefined ||
        this.props.viewobj_json_content === null
          ? {}
          : this.props.viewobj_json_content.layout),
      };
      //mise en forme des données de composantes de capteurs
      for (
        let indSetDonnees = 0;
        indSetDonnees < this.props.donnees.length;
        indSetDonnees++
      ) {
        let text = [];
        //créé l'info bulle de chaque point
        for (
          let indDonnee = 0;
          indDonnee < this.props.donnees[indSetDonnees].x.length;
          indDonnee++
        ) {
          // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
          let name =
            this.props.donnees[indSetDonnees].name !== ''
              ? this.props.donnees[indSetDonnees].name
              : this.props.donnees[indSetDonnees].sensor_name +
                '[' +
                (emptyString(this.props.donnees[indSetDonnees].prefix)
                  ? this.props.donnees[indSetDonnees].prefix
                  : this.props.donnees[indSetDonnees].position_in_sensor) +
                ']';

          //mise en forme de l'information en x et y
          const valeurX =
            layout.xaxis.title +
            ': ' +
            this.props.donnees[indSetDonnees].x[indDonnee];
          let valeurY;
          if (this.props.donnees[indSetDonnees].yaxis === 'y1') {
            valeurY =
              layout.yaxis.title +
              ': ' +
              this.props.donnees[indSetDonnees].y[indDonnee];
          } else if (this.props.donnees[indSetDonnees].yaxis === 'y2') {
            valeurY =
              layout.yaxis2.title +
              ': ' +
              this.props.donnees[indSetDonnees].y[indDonnee];
          }
          // mise en forme de l'info bulle pour chaque donnée
          let infoBulle;
          if (this.props.donnees[indSetDonnees].t.length > 1) {
            //cas de données XYT (chaque couple (X,Y) a une date T)
            const dateT = utsToDate(
              this.props.donnees[indSetDonnees].t[indDonnee]
            );
            infoBulle = [name, dateT, valeurX, valeurY];
          } else {
            //cas de données XY (une même date pour tous les couples (X, Y)
            infoBulle = [name, valeurX, valeurY];
          }
          text.push(infoBulle.join('<br>'));
        }
        let legende_name;
        if (this.props.donnees[indSetDonnees].t.length > 1) {
          //cas de données XYT (chaque couple (X,Y) a une date T)
          legende_name = this.props.donnees[indSetDonnees].name;
        } else {
          //cas de données XY (une même date pour tous les couples (X, Y)
          legende_name =
            this.props.donnees[indSetDonnees].name +
            ' ' +
            utsToDate(this.props.donnees[indSetDonnees].t[0]);
        }
        //regroupe les données créés
        let courbe = {
          ...this.props.donnees[indSetDonnees],
          name: legende_name,
          text: text,
          hovertemplate: '%{text}<extra></extra>',
          marker: {
            color: this.props.donnees[indSetDonnees].color,
          },
        };

        //affiche le nom du capteur [prefix || num composante] si name ===""
        // if(this.props.donnees[indSetDonnees].name===""){
        //   courbe.name = this.props.donnees[indSetDonnees].sensor_name + "["+(this.props.donnees[indSetDonnees].prefix!==""?this.props.donnees[indSetDonnees].prefix:this.props.donnees[indSetDonnees].position_in_sensor)+"]";
        // }
        //ajoute la courbe à la liste des données d'affichage
        data.push(courbe);
      }

      //mise en forme des données des seuils de composantes
      for (
        let indSetSeuils = 0;
        indSetSeuils < this.props.seuils.length;
        indSetSeuils++
      ) {
        let text = [];

        if (
          this.props.seuils[indSetSeuils].show_alarms === undefined ||
          parseInt(this.props.seuils[indSetSeuils].show_alarms) === 1
        ) {
          let afficherDansLegende = true;
          for (
            let indSeuil = 0;
            indSeuil < this.props.seuils[indSetSeuils].data.length;
            indSeuil++
          ) {
            //asymptote de x-y-t
            if (
              !(
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  null
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  []
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  [null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  [null, null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  undefined
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].x,
                  ''
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  null
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  []
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  [null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  [null, null]
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  undefined
                ) ||
                jsDictionaryEquals(
                  this.props.seuils[indSetSeuils].data[indSeuil].y,
                  ''
                )
              )
            ) {
              if (
                emptyArray(this.props.seuils[indSetSeuils].data[indSeuil].y)
              ) {
                //asymptotes en vertical
                for (
                  let indAsym = 0;
                  indAsym <
                  this.props.seuils[indSetSeuils].data[indSeuil].x.length;
                  indAsym++
                )
                  layout = {
                    ...layout,
                    shapes: [
                      ...(layout.shapes !== undefined ? layout.shapes : []),
                      {
                        type: 'line',
                        x0: this.props.seuils[indSetSeuils].data[indSeuil].x[
                          indAsym
                        ],
                        y0: 0,
                        x1: this.props.seuils[indSetSeuils].data[indSeuil].x[
                          indAsym
                        ],
                        yref: 'paper',
                        y1: 1,
                        line: {
                          ...this.props.seuils[indSetSeuils].line,
                        },
                      },
                    ],
                  };
              } else if (
                emptyArray(this.props.seuils[indSetSeuils].data[indSeuil].x)
              ) {
                //asymptotes en horizontale
                for (
                  let indAsym = 0;
                  indAsym <
                  this.props.seuils[indSetSeuils].data[indSeuil].y.length;
                  indAsym++
                ) {
                  layout = {
                    ...layout,
                    shapes: [
                      ...(layout.shapes !== undefined ? layout.shapes : []),
                      {
                        type: 'line',
                        x0: 0, //visibilité du segment sur la fenêtre visible du graphe à gauche
                        y0: this.props.seuils[indSetSeuils].data[indSeuil].y[
                          indAsym
                        ],
                        x1: 1, //visibilité du segment sur la fenêtre visible du graphe à droite
                        xref: 'paper', //segment fixe par rapport à l'axe horizontal de l'affichage du graphe
                        yref: this.props.seuils[indSetSeuils].yaxis,
                        y1: this.props.seuils[indSetSeuils].data[indSeuil].y[
                          indAsym
                        ],
                        line: {
                          ...this.props.seuils[indSetSeuils].line,
                        },
                        showlegend: true,
                      },
                    ],
                  };
                }
              } else {
                // mise en forme du nom : name || sensor_name[prefix || position_in_sensor]
                const sensorName =
                  '[' +
                    checkMot('alarme') +
                    '] ' +
                    this.props.seuils[indSetSeuils].name !==
                  ''
                    ? this.props.seuils[indSetSeuils].name
                    : this.props.seuils[indSetSeuils].sensor_name +
                      '[' +
                      (emptyString(this.props.seuils[indSetSeuils].prefix)
                        ? this.props.seuils[indSetSeuils].prefix
                        : this.props.seuils[indSetSeuils].position_in_sensor) +
                      ']';
                if (
                  this.props.seuils[indSetSeuils].data[indSeuil].y !== undefined
                ) {
                  for (
                    let indValeur = 0;
                    indValeur <
                    this.props.seuils[indSetSeuils].data[indSeuil].y.length;
                    indValeur++
                  ) {
                    const valeurX =
                      this.props.configuration.xaxis.title +
                      ': ' +
                      this.props.seuils[indSetSeuils].data[indSeuil].x[
                        indValeur
                      ];
                    let valeurY;
                    if (this.props.seuils[indSetSeuils].yaxis === 'y1') {
                      valeurY =
                        this.props.configuration.yaxis.title +
                        ': ' +
                        this.props.seuils[indSetSeuils].data[indSeuil].y[
                          indValeur
                        ];
                    } else if (this.props.seuils[indSetSeuils].yaxis === 'y2') {
                      valeurY =
                        this.props.configuration.yaxis2.title +
                        ': ' +
                        this.props.seuils[indSetSeuils].data[indSeuil].y[
                          indValeur
                        ];
                    }
                    // mise en forme de l'info bulle pour chaque donnée
                    let infoBulle = [sensorName, valeurX, valeurY];
                    text.push(infoBulle.join('<br>'));
                  }
                }

                //distribue la configuration générale de la courbe de seuils dans les données
                let courbe = {
                  ...this.props.seuils[indSetSeuils],
                  name: this.props.seuils[indSetSeuils].sensor_name,
                  ...this.props.seuils[indSetSeuils].data[indSeuil],
                };
                data.push({
                  ...courbe,
                  line: {
                    ...courbe.line,
                    color:
                      courbe.line_color.startsWith('High ') ||
                      courbe.line_color.startsWith('Low ')
                        ? courbe.line_color.split(' ')[1].toLowerCase()
                        : courbe.line_color,
                  },
                  name:
                    '[' +
                    checkMot('alarme') +
                    '] ' +
                    this.props.seuils[indSetSeuils].name +
                    utsToDate(
                      this.props.seuils[indSetSeuils].data[indSeuil].t[0]
                    ),
                  legendgroup: this.props.seuils[indSetSeuils].name,
                  showlegend: afficherDansLegende,
                  text: text,
                  hovertemplate: '%{text}<extra></extra>',
                });
                afficherDansLegende = false;
              }
            }
          }
        }
      }

      //mise en forme des données des gabarits
      if (
        this.props.donneesGraphe.template !== undefined &&
        this.props.donneesGraphe.template.length > 0
      ) {
        for (
          let indGabarit = 0;
          indGabarit < this.props.donneesGraphe.template.length;
          indGabarit++
        ) {
          if (
            isJsonString(
              this.props.donneesGraphe.template[indGabarit].template_name
            )
          ) {
            const para_json = JSON.parse(
              this.props.donneesGraphe.template[indGabarit].template_name
            );
            let text = [];
            // mise en forme du nom dans la légende:
            const name = para_json.name;

            //créé l'info bulle de chaque point
            for (
              let indDonnee = 0;
              indDonnee <
              this.props.donneesGraphe.template[indGabarit].x.length;
              indDonnee++
            ) {
              //mise en forme de l'information en x et y
              const valeurX =
                layout.xaxis.title +
                ': ' +
                this.props.donneesGraphe.template[indGabarit].x[indDonnee];

              // gabarit mis par défaut sur y1
              const valeurY =
                layout.yaxis.title +
                ': ' +
                this.props.donneesGraphe.template[indGabarit].y[indDonnee];

              // mise en forme de l'info bulle pour chaque donnée
              let infoBulle = [para_json.text, valeurX, valeurY];
              text.push(infoBulle.join('<br>'));
            }
            let courbe = {
              ...this.props.donneesGraphe.template[indGabarit],
              name: name,
              text: text,
              mode: 'lines',
              fill: 'toself',
              fillpattern: {
                shape: "'",
              },
              line: {
                color: para_json.fillColor,
                dash: 'null',
                width: 0,
              },
              hovertemplate: '%{text}<extra></extra>',
            };

            //affiche le nom du capteur [prefix || num composante] si name ===""
            // if(this.props.donnees[indSetDonnees].name===""){
            //   courbe.name = this.props.donnees[indSetDonnees].sensor_name + "["+(this.props.donnees[indSetDonnees].prefix!==""?this.props.donnees[indSetDonnees].prefix:this.props.donnees[indSetDonnees].position_in_sensor)+"]";
            // }
            //ajoute la courbe à la liste des données d'affichage
            data.push(courbe);
          } else {
            let text = [];
            // mise en forme du nom dans la légende:
            const name =
              '[' +
              checkMot('gabarit') +
              '] ' +
              this.props.donneesGraphe.template[indGabarit].template_name +
              '<br>' +
              '[' +
              (this.props.donneesGraphe.template[indGabarit].start === null
                ? '-'
                : utsToDate(
                    this.props.donneesGraphe.template[indGabarit].start
                  )) +
              ', ' +
              (this.props.donneesGraphe.template[indGabarit].end === null
                ? '-'
                : utsToDate(
                    this.props.donneesGraphe.template[indGabarit].end
                  )) +
              ']';

            //créé l'info bulle de chaque point
            for (
              let indDonnee = 0;
              indDonnee <
              this.props.donneesGraphe.template[indGabarit].x.length;
              indDonnee++
            ) {
              //mise en forme de l'information en x et y
              const valeurX =
                layout.xaxis.title +
                ': ' +
                this.props.donneesGraphe.template[indGabarit].x[indDonnee];

              // gabarit mis par défaut sur y1
              const valeurY =
                layout.yaxis.title +
                ': ' +
                this.props.donneesGraphe.template[indGabarit].y[indDonnee];

              // mise en forme de l'info bulle pour chaque donnée
              let infoBulle = [name, valeurX, valeurY];
              text.push(infoBulle.join('<br>'));
            }
            //regroupe les données créés
            let courbe = {
              ...this.props.donneesGraphe.template[indGabarit],
              name: name,
              text: text,
              mode: 'lines',
              line: {
                color: couleurAlarme(
                  this.props.donneesGraphe.template[indGabarit].alarm_level
                ),
                dash: 'dot',
              },
              hovertemplate: '%{text}<extra></extra>',
            };

            //affiche le nom du capteur [prefix || num composante] si name ===""
            // if(this.props.donnees[indSetDonnees].name===""){
            //   courbe.name = this.props.donnees[indSetDonnees].sensor_name + "["+(this.props.donnees[indSetDonnees].prefix!==""?this.props.donnees[indSetDonnees].prefix:this.props.donnees[indSetDonnees].position_in_sensor)+"]";
            // }
            //ajoute la courbe à la liste des données d'affichage
            data.push(courbe);
          }
        }
      }
    }

    if (
      this.state.afficherX2 === true &&
      data.length > 0 &&
      parseInt(this.props.configuration.graph_id) === 1921
    ) {
      // layout["legend"] = {
      //     x: 1.17,
      //     xanchor: 'right',
      //     y: 1
      // }
      const coords_tunnelier = [0, -35];
      const diametre_tunnelier = 4;
      layout['xaxis'] = {
        ...layout['xaxis'],
        range: [Math.min(...data[0].x), Math.max(...data[data.length - 1].x)],
        rangemode: 'tozero',
      };
      layout['xaxis2'] = {
        ...layout['xaxis'],
        range: [Math.min(...data[0].x), Math.max(...data[data.length - 1].x)],
        rangemode: 'tozero',
        title: '',
        titlefont: { color: 'rgb(166,22,63)' },
        tickfont: { color: 'rgb(166,22,63)' },
        overlaying: 'x',
        side: 'top',
        showlegend: false, //todo: hide legende
        gridcolor: 'rgba(166,22,63,0.3)',
        gridwidth: 1,
        zerolinecolor: 'rgba(166,22,63,0.7)',
        zerolinewidth: 2,
        hovermode: false,
        visible: false,
      };
      layout['xaxis3'] = {
        ...layout['xaxis'],
        range: [
          Math.min(...data[0].x) - Math.max(...data[data.length - 1].x),
          0,
        ],
        rangemode: 'tozero',
        title: '',
        titlefont: { color: 'rgb(166,22,63)' },
        tickfont: { color: 'rgb(166,22,63)' },
        overlaying: 'x',
        side: 'top',
        showlegend: false, //todo: hide legende
        gridcolor: 'rgba(166,22,63,0.3)',
        gridwidth: 1,
        zerolinecolor: 'rgba(166,22,63,0.7)',
        zerolinewidth: 2,
        hovermode: false,
      };
      let seuils_list = [];
      for (let seuils of this.props.seuils) {
        //récupère la liste des seuils pour trouver les extremums
        for (let seuil_couleur of seuils.data) {
          seuils_list.push(...seuil_couleur.y);
        }
      }
      data.push({
        //todo: hide text bulle
        x: [-Math.max(...data[data.length - 1].x), -Math.min(...data[0].x)],
        y: [Math.min(...data[0].y), Math.max(...data[0].y)],
        name: 'zzzzz', // zzzzz permet de créer la courbe en dernière
        showlegend: false,
        xaxis: 'x3',
        type: data[0].type,
        hoverinfo: 'none',
        opacity: 0,
      });
      layout['shapes'] = [
        // 1st highlight during Feb 4 - Feb 6
        {
          type: 'rect',
          // x-reference is assigned to the x-values
          xref: 'x3',
          // y-reference is assigned to the plot paper [0,1]
          yref: 'y',
          x0: -Math.max(...data[0].x, ...seuils_list),
          y0: coords_tunnelier[1] + diametre_tunnelier / 2,
          x1: coords_tunnelier[0],
          y1: coords_tunnelier[1] - diametre_tunnelier / 2,
          fillcolor: '#d3d3d3',
          opacity: 0.3,
          line: {
            width: 1,
          },
        },
      ];
      layout['images'] = [
        {
          source:
            'https://argos-v19.itmsol.fr/images/gtcSymbol/Vehicules/image%20tunnelier%20itm+.png',
          xref: 'x3',
          yref: 'y',
          x: coords_tunnelier[0],
          y: coords_tunnelier[1],
          sizex: diametre_tunnelier * 200 * 20,
          sizey: diametre_tunnelier,
          opacity: 1,
          xanchor: 'right',
          yanchor: 'middle',
        },
      ];
    }

    let jsonLayout = {};
    if (this.props.viewobj_json_content !== undefined) {
      if (this.props.viewobj_json_content.layout !== undefined) {
        jsonLayout = this.props.viewobj_json_content.layout;
      }
    }

    //booleen pour trier la légende dans l'ordre alphabétique
    const sensor_name_sort = true;
    return (
      <BibliothequePlotly
        sensor_name_sort={sensor_name_sort}
        data={data}
        layout={{
          ...layout,
          ...jsonLayout,
        }}
        dimensionWidth={this.props.dimensionWidth}
        dimensionHeight={this.props.dimensionHeight}
        ouvrirPopupModifierIntervalle={
          this.props.switchAfficherModifierIntervalle
        }
        donneesGraphe={this.props.donneesGraphe}
        minimaliste={this.props.minimaliste}
        viewobj_json_content={this.props.viewobj_json_content}
        afficherX2={
          parseInt(this.props.configuration.graph_id) === 1921
            ? () => this.setState({ afficherX2: !this.state.afficherX2 })
            : null
        }
      />
    );
  }
}

export default GrapheXY;

import React, { Component } from 'react';
import Modal from 'react-modal';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import Bouton from '../Bouton/Bouton';
import S from './PopupSmall.module.scss';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';

class PopupSmall extends Component {
  state = {
    showModal: false,
    overflowX: 'hidden',
    overflowY: 'hidden',

    inset: '5% 0.1% 0% 21%',
    afficherExpand: false,
  };

  setShowModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
    if (this.props.eventAuClique instanceof Function) {
      this.props.eventAuClique();
    }
  };

  setExpand = () => {
    this.setState({
      inset: '0% 0% 0% 0%',
      afficherExpand: true,

      overflowX: 'hidden',
    });
  };

  setPetit = () => {
    this.setState({
      inset: '5% 0.1% 0% 15.1%',

      afficherExpand: false,
      overflowX: 'auto',
      overflowY: 'auto',
    });
  };

  render() {
    let Textheader;
    let colorBg = [s.text_right, s.col_1];
    let classpopup = [s.row, s.text_right];
    if (!(this.props.hideHeader === true)) {
      classpopup.push([s.card_header, S.styleB].join(' '));

      Textheader = (
        <span className={[S.headerText, s.col_11].join(' ')}>
          {this.props.headerPopupText}
        </span>
      );
    } else {
      colorBg.push([S.headerTextD].join(' '));
      Textheader = (
        <span className={[S.headerTextD, s.col_11].join(' ')}>
          {this.props.headerPopupText}
        </span>
      );
    }

    let boutonExpand;
    if (this.state.afficherExpand) {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture].join(' ')}
          type={type_btn.Min}
          rond={true}
          cliquer={this.setPetit}
        />
      );
    } else {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture].join(' ')}
          type={type_btn.Max}
          rond={true}
          cliquer={this.setExpand}
        />
      );
    }

    return (
      <>
        {this.props.afficherLien === true ? (
          <a
            href={''}
            onClick={(event) => {
              event.preventDefault();
              this.setShowModal();
            }}
          >
            {this.props.icone}
            {this.props.texte_ouverture}
          </a>
        ) : (
          <Bouton
            hoverText={this.props.hoverText}
            ajoutClass={S.format}
            rond={this.props.true}
            type={this.props.typeBoutonAffichage}
            cliquer={this.setShowModal}
          >
            {this.props.icone}
            {this.props.texte_ouverture}
          </Bouton>
        )}
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.setShowModal}
          style={{
            overlay: {
              position: 'absolute',
              zIndex: '1000',

              //    padding: "1px",
              // paddingTop: "50px",
              //    backgroundColor: "white",
              border: 'black 1px solid',
              //    borderRadius: "10px",
              backgroundColor: 'transparent',

              transition: 'width 2s, height 4s',
            },
            content: {
              overflowX: this.state.overflowX,
              overflowY: this.state.overflowY,

              inset: this.state.inset,
              width: '45%',
              height: 'fit-content',
              padding: '0px',
              boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.226)',
            },
          }}
        >
          <div className={classpopup.join(' ')}>
            {Textheader}
            <span className={colorBg.join(' ')}>
              {/* {boutonExpand} */}

              <Bouton
                ajoutClass={[S.PositionBoutonFermeture].join(' ')}
                type={type_btn.fermer}
                rond={true}
                cliquer={this.setShowModal}
              />
            </span>
          </div>
          {/* <a href="https://www.itmsol.fr" target="_blank" rel="noopener noreferrer">
                    <img className={S.glogo} src={glogo} alt="itmsol"/>
                    </a> */}

          {this.props.children}
        </Modal>
      </>
    );
  }
}

export default PopupSmall;

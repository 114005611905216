import { store } from '../../index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import {
  createCapteur,
  createOrReplaceImageForSensorType,
  createSensorType,
  deleteCapteur,
  deleteSensorType,
  getAllCapteurByProjectId,
  getAllImportFilters,
  getSensorTypesList,
  updateCapteur,
  updateSensorType,
  getAllEmailGroups,
} from '../api';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  const state = store.getState();
  dispatch(actions.debutAttente(requestId));
  try {
    let response;
    switch (requestId) {
      case requestType.creation_d_un_capteur:
        response = await createCapteur(payload);
        if (response.data.state) {
          window.onbeforeunload = null;
          dispatch(
            actions.reqRecupererListeCapteurs(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.modification_d_un_capteur:
        response = await updateCapteur(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeCapteurs(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.supprimer_un_capteur:
        response = await deleteCapteur(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeCapteurs(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.recuperation_de_la_liste_des_capteurs:
        response = await getAllCapteurByProjectId(payload);
        if (response.data.state) {
          dispatch(enregistrerListeCapteurs(response.data.sensors_list));
          return;
        }
        break;
      case requestType.creation_d_un_sensor_type:
        response = await createSensorType(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeSensorType());
          return;
        }
        break;
      case requestType.modification_d_un_sensor_type:
        response = await updateSensorType(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeSensorType());
          return;
        }
        break;
      case requestType.suppression_d_un_sensor_type:
        response = await deleteSensorType(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeSensorType());
          return;
        }
        break;
      case requestType.recuperation_de_la_liste_des_sensor_type:
        response = await getSensorTypesList(payload);
        if (response.data.state) {
          dispatch(enregistrerListeSensorType(response.data.sensor_types_list));

          return;
        }
        break;
      case requestType.recuperation_de_la_liste_des_filtres_d_importation:
        response = await getAllImportFilters(payload);
        if (response.data.state) {
          dispatch(
            enregistrerListeImportFilters(response.data.import_filters_list)
          );
          return;
        }
        break;
      case requestType.ajouter_une_image_de_type_de_capteur:
        response = await createOrReplaceImageForSensorType(payload);
        if (response.data.state) {
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqAjouterCapteur = (formulaireCreerCapteur) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.creation_d_un_capteur,
      formulaireCreerCapteur
    );
  };
};
export const reqModifierCapteur = (formulaireModifierCapteur) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modification_d_un_capteur,
      formulaireModifierCapteur
    );
  };
};
export const reqSupprimerCapteur = (sensor_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_capteur, { sensor_id });
  };
};
export const reqAjouterSensorType = (sensor_type_name, sensor_type_display) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.creation_d_un_sensor_type, {
      sensor_type_name,
      sensor_type_display,
    });
  };
};
export const reqModifierSensorType = (
  sensor_type_id,
  sensor_type_name,
  sensor_type_display
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modification_d_un_sensor_type, {
      sensor_type_id,
      sensor_type_name,
      sensor_type_display,
    });
  };
};
export const reqSupprimerSensorType = (sensor_type_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.suppression_d_un_sensor_type, {
      sensor_type_id,
    });
  };
};
export const reqRecupererListeCapteurs = (projects_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_de_la_liste_des_capteurs, {
      projects_id,
    });
  };
};
export const reqRecupererListeSensorType = () => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_de_la_liste_des_sensor_type);
  };
};
export const reqRecupererListeImportFilters = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_de_la_liste_des_filtres_d_importation
    );
  };
};
export const reqRecupererListeEmailgroups = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_de_la_liste_des_groupes_d_emails
    );
  };
};
export const reqAjouterImageTypeDeCapteur = (sensor_type_id, base64_img) => {
  /**
   * interface ajouter_une_image_de_type_de_capteur permettant de d'ajouter une image à un type de capteur
   */
  return (dispatch) => {
    requetePost(dispatch, requestType.ajouter_une_image_de_type_de_capteur, {
      sensor_type_id,
      base64_img,
    });
  };
};
export const reqImportationCapteursEnLot = (sensors_list) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.importation_des_capteurs_en_lot,
      sensors_list
    );
  };
};

const enregistrerListeCapteurs = (sensors_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_CAPTEURS,
    capteursListe: sensors_list,
  };
};
const enregistrerListeSensorType = (sensor_types_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_SENSORS_TYPES,
    sensor_types_list: sensor_types_list,
  };
};
const enregistrerListeImportFilters = (import_filters_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_IMPORT_FILTERS,
    import_filters_list: import_filters_list,
  };
};

import React from 'react';

import S from './Bouton.module.scss';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import { VscRefresh } from 'react-icons/vsc';
import { IoIosClose } from 'react-icons/io';
import { FiMaximize, FiMinimize } from 'react-icons/fi';

const Bouton = (props) => {
  let classesBouton = [s.btn, s.btn_sm, props.ajoutClass, S.btnATEsTER];

  if (props.rond) {
    classesBouton.push(S.btnCircle);
  }

  //icône à afficher dans le bouton avant le text
  let icone = null;

  //type de bouton en fonction de props.types
  switch (props.type) {
    case type_btn.lupeSearchClose:
      classesBouton.push(S.lupeSearchClose);

      break;
    case type_btn.lupeSearch:
      classesBouton.push(S.lupeSearch);

      break;
    case type_btn.succeSimple:
      classesBouton.push(S.succeSimple);

      break;
    case type_btn.succes:
      classesBouton.push(S.btnSuccess);

      break;
    case type_btn.btnDisable:
      classesBouton.push(S.btnSuccessDisable);

      break;
    case type_btn.danger:
      classesBouton.push(S.btnDanger);

      break;
    case type_btn.Wait:
      classesBouton.push(S.btnWait);

      break;
    case type_btn.actualiser:
      classesBouton.push(S.btnSuccess);

      icone = <VscRefresh size={15} />; //icône de refresh qui s'affiche avant le text
      break;
    case type_btn.fermer:
      classesBouton.push(S.btnFermer);

      icone = <IoIosClose size={30} />; //icône de refresh qui s'affiche avant le text

      break;
    case type_btn.Max:
      classesBouton.push(S.btnMax);

      icone = <FiMaximize size={20} />; //icône de refresh qui s'affiche avant le text

      break;
    case type_btn.Min:
      classesBouton.push(S.btnMin);

      icone = <FiMinimize size={20} />; //icône de refresh qui s'affiche avant le text

      break;
    case type_btn.popupferme:
      classesBouton.push(S.popupferme);

      icone = <IoIosClose size={18} />; //icône de refresh qui s'affiche avant le text

      break;
    case type_btn.outil:
      classesBouton.push([S.btnOutil, S.btnCircle].join(' '));

      break;
    default:
      classesBouton.push(s.btn_primary);
  }

  let contentHoverText;
  if (props.hoverText !== undefined) {
    contentHoverText = (
      <span className={S.classdutexte}>{props.hoverText}</span>
    );
  }
  return (
    <button
      title={props.title}
      style={{ ...props.style }}
      className={classesBouton.join(' ')}
      onClick={props.cliquer}
      onDoubleClick={props.dobleClique}
      disabled={props.desactive ? 'disabled' : null}
    >
      {icone}
      {props.children} {contentHoverText}
    </button>
  );
};

export default Bouton;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormulaireSelectionProjet from '../../../../composant/Capteur/Ajouter/FormulaireSelectionProjet/FormulaireSelectionProjet';
import FormulaireCourbe from './FormulaireCourbe/FormulaireCourbe';
import FormulaireAxe from './FormulaireAxe/FormulaireAxe';
import FormulaireSelectionDateHeureUnix from './FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix';
import FormulaireSelectUtsDate from './FormulaireSelectUtsDate/FormulaireSelectUtsDate';

import Bouton from '../../../UI/Bouton/Bouton';

import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';
import Collapse from '../../../../hoc/Collapse/Collapse';

import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_type_de_graphe from '../../../../ressources/glossaires/glossaire_type_de_graphe';
import { jsDictionaryEquals } from '../../../../ressources/functionJS/jsDictionaryEquals';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import * as actions from '../../../../services/actions';
import help from '../../../../ressources/imgStatic/N_temp.gif';
import helpB from '../../../../ressources/imgStatic/n_line.gif';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import { AiFillPlusCircle } from 'react-icons/ai';
import { AiOutlineCheck } from 'react-icons/ai';
import * as S from './FormulaireGraphe.module.scss';
import FormulaireSelectionGabarits from '../../../UI/FormulaireSelectionGabarits/FormulaireSelectionGabarits';
import FormulaireSaisieDuree from '../../../UI/FormulaireSaisieDurée/FormulaireSaisieDuree';
import HoverButton from '../../../UI/BoutonHover/BoutonHover';
import FormulaireSelectionTimeZone from '../../../UI/FormulaireSelectionTimeZone/FormulaireSelectionTimeZone';

const valeurParDefautCourbe = {
  //config générale d'une courbe
  name: '', //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: nom de la courbe
  show_alarms: 1, //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
  formula: '', //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
  parameters: '', //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
  yaxis: 'y1', //TYPE: String + ValeurParDéfaut: "y1" +  DESCRIPTION: "y1" ou "y2"
  type: 'scatter', //TYPE: String + ValeurParDéfaut: "scatter" +  DESCRIPTION: "scatter" ou "bar"

  //configuration pour un type de courbe scatter
  mode: 'lines', //TYPE: String + ValeurParDéfaut: "lines+markers" +  DESCRIPTION: 'lines et/ou markers et/ou text' concaténer avec un "+"

  //configuration pour un mode incluant line
  line: {
    //TYPE: Object + ValeurParDéfaut: {} +  DESCRIPTION: si mode contient "line"
    dash: 'solid', //TYPE: String + ValeurParDéfaut: "solid" +  DESCRIPTION:  'solid' ou 'dashdot' ou 'dot'
    shape: 'linear', //TYPE: String + ValeurParDéfaut: "linear" +  DESCRIPTION: 'vh' ou 'hv' ou 'linear' ou 'spline' ou 'hvh' ou 'vhv'
  },
  color: '',
};

class FormulaireGraphe extends Component {
  state = {
    //paramètre par défaut du graphe
    private: 1,
    time_refresh: null,
    time_period: 2678400, // 31×24×60×60=2678400 secondes.  31 jour
    measures_num: null,
    cumul_interval: '', //'heure' ou 'jour' ou 'semaine' ou 'mois' ou 'annee'
    cumul_num: null,
    time_zone: 'Europe/Paris',
    select_time: [],
    time_interval: [null, null],

    graph_type_id: glossaire_type_de_graphe.graphe_x_t,
    graph_name: '',
    title: '',
    subtitle: '',
    showgrid: 1,
    showlegend: 1,
    xaxis: {
      title: '',
      type: 'date',
      range: [null, null],
    },
    yaxis: {
      title: '',
      type: 'linear',
      range: [null, null],
    },
    yaxis2: {
      title: '',
      type: 'linear',
      range: [null, null],
    },
    xaxis_type: 'date',
    yaxis_type: 'linear',
    yaxis2_type: 'linear',
    barmode: 'group',
    curves_conf_list: [],
    list_template_id: [],
  };

  componentDidMount() {
    if (
      this.props !== undefined &&
      this.props.preconfigurerGraphe === undefined
    ) {
      this.setState({
        //todo: paramètre par défaut du graphe
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigurerGraphe !== undefined
    ) {
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigurerGraphe,
        xaxis: {
          ...this.props.preconfigurerGraphe.xaxis,
        },
        yaxis: {
          ...this.props.preconfigurerGraphe.yaxis,
        },
        yaxis2: {
          ...this.props.preconfigurerGraphe.yaxis2,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.curves_conf_list !== prevState.curves_conf_list ||
      this.state.graph_type_id !== prevState.graph_type_id
    ) {
      //liste formulaire de courbe
      let listeFormulaireCourbe = [];
      for (
        let indCourbe = 0;
        indCourbe < this.state.curves_conf_list.length;
        indCourbe++
      ) {
        listeFormulaireCourbe.push(
          <>
            <div className={[S.info].join(' ')}>
              <FormulaireCourbe
                typoGraphe={this.state.graph_type_id}
                preConfigCourbe={{ ...this.state.curves_conf_list[indCourbe] }}
                gererConfigCourbe={(configCourbe) =>
                  this.gererCourbe(indCourbe, configCourbe)
                }
                supprimerCourbe={() => this.supprimerIndiceCourbe(indCourbe)}
                disabled={this.props.affichageProfil === true}
              />
            </div>
          </>
        );
      }
      let xaxis_type;
      let yaxis_type;
      let yaxis2_type;
      switch (parseInt(this.state.graph_type_id)) {
        case glossaire_type_de_graphe.graphe_x_t:
          xaxis_type = 'date';
          yaxis_type = 'linear';
          yaxis2_type = 'linear';
          break;
        case glossaire_type_de_graphe.graphe_x_y:
          xaxis_type = 'linear';
          yaxis_type = 'linear';
          yaxis2_type = 'linear';
          break;
        case glossaire_type_de_graphe.graphe_x_y_t:
          xaxis_type = 'linear';
          yaxis_type = 'linear';
          yaxis2_type = 'linear';
          break;
        default:
          xaxis_type = 'date';
          yaxis_type = 'linear';
          yaxis2_type = 'linear';
          break;
      }
      this.setState({
        ...this.state,
        listeFormulaireCourbe: listeFormulaireCourbe,
        xaxis_type: xaxis_type,
        yaxis_type: yaxis_type,
        yaxis2_type: yaxis2_type,
      });
    }

    if (
      this.props !== undefined &&
      this.props.preconfigurerGraphe === undefined &&
      !jsDictionaryEquals(prevProps, this.props)
    ) {
      this.setState({
        //todo: paramètre par défaut du graphe
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigurerGraphe !== undefined &&
      !jsDictionaryEquals(
        prevProps.preconfigurerGraphe,
        this.props.preconfigurerGraphe
      )
    ) {
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigurerGraphe,
        xaxis: {
          ...this.props.preconfigurerGraphe.xaxis,
        },
        yaxis: {
          ...this.props.preconfigurerGraphe.yaxis,
        },
        yaxis2: {
          ...this.props.preconfigurerGraphe.yaxis2,
        },
      });
    }
  }

  gererEnvoi = () => {
    //mise en forme de l'objet pour l'envoi de la requête API
    let formulaireGraphe = {
      //clé du formulaire de graphe de l'api
      project_id: parseInt(this.state.project_id),
      private: parseInt(this.state.private),
      time_refresh: parseInt(this.state.time_refresh),
      time_period: parseInt(this.state.time_period),
      measures_num: parseInt(this.state.measures_num),
      time_zone: this.state.time_zone,
      cumul_interval: this.state.cumul_interval,
      cumul_num: parseInt(this.state.cumul_num),
      select_time: this.state.select_time,
      time_interval: [
        parseInt(this.state.time_interval[0]),
        parseInt(this.state.time_interval[1]),
      ],

      graph_type_id: parseInt(this.state.graph_type_id),
      graph_name: this.state.graph_name,
      title: this.state.title,
      subtitle: this.state.subtitle,
      showlegend: parseInt(this.state.showlegend),
      showgrid:
        this.state.showgrid === null ? 1 : parseInt(this.state.showgrid),
      xaxis: {
        ...this.state.xaxis,
        type: this.state.xaxis_type,
      },
      yaxis: {
        ...this.state.yaxis,
        type: this.state.yaxis_type,
      },
      yaxis2: {
        ...this.state.yaxis2,
        type: this.state.yaxis2_type,
      },
      curves_conf_list: [...this.state.curves_conf_list],
      list_template_id: [...this.state.list_template_id],
      barmode: this.state.barmode,
    };
    // formulaireGraphe["configs_y2axis_list"] = newConfigs_y2axis_list;

    // //ajout de la configuration de barmode si une courbe au moins à un type === "bar"
    // for(let courbe of this.state.curves_conf_list){
    //     if(courbe.type === "bar"){
    //         formulaireGraphe["barmode"] = this.state.barmode
    //         break;
    //     }
    // }

    //Envoi la requête de création de graphe si le graphe n'a pas de pré-remplissage des champs du formulaire sinon modification
    if (
      this.props !== undefined &&
      this.props.preconfigurerGraphe === undefined
    ) {
      this.props.reqCreerGraphe(formulaireGraphe);
    } else {
      formulaireGraphe['graph_id'] = parseInt(
        this.props.preconfigurerGraphe.graph_id
      );
      this.props.reqModifierGraphe(formulaireGraphe);
    }
  };
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererAxe = (axe, etatAxe) => {
    this.setState({ ...this.state, [axe]: etatAxe });
  };
  gererProjetId = (projetId) => {
    this.setState({ ...this.state, project_id: projetId });
  };
  gererListTemplateId = (listTemplateId) => {
    this.setState({ ...this.state, list_template_id: [...listTemplateId] });
  };
  ajouterCourbe = () =>
    this.setState((prevState) => ({
      ...this.state,
      curves_conf_list: [...prevState.curves_conf_list, valeurParDefautCourbe],
    }));
  gererCourbe = (indCourbe, newConfigCourbe) => {
    let newCurves_conf_list = [...this.state.curves_conf_list];
    newCurves_conf_list[indCourbe] = newConfigCourbe;
    this.setState({ ...this.state, curves_conf_list: newCurves_conf_list });
  };
  supprimerIndiceCourbe = (indCourbe) => {
    let newCurves_conf_list = [];
    for (let courbe of this.state.curves_conf_list) {
      newCurves_conf_list.push({ ...courbe });
    }
    newCurves_conf_list.splice(indCourbe, 1);
    this.setState({ ...this.state, curves_conf_list: newCurves_conf_list });
  };
  gererConfigSelectUtsDate = (newConfigSelectUtsDate) => {
    this.setState({ ...this.state, select_time: [...newConfigSelectUtsDate] });
  };

  gereDate = (event) => {
    this.setState({ date: event.target.value });

    if (this.state.date !== null) {
      const splitDate = this.state.date.split('-');
      const splitHour = this.state.time.split(':');
      //les mois sont compris entre 0 and 11 (January to December).
      //Les heures sont comprises entre 0 et 23 (00h pour minuit et 23h)
      let datum = new Date(
        Date.UTC(
          splitDate[0],
          parseInt(splitDate[1]) - 1,
          splitDate[2],
          parseInt(splitHour[0]) - 1,
          splitHour[1],
          '00'
        )
      );
      const timeToUnix = datum.getTime();
      this.setState({ select_xaxis_manual_scalingMinX: timeToUnix / 1000 });
    } else {
      // onUpdate(null);
    }
  };
  gererDebut_time_interval = (unix) =>
    this.setState({
      ...this.state,
      time_interval: [unix, this.state.time_interval[1]],
    });
  gererFin_time_interval = (unix) =>
    this.setState({
      ...this.state,
      time_interval: [this.state.time_interval[0], unix],
    });
  gererTimeRefresh = (time_refresh) =>
    this.setState({ ...this.state, time_refresh: parseInt(time_refresh) });
  gererTimePeriod = (time_period) =>
    this.setState({ ...this.state, time_period: parseInt(time_period) });
  gererTimeZone = (time_zone) =>
    this.setState({ ...this.state, time_zone: time_zone });

  render() {
    //Fomulaire de configuration général de bar si une courbe au moins à un type === "bar"
    let formulaire_general_bar;
    for (let courbe of this.state.curves_conf_list) {
      if (courbe.type === 'bar') {
        formulaire_general_bar = (
          <div className={[s.row, S.info].join(' ')}>
            {/**DEBUT private**/}
            <div className={s.col_12}>
              {/**DEBUT private**/}
              <div className={[S.stil2].join(' ')}>
                {checkMot('mode_d_affichage_de_bar')}
              </div>
              <select
                onChange={(event) => this.gererEtatDeCibleName(event)}
                name="barmode"
                defaultValue={this.state.barmode}
                disabled={this.props.affichageProfil === true}
                value={this.state.barmode}
                className={[s.custom_select, s.mr_sm_2].join(' ')}
              >
                <option value="stack">{checkMot('stack')}</option>
                <option value="group">{checkMot('groupe')}</option>
                <option value="relative">{checkMot('relative')}</option>
              </select>
            </div>
            {/**FIN private**/}
          </div>
        );
        break;
      } else {
        formulaire_general_bar = null;
      }
    }
    return (
      <>
        <div className={S.ConteneurInfo}>
          <div className={[s.container, s.text_left].join(' ')}>
            <form onSubmit={(event) => event.preventDefault()}>
              <Collapse ExpandText={checkMot('info_general')}>
                {/**DEBUT project_id **/}
                <div className={s.form_row}>
                  <FormulaireSelectionProjet
                    affichageProfil={this.props.affichageProfil}
                    gererProjetId={this.gererProjetId}
                    preconfigurerProjetId={this.state.project_id}
                  />
                </div>
                {/**FIN project_id **/}
                <div className={s.form_group}>
                  {/**DEBUT graph_name**/}
                  <div className={[s.form_row, s.align_items_center].join(' ')}>
                    <div className={s.col_12}>
                      <label className={[S.stil2].join(' ')}>
                        {checkMot('nom_du_graphe')}
                      </label>
                      <input
                        type="text"
                        pattern="[0-9a-zA-Z._%+-\\s ]+"
                        className={
                          typeof this.state.graph_name === 'string' &&
                          this.state.graph_name.trim() === ''
                            ? [S.redchamp, s.form_control, s.mb_2].join(' ')
                            : [s.form_control, s.mb_2].join(' ')
                        }
                        placeholder="nom_du_graphe"
                        name="graph_name"
                        value={this.state.graph_name}
                        disabled={this.props.affichageProfil === true}
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                      />
                    </div>
                  </div>
                </div>
                {/**FIN graph_name**/}

                <div className={s.form_group}>
                  {/**DEBUT graph_title **/}
                  <div className={s.form_row}>
                    <div className={s.col_6}>
                      <label className={S.stil2}>
                        {checkMot('titre_du_graphe')}
                      </label>
                      <input
                        type="text"
                        pattern="[0-9a-zA-Z._%+-\\s ]+"
                        className={[s.form_control, s.mb_2].join(' ')}
                        placeholder="titre_du_graphe"
                        name="title"
                        disabled={this.props.affichageProfil === true}
                        value={this.state.title}
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                      />
                    </div>

                    <div className={s.col_6}>
                      <label className={S.stil2}>
                        {checkMot('sous_titre_du_graphe')}
                      </label>
                      <input
                        type="text"
                        pattern="[0-9a-zA-Z._%+-\\s ]+"
                        className={[s.form_control, s.mb_2].join(' ')}
                        placeholder="sous_titre_du_graphe"
                        name="subtitle"
                        disabled={this.props.affichageProfil === true}
                        value={this.state.subtitle}
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                      />
                    </div>
                  </div>
                </div>
                {/**FIN graph_title **/}
                {/**DEBUT private**/}
                <div className={[s.form_row, S.heder].join(' ')}>
                  <div className={s.col_12}>
                    <label className={S.stil2}>{checkMot('acces_prive')}</label>
                    <select
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="private"
                      defaultValue={this.state.private}
                      disabled={this.props.affichageProfil === true}
                      value={this.state.private}
                      className={[s.custom_select, S.stile].join(' ')}
                    >
                      <option value={1}>{checkMot('prive')}</option>
                      <option value={0}>{checkMot('publique')}</option>
                    </select>
                  </div>
                </div>
                {/**FIN private**/}

                <div className={[s.form_group, S.stil2].join(' ')}>
                  {/**DEBUT showlegend and showgrid**/}
                  <div className={s.form_row}>
                    <div className={s.col_6}>
                      {/**DEBUT showgrid**/}
                      <label className={S.stil2}>{checkMot('grille')}</label>
                      <select
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                        name="showgrid"
                        defaultValue={this.state.showgrid}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.showgrid}
                        className={[s.custom_select, s.mr_sm_2].join(' ')}
                      >
                        <option value={1}>
                          {checkMot('afficher_la_grille_de_fond')}
                        </option>
                        <option value={0}>
                          {checkMot('masquer_la_grille_de_fond')}
                        </option>
                      </select>
                    </div>
                    {/**FIN showgrid**/}
                    <div className={s.col_6}>
                      {/**DEBUT showlegend**/}
                      <label className={S.stil2}>
                        {checkMot('legende_du_graphe')}
                      </label>
                      <select
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                        name="showlegend"
                        defaultValue={this.state.showlegend}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.showlegend}
                        className={[s.custom_select, s.mr_sm_2].join(' ')}
                      >
                        <option value={1}>
                          {checkMot('afficher_la_legende')}
                        </option>
                        <option value={0}>
                          {checkMot('masquer_la_legende')}
                        </option>
                      </select>
                    </div>
                    {/**FIN showlegend**/}
                  </div>
                </div>
                {/**FIN showlegend and showgrid and **/}

                {
                  /*formulaire pour barmode si au moins une courbe et affiché en type bar*/ formulaire_general_bar
                }

                <div className={[s.form_group, S.stylebas].join(' ')}>
                  {/**DEBUT graph_type_id**/}
                  <label className={S.stil2}>
                    {checkMot('type_de_graphe')}
                  </label>
                  <select
                    onChange={(event) => this.gererEtatDeCibleName(event)}
                    name="graph_type_id"
                    defaultValue={this.state.graph_type_id}
                    value={this.state.graph_type_id}
                    disabled={
                      this.props.preconfigurerGraphe !== undefined ||
                      this.props.affichageProfil === true
                    }
                    className={[s.custom_select, s.mr_sm_2].join(' ')}
                  >
                    {Object.keys(glossaire_type_de_graphe).map(
                      (keyTypeDeGraphe) => {
                        if (keyTypeDeGraphe !== 'graphe_automatique') {
                          return (
                            <option
                              value={glossaire_type_de_graphe[keyTypeDeGraphe]}
                            >
                              {checkMot(keyTypeDeGraphe)}
                            </option>
                          );
                        }
                      }
                    )}
                  </select>
                </div>

                {/**FIN graph_type_id**/}
              </Collapse>
              <Collapse ExpandText={checkMot('info_avance')}>
                <div className={[S.stil2, S.stilles].join(' ')}>
                  {/*Formulaire pour l'interval de récupération des données*/}
                  <div className={s.form_group}>
                    {/**DEBUT time_interval**/}
                    <div
                      className={[s.form_row, s.align_items_center].join(' ')}
                    >
                      <label className={[s.col_12, S.news].join(' ')}>
                        {checkMot(
                          'interval_de_recuperation_de_donnees_debut_fin'
                        )}
                      </label>
                      <div className={[s.col_6, s.card_body, S.news].join(' ')}>
                        <FormulaireSelectionDateHeureUnix
                          value={this.state.time_interval[0]}
                          gereUnixDate={this.gererDebut_time_interval}
                          disabled={this.props.affichageProfil === true}
                        />
                      </div>
                      <div className={[s.col_6, s.card_body, S.news].join(' ')}>
                        <FormulaireSelectionDateHeureUnix
                          value={this.state.time_interval[1]}
                          gereUnixDate={this.gererFin_time_interval}
                          disabled={this.props.affichageProfil === true}
                        />
                      </div>
                    </div>
                  </div>
                  {/**FIN time_interval**/}

                  <div className={[S.sti1].join(' ')}>
                    <div className={s.form_group}>
                      {/**DEBUT time_refresh**/}
                      <div className={s.row}>
                        <div className={s.col_12}>
                          <label className={S.stil2}>
                            {checkMot('temps_apres_actualisation')}
                          </label>
                          <FormulaireSaisieDuree
                            affichageProfil={this.props.affichageProfil}
                            gererDuree={this.gererTimeRefresh}
                            preconfigurerDuree={this.state.time_refresh}
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN time_refresh**/}
                    <div className={s.form_group}>
                      {/**DEBUT time_period**/}
                      <div className={s.form_row}>
                        <div className={s.col_12}>
                          <label className={S.stil2}>
                            {checkMot(
                              'recuperation_des_donnees_sur_le_dernier_interval_de_temps_jusqu_a_present'
                            )}
                            <HoverButton
                              customClass={S.btn_stile}
                              imageUrl={help}
                            />
                          </label>
                          <FormulaireSaisieDuree
                            affichageProfil={this.props.affichageProfil}
                            gererDuree={this.gererTimePeriod}
                            preconfigurerDuree={this.state.time_period}
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN time_period**/}
                    <div className={s.form_group}>
                      {/**DEBUT measures_num**/}
                      <div className={s.form_row}>
                        <div className={s.col_12}>
                          <label className={S.stil2}>
                            {checkMot(
                              'recuperation_des_n_derniere_donnees_jusqu_a_present'
                            )}
                            <HoverButton
                              customClass={S.btn_stile}
                              imageUrl={helpB}
                            />
                          </label>
                          <input
                            type="text"
                            pattern="[0-9]+"
                            className={[s.form_control, s.mb_2].join(' ')}
                            placeholder="measures_num"
                            name="measures_num"
                            value={this.state.measures_num}
                            disabled={this.props.affichageProfil === true}
                            required
                            onChange={(event) =>
                              this.gererEtatDeCibleName(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN measures_num**/}
                    {/**DEBUT time_zone**/}
                    <div className={s.form_group}>
                      {/**DEBUT measures_num**/}
                      <div className={s.form_row}>
                        <div className={s.col_12}>
                          <FormulaireSelectionTimeZone
                            affichageProfil={this.props.affichageProfil}
                            gererTimeZone={this.gererTimeZone}
                            preconfigurerTimeZone={this.state.time_zone}
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN time_zone**/}
                    <div className={s.form_group}>
                      {/**DEBUT cumul_interval && cumul_num**/}
                      <div className={s.form_row}>
                        <div className={s.col_5}>
                          <label className={S.stil2}>
                            {checkMot('cumul_interval')}
                            <HoverButton
                              customClass={S.btn_stile}
                              texte={checkMot(
                                'Ce champ spécifie l\'intervalle de temps pour le cumul des données. Les valeurs possibles sont : "heure", "jour", "semaine", "mois", "année". La valeur par défaut est une chaîne vide ("").'
                              )}
                            />
                          </label>
                          <select
                            onChange={(event) =>
                              this.gererEtatDeCibleName(event)
                            }
                            name="cumul_interval"
                            defaultValue={this.state.cumul_interval}
                            disabled={this.props.affichageProfil === true}
                            value={this.state.cumul_interval}
                            className={[s.custom_select, s.mr_sm_2].join(' ')}
                          >
                            <option value="">{checkMot('selectionne')}</option>
                            <option value="heure">{checkMot('heure')}</option>
                            <option value="jour">{checkMot('jour')}</option>
                            <option value="semaine">
                              {checkMot('semaine')}
                            </option>
                            <option value="mois">{checkMot('mois')}</option>
                            <option value="annee">{checkMot('annee')}</option>
                          </select>
                        </div>
                        <div className={s.col_7}>
                          <label className={S.stil2}>
                            {checkMot('nombre_de_periodes_de_cumul')}
                            <HoverButton
                              customClass={S.btn_stile}
                              texte={checkMot(
                                'Ce champ indique le nombre de périodes pour lesquelles les données seront cumulées. Si la valeur est positive, elle représente le nombre de périodes passées à inclure dans le cumul (par exemple, 2 pour les deux dernières semaines si "cumul_interval" est défini sur \'semaine\'). Si la valeur est négative, elle représente le nombre de périodes futures à inclure dans le cumul. La valeur par défaut est nulle.'
                              )}
                            />
                          </label>
                          <input
                            type="text"
                            pattern="[0-9]+"
                            className={[s.form_control, s.mb_2].join(' ')}
                            placeholder="cumul_num"
                            name="cumul_num"
                            value={this.state.cumul_num}
                            disabled={this.props.affichageProfil === true}
                            required
                            onChange={(event) =>
                              this.gererEtatDeCibleName(event)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN cumul_interval && cumul_num**/}
                    <div className={s.form_group}>
                      {/**DEBUT select_time**/}
                      <div className={s.form_row}>
                        <div className={s.col_12}>
                          <label className={S.stil2}>
                            {checkMot(
                              'recuperation_des_donnees_des_dates_selectionnees'
                            )}
                          </label>
                          <FormulaireSelectUtsDate
                            preConfigSelectUtsDate={this.state.select_time}
                            gererConfigSelectUtsDate={
                              this.gererConfigSelectUtsDate
                            }
                            disabled={this.props.affichageProfil === true}
                          />
                        </div>
                      </div>
                    </div>
                    {/**FIN select_time**/}
                  </div>

                  {/*Formulaire de configuration du graphe en général*/}
                </div>
              </Collapse>
              <Collapse ExpandText={checkMot('parametre_de_axe')}>
                <div className={[s.col_12].join(' ')}>
                  <FormulaireAxe
                    gererConfigAxe={this.gererAxe}
                    axisKey={'xaxis'}
                    axisName={checkMot('xaxis')}
                    preConfigAxe={{
                      ...this.state.xaxis,
                      type: this.state.xaxis_type, //Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                    }}
                    disabled={this.props.affichageProfil === true}
                  >
                    {/**DEBUT xaxis_type**/}
                    <div className={s.col_12}>
                      <label className={S.stil2}>
                        {checkMot('xaxis_type')}
                      </label>
                      <select
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                        name="xaxis_type"
                        defaultValue={this.state.xaxis_type}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.xaxis_type}
                        className={[s.custom_select, S.stile].join(' ')}
                      >
                        <option value="date">{checkMot('Date')}</option>
                        <option value="linear">{checkMot('Lineaire')}</option>
                        <option value="log">{checkMot('Logarithmique')}</option>
                      </select>
                    </div>
                    {/**FIN xaxis_type**/}
                  </FormulaireAxe>
                </div>
                <div className={[s.col_12].join(' ')}>
                  <FormulaireAxe
                    gererConfigAxe={this.gererAxe}
                    axisKey={'yaxis'}
                    axisName={checkMot('yaxis')}
                    preConfigAxe={{
                      ...this.state.yaxis,
                      type: this.state.yaxis_type, //Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                    }}
                    disabled={this.props.affichageProfil === true}
                  >
                    {/**DEBUT yaxis_type**/}
                    <div className={s.col_12}>
                      <label className={S.stil2}>
                        {checkMot('yaxis_type')}
                      </label>
                      <select
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                        name="yaxis_type"
                        defaultValue={this.state.yaxis_type}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.yaxis_type}
                        className={[s.custom_select, S.stile].join(' ')}
                      >
                        <option value="date">{checkMot('Date')}</option>
                        <option value="linear">{checkMot('Lineaire')}</option>
                        <option value="log">{checkMot('Logarithmique')}</option>
                      </select>
                    </div>
                    {/**FIN yaxis_type**/}
                  </FormulaireAxe>
                </div>
                <div className={[s.col_12].join(' ')}>
                  <FormulaireAxe
                    gererConfigAxe={this.gererAxe}
                    axisKey={'yaxis2'}
                    axisName={checkMot('yaxis2')}
                    preConfigAxe={{
                      ...this.state.yaxis2,
                      type: this.state.yaxis2_type, //Force l'axe à être linéaire, laisse la possibilité de changer le type de l'axe pour une application future
                    }}
                    disabled={this.props.affichageProfil === true}
                  >
                    {/**DEBUT yaxis2_type**/}
                    <div className={s.col_12}>
                      <label className={S.stil2}>
                        {checkMot('yaxis2_type')}
                      </label>
                      <select
                        onChange={(event) => this.gererEtatDeCibleName(event)}
                        name="yaxis2_type"
                        defaultValue={this.state.yaxis2_type}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.yaxis2_type}
                        className={[s.custom_select, S.stile].join(' ')}
                      >
                        <option value="date">{checkMot('Date')}</option>
                        <option value="linear">{checkMot('Lineaire')}</option>
                        <option value="log">{checkMot('Logarithmique')}</option>
                      </select>
                    </div>
                    {/**FIN yaxis2_type**/}
                  </FormulaireAxe>
                </div>
              </Collapse>
              {parseInt(this.state.graph_type_id) ===
                glossaire_type_de_graphe.graphe_x_y_t ||
              parseInt(this.state.graph_type_id) ===
                glossaire_type_de_graphe.graphe_x_y ? (
                <Collapse ExpandText={checkMot('parametre_gabarit')}>
                  <FormulaireSelectionGabarits
                    preconfigurerListTemplateId={this.state.list_template_id}
                    gererListTemplateId={this.gererListTemplateId}
                    desactiverModification={this.props.affichageProfil === true}
                  />
                </Collapse>
              ) : null}
              <Collapse ExpandText={checkMot('parametre_courbe')}>
                {/*liste de formulaireCourbe*/}
                {/*bouton pour ajouter une courbe*/}
                {this.state.listeFormulaireCourbe}
                {this.props.affichageProfil === true ? null : (
                  <>
                    <Bouton
                      type={type_btn.succes}
                      ajoutClass={S.addCurve}
                      cliquer={(e) => {
                        e.preventDefault();
                        this.ajouterCourbe();
                      }}
                    >
                      <AiFillPlusCircle size={16} /> courbe
                    </Bouton>
                    <br />
                  </>
                )}
              </Collapse>
              {this.props.affichageProfil === true ? undefined : (
                <div className={s.form_group}>
                  <div
                    className={[s.d_flex, s.justify_content_between].join(' ')}
                  >
                    <div>
                      <Bouton
                        cliquer={(e) => {
                          e.preventDefault();
                          this.props.annuler();
                        }}
                        type={type_btn.danger}
                      >
                        {checkMot('annuler')}
                      </Bouton>
                    </div>
                    <div>
                      {this.props !== undefined &&
                      this.props.preconfigurerGraphe === undefined ? (
                        <AttenteRequete
                          request_type={requestType.creer_un_graphe}
                          str={checkMot('creation_en_cour')}
                        >
                          <Bouton
                            ajoutClass={S.Bts}
                            desactive={
                              !this.state.graph_name ||
                              this.state.graph_name.trim() === ''
                            }
                            type={
                              !this.state.graph_name ||
                              this.state.graph_name.trim() === ''
                                ? type_btn.btnDisable
                                : type_btn.succes
                            }
                            cliquer={() => this.gererEnvoi()}
                          >
                            <AiOutlineCheck /> {checkMot('creer')}
                          </Bouton>
                        </AttenteRequete>
                      ) : (
                        <AttenteRequete
                          request_type={requestType.modifier_un_graphe}
                          str={checkMot('modification_en_cour')}
                        >
                          <Bouton
                            cliquer={() => this.gererEnvoi()}
                            type={type_btn.succes}
                          >
                            {checkMot('enregistrer')}
                          </Bouton>
                        </AttenteRequete>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqCreerGraphe: (formulaireGrapheT_X) =>
      dispatch(actions.reqCreerGraphe(formulaireGrapheT_X)),
    reqModifierGraphe: (formulaireGrapheT_X) =>
      dispatch(actions.reqModifierGraphe(formulaireGrapheT_X)),
  };
};

export default connect(null, mapDispatchToProps)(FormulaireGraphe);

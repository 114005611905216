export const visualiser_ses_projets = 1000;
export const recuperer_la_liste_des_graphes = 1100;

export const visualiser_listes_des_alarmes_associe_aux_projets = 2000;
export const confirmer_alarme = 2001;
export const modifier_seuils = 2002;

export const acces_a_l_interface_admin = 3000;
export const visualiser_listes_des_capteurs_associe_aux_projets = 3001;
export const visualiser_listes_des_utilisateurs_associe_aux_projets = 3002;

export const creer_des_visiteurs = 3100;
export const modifier_des_visiteurs = 3101;
export const supprimer_des_visiteurs = 3102;

export const creer_des_clients = 3110;
export const modifier_des_clients = 3111;
export const supprimer_des_clients = 3112;

export const creer_des_projets = 3200;
export const modifier_des_projets = 3201;
export const supprimer_des_projets = 3202;

export const creer_des_capteurs = 3300;
export const modifier_des_capteurs = 3301;
export const supprimer_des_capteurs = 3302;

export const creer_des_sensor_types = 3310;
export const modifier_des_sensor_types = 3311;
export const supprimer_des_sensor_types = 3312;
export const recuperer_list_sensor_type = 3313;

export const creer_des_graphes = 3400;
export const modifier_des_graphes = 3401;
export const supprimer_des_graphes = 3402;

export const creer_des_vues = 3500;
export const modifier_des_vues = 3501;
export const supprimer_des_vues = 3502;

export const creer_des_groupes_emails = 3600;
export const modifier_des_groupes_emails = 3601;
export const supprimer_des_groupes_emails = 3602;
export const recuperer_listes_groupes_emails = 3603;

export const creer_des_alarmes = 3700;
export const modifier_des_alarmes = 3701;
export const supprimer_des_alarmes = 3702;

export const creer_des_gabarits = 3800;
export const modifier_des_gabarits = 3801;
export const supprimer_des_gabarits = 3802;

export const creer_des_rapports_gabarits = 3802;
export const modifier_des_rapports_gabarits = 3802;
export const supprimer_des_rapports_gabarits = 3802;
export const generer_des_rapports = 3802;
export const modifier_des_rapports_generes = 3802;
export const supprimer_des_rapports_generes = 3802;
export const publier_des_rapports = 3802;

// export const creer_des_rapports_gabarits = 3900;
// export const modifier_des_rapports_gabarits = 3901;
// export const supprimer_des_rapports_gabarits = 3902;
// export const generer_des_rapports = 3910;
// export const modifier_des_rapports_generes = 3911;
// export const supprimer_des_rapports_generes = 3912;
// export const publier_des_rapports = 3920;

export const acces_a_tous_les_projets = 4000;

export const creer_des_admins = 4100;
export const modifier_des_admins = 4101;
export const supprimer_des_admins = 4102;
export const creer_des_supers_admins = 5100;
export const modifier_des_supers_admins = 5101;
export const supprimer_des_supers_admins = 5102;

import { store } from '../../index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import {
  getAllUsers,
  createUser,
  updateUser,
  deleteUser,
  getUser,
} from '../api';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import { affichageRetourErreurs } from '../utils/utils';

export const reqAjouterUtilisateur = (formulaireCreerUtilisateur) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.creation_d_un_utilisateur,
      formulaireCreerUtilisateur
    );
  };
};
export const reqModifierUtilisateur = (formulaireDeModificationUtilisateur) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modification_d_un_utilisateur,
      formulaireDeModificationUtilisateur
    );
  };
};
export const reqSupprimerUtilisateur = (user_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_utilisateur, { user_id });
  };
};
export const reqRecupererListeUtilisateurs = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_de_la_liste_des_utilisateurs
    );
  };
};
export const reqRecupererUnUtilisateur = (user_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_d_un_utilisateur, {
      user_id,
    });
  };
};
const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */
  dispatch(actions.debutAttente(requestId));
  try {
    let response;
    switch (requestId) {
      case requestType.creation_d_un_utilisateur:
        response = await createUser(payload);
        if (response.data.state) {
          if (response.data.user_id > 0) {
            dispatch(actions.reqRecupererListeUtilisateurs());
          }
          return;
        }
        break;
      case requestType.modification_d_un_utilisateur:
        response = await updateUser(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeUtilisateurs());
          return;
        }

        break;
      case requestType.recuperation_d_un_utilisateur:
        response = await getUser(payload);
        if (response.data.state) {
          const state = store.getState();
          if (state.authentifie.user_id === null) {
            // || state.authentifie.user_id===res.data.user_id){
            dispatch(actions.enregistrerUtilisateur(response.data));
          }
          return;
        }
        break;
      case requestType.supprimer_un_utilisateur:
        response = await deleteUser(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeUtilisateurs());
          return;
        }
        break;
      case requestType.recuperation_de_la_liste_des_utilisateurs:
        response = await getAllUsers(payload);
        if (response.data.state) {
          dispatch(enregistrerListeUtilisateurs(response.data.users_list));
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
    // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

const enregistrerListeUtilisateurs = (users_liste) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_UTILISATEURS,
    utilisateursListe: users_liste,
  };
};

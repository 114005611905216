export const jsDictionaryEquals = (dictionary1, dictionary2) => {
  // Si les deux références sont identiques, retourne true immédiatement
  if (dictionary1 === dictionary2) return true;

  // Si l'un des deux est null ou pas un objet, on compare simplement les valeurs
  if (
    typeof dictionary1 !== 'object' ||
    dictionary1 === null ||
    typeof dictionary2 !== 'object' ||
    dictionary2 === null
  ) {
    return dictionary1 === dictionary2;
  }

  // Fonction pour comparer des objets de manière sécurisée
  const compareObjects = (obj1, obj2, visited = new WeakMap()) => {
    // Vérification de références identiques
    if (obj1 === obj2) return true;

    // Vérification des types non-objets
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    ) {
      return obj1 === obj2;
    }

    // Détection des références circulaires
    if (visited.has(obj1)) {
      return visited.get(obj1) === obj2;
    }

    // Marquer cet objet comme visité
    visited.set(obj1, obj2);

    // Comparaison des tableaux
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;

      for (let i = 0; i < obj1.length; i++) {
        if (!compareObjects(obj1[i], obj2[i], visited)) {
          return false;
        }
      }

      return true;
    }

    // Récupération des clés des deux objets
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        !key.startsWith('_') && // Ignorer les propriétés internes de React
        typeof obj1[key] !== 'function'
    ); // Ignorer les méthodes

    const keys2 = Object.keys(obj2).filter(
      (key) => !key.startsWith('_') && typeof obj2[key] !== 'function'
    );

    // Si le nombre de propriétés est différent, les objets sont différents
    if (keys1.length !== keys2.length) return false;

    // Vérifier chaque propriété
    return keys1.every((key) => {
      if (!keys2.includes(key)) return false;
      return compareObjects(obj1[key], obj2[key], visited);
    });
  };

  return compareObjects(dictionary1, dictionary2);
};

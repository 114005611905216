import * as actionTypes from './actionTypes';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actions from './index';
import { store } from '../../index';
import CryptoJS from 'crypto-js';
import {
  authentication,
  logout,
  requestPasswordChange,
  askForPasswordReset,
  validateCodeVerification,
  resetPassword,
  checkConnexion,
} from '../api';
import { affichageRetourErreurs } from '../utils/utils';

export const intervalDeTempsDemandeInfoConnexionApresConnexion = 14400000;

const requestBack = async (dispatch, requestId, payload) => {
  dispatch(actions.debutAttente(requestId));
  try {
    let response;
    switch (requestId) {
      case requestType.connexion:
        response = await authentication(payload);
        // response = {
        //   data: {
        //     errors: [],
        //     privileges_list: [
        //       1000, 3000, 3001, 3002, 3100, 3101, 3102, 3110, 3111, 3112, 3200,
        //       3201, 3202, 3300, 3301, 3302, 3310, 3311, 3312, 3313, 4000, 4100,
        //       4101, 4102, 3400, 3401, 3402, 3600, 3601, 3602, 3603, 3700, 3701,
        //       3702, 2000, 2001, 2002, 3500, 3502, 3501, 3800, 3801, 3802, 1100,
        //     ],
        //     projects_list: [
        //       {
        //         project_id: 33,
        //         parent_id: 33,
        //         project_name: 'Projet de Test - Team d\u00e9veloppeur ',
        //         groupe: 'L\u00e9on / Alfredo / Mimoun / Mohamed',
        //         description: 'TOTI',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_33.jpg',
        //         users_list_id: [146, 172],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 35,
        //         parent_id: null,
        //         project_name: 'Projet de Test - V\u00e9rification valeur',
        //         groupe: 'Op\u00e9ration - Mat\u00e9riel',
        //         description: '',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_35.jpg',
        //         users_list_id: [146, 172],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 58,
        //         parent_id: null,
        //         project_name: 'Arrosage',
        //         groupe: 'Arrosage',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [1, 97, 145, 146, 290, 437],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 59,
        //         parent_id: null,
        //         project_name: 'Projet de d\u00e9monstration - Smart City',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [1, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 60,
        //         parent_id: null,
        //         project_name: 'Cath\u00e9drale de Beauvais - ND',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_60.jpg',
        //         users_list_id: [122, 138, 143, 145, 155, 290],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 61,
        //         parent_id: null,
        //         project_name: '20 Ans Groupe Village des athl\u00e8tes',
        //         groupe: '',
        //         description: 'Projet de test - Maxime Meru',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 104, 155, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 62,
        //         parent_id: 69,
        //         project_name: '[AO] Gestionnaire r\u00e9seau',
        //         groupe: 'AO Smart-city',
        //         description: '',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_62.jpg',
        //         users_list_id: [97, 145, 290, 337, 360, 437],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 63,
        //         parent_id: 69,
        //         project_name: '[AO] Smart-city',
        //         groupe: 'AO Smart-city',
        //         description: '',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_63.jpg',
        //         users_list_id: [97, 145, 290, 337, 360, 437],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 64,
        //         parent_id: 69,
        //         project_name: '[AO] Retouche - Visualisation ',
        //         groupe: 'AO Smart-city',
        //         description: '',
        //         active: 0,
        //         project_image: null,
        //         users_list_id: [97, 145, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 65,
        //         parent_id: 69,
        //         project_name: '[AO] Multifluides',
        //         groupe: 'AO Smart-city',
        //         description: '',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_65.jpg',
        //         users_list_id: [97, 145, 290, 337, 360, 437],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 66,
        //         parent_id: null,
        //         project_name: '[Pr\u00e9sentation] Mesil Tunnelier',
        //         groupe: 'Sp',
        //         description: 'RTE',
        //         active: 0,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 143, 145, 150, 154, 237, 296, 312,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 69,
        //         parent_id: null,
        //         project_name: '[AO] Projet - D\u00e9monstration',
        //         groupe: 'AO Smart-city',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 145, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 70,
        //         parent_id: null,
        //         project_name: 'Projet L18',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 143, 145, 154, 155, 290, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 71,
        //         parent_id: null,
        //         project_name: 'Projet d\u00e9monstration EDF',
        //         groupe: 'Spie Batignolles',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 110, 143, 145, 154, 237, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 72,
        //         parent_id: null,
        //         project_name: 'RTE Mesil',
        //         groupe: 'Spie Batignolles',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 143, 145, 150, 154, 237, 296, 312,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 73,
        //         parent_id: null,
        //         project_name: 'Cath\u00e9drale de Beauvais',
        //         groupe: 'Minist\u00e8re de la culture ',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 138, 142, 143, 145, 146, 150, 154, 156, 222, 296, 298,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 74,
        //         parent_id: null,
        //         project_name: 'Bernes - Rotoforeuse',
        //         groupe: 'Spie Batignolles Fondations',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [49, 97, 122, 143, 145, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 75,
        //         parent_id: null,
        //         project_name: 'Consommation \u00e9lectrique SB',
        //         groupe: 'Spie batignolles',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 76,
        //         parent_id: null,
        //         project_name: "[AO] Compteur d'eau ARMAGNAC",
        //         groupe: 'S.A.T',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 77,
        //         parent_id: null,
        //         project_name: 'BNF',
        //         groupe: 'Spie batignolles Energie',
        //         description:
        //           "Mesure d'humidit\u00e9 de la biblioth\u00e8que nationale de France",
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 126, 127, 128, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 79,
        //         parent_id: null,
        //         project_name: 'Spie Batignolles Malet - Traceur',
        //         groupe: 'Spie Batignolles Malet',
        //         description: null,
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 145, 276, 291, 292, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 81,
        //         parent_id: null,
        //         project_name: 'Consommation \u00e9lectrique SBE',
        //         groupe: 'Spie batignolles Energie',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 129, 130, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 82,
        //         parent_id: null,
        //         project_name: 'Consommation si\u00e8ges SB',
        //         groupe: 'Spie batignolles',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 131, 132, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 83,
        //         parent_id: null,
        //         project_name: 'Consommation \u00e9lectrique SBF',
        //         groupe: 'Spie batignolles Fondations',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 133, 143, 145, 279],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 84,
        //         parent_id: null,
        //         project_name: 'Consommation \u00e9lectrique SBV',
        //         groupe: 'Spie batignolles Val\u00e9rian',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 134, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 86,
        //         parent_id: null,
        //         project_name: 'Metro de Lisboa Lot2',
        //         groupe: 'Metro de Lisboa Lot2',
        //         description:
        //           'Programme G\u00e9n\u00e9ral d\u2019Auscultations et Plans \u2013 M\u00e9tro Santos- Cais do Sodr\u00e9 ',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_86.jpg',
        //         users_list_id: [
        //           97, 101, 110, 122, 138, 143, 145, 150, 154, 155, 172, 202,
        //           222, 237, 266, 294, 296, 298, 378,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 87,
        //         parent_id: null,
        //         project_name:
        //           'H\u00f4tel Op\u00e9ra Cadet - Suivi consommation ',
        //         groupe: 'Spie Batignolles IDF',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [1, 97, 141, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 88,
        //         parent_id: null,
        //         project_name: 'CNPE Tricastin - Auscultations',
        //         groupe: 'Spie Batignolles Fondations',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [80, 97, 143, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 89,
        //         parent_id: null,
        //         project_name: 'Solideo : Village des athl\u00e8tes',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 143, 145, 146, 337],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 90,
        //         parent_id: null,
        //         project_name: 'Outarex : Village des athl\u00e8tes',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           80, 95, 97, 138, 143, 145, 146, 148, 149, 152, 153, 154, 155,
        //           170, 176, 177, 178, 179, 180, 295, 337,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 91,
        //         parent_id: null,
        //         project_name: 'Carri\u00e8re de meudon',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 145, 150, 151, 154, 222, 241, 242, 267, 296,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 92,
        //         parent_id: 95,
        //         project_name: 'Tour Triangle - Acoustique et vibration',
        //         groupe: 'Besix',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 143, 150, 154, 155, 172, 222, 298, 306,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 93,
        //         parent_id: 95,
        //         project_name: 'Tour Triangle - Auscultation - Surface',
        //         groupe: 'Besix',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 138, 142, 145, 146, 150, 172, 199, 200, 222, 234,
        //           235, 237, 296, 298, 306, 361,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 94,
        //         parent_id: 95,
        //         project_name: 'Tour Triangle - Topographie RATP',
        //         groupe: 'Besix',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 138, 142, 143, 145, 150, 172, 199, 200, 222, 234,
        //           235, 237, 259, 260, 261, 266, 296, 298, 306, 361,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 95,
        //         parent_id: null,
        //         project_name: 'Tour triangle',
        //         groupe: 'Besix',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 138, 143, 145, 150, 155, 237, 296, 298,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 96,
        //         parent_id: null,
        //         project_name: 'Centrale ONERA',
        //         groupe: '',
        //         description: 'Centrale ONERA',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 145, 150, 172, 237, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 97,
        //         parent_id: null,
        //         project_name: 'H\u00f4pital Limeil Brevannes',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 143, 145, 146, 150, 157, 158, 159, 160, 169, 296, 337,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 98,
        //         parent_id: null,
        //         project_name: 'GCC : Village des athl\u00e8tes',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 138, 143, 145, 152, 155, 161, 162, 163, 164, 165, 166,
        //           177, 178, 179, 180, 270, 271, 337,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 99,
        //         parent_id: null,
        //         project_name: 'Lafayette',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 142, 143, 155, 296, 338, 339, 340, 341, 342,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 101,
        //         parent_id: null,
        //         project_name: 'Contournement de Modane',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 138, 142, 143, 145, 150, 155, 171, 172, 173,
        //           174, 175, 201, 222, 236, 237, 296, 298, 327, 387,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 102,
        //         parent_id: null,
        //         project_name: 'SB G\u00e9nie civil',
        //         groupe: 'Spie Batignolles GC',
        //         description:
        //           'Parc machine de Spie Batignolles G\u00e9nie civil',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 143, 376, 393],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 103,
        //         parent_id: null,
        //         project_name: 'GTC DEMO',
        //         groupe: '',
        //         description:
        //           'D\u00e9monstration de visualisations pour Gestion Technique Centralis\u00e9e',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 104,
        //         parent_id: 103,
        //         project_name: ' Comptage',
        //         groupe: '',
        //         description: 'Comptage GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 105,
        //         parent_id: 103,
        //         project_name: ' Surveillance   atmospherique',
        //         groupe: '',
        //         description: 'surveillance atmosph\u00e9rique',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 294],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 106,
        //         parent_id: 103,
        //         project_name: ' Station de pompage',
        //         groupe: '',
        //         description: 'station de pompage',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 107,
        //         parent_id: 103,
        //         project_name: ' Groupes electrogenes',
        //         groupe: '',
        //         description: 'groupes \u00e9lectrog\u00e8nes',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 108,
        //         parent_id: 103,
        //         project_name: ' Mesures exterieures',
        //         groupe: '',
        //         description: 'mesures ext\u00e9rieures',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 109,
        //         parent_id: 103,
        //         project_name: ' Auscultations',
        //         groupe: '',
        //         description: 'Auscultations GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 110,
        //         parent_id: 103,
        //         project_name: ' Ventilation',
        //         groupe: '',
        //         description: 'Ventilation GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 111,
        //         parent_id: 103,
        //         project_name: ' Refroidissement',
        //         groupe: '',
        //         description: 'Refroidissement GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 112,
        //         parent_id: 103,
        //         project_name: ' Batteries TSP',
        //         groupe: '',
        //         description: 'Batteries TSP  GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 113,
        //         parent_id: 103,
        //         project_name: ' Convoyeurs',
        //         groupe: '',
        //         description: 'Convoyeurs  GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 114,
        //         parent_id: 103,
        //         project_name: ' Tunneliers',
        //         groupe: '',
        //         description: 'Tunneliers  GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 115,
        //         parent_id: 103,
        //         project_name: ' WORM',
        //         groupe: '',
        //         description: 'WORM GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 116,
        //         parent_id: 103,
        //         project_name: ' Systeme informatique',
        //         groupe: '',
        //         description: 'Syst\u00e8me informatique GTC',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 118,
        //         parent_id: null,
        //         project_name: 'Spie Batignolles Fondation - Traceurs',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 143, 146, 276, 277, 278, 279, 280],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 119,
        //         parent_id: null,
        //         project_name: 'ARC T3C Eiffage',
        //         groupe: 'Eiffage',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 142, 143, 155, 181, 182, 183, 296, 394,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 120,
        //         parent_id: null,
        //         project_name:
        //           '\u00c9glise Sainte-Anne Butte aux cailles - Paris 13',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           94, 97, 110, 138, 142, 143, 145, 150, 222, 224, 225, 226, 227,
        //           228, 229, 230, 231, 232, 233, 249, 250, 251, 252, 253, 254,
        //           255, 256, 257, 296, 298, 314,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 121,
        //         parent_id: null,
        //         project_name: 'Formation ARGOS V19',
        //         groupe: 'Formation',
        //         description: 'Tutoriel pour la formation de configuration',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_121.jpg',
        //         users_list_id: [
        //           94, 96, 97, 110, 122, 138, 143, 155, 172, 237, 290, 296,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 124,
        //         parent_id: 79,
        //         project_name: 'C31 MERCEDES 6X4 BENNE',
        //         groupe: '',
        //         description: 'Machine C31',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 145, 146, 276, 277, 291, 292, 296, 337, 392,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 125,
        //         parent_id: 79,
        //         project_name: 'C404 VOLVO 8X4 BENNE',
        //         groupe: 'NC',
        //         description: 'Machine C404',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 126,
        //         parent_id: 79,
        //         project_name: 'C46 RENAULT 6X4 POLYBENNE ',
        //         groupe: 'NC',
        //         description: 'Machine C46',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 127,
        //         parent_id: 79,
        //         project_name: 'CG102A MAN 6X4 BENNE GRAVILLONNEUR ',
        //         groupe: 'MG',
        //         description: 'Machine CG102A',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 128,
        //         parent_id: 79,
        //         project_name: 'CG109 MERCEDES 6X4 GRAVILLONNEUR',
        //         groupe: 'MG',
        //         description: 'Machine CG109',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 129,
        //         parent_id: 79,
        //         project_name: 'CR38 MERCEDES CAMION REPANDEUR ACMAR 625A ',
        //         groupe: 'DD',
        //         description: 'Machine CR38',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 130,
        //         parent_id: 79,
        //         project_name: 'F103 VOGELE MINI FINISSEUR 5T ',
        //         groupe: 'OD',
        //         description: 'Machine F103',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 131,
        //         parent_id: 79,
        //         project_name: 'F51 ABG FINISSEUR 17T BLANC ',
        //         groupe: 'GG',
        //         description: 'Machine F51',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 132,
        //         parent_id: 79,
        //         project_name: 'F02A ABG FINISSEUR 17T STANDARD',
        //         groupe: 'PT',
        //         description: 'Machine F55',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 133,
        //         parent_id: 79,
        //         project_name: 'F56 ABG FINISSEUR 20T',
        //         groupe: 'GG',
        //         description: 'Machine F56',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 134,
        //         parent_id: 79,
        //         project_name: 'F57 ABG FINISSEUR 17T ',
        //         groupe: 'OD',
        //         description: 'Machine F57',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 135,
        //         parent_id: 79,
        //         project_name: 'F58 ABG FINISSEUR 17T STANDARD',
        //         groupe: 'DD',
        //         description: 'Machine F58',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 136,
        //         parent_id: 79,
        //         project_name: 'F62 ABG FINISSEUR 17T STANDARD ',
        //         groupe: 'MG',
        //         description: 'Machine F62',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 137,
        //         parent_id: 79,
        //         project_name: 'L65 CATERPILLAR CHARGEURS/PNEUS 220CV',
        //         groupe: 'JMR',
        //         description: 'Machine L65',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 138,
        //         parent_id: 79,
        //         project_name: 'M04 CATERPILLAR MOTOSCRAPERS 17 M3 AUTO ',
        //         groupe: 'OD',
        //         description: 'Machine M04',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 139,
        //         parent_id: 79,
        //         project_name: 'P249 MECALAC PELLE CHARGEUSE 80CV ',
        //         groupe: 'NC',
        //         description: 'Machine P249',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 140,
        //         parent_id: 79,
        //         project_name: 'RTV37 DOUB VIB TANDEM 1.4M 7T VT1 ',
        //         groupe: 'NC',
        //         description: 'Machine RTV37',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 141,
        //         parent_id: 79,
        //         project_name: 'RTV511 BOMAG DOUB VIB TANDEM 1,5M 10T AM VT0-2 ',
        //         groupe: 'DD',
        //         description: 'Machine RTV511',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 142,
        //         parent_id: 79,
        //         project_name: 'RV66 BOMAG VIBRANT MONOBILLE VM0-VM5',
        //         groupe: 'OD',
        //         description: 'Machine RV66',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 143,
        //         parent_id: null,
        //         project_name: 'Spie Batignolles Sogecer - Traceur',
        //         groupe: '',
        //         description: 'Parc machine de Spie batignolles Sogecer',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 276, 277, 291, 292],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 144,
        //         parent_id: 143,
        //         project_name: 'MCG02T MILLER MACHINE COFFRAGE GLISSANT',
        //         groupe: 'MK',
        //         description: 'Machine MCG02T',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 145,
        //         parent_id: 143,
        //         project_name: 'MCG03T WIRTGEN MACHINE COFFRAGE GLISSANT ',
        //         groupe: 'MK',
        //         description: 'Machine MCG03T',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 146,
        //         parent_id: null,
        //         project_name: 'Villa Durand Saint Cloud',
        //         groupe: 'Spie Batignolles Fondations',
        //         description: 'Consolidation des fondations de la Villa Durand',
        //         active: 1,
        //         project_image:
        //           './images/profiles/projects/project_picture_146.jpg',
        //         users_list_id: [97, 110, 145, 150, 172, 237, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 147,
        //         parent_id: 166,
        //         project_name: 'RECAPE',
        //         groupe: '',
        //         description:
        //           'Mapas de monitoriza\u00e7\u00e3o de Recursos H\u00eddricos e Vibra\u00e7\u00f5es',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 101, 122, 138, 150, 155, 192, 198, 202, 258, 266, 343,
        //           386,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 148,
        //         parent_id: null,
        //         project_name:
        //           'Construction du centre sportif et de loisirs Hunebelle',
        //         groupe: 'itmsol',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 138, 142, 143, 145, 150, 155, 172, 222, 237,
        //           264, 272, 274, 290, 296, 298, 379,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 149,
        //         parent_id: 103,
        //         project_name: 'Vid\u00e9o Surveillance ',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 151,
        //         parent_id: null,
        //         project_name: 'Lyc\u00e9e Voillaume',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 143, 145, 150, 265, 269, 296, 337],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 152,
        //         parent_id: 154,
        //         project_name: 'Bases radios',
        //         groupe: 'Maintenance',
        //         description: 'Surveille les bases radios',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 146, 150],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 153,
        //         parent_id: 154,
        //         project_name: 'Sites et Serveurs',
        //         groupe: 'Maintenance',
        //         description: 'Surveille les sites et les serveurs',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 146, 150],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 154,
        //         parent_id: null,
        //         project_name: 'Maintenance',
        //         groupe: 'Maintenance',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 138, 146, 150],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 158,
        //         parent_id: null,
        //         project_name: 'ONERA',
        //         groupe: 'itmsol',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 138, 142, 143, 145, 172, 237, 281, 282, 283,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 159,
        //         parent_id: null,
        //         project_name: 'Rapport Malet',
        //         groupe: 'Spie batignolles Malet',
        //         description:
        //           'Projet contenant les graphes pour la cr\u00e9ation de rapport de Malet ',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [337],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 160,
        //         parent_id: null,
        //         project_name: 'La Tour Reiwa ',
        //         groupe: 'GCC',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 143, 145, 222, 285, 286, 287, 288, 289, 344,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 161,
        //         parent_id: null,
        //         project_name: 'LISBOA-TR03_Secret',
        //         groupe: 'ITMSOL',
        //         description:
        //           'Projet avec r\u00e9f\u00e9rences pour LISBOA-TR03',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [110, 145, 150, 172, 222, 237, 290],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 162,
        //         parent_id: null,
        //         project_name: 'Ilot D5 Acoustique',
        //         groupe: 'Eiffage',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 142, 143, 145, 150, 155, 297, 299, 300,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 163,
        //         parent_id: null,
        //         project_name: 'Luzresi Lotes 3 e 4 - Campo Pequeno',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 101, 110, 122, 138, 143, 145, 150, 266, 301, 302, 303,
        //           304, 305, 328,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 164,
        //         parent_id: null,
        //         project_name: 'Ivry Acoustique',
        //         groupe: 'Legendre',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 143, 145, 150, 155, 307, 308, 309, 310, 311,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 165,
        //         parent_id: null,
        //         project_name: 'CARBO PARC',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 280, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 166,
        //         parent_id: null,
        //         project_name:
        //           'METROPOLITANO DE LISBOA LOTE 2 CAIS DO SODR\u00c9 \u2013 SANTOS',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 101, 110, 122, 138, 142, 145, 150, 155, 190, 191, 192,
        //           193, 194, 195, 196, 197, 198, 203, 204, 205, 206, 207, 208,
        //           209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220,
        //           221, 237, 243, 266, 284, 293, 294, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 167,
        //         parent_id: 166,
        //         project_name: '01) Plantas Gerais',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 168,
        //         parent_id: 166,
        //         project_name: '02) Prismas Topogr\u00e1ficos',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 169,
        //         parent_id: 166,
        //         project_name: '03) Inclin\u00f3metros ',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 170,
        //         parent_id: 166,
        //         project_name: '04) Piez\u00f3metros',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 171,
        //         parent_id: 166,
        //         project_name: '05) Extens\u00f3metros',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 172,
        //         parent_id: 166,
        //         project_name: '06) Sism\u00f3grafos',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 122, 138, 145, 150, 155, 190, 191, 192, 193,
        //           194, 195, 196, 197, 198, 203, 204, 205, 206, 207, 208, 209,
        //           210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221,
        //           243, 266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 173,
        //         parent_id: 166,
        //         project_name: '07) C\u00e9lulas de Carga',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 243, 266,
        //           284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 174,
        //         parent_id: 166,
        //         project_name: '08) Tiltmetros e Fissur\u00f3metros',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 243, 266,
        //           284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 175,
        //         parent_id: 166,
        //         project_name: '09) Sensores de N\u00edvel L\u00edquido',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 243, 266,
        //           284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 176,
        //         parent_id: 168,
        //         project_name:
        //           '2.1) Prismas Topogr\u00e1ficos - Edif\u00edcios e Pavimento',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 177,
        //         parent_id: 168,
        //         project_name: '2.2) Prismas Topogr\u00e1ficos - Carril',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 178,
        //         parent_id: 168,
        //         project_name:
        //           '2.3) Prismas Topogr\u00e1ficos - Conten\u00e7\u00e3o e Converg\u00eancias',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 97, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 237, 243,
        //           266, 284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 179,
        //         parent_id: null,
        //         project_name: 'Fondation Rothschild',
        //         groupe: 'Spie Batignolles Ile de France',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 138, 150, 350, 351, 352, 353, 354, 355, 356, 357, 358,
        //           359,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 180,
        //         parent_id: null,
        //         project_name:
        //           'Lisbonne fichier de localisation de zones - annexe',
        //         groupe: '',
        //         description:
        //           'Lisbonne fichier de localisation de zones - annexe',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 110, 128, 298],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 181,
        //         parent_id: null,
        //         project_name: '[AO] Rue Aubagne - Auscultation',
        //         groupe: 'AO Marseille',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 183,
        //         parent_id: 79,
        //         project_name:
        //           'F74 VOGELE Finisseurs 18T 2.5 - 5 m 900 t/h PB-AF',
        //         groupe: 'PT',
        //         description: 'Machine F74',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 184,
        //         parent_id: null,
        //         project_name: 'Rua das Taipas, 39',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 122, 138, 143, 145, 150, 155, 266, 322, 323, 324, 325,
        //           326, 378,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 185,
        //         parent_id: null,
        //         project_name: 'Villejuif Louis Aragon - Acoustique',
        //         groupe: 'Spie Batignolles Energie',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           122, 138, 142, 143, 145, 146, 155, 316, 318, 319, 320, 321,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 186,
        //         parent_id: null,
        //         project_name: 'MB',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 187,
        //         parent_id: null,
        //         project_name: 'H\u00f4pital Am\u00e9ricain - Neuilly-sur-Seine',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 122, 138, 142, 143, 145, 150, 155, 172, 332, 334,
        //           335,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 188,
        //         parent_id: null,
        //         project_name: 'Remblai SNCF Lisy',
        //         groupe: 'SNCF',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           80, 110, 138, 142, 143, 145, 150, 298, 336, 439,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 189,
        //         parent_id: null,
        //         project_name: 'MEDOC',
        //         groupe: 'Spie Batignolles itm+',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 143, 337, 360, 364, 365, 366, 367, 368, 369, 370, 371,
        //           372, 373, 375,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 190,
        //         parent_id: null,
        //         project_name: 'Centrale de Flamanville',
        //         groupe: 'Spie Batignolles Fondations',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 122, 145, 298, 345, 346, 347],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 191,
        //         parent_id: null,
        //         project_name: 'Fondation Rothschild Backup vues',
        //         groupe: 'Fondation Rothschild Backup vues',
        //         description: 'Fondation Rothschild Backup vues',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 122],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 192,
        //         parent_id: 166,
        //         project_name: '10) Extens\u00f3metros de corda vibrante',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           95, 101, 110, 138, 145, 150, 190, 191, 192, 193, 194, 195,
        //           196, 197, 198, 203, 204, 205, 206, 207, 208, 209, 210, 211,
        //           212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 243, 266,
        //           284, 293, 298, 312, 378, 386, 438,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 193,
        //         parent_id: null,
        //         project_name: 'Maintenance Sono et vibration ',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 194,
        //         parent_id: null,
        //         project_name: 'Vallabr\u00e8gues',
        //         groupe: 'Spie Batignolles Fondations',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 334, 348, 349],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 195,
        //         parent_id: null,
        //         project_name: 'Auscultation ZAC Coteau Beauclair',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           110, 142, 145, 172, 237, 290, 298, 415, 416, 417, 418, 419,
        //           420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431,
        //           432, 433, 434, 435, 436,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 196,
        //         parent_id: 79,
        //         project_name: 'P144 LIEBHERR PELLES/PNEUS 18T 110 CV',
        //         groupe: 'MG',
        //         description: 'Machine P144',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 197,
        //         parent_id: 79,
        //         project_name: 'VU13A UTILITAIRE 3.5T DOUBLE CABINE BENNE',
        //         groupe: 'MG',
        //         description: 'Machine VU13A',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 198,
        //         parent_id: 79,
        //         project_name: 'MIP29 MINI PELLES 2.7T +BRH',
        //         groupe: 'NC',
        //         description: 'Machine MIP29',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 199,
        //         parent_id: null,
        //         project_name: 'Argos d\u00e9mo composants anim\u00e9s',
        //         groupe: '',
        //         description: 'Argos d\u00e9mo composants anim\u00e9s',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 200,
        //         parent_id: null,
        //         project_name: 'Paris Miollis - Acoustique',
        //         groupe: 'Spie Batignolles Ile de France',
        //         description: 'Paris Miollis - Acoustique',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 138, 142, 150, 362, 363, 380, 381, 382, 383, 384, 385,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 201,
        //         parent_id: null,
        //         project_name: 'Gendarmerie - Aubagne',
        //         groupe: 'Aubagne',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [138, 142, 143, 145, 150, 172, 298, 395, 396],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 202,
        //         parent_id: null,
        //         project_name: 'Hydrasol - Bagnolet',
        //         groupe: 'Spie Batignolles GC',
        //         description: 'test',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 142, 150, 413, 414],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 203,
        //         parent_id: 79,
        //         project_name: 'F68 FINISSEUR 17T STANDARD',
        //         groupe: 'GG',
        //         description: 'Machine F68',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 204,
        //         parent_id: null,
        //         project_name: 'test_julien',
        //         groupe: '',
        //         description: 'Projet de test',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 290, 337, 360],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 205,
        //         parent_id: 79,
        //         project_name: 'VU600 3,5T BENNE DBLE CABINE alu',
        //         groupe: 'MG',
        //         description: 'Machine VU600',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [95, 97, 146, 276, 277, 291, 292, 296, 392],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 206,
        //         parent_id: null,
        //         project_name: 'BOBIGNY COGEIM - B\u00e2timent E2b',
        //         groupe: 'Spie Batignolles',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [138, 142, 150, 388, 389, 390, 391],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 207,
        //         parent_id: null,
        //         project_name: 'Baie de Somme - Hydrologie',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [138, 142, 146, 150, 397, 398],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 208,
        //         parent_id: null,
        //         project_name: 'Tour Cristal Acoustique',
        //         groupe: 'Spie Batignolles Ile de France',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 122, 138, 143, 155],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 209,
        //         parent_id: null,
        //         project_name: 'Montrouge SNCF - Spie',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 142, 143, 145, 150, 172, 237, 290, 399, 400, 401,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 210,
        //         parent_id: null,
        //         project_name: 'Montrouge SNCF',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [
        //           97, 110, 143, 145, 150, 172, 237, 290, 399, 400, 401, 402,
        //           403, 404, 405, 406, 407, 408, 409, 410, 411, 412,
        //         ],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 211,
        //         parent_id: null,
        //         project_name: 'D\u00e9mo Tableaux de bord',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [49, 97, 101, 138, 145],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 212,
        //         parent_id: 58,
        //         project_name: 'Lisbonne',
        //         groupe: '',
        //         description: 'Lisbonne',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [1, 60, 97, 101, 138],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 213,
        //         parent_id: 58,
        //         project_name: 'Toulouse',
        //         groupe: '',
        //         description: 'Toulouse',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [1, 60, 97, 101, 138, 237],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 214,
        //         parent_id: null,
        //         project_name:
        //           'Orl\u00e9ans M\u00e9tropole - Surveillance de crue',
        //         groupe: 'M\u00e9tropole Orl\u00e9ans',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 142, 143, 145, 146, 150, 298, 379],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 215,
        //         parent_id: 79,
        //         project_name: 'L71 CHARGEURS/PNEUS 220CV',
        //         groupe: 'GG',
        //         description: 'Machine L71',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 146, 276, 277, 291, 292, 296],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 216,
        //         parent_id: null,
        //         project_name: 'Stef Plessis Belleville',
        //         groupe: '',
        //         description: '',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [97, 110, 145, 172, 237, 290, 298],
        //         geo_barr: [],
        //       },
        //       {
        //         project_id: 217,
        //         parent_id: null,
        //         project_name: 'Rotonde - Montrouge',
        //         groupe: 'Spie Batignolles Fondations',
        //         description:
        //           'Grand Montrouge \u2013 Am\u00e9nagement de la Zone Nord \u2013 Lot 400',
        //         active: 1,
        //         project_image: null,
        //         users_list_id: [110, 138, 143, 145, 150, 172, 237, 290, 298],
        //         geo_barr: [],
        //       },
        //     ],
        //     favorite_projects_list_id: [
        //       58, 73, 74, 76, 79, 88, 90, 92, 95, 98, 99, 101, 103, 118, 119,
        //       143, 148, 154, 158, 162, 163, 164, 166, 179, 184, 187, 212, 213,
        //     ],
        //     state: 1,
        //     user_id: 97,
        //     lastname: 'Superadmin',
        //     name: 'ARGOS',
        //     email: 'superadmin.argos@itmsol.fr',
        //     phone_index: 33,
        //     phone_number: 758985874,
        //     company_name: 'itmsol',
        //   },
        // };
        if (response.data.state && response.data.user_id > 0) {
          localStorage.setItem(
            'auth',
            JSON.stringify({
              login_name: payload.idConnexion,
              login_password: CryptoJS.SHA1(payload.motDePasse).toString(),
            })
          );
          dispatch(enregistrerUtilisateur(response.data));
          setTimeout(
            () => actions.demandeInformationDeConnexion(dispatch),
            intervalDeTempsDemandeInfoConnexionApresConnexion
          );
          return;
        }
        break;

      case requestType.deconnexion:
        response = await logout();
        if (response.data.state) {
          dispatch(actions.deconnexion());
          window.location.href = '/';
          return;
        }
        break;

      case requestType.changer_son_mot_de_passe:
        response = await requestPasswordChange(payload);
        if (response.data.state) {
          dispatch(validationChangementMotDePasse());
          return;
        }
        break;

      case requestType.demande_de_reinitialisation_du_mot_de_passe:
        response = await askForPasswordReset(payload);
        if (response.data.state) {
          dispatch(demandeReinitialisationMDPReussie());
          return;
        }
        break;

      case requestType.validation_du_code_de_verification:
        response = await validateCodeVerification(payload);
        if (response.data.state) {
          dispatch(validationCodeVerificationReussie());
          return;
        }
        break;

      case requestType.reinitialisation_du_mot_de_passe:
        response = await resetPassword(payload);
        if (response.data.state) {
          dispatch(reinitialisationMDPReussie());
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
  } finally {
    dispatch(actions.finAttente(requestType));
  }
};

//[non authentifié] fonction permettant de se connecter
export const authentification = (login_name, login_password) => {
  return (dispatch) => {
    requestBack(dispatch, requestType.connexion, {
      login_name,
      login_password,
    });
  };
};
//[déjà authentifié] Groupe de fonction permettant de gérer les statuts d'authentification
export const demandeInformationDeConnexion = (dispatch) => {
  /**
   * Fonction récursive pour la requête d'information de connexion
   * @return une fonction dispatch - une fonction pour réaliser des action asynchrone, dispatch aussi des alertes pour agir sur le réducteur alerte.js
   *      tant que l'utilisateur est connécté sur la base de donné fait un appel récursive
   */
  const state = store.getState();
  dispatch(actions.debutAttente(requestType.information_de_connexion));
  checkConnexion()
    .then((res) => {
      if (res?.data.state) {
        setTimeout(
          () => actions.demandeInformationDeConnexion(dispatch),
          intervalDeTempsDemandeInfoConnexionApresConnexion
        );
        if (state.authentifie.user_id === null) {
          //cas d'une nouvelle page ou du rafraichissement de la page
          //récupération des informations de l'utilisateur connecté
          dispatch(actions.reqRecupererUnUtilisateur(res.data.user_id));
        } else if (res.data.user_id !== state.authentifie.user_id) {
          //cas où user_id de la session sur le serveur ne correspond pas à user_id local
          dispatch(actions.deconnexionAutomatique()); //déconnexion partielle si l'utilisateur n'est pas le même que celui connecté sur le serveur
        }
      } else {
        dispatch(actions.deconnexionAutomatique()); //déconnexion partielle si l'utilisateur n'est pas connecté sur le serveur
        affichageRetourErreurs(dispatch, 'danger', res);
      }
    })
    .catch((err) => {
      console.error(err);
    })
    .finally(() =>
      dispatch(actions.finAttente(requestType.information_de_connexion))
    );
};

export const seDeconnecter = () => {
  // return {type:actionTypes.RE_CONNEXION, idConnexion:idConnexion, motDePasse:motDePasse};
  return (dispatch) => {
    requestBack(dispatch, requestType.deconnexion);
  };
};

//[déjà authentifié] Groupe de fonction permettant de gérer les informations de l'utilisateur connecté
export const reqChangerMDP = (
  login_password,
  new_password,
  new_pass_confirm
) => {
  return (dispatch) => {
    requestBack(dispatch, requestType.changer_son_mot_de_passe, {
      login_password,
      new_password,
      new_pass_confirm,
    });
  };
};

//[non authentifié] Groupe de fonction permettant de réinitialiser un mot de passe
export const demandeReinitialisationMDP = (email) => {
  return (dispatch) => {
    requestBack(
      dispatch,
      requestType.demande_de_reinitialisation_du_mot_de_passe,
      {
        email,
      }
    );
  };
};

export const validationCodeVerification = (codeVerification) => {
  /**
   * envoie la requête de vérification du code de validation
   */
  return (dispatch) => {
    requestBack(dispatch, requestType.validation_du_code_de_verification, {
      codeVerification,
    });
  };
};

export const reinitialisationMDP = (
  verification_code,
  new_password,
  new_password_confirm
) => {
  return (dispatch) => {
    requestBack(dispatch, requestType.reinitialisation_du_mot_de_passe, {
      verification_code,
      new_password,
      new_password_confirm,
    });
  };
};

//[déjà authentifié] Groupe de fonction permettant de gérer l'interface de l'utilisateur (commutateur interface admin/client, filtre par projet_id)
export const commutateurInterfaceClient = () => {
  return { type: actionTypes.COMMUTER_INTERFACE_CLIENT };
};
export const commutateurInterfaceAdmin = () => {
  return { type: actionTypes.COMMUTER_INTERFACE_ADMIN };
};
export const gererFiltreParProjet = (filtre_projects_list_id) => {
  return {
    type: actionTypes.GERER_FILTRE_PAR_PROJET,
    filtre_projects_list_id: filtre_projects_list_id,
  };
};
export const deconnexionAutomatique = () => {
  return { type: actionTypes.DECONNEXION_AUTOMATIQUE };
};
export const deconnexion = () => {
  return { type: actionTypes.DECONNEXION };
};
const demandeReinitialisationMDPReussie = () => {
  return { type: actionTypes.DEMANDE_DE_REINITIALISATION_MDP_REUSSIE };
};
const validationCodeVerificationReussie = () => {
  return { type: actionTypes.VALIDATION_CODE_VERIFICATION_REUSSIE };
};
const reinitialisationMDPReussie = () => {
  return { type: actionTypes.REINITIALISATION_MDP_REUSSIE };
};
const validationChangementMotDePasse = () => {
  return { type: actionTypes.VALIDATION_CHANGEMENT_MOT_DE_PASSE };
};
export const enregistrerUtilisateur = (data) => {
  return {
    type: actionTypes.DEBUT_AUTH,
    ...data,
    // user_id: data.user_id,
    // lastname: data.lastname,
    // name: data.name,
    // privileges_list: data.privileges_list,
    // projects_list: data.projects_list,
    // favorite_projects_list_id: data.favorite_projects_list_id,
    // email:data.email,
    // phone_index:data.phone_index,
    // phone_number:data.phone_number,
    // company_name:data.company_name,
    // profil_image:data.profil_image
  };
};

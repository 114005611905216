import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import S from './App.module.scss';
import MiseEnPage from './conteneur/MiseEnPage/MiseEnPage';
import ReinitialisationMDP from './conteneur/ReinitialisationMDP/ReinitialisationMDP';

class App extends Component {
  render() {
    return (
      <div className={S.Theme}>
        <Route path="/" component={() => <MiseEnPage />} />
        <Route
          path="/reinitialisation"
          component={() => <ReinitialisationMDP />}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    langueSite: state.dico.langueSite,
  };
};

export default connect(mapStateToProps)(App);

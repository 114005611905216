import React, { Component } from 'react';

import FormulairePersonalisationPrivileges from './FormulairePersonalisationPrivileges/FormulairePersonalisationPrivileges';

// import FormulaireAjoutProjetsAUtilisateur from "../../UI/FormulaireSelectionProjets/FormulaireSelectionProjets";
import FormulaireSelectionProjetsArborecenseUtilisateur from '../../UI/FormulaireSelectionProjetsArborecenseUtilisateur/FormulaireSelectionProjetsArborecenseUtilisateur';
import Bouton from '../../UI/Bouton/Bouton';

import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';

import * as requestType from '../../../ressources/glossaires/glossaire_requetes';
import { checkMot } from '../../../ressources/functionJS/checkMot';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './AjouterUtilisateur.module.scss';

class AjouterUtilisateur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: '',
      prenom: '',
      emailDebut: '',
      emailFin: '',
      indicatifTelephone: '',
      telephone: '',
      entreprise: '',
      privileges_list: [],
      projects_list_id: [],

      personnaliserPrivilegeBool: true, //todo mettre à false
    };
  }
  gererEtatDeCibleName = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  gererPrivilegesList = (privileges_list) => {
    this.setState({ ...this.state, privileges_list: privileges_list });
  };
  gererProjetsList = (projects_list_id) => {
    this.setState({ ...this.state, projects_list_id: projects_list_id });
  };
  gererEnvoi(event) {
    event.preventDefault();
    const formulaireCreerUtilisateur = {
      login_name: this.state.emailDebut + '@' + this.state.emailFin,
      lastname: this.state.nom,
      name: this.state.prenom,
      email: this.state.emailDebut + '@' + this.state.emailFin,
      phone_index: this.state.indicatifTelephone,
      phone_number: this.state.telephone,
      company_name: this.state.entreprise,
      privileges_list: this.state.privileges_list,
      projects_list_id: this.state.projects_list_id,
    };
    this.props.ajouter(formulaireCreerUtilisateur);
  }
  // todo: implémenter la sélection de configuration par défaut avec possibilité de modifier la sélection par défaut
  // visiteur, client, admin, sAdmin, root, [personnalisation -> 1 privilège pour tout les projets
  // visiteur(privilège)={0,0,0,0,0,0,0,0,0,0,0,supprimer:1,modifier:1,créer:0}
  // client(privilège)=[0,0,0,0,0,0,0,0,0,0,0,1,1,1]
  render() {
    return (
      <>
        <div className={[S.ConteneurInfo].join(' ')}>
          <div className={[s.container, s.text_left, S.Formulaire].join(' ')}>
            <form onSubmit={(event) => this.gererEnvoi(event)}>
              <div className={s.form_group}>
                <div className={s.form_row}>
                  <div className={s.col_sm_12}>
                    <label className={[s.card_header, S.style].join(' ')}>
                      {checkMot('nom')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, S.Input].join(' ')}
                      placeholder="nom"
                      name="nom"
                      value={this.state.nom}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      required={true}
                    />
                  </div>
                  <div className={s.col_sm_12}>
                    <label className={[s.card_header, S.style].join(' ')}>
                      {checkMot('prenom')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, S.Input].join(' ')}
                      placeholder="prenom"
                      name="prenom"
                      value={this.state.prenom}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              {/**Prénom nom**/}
              <label className={[s.card_header, S.style].join(' ')}>
                {checkMot('email')}
              </label>
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_5}>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2, S.Input].join(' ')}
                      placeholder="exemple"
                      name="emailDebut"
                      value={this.state.emailDebut}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      required={true}
                    />
                  </div>
                  <div className={s.col_7}>
                    <div className={[s.input_group, s.mb_2].join(' ')}>
                      <div className={s.input_group_prepend}>
                        <div
                          className={[
                            s.input_group_text,
                            s.form_control,
                            S.Input,
                          ].join(' ')}
                        >
                          @
                        </div>
                      </div>
                      <input
                        className={[s.form_control, S.Input].join(' ')}
                        type="text"
                        name="emailFin"
                        value={this.state.emailFin}
                        onChange={this.gererEtatDeCibleName}
                        pattern="([àáâãäåæçèéêëìíîïñòóôõöœùúûü_0-9a-zA-Z\-]{1,}\.)+[a-zA-Z]{2,}"
                        placeholder="nomDeDomaine"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/**Email**/}
              <div className={s.form_group}>
                <div className={s.form_row}>
                  <div className={s.col}>
                    <label className={[s.card_header, S.style].join(' ')}>
                      {checkMot('entreprise')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2, S.Input].join(' ')}
                      placeholder="entreprise"
                      name="entreprise"
                      value={this.state.entreprise}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                  </div>
                </div>
              </div>
              {/**Entreprise**/}
              <div className={s.form_group}>
                <label className={[s.card_header, S.style].join(' ')}>
                  {checkMot('telephone')}
                </label>
                <div className={s.form_row}>
                  <div className={s.col_sm_4}>
                    <input
                      name={'indicatifTelephone'}
                      value={this.props.indicatifTelephone}
                      type="number"
                      size="5"
                      placeholder="index"
                      pattern={'([0-9])*'}
                      className={[s.form_control, s.mr_sm_2, S.Input].join(' ')}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                  </div>
                  <div className={s.col_sm_8}>
                    <div className={[s.input_group, s.mb_2].join(' ')}>
                      <div className={s.input_group_prepend}>
                        <div
                          className={[
                            s.input_group_text,
                            s.form_control,
                            S.Input,
                          ].join(' ')}
                        >
                          <svg
                            width="1em"
                            height="1em"
                            viewBox="0 0 16 16"
                            className="bi bi-telephone-fill"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                            />
                          </svg>
                        </div>
                      </div>
                      <input
                        className={[s.form_control, s.mb_2, S.Input].join(' ')}
                        value={this.state.telephone}
                        onChange={this.gererEtatDeCibleName}
                        pattern={'([0-9])*'}
                        name="telephone"
                        placeholder="telephone"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/**Telephone**/}
              <label className={s.card_header}>
                {' '}
                <FormulairePersonalisationPrivileges
                  gererPrivilegesList={this.gererPrivilegesList}
                />
              </label>
              {/* <label className={s.card_header}> <FormulaireAjoutProjetsAUtilisateur gererProjetsList={this.gererProjetsList}/></label> */}
              <label className={s.card_header}>
                {' '}
                <FormulaireSelectionProjetsArborecenseUtilisateur
                  gererProjetsList={this.gererProjetsList}
                />
              </label>

              <div className={s.form_group}>
                <div
                  className={[s.d_flex, s.justify_content_between].join(' ')}
                >
                  <div>
                    {/*<Bouton cliquer={this.props.annuler}>{checkMot("annuler")}</Bouton>*/}
                  </div>
                  <div>
                    <AttenteRequete
                      request_type={requestType.creation_d_un_utilisateur}
                      str="Creation en cour"
                    >
                      <Bouton>{checkMot('creer')}</Bouton>
                    </AttenteRequete>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default AjouterUtilisateur;

import Bouton from '../../../../../UI/Bouton/Bouton';
import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../../../services/actions';
import AttenteRequete from '../../../../../../hoc/AttenteRequete/AttenteRequete';
import * as requestType from '../../../../../../ressources/glossaires/glossaire_requetes';
// import { AiTwotoneStar } from "react-icons/ai";
import S from './SupprimerProjetFavori.module.scss';
import { RiPushpinFill } from 'react-icons/ri';

import { confirmeOnClick } from '../../../../../../ressources/functionJS/confirmeOnClick';
import { checkMot } from '../../../../../../ressources/functionJS/checkMot';
const SupprimerProjetFavori = (props) => (
  <AttenteRequete
    request_type={requestType.supprimer_un_projet_des_favoris}
    str={checkMot('ajout_du_projet_favori_en_cours')}
  >
    <Bouton
      ajoutClass={S.style}
      cliquer={() =>
        confirmeOnClick(
          checkMot('voulez_vous_vraiment_supprimer_ce_projet_de_vos_favoris'),
          () => props.reqSupprimer(parseInt(props.project_id))
        )
      }
    >
      {/* <AiTwotoneStar size={14} color={'#eeb214'}/> */}
      <RiPushpinFill size={14} color={'#eeb214'} />
    </Bouton>
  </AttenteRequete>
);
const mapDispatchToProps = (dispatch) => {
  return {
    reqSupprimer: (project_id) =>
      dispatch(actions.reqSupprimerProjetFavori(project_id)),
  };
};
export default connect(null, mapDispatchToProps)(SupprimerProjetFavori);

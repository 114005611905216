import React, { Component } from 'react';
import FormulaireAjoutUtilisateursAProjet from './FormulaireAjoutUtilisateursAProjet/FormulaireAjoutUtilisateursAProjet';
import FormulaireSelectionProjetParent from './FormulaireSelectionProjetParent/FormulaireSelectionProjetParent';
import Bouton from '../../UI/Bouton/Bouton';

import { stringOrEmptyString } from '../../../ressources/functionJS/stringOrEmptyString';
import { IsArrayString } from '../../../ressources/functionJS/IsArrayString';
import * as requestType from '../../../ressources/glossaires/glossaire_requetes';

import AttenteRequete from '../../../hoc/AttenteRequete/AttenteRequete';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { ConteneurInfo, Formulaire, btsSty, fore } from './Ajouter.module.scss';
import { AiOutlineCheck } from 'react-icons/ai';
import { checkMot } from '../../../ressources/functionJS/checkMot';

const ArrayOrString = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

class AjouterProjet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_name: '',
      parent_id: null,
      geo_barr: '',
      groupe: '',
      users_list_id: [],
      description: '',
      active: 1,
    };
  }
  gererEtatDeCibleName = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  gererProjetParentId = (parentId) => {
    this.setState({
      ...this.state,
      parent_id: parentId,
    });
  };
  gererUtilisateursList = (utilisateurIdList) => {
    this.setState({
      ...this.state,
      users_list_id: utilisateurIdList,
    });
  };
  gererEnvoi(event) {
    const infoNouveauProjet = {
      project_name: stringOrEmptyString(this.state.project_name),
      parent_id: parseInt(this.state.parent_id),
      geo_barr: IsArrayString(this.state.geo_barr, []),
      groupe: stringOrEmptyString(this.state.groupe),
      users_list_id: this.state.users_list_id,
      description: stringOrEmptyString(this.state.description),
      active: parseInt(this.state.active),
    };
    this.props.ajouter(infoNouveauProjet);
    event.preventDefault();
  }
  render() {
    return (
      <>
        <div className={[ConteneurInfo].join(' ')}>
          <div className={[s.container, s.text_left, Formulaire].join(' ')}>
            <form onSubmit={(event) => this.gererEnvoi(event)}>
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <label className={s.card_header}>
                      {checkMot('project_name')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2, fore].join(' ')}
                      placeholder="Nom du projet"
                      name="project_name"
                      value={this.state.project_name}
                      required
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                  </div>
                </div>
              </div>
              {/**project_name**/}
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <label className={s.card_header}>
                      {checkMot('groupe')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2].join(' ')}
                      placeholder="groupe"
                      name="groupe"
                      value={this.state.groupe}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                  </div>
                </div>
              </div>
              {/**groupe**/}
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <label className={s.card_header}>
                      {checkMot('description_de_projet')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2].join(' ')}
                      placeholder="description_de_projet"
                      name="description"
                      value={this.state.description}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                  </div>
                </div>
              </div>
              {/**description**/}
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <FormulaireSelectionProjetParent
                      gererProjetParentId={this.gererProjetParentId}
                    />
                  </div>
                </div>
              </div>
              {/**parent_id**/}
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <label className={s.card_header}>
                      {checkMot('etatduproject')}
                    </label>
                    <select
                      // value={}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="active"
                      defaultValue={this.state.active}
                      className={[s.custom_select, s.mr_sm_2].join(' ')}
                    >
                      <option value={1}>active</option>
                      <option value={0}>desactive</option>
                    </select>
                  </div>
                </div>
              </div>
              {/**active**/}
              <div className={s.form_group}>
                <div className={[s.form_row, s.align_items_center].join(' ')}>
                  <div className={s.col_12}>
                    <label className={s.card_header}>
                      {checkMot('geo_barriere')}
                    </label>
                    <input
                      type="text"
                      className={[s.form_control, s.mb_2].join(' ')}
                      placeholder="geo-barrière"
                      name="geo_barr"
                      value={this.state.geo_barr}
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                    />
                    {Array.isArray(ArrayOrString(this.state.geo_barr))
                      ? null
                      : checkMot('format_incorect')}
                  </div>
                </div>
              </div>
              {/**geo_barr**/}
              <FormulaireAjoutUtilisateursAProjet
                gererUtilisateursList={this.gererUtilisateursList}
              />
              <div className={s.form_group}>
                <div
                  className={[s.d_flex, s.justify_content_between].join(' ')}
                >
                  <div>
                    {/* <Bouton cliquer={this.props.annuler}>{checkMot("annuler")}</Bouton> */}
                  </div>
                  <div className={btsSty}>
                    <AttenteRequete
                      request_type={requestType.creation_d_un_projet}
                      str={checkMot('creation_en_cour')}
                    >
                      <Bouton>
                        <AiOutlineCheck /> {checkMot('creer')}
                      </Bouton>
                    </AttenteRequete>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default AjouterProjet;

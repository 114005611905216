import L from 'leaflet';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';
import { store } from '../../../index';
import ObjetCapteur from './ObjetCapteur/ObjetCapteur';
import ObjetGraphe from './ObjetGraphe/ObjetGraphe';
import ObjetVue from './ObjetVue/ObjetVue';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import { jsDictionaryEquals } from '../../../ressources/functionJS/jsDictionaryEquals';
import * as glossaire_requetes from '../../../ressources/glossaires/glossaire_requetes';
import * as glossaire_type_d_objet from '../../../ressources/glossaires/glossaire_type_d_objet_associe_a_une_vue';
import * as glossaire_type_de_couche from '../../../ressources/glossaires/glossaire_type_de_couche';
import * as glossaire_type_de_graphe from '../../../ressources/glossaires/glossaire_type_de_graphe';
import { glossaire_type_de_vue } from '../../../ressources/glossaires/glossaire_type_de_vue';
import * as actions from '../../../services/actions';
import { Spinner } from 'reactstrap';
import '../../../ressources/StyleGlobal/MarkerCluster.Default.css';
import '../../../ressources/StyleGlobal/MarkerCluster.css';
import * as S from './AffichageVue.module.scss';
import proj4 from 'proj4';
import 'proj4leaflet';
import { VscListUnordered } from 'react-icons/vsc';
import { unixTimestampToDate } from '../../../ressources/functionJS/unixTimestampToDate';
import { geoposition } from '../../../ressources/glossaires/glossaire_affichage_type_capteur';
import * as type_btn from '../../../ressources/glossaires/glossaire_type_de_bouton';
import FormulaireDateDeCouche from './FormulaireDateDeCouche/FormulaireDateDeCouche';
import ObjetTexte from './ObjetTexte/ObjetTexte';
import { setUrlPathFromDomain, debounce } from '../../../services/utils/utils';
import {
  createGeoJSONCircle,
  adjustValueToProvidedDistanceUnit,
  projectLineString,
  getSensorColor,
} from '../../../services/utils/utils';
require('leaflet.markercluster');
require('leaflet-polylinedecorator');

const glossaire_repere = {
  repere_fictif: 0,
  wgs84: 1,
  EPSG3949: 2,
};
const stylePolyline = {
  // color: "#008000",
  weight: 3,
  opacity: 0.6,
  dashArray: '12, 10',
  dashOffset: '20',
};
const niveauDePixelAvantCluster = 20; //rapprochement maximal entre 2 objets avant clusterisation
export const dimensionsIconCapteur = 22; //taille de l'icone du marker de l'objet capteur par défaut
const dimensionsIconGraphe = 22; //taille de l'icone du marker de l'objet graphe par défaut

const markClustColorArray = [
  S.MarkerCluster_green,
  S.MarkerCluster_blue,
  S.MarkerCluster_orange,
  S.MarkerCluster_red,
  S.MarkerCluster_black,
];
class AffichageVue extends Component {
  state = {
    map_vue: null,
    afficherLesObjetsViaRender: true,
    divDejaAfficher: false,
    afficherCouche: true,
    objetsARender: {},
    afficherMarkersApresReq10614: true,
    afficherApresReq10604: true,
    afficherApresReq10613: true,
    newObjectCreated: false,
    update_time_unactive: true,
    markersList: {},
    coucheAAfficherApresJson: [],
    geopositionMarkersList: [],
    historiqueGeoPositionList: [],
    chantierMarkersList: [],
    changement_date: true,
  };

  componentDidMount() {
    if (this.props.view_id !== undefined) {
      //appel des différentes interface de récupération de données de la vue
      //todo refresh automatique
      this.props.reqRecupererVue(this.props.view_id);
      this.props.reqRecupererListeCouchesVue(this.props.view_id);
      this.setState({ afficherApresReq10604: true }); //attente de la fin de la req de récupération des couches
      this.props.reqRecupererListeObjetsAAfficherAssocieVue(this.props.view_id);
      this.setState({ afficherApresReq10613: true }); //attente de la fin de la req de récupération des objets
      if (this.props.views_list_by_view_id[this.props.view_id] === undefined) {
        this.props.reqRecupererVue(this.props.view_id);
      }
      if (this.props.views_layers_list[this.props.view_id] === undefined) {
        this.props.reqRecupererListeCouchesVue(this.props.view_id);
        this.setState({ afficherApresReq10604: true });
      }
      if (this.props.data_objects_list_view === undefined) {
        this.props.reqRecupererListeObjetsAAfficherAssocieVue(
          this.props.view_id
        );
        this.setState({ afficherApresReq10613: true });
      }
    }

    const mapDynamicDiv = document.getElementById('mapDynamicDiv');
    if (mapDynamicDiv !== null) {
      //relance l'affichage de la vue après avoir cliqué sur le bouton aggrandisement/rétrécissement
      const popupConteneur = mapDynamicDiv.parentElement;
      if (popupConteneur !== null) {
        let boutonFullScreen;
        if (popupConteneur.childNodes[0]?.childNodes[1] !== undefined) {
          boutonFullScreen = popupConteneur.childNodes[0].childNodes[1];
        } else if (popupConteneur.parentElement?.parentElement !== null) {
          boutonFullScreen =
            popupConteneur.parentElement.parentElement.childNodes[0]
              .childNodes[1];
        }
        if (boutonFullScreen) {
          boutonFullScreen.addEventListener('click', () => {
            this.setState({
              afficherApresReq10604: true,
            });
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    //paramètre d'initialisation si l'id de la vue change
    if (this.props.view_id !== prevProps.view_id) {
      this.setState({
        map_vue: null,
        afficherLesObjetsViaRender: true,
        divDejaAfficher: false,
        afficherCouche: true,
        objetsARender: {},
        afficherMarkersApresReq10614: true,
        afficherApresReq10604: true,
        afficherApresReq10613: true,
        newObjectCreated: false,
        markersList: {},
      });
    }

    // Initialisation de l'objet leaflet (vue, couches et objets)
    if (
      this.props.views_list_by_view_id[this.props.view_id] !== undefined && //si les données de la vue à afficher est récupéré
      (this.props.view_id !== prevProps.view_id || //si l'id de la vue change
        this.state.map_vue === null || //si l'objet leaflet n'est pas initialisé
        //si les informations de la vue est déjà récupéré
        (this.state.afficherApresReq10604 &&
          !(
            this.props.attenteReqListe[
              'ATTENTE_REQUETE' +
                glossaire_requetes.recuperer_la_liste_des_couches_de_vue
            ] === true
          )) ||
        //si la req des couches n'est pas encore récupéré, ne pas lancer l'event des objets de la vue
        (!(
          this.props.attenteReqListe[
            'ATTENTE_REQUETE' +
              glossaire_requetes.recuperer_la_liste_des_couches_de_vue
          ] === true
        ) &&
          this.state.afficherApresReq10613 &&
          !(
            this.props.attenteReqListe[
              'ATTENTE_REQUETE' +
                glossaire_requetes.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue
            ] === true
          )))
    ) {
      let afficherApresReq10604 = !this.state.afficherApresReq10604;
      let afficherApresReq10613 = !this.state.afficherApresReq10613;
      if (
        this.state.afficherApresReq10604 &&
        !(
          this.props.attenteReqListe[
            'ATTENTE_REQUETE' +
              glossaire_requetes.recuperer_la_liste_des_couches_de_vue
          ] === true
        )
      ) {
        this.setState({
          afficherApresReq10604: false,
          afficherLesObjetsViaRender: true,
        });
        afficherApresReq10604 = true;
      }
      if (
        this.state.afficherApresReq10613 &&
        !(
          this.props.attenteReqListe[
            'ATTENTE_REQUETE' +
              glossaire_requetes.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue
          ] === true
        )
      ) {
        this.setState({
          afficherApresReq10613: false,
          afficherLesObjetsViaRender: true,
        });
        afficherApresReq10613 = true;
      }
      //initialisation de la variable contenant la map leaflet
      let map_vue;
      let mapboxAttribution;
      const repereDeLaVue =
        this.props.views_list_by_view_id[this.props.view_id].view_projection;

      //si l'objet leaflet est déjà stocker dans les états de AffichageVue
      if (this.state.map_vue === null || this.state.map_vue === undefined) {
        //information requis pour l'initialisation de l'objet leaflet (zooms min et max)
        const default_max_zoom = 28;
        let minZoom;
        let maxZoom;
        //zooms levels des configuration de la vue (données de l'api stocké dans les états globaux de redux)
        const minZoomConfig =
          parseInt(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_zoom_levels[0]
          ) - 1;
        const maxZoomConfig =
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[1];
        //initialisation de l'intervalle de zoom (
        let zoomEtBounds = {
          zoomDelta: 0.1,
          zoomSnap: 0.1,
          // maxBounds: this.props.views_list_by_view_id[this.props.view_id].view_bound_coordinates,//TODO: utiliser les clés
        };

        if (
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels !== undefined &&
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[0] !== null
        ) {
          minZoom = minZoomConfig;
          maxZoom = maxZoomConfig;
          if (minZoomConfig < -5) {
            minZoom = -5;
          }
          if (default_max_zoom < maxZoomConfig) {
            maxZoom = default_max_zoom;
          }
        } else {
          minZoom = -5;
          maxZoom = default_max_zoom;
        }

        switch (repereDeLaVue) {
          case glossaire_repere.wgs84:
            //utilisation du zoom min/max native de la couche de tuiles
            zoomEtBounds['minNativeZoom'] = minZoom;
            zoomEtBounds['maxNativeZoom'] = maxZoom;
            zoomEtBounds['minZoom'] = minZoom;
            zoomEtBounds['maxZoom'] = maxZoom;
            break;
          case glossaire_repere.EPSG3949:
            //utilisation du zoom min/max native de la couche de tuiles
            zoomEtBounds['minNativeZoom'] = minZoom;
            zoomEtBounds['maxNativeZoom'] = maxZoom;
            // minZoom = -5;
            // maxZoom = 15;
            zoomEtBounds['minZoom'] = minZoom;
            zoomEtBounds['maxZoom'] = maxZoom;
            break;
          default:
            zoomEtBounds['minZoom'] = minZoom;
            zoomEtBounds['maxZoom'] = maxZoom;
            break;
        }
        L.Control.coordProjection = L.Control.extend({
          options: {
            position: 'bottomleft',
            separator: ' | ',
            emptyString: ' ',
            lngFirst: false,
            numDigits: 3,
            lngFormatter: undefined,
            latFormatter: undefined,
            prefix: '',
            crs: 'EPSG4326',
          },

          onAdd: function (map) {
            this._container = L.DomUtil.create(
              'div',
              'leaflet-control-coord-projection'
            );
            L.DomEvent.disableClickPropagation(this._container);
            map.on('mousemove', this._onMouseMove, this);
            this._container.innerHTML = this.options.emptyString;
            return this._container;
          },

          onRemove: function (map) {
            map.off('mousemove', this._onMouseMove);
          },

          _onMouseMove: function (e) {
            let position = this._projectTo(
              this.options.crs,
              e.latlng,
              this.options.crsProjObject
            );

            if (this.options.crsProjObject || this.options.crs !== 'EPSG4326') {
              position = L.latLng(position.x, position.y);
              this.options.numDigits = 3;
            } else {
              this.options.numDigits = 6;
            }

            let lng = this.options.lngFormatter
              ? this.options.lngFormatter(position.lng)
              : L.Util.formatNum(position.lng, this.options.numDigits);
            let lat = this.options.latFormatter
              ? this.options.latFormatter(position.lat)
              : L.Util.formatNum(position.lat, this.options.numDigits);
            let value = this.options.lngFirst
              ? lng + this.options.separator + lat
              : lat + this.options.separator + lng;
            let prefixAndValue = this.options.prefix + ' ' + value;
            this._container.innerHTML = prefixAndValue;
          },

          _projectTo: function (crs, latLng) {
            let position;

            if (crs instanceof L.Proj.CRS) {
              position = crs.project(latLng);
              return position;
            }

            switch (crs) {
              case 'EPSG3395':
                position = L.Projection.Mercator.project(latLng);
                break;
              case 'EPSG3857':
                position = L.Projection.SphericalMercator.project(latLng);
                break;
              default:
                // Default is 'EPSG4326'
                return latLng;
            }
            return position;
          },

          changeCrs: function (crs) {
            this.options.crs = crs;
          },
        });

        L.Map.mergeOptions({
          positionControl: false,
        });

        L.Map.addInitHook(function () {
          if (this.options.positionControl) {
            this.positionControl = new L.Control.coordProjection();
            this.addControl(this.positionControl);
          }
        });

        L.control.coordProjection = function (options) {
          return new L.Control.coordProjection(options);
        };

        //Si la vue est dans le repère wgs84
        if (repereDeLaVue === glossaire_repere.wgs84) {
          map_vue = L.map('mapDynamicDiv', {
            center: [48.85661, 2.351499], //coordonnées du centre de paris par défaut
            zoom: 0, //niveau de zoom par défaut
            dragging:
              this.props.views_list_by_view_id[this.props.view_id]
                .view_flag_fix_pos === 0,
            ...zoomEtBounds,
          });
          map_vue.fitBounds(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_bound_coordinates
          );
        }
        //si la vue est dans un repère fictif
        else if (repereDeLaVue === glossaire_repere.repere_fictif) {
          map_vue = L.map('mapDynamicDiv', {
            center: [0, 0], //coordonnées du centre de paris par défaut
            zoom: 0, //niveau de zoom par défaut
            crs: L.CRS.Simple,
            dragging:
              this.props.views_list_by_view_id[this.props.view_id]
                .view_flag_fix_pos === 1,
            ...zoomEtBounds,
          });
          map_vue.fitBounds(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_bound_coordinates
          );
        } //si la vue est dans un repère EPSG:3949
        else if (repereDeLaVue === glossaire_repere.EPSG3949) {
          const crs = new L.Proj.CRS(
            'EPSG:3949',
            '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
            {
              // origin: [8174056,1643766],
              // bounds: L.bounds(L.point( 8174056, 1643766), L.point( 8182260, 1657579)),
              origin: [1643766, 8174056],
              // bounds: L.bounds(L.point( 1643766,8174056), L.point( 1657579,8182260)),
              resolutions: [
                16384, 8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4,
                2,
              ],
            }
          );
          map_vue = L.map('mapDynamicDiv', {
            center: [48.85661, 2.351499], //coordonnées du centre de paris par défaut
            zoom: 0, //niveau de zoom par défaut
            // crs: crs,
            dragging:
              this.props.views_list_by_view_id[this.props.view_id]
                .view_flag_fix_pos === 0,
            ...zoomEtBounds,
            // minZoom: 0,
            maxZoom: 28,
          });
          //affiche les coordonnées du repère EPSG3949
          map_vue.fitBounds(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_bound_coordinates
          );
          let coordP = L.control
            .coordProjection({
              crs: crs,
            })
            .addTo(map_vue);

          // change projection with:
          coordP.changeCrs(crs);
        } else {
          //todo enlever la condition de repère fictif sinon
          map_vue = L.map('mapDynamicDiv', {
            center: [0, 0], //coordonnées du centre de paris par défaut
            zoom: 0, //niveau de zoom par défaut
            crs: L.CRS.Simple,
            dragging:
              this.props.views_list_by_view_id[this.props.view_id]
                .view_flag_fix_pos === 1,
            ...zoomEtBounds,
          });
        }

        //après première initialisation de la map
        this.setState({
          map_vue: map_vue,
        });

        //droit d'auteur de la carte
        mapboxAttribution =
          this.props.views_list_by_view_id[this.props.view_id].view_copyright;
      }
      //si l'objet leaflet est déjà créé et stocké dans les états de AffichageVue
      else {
        map_vue = this.state.map_vue;
      }

      //relance l'affichage de la vue
      // map_vue.invalidateSize();

      /** relance la l'affichage des composant à chaque fin de mouvement**/
      map_vue.on(
        'moveend',
        debounce(() => {
          this.setState({ afficherLesObjetsViaRender: true });
        }, 300)
      );
      if (this.props.recupCoord !== undefined) {
        //outil de positionnement d'un objet sur la vue
        map_vue.on('contextmenu', (event) => {
          if (!this.state.newObjectCreated) {
            this.props.recupCoord(event.latlng);

            //afficher icon svg
            const svgIconCapteur = L.divIcon({
              html: `<div id="editer"></div>`,
              className: '',
              iconSize: [dimensionsIconCapteur, dimensionsIconCapteur],
              alt: 0,
            });
            let newObject = L.marker(event.latlng, {
              icon: svgIconCapteur,
              draggable: true,
            })
              .bindPopup('event.latlng' + event.latlng)
              .addTo(map_vue);
            newObject.on('dragend', () => {
              this.props.recupCoord(newObject.getLatLng());
              // this.setState({afficherLesObjetsViaRender:true})
            });
            this.setState({
              ...this.state,
              newObjectCreated: true,
              newObject: newObject,
            });
          } else {
            this.props.recupCoord(this.state.newObject.getLatLng());
            this.state.newObject
              .setLatLng(event.latlng)
              .bindPopup('event.latlng' + event.latlng);
          }
        });

        //affichage de l'instruction de positionnement à la place du copyright
        mapboxAttribution = checkMot(
          'faire_un_clique_droit_pour_positionner_l_objet'
        );
      }

      //affichage du copyright en bas à droite
      map_vue.attributionControl.addAttribution(mapboxAttribution);
      map_vue.attributionControl.setPrefix(false);

      //si la configuration de la vue est récupérer (initialisation des couches et des objets)
      if (this.props.views_list_by_view_id[this.props.view_id] !== undefined) {
        /**DEBUT affichage des couches de la vue**/
        if (
          this.props.views_layers_list[this.props.view_id] !== undefined &&
          this.state.afficherCouche
        ) {
          if (
            this.props.views_list_by_view_id[this.props.view_id]
              .view_projection === glossaire_repere.wgs84
          ) {
            let baseMaps = {};
            let overlayMaps = {};
            const listeCouches = this.props.views_layers_list[
              this.props.view_id
            ].sort((a, b) => {
              return a.position - b.position;
            });
            for (
              let indCouche = 0;
              indCouche < listeCouches.length;
              indCouche++
            ) {
              const coucheAAfficher = listeCouches[indCouche];
              switch (coucheAAfficher.layer_type) {
                case glossaire_type_de_couche.Image:
                  //todo: intégrer la projection des coordonnées à afficher (changement de repère)
                  // let imageLayer = new Image();
                  // imageLayer.onload = function () {

                  // };
                  // imageLayer.src = coucheAAfficher.layer_path;//result;
                  // const tailleImage = coucheAAfficher.bounds_image[1][0];
                  // let ratio = 1;
                  // if (imageLayer.height > 0 && imageLayer.width > 0) {
                  //     ratio = imageLayer.height > imageLayer.width ? imageLayer.height / tailleImage : imageLayer.width / tailleImage;//todo: ratio temporaire pour la dimension de l'image
                  // }
                  // let imageBounds = [
                  //     [coucheAAfficher.bounds_image[0][0], coucheAAfficher.bounds_image[0][1]],
                  //     [coucheAAfficher.bounds_image[0][0] + imageLayer.height / ratio, coucheAAfficher.bounds_image[0][1] + imageLayer.width / ratio]
                  // ];

                  const coucheImage = L.imageOverlay(
                    coucheAAfficher.layer_path,
                    coucheAAfficher.bounds_image
                  ).addTo(map_vue);
                  overlayMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ] = coucheImage;
                  overlayMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ].setZIndex(coucheAAfficher.position);
                  break;
                case glossaire_type_de_couche.Tuile:
                  const coucheTuile = L.tileLayer(coucheAAfficher.layer_path, {
                    id: 'MapID',
                    tileSize: 512,
                    zoomOffset: -1,
                    maxZoom: 28,
                  }).addTo(map_vue);
                  baseMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ] = coucheTuile;
                  baseMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ].setZIndex(coucheAAfficher.position);
                  break;
                case glossaire_type_de_couche.GeoJSON:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              layer_path: coucheAAfficher.layer_path,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                case glossaire_type_de_couche.QgisWms:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                default:
                  break;
              }
            }
            if (
              this.props.views_list_by_view_id[this.props.view_id].view_type !==
              glossaire_type_de_vue.tableau_de_bord
            ) {
              //ajout du panneau de control des couches
              let controlLayers = L.control
                .layers(baseMaps, overlayMaps, {
                  position: 'topleft',
                })
                .addTo(map_vue);
              this.setState({ controlLayers: controlLayers });
            }
          } else if (
            this.props.views_list_by_view_id[this.props.view_id]
              .view_projection === glossaire_repere.repere_fictif
          ) {
            let baseMaps = {};
            let overlayMaps = {};
            let zoomMinMax = [];
            const listeCouches =
              this.props.views_layers_list[this.props.view_id];
            for (
              let indCouche = 0;
              indCouche < listeCouches.length;
              indCouche++
            ) {
              const coucheAAfficher = listeCouches[indCouche];
              //récupération des limites de longitude latitude de tout les couches de la vue (hors tuiles)
              if (
                zoomMinMax.length > 0 &&
                Array.isArray(coucheAAfficher.bounds_image) &&
                Array.isArray(coucheAAfficher.bounds_image[0]) &&
                Array.isArray(coucheAAfficher.bounds_image[1])
              ) {
                zoomMinMax = [
                  [
                    Math.min(
                      zoomMinMax[0][0],
                      coucheAAfficher.bounds_image[0][0]
                    ),
                    Math.min(
                      zoomMinMax[0][1],
                      coucheAAfficher.bounds_image[0][1]
                    ),
                  ],
                  [
                    Math.max(
                      zoomMinMax[1][0],
                      coucheAAfficher.bounds_image[1][0]
                    ),
                    Math.max(
                      zoomMinMax[1][1],
                      coucheAAfficher.bounds_image[1][1]
                    ),
                  ],
                ];
              } else if (
                JSON.stringify(coucheAAfficher.bounds_image) !== '[[null]]'
              ) {
                //cas où le back retoune des layers sans limite d'image
                zoomMinMax = [...coucheAAfficher.bounds_image];
              }
              //affichage par type de couche
              switch (coucheAAfficher.layer_type) {
                case glossaire_type_de_couche.Image:
                  const imageBounds = coucheAAfficher.bounds_image;

                  // const coucheImage = L.imageOverlay(coucheAAfficher.layer_path, coucheAAfficher.bounds_image).addTo(map_vue);
                  const coucheImage = L.imageOverlay(
                    coucheAAfficher.layer_path,
                    imageBounds
                  ).addTo(map_vue);
                  coucheImage.setZIndex(coucheAAfficher.position);
                  overlayMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ] = coucheImage;
                  break;
                case glossaire_type_de_couche.GeoJSON:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              layer_path: coucheAAfficher.layer_path,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                case glossaire_type_de_couche.QgisWms:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                default:
                  break;
              }
            }
            //cadre l'affichage sur les limites des coordonnées des couches
            // map_vue.fitBounds(zoomMinMax);
            map_vue.fitBounds(
              this.props.views_list_by_view_id[this.props.view_id]
                .view_bound_coordinates
            );
            this.setState({ boundsVue: zoomMinMax });
            //zoom à vole d'oiseau sur les limites des couches
            // map_vue.setView([(zoomMinMax[1][0] - zoomMinMax[0][0]) / 2, (zoomMinMax[1][1] - zoomMinMax[0][1]) / 2], -1);

            if (
              this.props.views_list_by_view_id[this.props.view_id].view_type !==
              glossaire_type_de_vue.tableau_de_bord
            ) {
              //ajout du panneau de control des couches
              let controlLayers = L.control
                .layers(baseMaps, overlayMaps, {
                  position: 'topleft',
                })
                .addTo(map_vue);
              this.setState({ controlLayers: controlLayers });
            }
          } else if (
            this.props.views_list_by_view_id[this.props.view_id]
              .view_projection === glossaire_repere.EPSG3949
          ) {
            let baseMaps = {};
            let overlayMaps = {};
            const listeCouches = this.props.views_layers_list[
              this.props.view_id
            ].sort((a, b) => {
              return a.position - b.position;
            });
            for (
              let indCouche = 0;
              indCouche < listeCouches.length;
              indCouche++
            ) {
              const coucheAAfficher = listeCouches[indCouche];
              switch (coucheAAfficher.layer_type) {
                case glossaire_type_de_couche.Image:
                  //todo: intégrer la projection des coordonnées à afficher (changement de repère)
                  // let imageLayer = new Image();
                  // imageLayer.onload = function () {

                  // };
                  // imageLayer.src = coucheAAfficher.layer_path;//result;
                  // const tailleImage = coucheAAfficher.bounds_image[1][0];
                  // let ratio = 1;
                  // if (imageLayer.height > 0 && imageLayer.width > 0) {
                  //     ratio = imageLayer.height > imageLayer.width ? imageLayer.height / tailleImage : imageLayer.width / tailleImage;//todo: ratio temporaire pour la dimension de l'image
                  // }
                  // let imageBounds = [
                  //     [coucheAAfficher.bounds_image[0][0], coucheAAfficher.bounds_image[0][1]],
                  //     [coucheAAfficher.bounds_image[0][0] + imageLayer.height / ratio, coucheAAfficher.bounds_image[0][1] + imageLayer.width / ratio]
                  // ];

                  const coucheImage = L.imageOverlay(
                    coucheAAfficher.layer_path,
                    coucheAAfficher.bounds_image
                  ).addTo(map_vue);
                  coucheImage.setZIndex(coucheAAfficher.position);
                  overlayMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ] = coucheImage;
                  break;
                case glossaire_type_de_couche.Tuile:
                  const coucheTuile = L.tileLayer(coucheAAfficher.layer_path, {
                    id: 'MapID',
                    tileSize: 512,
                    zoomOffset: -1,
                    maxZoom: 28,
                  }).addTo(map_vue);
                  baseMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ] = coucheTuile;
                  baseMaps[
                    coucheAAfficher.position + ': ' + coucheAAfficher.layer_name
                  ].setZIndex(coucheAAfficher.position);
                  break;
                case glossaire_type_de_couche.GeoJSON:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              layer_path: coucheAAfficher.layer_path,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                case glossaire_type_de_couche.QgisWms:
                  try {
                    let cheminURL = setUrlPathFromDomain(
                      coucheAAfficher.layer_path,
                      'https://' +
                        document.domain +
                        coucheAAfficher.layer_path.slice(
                          1,
                          coucheAAfficher.layer_path.length
                        )
                    );
                    fetch(cheminURL + '?varget0' + Math.random())
                      .then((res) => res.json())
                      .then((data) => {
                        this.setState({
                          coucheAAfficherApresJson: [
                            ...this.state.coucheAAfficherApresJson,
                            {
                              ...data,
                              name: coucheAAfficher.layer_name,
                              position: coucheAAfficher.position,
                            },
                          ],
                        });
                      });
                  } catch (e) {
                    console.error('error', e);
                  }
                  break;
                default:
                  break;
              }
            }

            //ajout du panneau de control des couches
            let controlLayers = L.control
              .layers(baseMaps, overlayMaps, {
                position: 'topleft',
              })
              .addTo(map_vue);
            this.setState({ controlLayers: controlLayers });
          }
          this.setState({ afficherCouche: false });
        }

        /**DEBUT affichage des objets de la vue**/
        if (this.props.data_objects_list_view !== undefined) {
          //inversion des latitude/longitude en longitude/latitude
          var yx = L.latLng;
          var xy = function (x, y) {
            if (L.Util.isArray(x)) {
              // When doing xy([x, y]);
              return yx(x[1], x[0]);
            }
            return yx(y, x); // When doing xy(x, y);
          };
          let markersClusterGroup;
          //initialisation du marker cluster
          if (this.state.markersClusterGroup !== undefined) {
            markersClusterGroup = this.state.markersClusterGroup;
          } else {
            markersClusterGroup = L.markerClusterGroup({
              maxClusterRadius:
                this.props.views_list_by_view_id[this.props.view_id]
                  .is_clustered === 0
                  ? 0
                  : niveauDePixelAvantCluster,
              iconCreateFunction: (cluster) => {
                let markerClusterColorLevel = -1;
                const listeMarkersEnfants = cluster.getAllChildMarkers();
                for (
                  let indMaker = 0;
                  indMaker < listeMarkersEnfants.length;
                  indMaker++
                ) {
                  const sensor_color_level =
                    listeMarkersEnfants[indMaker].options.icon.options.alt;
                  if (sensor_color_level !== undefined) {
                    markerClusterColorLevel = Math.max(
                      markerClusterColorLevel,
                      sensor_color_level
                    );
                  }
                }
                const markerClusterColor =
                  markClustColorArray[markerClusterColorLevel] !== 1
                    ? markClustColorArray[markerClusterColorLevel]
                    : S.MarkerCluster_grey;
                return L.divIcon({
                  html: '<b>' + cluster.getChildCount() + '</b>',
                  className:
                    'leaflet-marker-icon marker-cluster leaflet-zoom-animated leaflet-interactive ' +
                    markerClusterColor,
                });
              },
            });
          }

          /** relance l'affichage des composant à chaque fin de d'une animation (ex: clique sur le cluster)**/
          markersClusterGroup.on(
            'animationend',
            debounce(() => {
              this.setState({ afficherLesObjetsViaRender: true });
            }, 300)
          );

          // initialisation de la liste des objets à afficher
          let composantsARender = { ...this.state.objetsARender };
          // initialisation des états à actualiser à la fin
          let newGeopositionMarkersList = [
            ...this.state.geopositionMarkersList,
          ];
          let newChantierMarkersList = [...this.state.chantierMarkersList];
          let newMarkersList = { ...this.state.markersList };
          let newHistoriqueGeoPositionList = [
            ...this.state.historiqueGeoPositionList,
          ];
          //parcours de la liste des objets et création d'objet si non créé
          for (
            let indObjetVue = 0;
            indObjetVue < this.props.data_objects_list_view.length;
            indObjetVue++
          ) {
            let objetAAfficher = this.props.data_objects_list_view[indObjetVue];
            //si les coordonnées ne sont pas défini
            if (
              objetAAfficher.coord_y !== null ||
              objetAAfficher.coord_y !== '' ||
              objetAAfficher.coord_x !== null ||
              objetAAfficher.coord_x !== ''
            ) {
              //nom et position du marker
              const markerName = objetAAfficher.name;
              const markerIcon = objetAAfficher.icone_path;
              let positionMarker = xy(
                objetAAfficher.coord_y,
                objetAAfficher.coord_x
              );

              //initialisation de la popup information et du marker de l'objet
              let infoBulle;
              let markerObjet;
              //clé unique de la div de l'objet à afficher
              const cleUniqObjet =
                /*Math.random() +*/ objetAAfficher.viewobj_id +
                ' ' +
                this.props.uniciteVue;
              let draggable = false; // initialisation de l'option de drag and drop de l'objet à configurer
              let showToolTips = undefined; //initialisation de l'affichage de l'étiquette du marqueur
              if (
                this.props.recupCoord !== undefined &&
                this.props.theProps !== undefined &&
                this.props.theProps.objetAAfficher !== undefined &&
                this.props.theProps.objetAAfficher.viewobj_id !== undefined &&
                parseInt(objetAAfficher.viewobj_id) ===
                  parseInt(this.props.theProps.objetAAfficher.viewobj_id)
              ) {
                //si on est en mode edit et que l'id de l'objet est le même que l'objet à modifier
                // draggable = true; // /!\ après le déplacement la fonction .on("dragend", [...]) ne permet pas d'afficher l'objet à nouveau /!\ mais par contre permet de récupérer la position du marker
                showToolTips = true; // afficher le tooltip de manière permanente
              }
              //cas où l'objet n'est pas créé
              if (this.state.objetsARender[cleUniqObjet] === undefined) {
                //switch pour créer les objet en fonction des types d'objets
                switch (objetAAfficher.viewobj_type) {
                  case glossaire_type_d_objet.text:
                    let svgIconTexte = L.divIcon({
                      html: `<div id="${cleUniqObjet}"></div>`,
                      className: '',
                      iconSize: [dimensionsIconCapteur, dimensionsIconCapteur],
                      alt: 0,
                    });

                    composantsARender[cleUniqObjet] = {
                      viewobj_type: objetAAfficher.viewobj_type,
                      objetAAfficher: objetAAfficher,
                    };
                    markerObjet = L.marker(positionMarker, {
                      icon: svgIconTexte,
                      draggable: draggable,
                    });
                    break;
                  case glossaire_type_d_objet.capteur:
                    let sensor_color_class = S.SensorLevel_green;
                    let sensor_color_level = 0;
                    let svgIconCapteur;
                    if (
                      objetAAfficher.get_values_onload === 0 ||
                      objetAAfficher.get_metadata_onload === 0
                    ) {
                      // demande de rêquete recuperer_les_informations_complementaires_d_un_objet pour afficher plus d'information via un clique sur le marker
                      //afficher icon svg
                      svgIconCapteur = L.divIcon({
                        html: `<div id="${cleUniqObjet}" class="${S.SensorLevel_green}"></div>`,
                        className: '',
                        iconSize: [
                          dimensionsIconCapteur,
                          dimensionsIconCapteur,
                        ],
                        alt: 0,
                      });
                      markerObjet = L.marker(positionMarker, {
                        icon: svgIconCapteur,
                        draggable: draggable,
                      })
                        .bindTooltip(markerName, {
                          direction: 'right',
                          permanent: showToolTips,
                        })
                        .on('click', () =>
                          this.props.reqRecupererObjetAAfficherAssocieVue(
                            this.props.view_id,
                            objetAAfficher.viewobj_id
                          )
                        );
                    } else if (
                      objetAAfficher.get_values_onload === 1 &&
                      objetAAfficher.get_metadata_onload === 1 &&
                      objetAAfficher.data !== undefined
                    ) {
                      const listeDernieresDonnees = objetAAfficher.data;
                      //clé unique de la div de l'objet à afficher

                      /**DEBUT info-bulle**/
                      // titre
                      infoBulle = markerName + '<p>';
                      //composantes de capteur
                      for (
                        let indLastData = 0;
                        indLastData < listeDernieresDonnees.length;
                        indLastData++
                      ) {
                        const derniereValeur =
                          listeDernieresDonnees[indLastData].y[
                            listeDernieresDonnees[indLastData].y.length - 1
                          ]; //dernière valeur de la liste de données
                        infoBulle += `${listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${listeDernieresDonnees[indLastData].engineering_unit}<br />`;
                      }
                      infoBulle += '</p>';
                      /**FIN info-bulle**/

                      //arrière plan de icône (couleur du seuil) seuils de couleur: noir, rouge, orange, bleu, vert
                      const listeComposantes =
                        objetAAfficher.metadata.components;

                      //trouve la couleur du capteur en fonction de ses seuils (sensor_color_level)
                      let sensor_color = 0;

                      //initialise début des clés de seuils haut et bas
                      const seuilBas = 'alarmlevel_low';
                      const seuilHaut = 'alarmlevel_high';
                      const uts_present_moins_8h =
                        Date.now() / 1000 - 60 * 60 * 8;

                      //boucle sur les dernières données des composantes
                      for (
                        let indLastData = 0;
                        indLastData < listeDernieresDonnees.length;
                        indLastData++
                      ) {
                        //dernière valeurs de la composante de la liste
                        const derniereValeur =
                          listeDernieresDonnees[indLastData].y[
                            listeDernieresDonnees[indLastData].y.length - 1
                          ]; //dernière valeur de la liste de données
                        const dernierUTS =
                          listeDernieresDonnees[indLastData].x[
                            listeDernieresDonnees[indLastData].x.length - 1
                          ]; //dernière valeur de la liste de données
                        //boucle sur les seuils des composantes
                        for (
                          let indComposant = 0;
                          indComposant < listeComposantes.length;
                          indComposant++
                        ) {
                          //comparaison de la dernière donnée du capteur et du seuil si les position_in_sensor correspondent
                          if (
                            listeDernieresDonnees[indLastData]
                              .position_in_sensor ===
                            listeComposantes[indComposant].position_in_sensor
                          ) {
                            const composantes = listeComposantes[indComposant]; //liste des composantes (avec les seuils)
                            //comparaison des seuils et de la dernière données
                            if (dernierUTS < uts_present_moins_8h) {
                              sensor_color_level = -1;
                              break;
                            } else if (
                              (composantes.start_formula === null &&
                                null == composantes.end_formula) ||
                              (composantes.start_formula === null &&
                                dernierUTS <= composantes.end_formula) ||
                              (composantes.start_formula < dernierUTS &&
                                null == composantes.end_formula) ||
                              (composantes.start_formula < dernierUTS &&
                                dernierUTS <= composantes.end_formula)
                            ) {
                              if (
                                sensor_color_level < 4 &&
                                composantes[seuilBas + 4] !== undefined &&
                                composantes[seuilHaut + 4] !== undefined &&
                                ((composantes[seuilBas + 4] !== null &&
                                  derniereValeur < composantes[seuilBas + 4]) ||
                                  (composantes[seuilHaut + 4] !== null &&
                                    composantes[seuilHaut + 4] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 4;
                              } else if (
                                sensor_color_level < 3 &&
                                composantes[seuilBas + 3] !== undefined &&
                                composantes[seuilHaut + 3] !== undefined &&
                                ((composantes[seuilBas + 3] !== null &&
                                  derniereValeur < composantes[seuilBas + 3]) ||
                                  (composantes[seuilHaut + 3] !== null &&
                                    composantes[seuilHaut + 3] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 3;
                              } else if (
                                sensor_color_level < 2 &&
                                composantes[seuilBas + 2] !== undefined &&
                                composantes[seuilHaut + 2] !== undefined &&
                                ((composantes[seuilBas + 2] !== null &&
                                  derniereValeur < composantes[seuilBas + 2]) ||
                                  (composantes[seuilHaut + 2] !== null &&
                                    composantes[seuilHaut + 2] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 2;
                              } else if (
                                sensor_color_level < 1 &&
                                composantes[seuilBas + 1] !== undefined &&
                                composantes[seuilHaut + 1] !== undefined &&
                                ((composantes[seuilBas + 1] !== null &&
                                  derniereValeur < composantes[seuilBas + 1]) ||
                                  (composantes[seuilHaut + 1] !== null &&
                                    composantes[seuilHaut + 1] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 1;
                              }
                            }
                          }
                        }
                        if (sensor_color_level === -1) {
                          // cas où une valeur est ancienne de plus de 8h
                          break;
                        }
                      }
                      //attribution de la classe en fonction du seuils le plus critique sensor_color_level
                      const sensorColor = getSensorColor(sensor_color_level);
                      sensor_color = sensorColor.color;
                      sensor_color_class = sensorColor.classColor;
                      /**DEBUT dimension de l'image (du type de capteur)**/
                      let i = new Image();
                      i.src = markerIcon; //result;
                      /**FIN dimension de l'image (du type de capteur)**/
                    }

                    /**DEBUT fond de couleur du capteur (contour de couleur)**/
                    //afficher icon svg
                    svgIconCapteur = L.divIcon({
                      // html: `<div id="${cleUniqObjet}" class="${sensor_color_class}"></div>`,
                      html: `<div id="${cleUniqObjet}"></div>`,
                      className: '',
                      iconSize: [dimensionsIconCapteur, dimensionsIconCapteur],
                      alt: sensor_color_level,
                    });
                    if (
                      objetAAfficher.viewobj_type ===
                        glossaire_type_d_objet.capteur &&
                      objetAAfficher.metadata !== undefined &&
                      (objetAAfficher.metadata.id_type === 71 ||
                        objetAAfficher.metadata.id_type === 72)
                    ) {
                      //initialisation des données à récupérer
                      let positionX = null;
                      let positionXUnity = null;
                      let positionY = null;
                      let positionYUnity = null;
                      let rayon = null;
                      let rayonUnity = null;
                      let date = 0;
                      //boucle pour récupérer les dernières valeurs des composantes où les préfixes sont x,y et r depuis les données de la requête 10613
                      for (
                        let ind_data = 0;
                        ind_data < objetAAfficher.data.length;
                        ind_data++
                      ) {
                        if (
                          positionX === null &&
                          objetAAfficher.data[ind_data].prefix === 'x'
                        ) {
                          //cas où le préfixe est "x"
                          positionX =
                            objetAAfficher.data[ind_data].y[
                              objetAAfficher.data[ind_data].y.length - 1
                            ];
                          date =
                            objetAAfficher.data[ind_data].x[
                              objetAAfficher.data[ind_data].x.length - 1
                            ];
                          positionXUnity =
                            objetAAfficher.data[ind_data].engineering_unit;
                        }
                        if (
                          positionY === null &&
                          objetAAfficher.data[ind_data].prefix === 'y'
                        ) {
                          //cas où le préfixe est "y"
                          positionY =
                            objetAAfficher.data[ind_data].y[
                              objetAAfficher.data[ind_data].y.length - 1
                            ];
                          date =
                            objetAAfficher.data[ind_data].x[
                              objetAAfficher.data[ind_data].x.length - 1
                            ];
                          positionYUnity =
                            objetAAfficher.data[ind_data].engineering_unit;
                        }
                        if (
                          rayon === null &&
                          objetAAfficher.data[ind_data].prefix === 'r'
                        ) {
                          //cas où le préfixe est "r"
                          rayonUnity =
                            objetAAfficher.data[ind_data].engineering_unit;
                          date =
                            objetAAfficher.data[ind_data].x[
                              objetAAfficher.data[ind_data].x.length - 1
                            ];
                          rayon = adjustValueToProvidedDistanceUnit(
                            objetAAfficher.data[ind_data].y[
                              objetAAfficher.data[ind_data].y.length - 1
                            ],
                            rayonUnity
                          );
                        }
                        if (
                          positionX !== null &&
                          positionY !== null &&
                          rayon !== null
                        ) {
                          //cas où les deux préfixes ont été trouvés
                          break;
                        }
                      }
                      //création de l'objet json à afficher, marker et cercle
                      let geoJsonChantier = {
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: {
                              type: 'Point',
                              coordinates: [positionY, positionX],
                            },
                            properties: {
                              name: `${unixTimestampToDate(
                                date
                              )}, ( ${positionX}${positionXUnity} : ${positionY}${positionYUnity} ) , ${rayon}${rayonUnity}`,
                            },
                          },
                          createGeoJSONCircle([positionY, positionX], rayon),
                        ],
                      };
                      //ajout de la couche sur la vue et ajout du geojson à l'objet leaflet geoJson
                      markerObjet = L.geoJSON(geoJsonChantier, {
                        // transforme tous les objets geoJson point, de l'objet leaflet geoJson, en objet marker leaflet
                        pointToLayer: (geoJsonPoint, latlng) =>
                          L.marker([latlng.lat, latlng.lng], {
                            icon: svgIconCapteur,
                            draggable: draggable,
                          }).bindTooltip(markerName, {
                            direction: 'right',
                            permanent: showToolTips,
                          }),
                      }).bindTooltip(markerName, {
                        direction: 'right',
                        permanent: showToolTips,
                      });
                    } else {
                      markerObjet = L.marker(positionMarker, {
                        icon: svgIconCapteur,
                        draggable: draggable,
                      }).bindTooltip(markerName, {
                        direction: 'right',
                        permanent: showToolTips,
                      });
                    }

                    composantsARender[cleUniqObjet] = {
                      sensor_color_class: sensor_color_class,
                      viewobj_type: objetAAfficher.viewobj_type,
                      listeDernieresDonnees: objetAAfficher.data,
                      objetAAfficher: objetAAfficher,
                      markerObjet: markerObjet,
                    };
                    /**FIN fond de couleur du capteur (contour de couleur)**/
                    // //affichage de l'icone du capteur
                    break;
                  case glossaire_type_d_objet.graphe:
                    let svgIconGraphe;
                    if (
                      newMarkersList[cleUniqObjet] === undefined &&
                      objetAAfficher.get_values_onload === 1 &&
                      objetAAfficher.get_metadata_onload === 1 &&
                      objetAAfficher.data !== undefined &&
                      objetAAfficher.metadata !== undefined
                    ) {
                      if (
                        objetAAfficher.metadata.graph_type_id ===
                        glossaire_type_de_graphe.historique_geo_positions
                      ) {
                        let coords = [];

                        for (
                          let i = 0;
                          i < objetAAfficher.data.data[0].y.length;
                          i++
                        ) {
                          coords.push([
                            objetAAfficher.data.data[1].y[i],
                            objetAAfficher.data.data[0].y[i],
                          ]);
                          // coords.push([objetAAfficher.data.data[0].y[i], objetAAfficher.data.data[1].y[i]])
                        }

                        let json = {
                          type: 'FeatureCollection',
                          features: [
                            {
                              type: 'Feature',
                              geometry: {
                                type: 'LineString',
                                coordinates: coords,
                              },
                              properties: {
                                prop0: 'value0',
                                prop1: 0.0,
                              },
                            },
                          ],
                        };
                        for (
                          let i = 0;
                          i < objetAAfficher.data.data[0].y.length;
                          i++
                        ) {
                          json.features.push({
                            type: 'Feature',
                            geometry: {
                              type: 'Point',
                              coordinates: [
                                objetAAfficher.data.data[1].y[i],
                                objetAAfficher.data.data[0].y[i],
                              ],
                            },
                            properties: {
                              name: unixTimestampToDate(
                                objetAAfficher.data.data[1].x[i]
                              ),
                            },
                          });
                        }

                        const myIcon = L.divIcon({
                          html: `<div id="${cleUniqObjet}"></div>`,
                          className: S.iconHistoriqueGeoposition,
                        });
                        markerObjet = L.geoJSON(json, {
                          style: () => stylePolyline,
                          pointToLayer: (geoJsonPoint, latlng) =>
                            L.marker(latlng, { icon: myIcon }).bindTooltip(
                              geoJsonPoint.properties.name,
                              {
                                direction: 'right',
                                permanent: showToolTips,
                              }
                            ),
                        });

                        markerObjet['graph_id'] = objetAAfficher.graph_id;
                      } else {
                        const listeDernieresDonnees = objetAAfficher.data;
                        /**DEBUT info-bulle**/
                        // titre
                        infoBulle = markerName + '<p>';
                        //composantes de graphe
                        for (
                          let indLastData = 0;
                          indLastData < listeDernieresDonnees.length;
                          indLastData++
                        ) {
                          const derniereValeur =
                            listeDernieresDonnees[indLastData].y[
                              listeDernieresDonnees[indLastData].y.length - 1
                            ]; //dernière valeur de la liste de données
                          infoBulle += `${listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${listeDernieresDonnees[indLastData].engineering_unit}<br />`;
                        }
                        infoBulle += '</p>';
                        /**FIN info-bulle**/
                        /**DEBUT fond de couleur du graphe (contour de couleur)**/
                        //afficher icon svg
                        svgIconGraphe = L.divIcon({
                          html: `<div id="${cleUniqObjet}"></div>`,
                          className: '',
                          iconSize: [
                            dimensionsIconGraphe,
                            dimensionsIconGraphe,
                          ],
                        });
                        composantsARender[cleUniqObjet] = {
                          viewobj_type: objetAAfficher.viewobj_type,
                          objetAAfficher: objetAAfficher,
                        };
                        /**FIN fond de couleur du graphe (contour de couleur)**/
                        markerObjet = L.marker(positionMarker, {
                          icon: svgIconGraphe,
                          draggable: draggable,
                        }).bindTooltip(markerName, {
                          direction: 'right',
                          permanent: showToolTips,
                        });
                      }
                    } else if (
                      objetAAfficher.get_values_onload === 0 ||
                      objetAAfficher.get_metadata_onload === 0
                    ) {
                      svgIconGraphe = L.divIcon({
                        html: `<div id="${cleUniqObjet}" class="${
                          S.FondImageGraphe
                        }" onclick="${() =>
                          this.props.reqRecupererObjetAAfficherAssocieVue(
                            this.props.view_id,
                            objetAAfficher.viewobj_id
                          )}"></div>`,
                        className: '',
                        iconSize: [dimensionsIconGraphe, dimensionsIconGraphe],
                      });
                    }
                    break;
                  case glossaire_type_d_objet.vue:
                    let svgIconVue = L.divIcon({
                      html: `<div id="${cleUniqObjet}"></div>`,
                      className: '',
                      iconSize: [dimensionsIconCapteur, dimensionsIconCapteur],
                      alt: 0,
                    });

                    composantsARender[cleUniqObjet] = {
                      viewobj_type: objetAAfficher.viewobj_type,
                      objetAAfficher: objetAAfficher,
                    };
                    markerObjet = L.marker(positionMarker, {
                      icon: svgIconVue,
                      draggable: draggable,
                    });

                    break;
                  default:
                    console.warn(
                      "type d'objet inconnu:",
                      objetAAfficher.viewobj_type,
                      ' -> ',
                      objetAAfficher
                    );
                    break;
                }
              }
              //cas où l'objet est déjà créé (pour actualiser les données des objets après la req 10613)
              else {
                //switch pour récupérer distribuer les données en fonction des types d'objets
                switch (objetAAfficher.viewobj_type) {
                  case glossaire_type_d_objet.text:
                    break;
                  case glossaire_type_d_objet.capteur:
                    let sensor_color_class = S.SensorLevel_green;
                    let sensor_color_level = 0;
                    if (
                      objetAAfficher.get_values_onload === 1 &&
                      objetAAfficher.get_metadata_onload === 1
                    ) {
                      const listeDernieresDonnees = objetAAfficher.data;
                      //clé unique de la div de l'objet à afficher

                      /**DEBUT info-bulle**/
                      // titre
                      infoBulle = markerName + '<p>';
                      //composantes de capteur
                      for (
                        let indLastData = 0;
                        indLastData < listeDernieresDonnees.length;
                        indLastData++
                      ) {
                        const derniereValeur =
                          listeDernieresDonnees[indLastData].y[
                            listeDernieresDonnees[indLastData].y.length - 1
                          ]; //dernière valeur de la liste de données
                        infoBulle += `${listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${listeDernieresDonnees[indLastData].engineering_unit}<br />`;
                      }
                      infoBulle += '</p>';
                      /**FIN info-bulle**/

                      //arrière plan de icône (couleur du seuil) seuils de couleur: noir, rouge, orange, bleu, vert
                      const listeComposantes =
                        objetAAfficher.metadata.components;

                      //trouve la couleur du capteur en fonction de ses seuils (sensor_color_level)
                      let sensor_color = 0;

                      //initialise début des clés de seuils haut et bas
                      const seuilBas = 'alarmlevel_low';
                      const seuilHaut = 'alarmlevel_high';

                      const uts_present_moins_8h =
                        Date.now() / 1000 - 60 * 60 * 8;

                      //boucle sur les dernières données des composantes
                      for (
                        let indLastData = 0;
                        indLastData < listeDernieresDonnees.length;
                        indLastData++
                      ) {
                        //dernière valeurs de la composante de la liste
                        const derniereValeur =
                          listeDernieresDonnees[indLastData].y[
                            listeDernieresDonnees[indLastData].y.length - 1
                          ]; //dernière valeur de la liste de données
                        const dernierUTS =
                          listeDernieresDonnees[indLastData].x[
                            listeDernieresDonnees[indLastData].x.length - 1
                          ]; //dernière valeur de la liste de données
                        //boucle sur les seuils des composantes
                        for (
                          let indComposant = 0;
                          indComposant < listeComposantes.length;
                          indComposant++
                        ) {
                          //comparaison de la dernière donnée du capteur et du seuil si les position_in_sensor correspondent
                          if (
                            listeDernieresDonnees[indLastData]
                              .position_in_sensor ===
                            listeComposantes[indComposant].position_in_sensor
                          ) {
                            const composantes = listeComposantes[indComposant]; //liste des composantes (avec les seuils)
                            //comparaison des seuils et de la dernière données
                            if (dernierUTS < uts_present_moins_8h) {
                              sensor_color_level = -1;
                            } else if (
                              (composantes.start_formula === null &&
                                null == composantes.end_formula) ||
                              (composantes.start_formula === null &&
                                dernierUTS <= composantes.end_formula) ||
                              (composantes.start_formula < dernierUTS &&
                                null == composantes.end_formula) ||
                              (composantes.start_formula < dernierUTS &&
                                dernierUTS <= composantes.end_formula)
                            ) {
                              if (
                                sensor_color_level < 4 &&
                                composantes[seuilBas + 4] !== undefined &&
                                composantes[seuilHaut + 4] !== undefined &&
                                ((composantes[seuilBas + 4] !== null &&
                                  derniereValeur < composantes[seuilBas + 4]) ||
                                  (composantes[seuilHaut + 4] !== null &&
                                    composantes[seuilHaut + 4] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 4;
                              } else if (
                                sensor_color_level < 3 &&
                                composantes[seuilBas + 3] !== undefined &&
                                composantes[seuilHaut + 3] !== undefined &&
                                ((composantes[seuilBas + 3] !== null &&
                                  derniereValeur < composantes[seuilBas + 3]) ||
                                  (composantes[seuilHaut + 3] !== null &&
                                    composantes[seuilHaut + 3] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 3;
                              } else if (
                                sensor_color_level < 2 &&
                                composantes[seuilBas + 2] !== undefined &&
                                composantes[seuilHaut + 2] !== undefined &&
                                ((composantes[seuilBas + 2] !== null &&
                                  derniereValeur < composantes[seuilBas + 2]) ||
                                  (composantes[seuilHaut + 2] !== null &&
                                    composantes[seuilHaut + 2] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 2;
                              } else if (
                                sensor_color_level < 1 &&
                                composantes[seuilBas + 1] !== undefined &&
                                composantes[seuilHaut + 1] !== undefined &&
                                ((composantes[seuilBas + 1] !== null &&
                                  derniereValeur < composantes[seuilBas + 1]) ||
                                  (composantes[seuilHaut + 1] !== null &&
                                    composantes[seuilHaut + 1] <
                                      derniereValeur))
                              ) {
                                sensor_color_level = 1;
                              }
                            }
                          }
                        }
                        if (sensor_color_level === -1) {
                          // cas où une valeur est ancienne de plus de 8h
                          break;
                        }
                      }
                      const sensorColor = getSensorColor(sensor_color_level);
                      sensor_color = sensorColor.color;
                      sensor_color_class = sensorColor.classColor;
                      /**DEBUT dimension de l'image (du type de capteur)**/
                      let i = new Image();
                      i.src = markerIcon; //result;
                      /**FIN dimension de l'image (du type de capteur)**/
                    }

                    composantsARender[cleUniqObjet] = {
                      sensor_color_class: sensor_color_class,
                      viewobj_type: objetAAfficher.viewobj_type,
                      listeDernieresDonnees: objetAAfficher.data,
                      objetAAfficher: objetAAfficher,
                    };
                    /**FIN fond de couleur du capteur (contour de couleur)**/
                    // affichage de l'icône du capteur
                    break;
                  case glossaire_type_d_objet.graphe:
                    let svgIconGraphe;

                    if (
                      objetAAfficher.get_values_onload === 1 &&
                      objetAAfficher.get_metadata_onload === 1 &&
                      objetAAfficher.data !== undefined &&
                      objetAAfficher.metadata !== undefined
                    ) {
                      if (
                        objetAAfficher.metadata.graph_type_id ===
                        glossaire_type_de_graphe.historique_geo_positions
                      ) {
                        // let coords = []
                        //
                        // for (let i = 0; i < 2; i++) {
                        //     coords.push([Math.random() * 11 - 4, Math.random() * 10 + 41.5])
                        // }
                        // // for(let i=0;i<grapheAAfficher.data[0].y.length;i++){
                        // //     coords.push([grapheAAfficher.data[1].y[i],grapheAAfficher.data[0].y[i]])
                        // // }
                        //
                        // let spiral = [{
                        //     "type": "LineString",
                        //     "coordinates": coords
                        // }];
                        // markerObjet = L.geoJson(spiral, {
                        //     style: {
                        //         "color": "#d44a00",
                        //         "weight": 6,
                        //         "opacity": 0.3,
                        //         // "dasharray":"3 1"
                        //     }
                        // })
                        // markerObjet.addTo(map_vue)
                        // //todo: affichage d'un bouton de changement de valeurs
                        // // this.setState({spiralLayers: [...newSpiralLayers, spiralLayer]})
                        // // // this.props.map.removeLayer(spiralLayer)
                        // // representation_vue = <div key={this.props.key} style={{
                        // //     position: "absolute",
                        // //     bottom: 0,
                        // //     left: 0
                        // // }}>
                        // //     <Bouton
                        // //         cliquer={this.switchAfficherModifierIntervalle}>TEST</Bouton>
                        // //     <ChangementIntervalTempsGraphe
                        // //         graph_id={this.props.objetAAfficher.graph_id}
                        // //         afficherPopup={this.state.afficherModifierIntervalle}
                        // //         fermerPopupModifierIntervalle={this.switchAfficherModifierIntervalle}
                        // //         changementEchantillonObjetGrapheVue={() => this.setState({afficherEchantillonPersonnalise: true})}/>
                        // // </div>
                        // // break;
                      } else {
                        const listeDernieresDonnees = objetAAfficher.data;
                        /**DEBUT info-bulle**/
                        // titre
                        infoBulle = markerName + '<p>';
                        //composantes de graphe
                        for (
                          let indLastData = 0;
                          indLastData < listeDernieresDonnees.length;
                          indLastData++
                        ) {
                          const derniereValeur =
                            listeDernieresDonnees[indLastData].y[
                              listeDernieresDonnees[indLastData].y.length - 1
                            ]; //dernière valeur de la liste de données
                          infoBulle += `${listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${listeDernieresDonnees[indLastData].engineering_unit}<br />`;
                        }
                        infoBulle += '</p>';
                        /**FIN info-bulle**/
                        /**DEBUT fond de couleur du graphe (contour de couleur)**/
                        //afficher icon svg
                        svgIconGraphe = L.divIcon({
                          html: `<div id="${cleUniqObjet}"></div>`,
                          className: '',
                          iconSize: [
                            dimensionsIconGraphe,
                            dimensionsIconGraphe,
                          ],
                        });
                        composantsARender[cleUniqObjet] = {
                          viewobj_type: objetAAfficher.viewobj_type,
                          objetAAfficher: objetAAfficher,
                        };
                        /**FIN fond de couleur du graphe (contour de couleur)**/
                        markerObjet = L.marker(positionMarker, {
                          icon: svgIconGraphe,
                          draggable: draggable,
                        }).bindTooltip(markerName, {
                          direction: 'right',
                          permanent: showToolTips,
                        });
                      }
                    }
                    break;
                  // case glossaire_type_d_objet.vue:
                  //
                  //     let svgIconVue = L.divIcon({
                  //         html: `<div id="${cleUniqObjet}"></div>`,
                  //         className: "",
                  //         iconSize: [dimensionsIconCapteur, dimensionsIconCapteur],
                  //         alt: 0
                  //     });
                  //
                  //     composantsARender[cleUniqObjet] = {
                  //         viewobj_type: objetAAfficher.viewobj_type,
                  //         objetAAfficher: objetAAfficher,
                  //     }
                  //     markerObjet = L.marker(positionMarker, {
                  //         icon: svgIconVue,
                  //         draggable: draggable
                  //     });
                  //
                  //     break;
                  default:
                    console.warn(
                      "type d'objet inconnu:",
                      objetAAfficher.viewobj_type,
                      ' -> ',
                      objetAAfficher
                    );
                    break;
                }
              }
              //ajoute l'objet à la liste des markersList
              if (
                markerObjet !== undefined &&
                ((markerObjet._latlng !== null &&
                  markerObjet._latlng !== undefined &&
                  markerObjet._latlng.lat !== null &&
                  markerObjet._latlng.lat !== undefined &&
                  markerObjet._latlng.lng !== null &&
                  markerObjet._latlng.lng !== undefined) ||
                  markerObjet._layers !== undefined)
              ) {
                //modifie la position longitude, latitude de l'objet si c'est un capteur avec pour capteur_type 69 ou 70
                if (
                  newGeopositionMarkersList.indexOf(cleUniqObjet) < 0 &&
                  objetAAfficher.viewobj_type ===
                    glossaire_type_d_objet.capteur &&
                  objetAAfficher.metadata !== undefined &&
                  (objetAAfficher.metadata.id_type === 69 ||
                    objetAAfficher.metadata.id_type === 70 ||
                    objetAAfficher.metadata.id_type === 87 ||
                    objetAAfficher.sensor_type_display === geoposition)
                ) {
                  //todo: réflechir à un moyen de définir la géoposition selon une clé spécifique du capteur (et non l'id du type de capteur)
                  //affiche le capteur à la position géolocalisé des composantes x, y respectivement longitude, latitude
                  let positionX = null;
                  let positionY = null;
                  //boucle pour récupérer les dernières valeurs des composantes où les préfixes sont x,y depuis les données de la requête 10613
                  for (
                    let ind_data = 0;
                    ind_data < objetAAfficher.data.length;
                    ind_data++
                  ) {
                    if (
                      positionX === null &&
                      objetAAfficher.data[ind_data].prefix === 'x'
                    ) {
                      //cas où le préfixe est "x"
                      positionX =
                        objetAAfficher.data[ind_data].y[
                          objetAAfficher.data[ind_data].y.length - 1
                        ];
                    }
                    if (
                      positionY === null &&
                      objetAAfficher.data[ind_data].prefix === 'y'
                    ) {
                      //cas où le préfixe est "y"
                      positionY =
                        objetAAfficher.data[ind_data].y[
                          objetAAfficher.data[ind_data].y.length - 1
                        ];
                    }
                    if (positionX !== null && positionY !== null) {
                      //cas où les deux préfixes ont été trouvés
                      break;
                    }
                  }
                  if (
                    positionX !== null &&
                    positionY !== null &&
                    markerObjet.setLatLng instanceof Function
                  ) {
                    //converti les données trouver dans le repère de leaflet
                    let newLatLng = new L.LatLng(positionX, positionY);
                    // édite la position des markers
                    markerObjet.setLatLng(newLatLng);
                    // ajoute la clé de l'objet au capteur
                    newGeopositionMarkersList = [
                      ...newGeopositionMarkersList,
                      cleUniqObjet,
                    ];
                  }
                } else if (
                  objetAAfficher.viewobj_type ===
                    glossaire_type_d_objet.capteur &&
                  objetAAfficher.metadata !== undefined &&
                  (objetAAfficher.metadata.id_type === 71 ||
                    objetAAfficher.metadata.id_type === 72)
                ) {
                  //todo: réflechir à un moyen de définir la géoposition selon une clé spécifique du capteur (et non l'id du type de capteur)
                  // ajoute la clé de l'objet au capteur
                  newChantierMarkersList = [
                    ...newChantierMarkersList,
                    cleUniqObjet,
                  ];
                }

                //ajoute l'id du marker historique de positions dans la liste des capteurs
                if (
                  newHistoriqueGeoPositionList.indexOf(cleUniqObjet) < 0 &&
                  objetAAfficher.viewobj_type ===
                    glossaire_type_d_objet.graphe &&
                  objetAAfficher.metadata !== undefined &&
                  objetAAfficher.metadata.graph_type_id ===
                    glossaire_type_de_graphe.historique_geo_positions
                ) {
                  //todo: réflechir à un moyen de définir la géoposition selon une clé spécifique du capteur (et non l'id du type de capteur)
                  // ajoute la clé de l'objet au capteur
                  newHistoriqueGeoPositionList = [
                    ...newHistoriqueGeoPositionList,
                    cleUniqObjet,
                  ];
                }
                //ajout du marker au plugin de markerCluster
                markersClusterGroup.addLayer(markerObjet);
                //ajoute "viewobj_id" à l'objet marker leaflet
                markerObjet['viewobj_id'] = objetAAfficher.viewobj_id;
                //ajoute l'objet marker à la nouvelle liste de marker
                newMarkersList[cleUniqObjet] = markerObjet;
              }
            }
          }

          this.setState({
            markersList: { ...newMarkersList }, //actualise la liste de markers
            geopositionMarkersList: [...newGeopositionMarkersList], //actualise la liste des clés uniques d'objets markers
            chantierMarkersList: [...newChantierMarkersList], //actualise la liste des clés uniques d'objets markers
            historiqueGeoPositionList: [...newHistoriqueGeoPositionList], //actualise la liste des clés uniques d'objets markers
          });
          //si les div sont affichés
          let divDejaAfficher = this.state.divDejaAfficher;

          if (!divDejaAfficher) {
            map_vue.addLayer(markersClusterGroup);

            this.setState({ divDejaAfficher: true });
          }
          map_vue.whenReady(() =>
            setTimeout(() => map_vue.invalidateSize(), 300)
          );
          this.setState({
            objetsARender: composantsARender,
            markersClusterGroup: markersClusterGroup,
          });
        }
        /**FIN affichage des objets de la vue**/
      } else {
        //si les configurations de vue ne sont pas récupérés
        this.props.reqRecupererVue(this.props.view_id);
      }

      if (
        afficherApresReq10604 &&
        afficherApresReq10613 &&
        this.state.map_vue !== null &&
        this.props.views_list_by_view_id[this.props.view_id]
          .view_zoom_levels[0] ===
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[1] &&
        this.state.map_vue.getMinZoom() !== this.state.map_vue.getMaxZoom()
      ) {
        //cas où la vue à 1 seul zoom non entier (zoom décimal), il faut attendre que les données de capteur s'affiche avant de le setzoom d'un nombre décimal
        this.state.map_vue.setZoom(
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[0]
        );
        this.state.map_vue.setMinZoom(
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[0]
        );
      }
    }
    //Afficher/reafficher les objets à render dans le ReactDom
    else {
      //Recadre la vue automatiquement si view_flag_fix_pos == 1
      if (
        this.state.map_vue !== null &&
        this.props.views_list_by_view_id[this.props.view_id]
          .view_flag_fix_pos === 1
      ) {
        if (
          !jsDictionaryEquals(
            this.state.endBounds,
            this.state.map_vue.getBounds()
          ) &&
          this.state.boundsVue !== undefined
        ) {
          // this.state.map_vue.removeControl(this.state.map_vue.zoomControl);
          // this.setState({
          //     endBounds: this.state.map_vue.getBounds()
          // })
        }
      }
      //niveau de zoom fix  si view_zoom_levels === "[null]"
      if (
        this.state.map_vue !== null &&
        JSON.stringify(
          this.props.views_list_by_view_id[this.props.view_id].view_zoom_levels
        ) === '[null]'
      ) {
        if (
          !jsDictionaryEquals(
            this.state.endBounds,
            this.state.map_vue.getBounds()
          ) &&
          this.state.boundsVue !== undefined
        ) {
          // this.state.map_vue.fitBounds(this.state.boundsVue, {padding: [150, 150]});
          this.state.map_vue.removeControl(this.state.map_vue.zoomControl);
          const zoom_fix = this.state.map_vue.getZoom();
          this.state.map_vue.setMaxZoom(zoom_fix);
          // this.state.map_vue.setMinZoom(zoom_fix);
          this.state.map_vue.touchZoom.disable();
          this.state.map_vue.doubleClickZoom.disable();
          this.state.map_vue.scrollWheelZoom.disable();
          this.state.map_vue.boxZoom.disable();
          this.state.map_vue.keyboard.disable();
          // this.setState({
          //     endBounds: this.state.map_vue.getBounds()
          // })
        }
      }

      //Affiche les couche WMS de QGIS
      if (
        this.state.coucheAAfficherApresJson.length > 0 &&
        this.state.controlLayers !== undefined
      ) {
        //affiche les couches de tuiles via le json
        for (let json_couche of this.state.coucheAAfficherApresJson.sort(
          (a, b) => {
            return a.position - b.position;
          }
        )) {
          let json = { ...json_couche };
          if (json.type !== undefined) {
            // Définition des projections de départ et d'arrivée
            proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
            proj4.defs(
              'EPSG:3949',
              '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +units=m +no_defs'
            );
            switch (json.type) {
              case 'FeatureCollection':
                json.features.forEach((feature) => {
                  feature.geometry.coordinates = proj4(
                    'EPSG:4326',
                    'EPSG:3949',
                    feature.geometry.coordinates
                  );
                });
                const coucheGeoJSONFeatureCollection = L.geoJSON(json).addTo(
                  this.state.map_vue
                );
                this.state.controlLayers.addOverlay(
                  coucheGeoJSONFeatureCollection,
                  json.name
                );
                break;
              case 'Feature':
                json = {
                  ...json,
                  ...projectLineString(json, 'EPSG:4326', 'EPSG:4326'),
                };

                const coucheGeoJSONFeature = L.polyline(
                  json.geometry.coordinates
                );
                const coucheGeoJSONFeatureArrow = L.polylineDecorator(
                  json.geometry.coordinates,
                  {
                    patterns: [
                      {
                        offset: 25,
                        repeat: 50,
                        symbol: L.Symbol.arrowHead({
                          pixelSize: 15,
                          pathOptions: {
                            fillOpacity: 1,
                            weight: 0,
                          },
                        }),
                      },
                    ],
                  }
                );
                const avancement_tunnelier = L.layerGroup([
                  coucheGeoJSONFeature,
                  coucheGeoJSONFeatureArrow,
                ]).addTo(this.state.map_vue);
                this.state.controlLayers.addOverlay(
                  avancement_tunnelier,
                  json.name
                );
                json['layer_historique_name'] = json.name;
                break;
              default:
                break;
            }
          } else {
            const QgisWMSText = json;
            const QgisWMS = QgisWMSText; // convert it to an object
            const nexrad = L.tileLayer
              .wms(QgisWMS.url, {
                maxZoom: 28,
                ...QgisWMS.parametre,
              })
              .addTo(this.state.map_vue);
            this.state.controlLayers.addOverlay(nexrad, QgisWMSText.name);
            json['layer_historique_name'] = QgisWMSText.name;
          }
          if (this.state.historique_couches === undefined) {
            this.setState({
              historique_couches: [json],
              changement_date: true,
            });
          } else {
            this.setState({
              historique_couches: [...this.state.historique_couches, json],
              changement_date: true,
            });
          }
        }

        this.setState({ coucheAAfficherApresJson: [] });
      }
      // Ajoute un bouton pour changer les couches modifiables selon les dates sélectionnés
      if (
        this.state.historique_couches !== undefined &&
        this.state.dateControl === undefined
      ) {
        let dateControl;
        // ajout d'un bouton de contrôle qui ouvre une popup avec un formulaire de date
        dateControl = L.control.layers(
          {},
          {},
          {
            position: 'topleft',
          }
        );
        dateControl.onAdd = (map) => {
          var div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');

          div.innerHTML = '<div id="div_changement_date">test</div>';

          return div;
        };
        dateControl.addTo(this.state.map_vue);

        this.setState({ dateControl: dateControl });
      }
      if (
        this.state.historique_couches !== undefined &&
        (this.state.changement_date === true ||
          jsDictionaryEquals(
            this.state.historique_couches,
            prevProps.historique_couches
          ))
      ) {
        ReactDOM.render(
          <Provider store={store}>
            <React.StrictMode>
              <FormulaireDateDeCouche
                hoverText={checkMot('sensor_type')}
                typeBoutonAffichage={type_btn.outil}
                ajoutOverlay={S.Overlay}
                eventAuCliqueOuverture={() =>
                  this.setState({ changement_date: true })
                }
                eventAuCliqueFermeture={() =>
                  this.setState({ changement_date: false })
                }
                historique_couches={this.state.historique_couches}
                icone={<VscListUnordered size={15} />}
                map_vue={this.state.map_vue}
                controlLayers={this.state.controlLayers}
                ajout_avancement_tunnelier={(json) => {
                  json = {
                    ...json,
                    ...projectLineString(json, 'EPSG:4326', 'EPSG:4326'),
                  };

                  const coucheGeoJSONFeature = L.polyline(
                    json.geometry.coordinates
                  );
                  const coucheGeoJSONFeatureArrow = L.polylineDecorator(
                    json.geometry.coordinates,
                    {
                      patterns: [
                        {
                          offset: 25,
                          repeat: 50,
                          symbol: L.Symbol.arrowHead({
                            pixelSize: 15,
                            pathOptions: {
                              fillOpacity: 1,
                              weight: 0,
                            },
                          }),
                        },
                      ],
                    }
                  );
                  const avancement_tunnelier = L.layerGroup([
                    coucheGeoJSONFeature,
                    coucheGeoJSONFeatureArrow,
                  ]).addTo(this.state.map_vue);
                  this.state.controlLayers.addOverlay(
                    avancement_tunnelier,
                    json.name
                  );
                  json['layer_historique_name'] = json.name;
                }}
                ajoutLayer={(layer) =>
                  this.setState({
                    coucheAAfficherApresJson: [
                      ...this.state.coucheAAfficherApresJson,
                      layer,
                    ],
                  })
                }
              >
                {/*<div style={{padding: "5px"}}>*/}
                {/*    <h5>{checkMot("couche_avec_historique")}</h5>*/}
                {/*    {this.state.historique_couches.map(couche=><ul>{couche.name}</ul>)}*/}
                {/*</div>*/}
              </FormulaireDateDeCouche>
            </React.StrictMode>
          </Provider>,
          document.getElementById('div_changement_date')
        );
        this.setState({ changement_date: false });
      }

      if (this.state.afficherLesObjetsViaRender) {
        this.setState({
          afficherLesObjetsViaRender: false,
        });
        for (const keyComposant in this.state.objetsARender) {
          const composantARender = this.state.objetsARender[keyComposant];
          if (
            composantARender !== undefined &&
            composantARender.viewobj_type !== undefined &&
            document.getElementById(keyComposant) !== null
          ) {
            switch (composantARender.viewobj_type) {
              case glossaire_type_d_objet.text:
                ReactDOM.render(
                  <Provider store={store}>
                    <React.StrictMode>
                      <ObjetTexte
                        key={keyComposant}
                        objetAAfficher={composantARender.objetAAfficher}
                        view_id={this.props.view_id}
                        dimensionsIcon={dimensionsIconGraphe}
                        mapZoom={2 ** this.state.map_vue.getZoom()}
                      />
                    </React.StrictMode>
                  </Provider>,
                  document.getElementById(keyComposant)
                );
                break;
              case glossaire_type_d_objet.capteur:
                ReactDOM.render(
                  <Provider store={store}>
                    <React.StrictMode>
                      <ObjetCapteur
                        key={keyComposant}
                        view_id={this.props.view_id}
                        listeDernieresDonnees={
                          composantARender.listeDernieresDonnees
                        }
                        objetAAfficher={composantARender.objetAAfficher}
                        dimensionsIcon={dimensionsIconCapteur}
                        sensor_color_class={composantARender.sensor_color_class}
                        mapZoom={2 ** this.state.map_vue.getZoom()}
                      />
                    </React.StrictMode>
                  </Provider>,
                  document.getElementById(keyComposant)
                );
                break;
              case glossaire_type_d_objet.graphe:
                ReactDOM.render(
                  <Provider store={store}>
                    <React.StrictMode>
                      <ObjetGraphe
                        key={keyComposant}
                        objetAAfficher={composantARender.objetAAfficher}
                        dimensionsIcon={dimensionsIconGraphe}
                        mapZoom={2 ** this.state.map_vue.getZoom()}
                      />
                    </React.StrictMode>
                  </Provider>,
                  document.getElementById(keyComposant)
                );
                break;
              case glossaire_type_d_objet.vue:
                ReactDOM.render(
                  <Provider store={store}>
                    <React.StrictMode>
                      <ObjetVue
                        key={keyComposant}
                        objetAAfficher={composantARender.objetAAfficher}
                        view_id={this.props.view_id}
                        dimensionsIcon={dimensionsIconGraphe}
                        mapZoom={2 ** this.state.map_vue.getZoom()}
                      />
                    </React.StrictMode>
                  </Provider>,
                  document.getElementById(keyComposant)
                );
                break;
              default:
                console.warn(
                  "type d'objet inconnu:",
                  composantARender.viewobj_type,
                  ' -> ',
                  composantARender
                );
                break;
            }
          }
        }
        if (
          !this.state.afficherApresReq10604 &&
          !this.state.afficherApresReq10613 &&
          this.state.map_vue !== null &&
          this.state.map_vue.getZoom() !==
            this.props.views_list_by_view_id[this.props.view_id]
              .view_zoom_levels[0] &&
          this.props.views_list_by_view_id[this.props.view_id]
            .view_zoom_levels[0] ===
            this.props.views_list_by_view_id[this.props.view_id]
              .view_zoom_levels[1]
        ) {
          //cas où la vue à 1 seul zoom non entier (zoom décimal), il faut attendre que les données de capteur s'affiche avant de le setzoom d'un nombre décimal
          this.state.map_vue.setZoom(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_zoom_levels[0]
          );
          this.state.map_vue.setMinZoom(
            this.props.views_list_by_view_id[this.props.view_id]
              .view_zoom_levels[0]
          );
        }
      }

      if (this.props.recupCoord !== undefined) {
        //affiche l'objet à editer
        const keyComposant = 'editer';
        if (document.getElementById(keyComposant) !== null) {
          let theProps = { ...this.props.theProps };
          switch (theProps.objetAAfficher.viewobj_type) {
            case glossaire_type_d_objet.capteur:
              ReactDOM.render(
                <Provider store={store}>
                  <React.StrictMode>
                    <ObjetCapteur
                      key={keyComposant}
                      view_id={this.props.view_id}
                      listeDernieresDonnees={theProps.listeDernieresDonnees}
                      objetAAfficher={theProps.objetAAfficher}
                      dimensionsIcon={dimensionsIconCapteur}
                      sensor_color_class={theProps.sensor_color_class}
                      viewobj_json_content={this.props.viewobj_json_content}
                      mapZoom={2 ** this.state.map_vue.getZoom()}
                    />
                  </React.StrictMode>
                </Provider>,
                document.getElementById(keyComposant)
              );
              break;
            case glossaire_type_d_objet.graphe:
              ReactDOM.render(
                <Provider store={store}>
                  <React.StrictMode>
                    <ObjetGraphe
                      key={keyComposant}
                      objetAAfficher={theProps.objetAAfficher}
                      dimensionsIcon={dimensionsIconGraphe}
                      viewobj_json_content={this.props.viewobj_json_content}
                      mapZoom={2 ** this.state.map_vue.getZoom()}
                    />
                  </React.StrictMode>
                </Provider>,
                document.getElementById(keyComposant)
              );
              break;
            case glossaire_type_d_objet.vue:
              ReactDOM.render(
                <Provider store={store}>
                  <React.StrictMode>
                    <ObjetVue
                      key={keyComposant}
                      objetAAfficher={theProps.objetAAfficher}
                      view_id={this.props.view_id}
                      dimensionsIcon={dimensionsIconGraphe}
                      viewobj_json_content={this.props.viewobj_json_content}
                      mapZoom={2 ** this.state.map_vue.getZoom()}
                    />
                  </React.StrictMode>
                </Provider>,
                document.getElementById(keyComposant)
              );
              break;
            default:
              break;
          }
        }
      }
    }

    // Repositionner le marker capteur avec geo-position en fonction de la dernière valeur
    if (
      this.props.data_objects_list_view !== undefined &&
      this.state.geopositionMarkersList.length > 0 &&
      this.state.afficherApresReq10613 &&
      !(
        this.props.attenteReqListe[
          'ATTENTE_REQUETE' +
            glossaire_requetes.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue
        ] === true
      )
    ) {
      //affiche le capteur à la position géolocalisé des composantes x, y respectivement longitude, latitude
      for (
        let ind_data_objects = 0;
        ind_data_objects < this.props.data_objects_list_view.length;
        ind_data_objects++
      ) {
        for (let geopositionMarkerKey of this.state.geopositionMarkersList) {
          if (
            this.state.markersList[geopositionMarkerKey] !== undefined &&
            this.state.markersList[geopositionMarkerKey].viewobj_id ===
              this.props.data_objects_list_view[ind_data_objects].viewobj_id
          ) {
            //initialisation des données à récupérer
            let positionX = null;
            let positionY = null;
            //boucle pour récupérer les dernières valeurs des composantes où les préfixes sont x,y depuis les données de la requête 10613
            for (
              let ind_data = 0;
              ind_data <
              this.props.data_objects_list_view[ind_data_objects].data.length;
              ind_data++
            ) {
              if (
                positionX === null &&
                this.props.data_objects_list_view[ind_data_objects].data[
                  ind_data
                ].prefix === 'x'
              ) {
                //cas où le préfixe est "x"
                positionX =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].y[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].y.length - 1
                  ];
              }
              if (
                positionY === null &&
                this.props.data_objects_list_view[ind_data_objects].data[
                  ind_data
                ].prefix === 'y'
              ) {
                //cas où le préfixe est "y"
                positionY =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].y[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].y.length - 1
                  ];
              }
              if (positionX !== null && positionY !== null) {
                //cas où les deux préfixes ont été trouvés
                break;
              }
            }
            //converti les données trouver dans le repère de leaflet
            let newLatLng = new L.LatLng(positionX, positionY);
            // édite la position des markers
            this.state.markersList[geopositionMarkerKey].setLatLng(newLatLng);
          }
        }
      }
    }
    // Repositionner les markers capteurs chantiers avec geoposition et rayon de délimitation en fonction des dernières valeurs
    if (
      this.props.data_objects_list_view !== undefined &&
      this.state.chantierMarkersList.length > 0 &&
      this.state.afficherApresReq10613 &&
      !(
        this.props.attenteReqListe[
          'ATTENTE_REQUETE' +
            glossaire_requetes.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue
        ] === true
      )
    ) {
      //affiche le capteur à la position géolocalisé des composantes x, y respectivement longitude, latitude
      for (
        let ind_data_objects = 0;
        ind_data_objects < this.props.data_objects_list_view.length;
        ind_data_objects++
      ) {
        for (let chantierMarkerKey of this.state.chantierMarkersList) {
          if (
            this.state.markersList[chantierMarkerKey] !== undefined &&
            this.state.markersList[chantierMarkerKey].viewobj_id ===
              this.props.data_objects_list_view[ind_data_objects].viewobj_id
          ) {
            //initialisation de la liste des couches à supprimer
            const removeLayersList = [
              ...this.state.markersList[chantierMarkerKey].getLayers(),
            ];
            //initialisation des données à récupérer
            let positionX = null;
            let positionXUnity = null;
            let positionY = null;
            let positionYUnity = null;
            let rayon = null;
            let rayonUnity = null;
            let date = 0;
            //boucle pour récupérer les dernières valeurs des composantes où les préfixes sont x,y et r depuis les données de la requête 10613
            for (
              let ind_data = 0;
              ind_data <
              this.props.data_objects_list_view[ind_data_objects].data.length;
              ind_data++
            ) {
              if (
                positionX === null &&
                this.props.data_objects_list_view[ind_data_objects].data[
                  ind_data
                ].prefix === 'x'
              ) {
                //cas où le préfixe est "x"
                positionX =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].y[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].y.length - 1
                  ];
                date =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].x[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].x.length - 1
                  ];
                positionXUnity =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].engineering_unit;
              }
              if (
                positionY === null &&
                this.props.data_objects_list_view[ind_data_objects].data[
                  ind_data
                ].prefix === 'y'
              ) {
                //cas où le préfixe est "y"
                positionY =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].y[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].y.length - 1
                  ];
                date =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].x[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].x.length - 1
                  ];
                positionYUnity =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].engineering_unit;
              }
              if (
                rayon === null &&
                this.props.data_objects_list_view[ind_data_objects].data[
                  ind_data
                ].prefix === 'r'
              ) {
                //cas où le préfixe est "r"
                date =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].x[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].x.length - 1
                  ];
                rayonUnity =
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].engineering_unit;
                rayon = adjustValueToProvidedDistanceUnit(
                  this.props.data_objects_list_view[ind_data_objects].data[
                    ind_data
                  ].y[
                    this.props.data_objects_list_view[ind_data_objects].data[
                      ind_data
                    ].y.length - 1
                  ],
                  rayonUnity
                );
              }
              if (positionX !== null && positionY !== null && rayon !== null) {
                //cas où les deux préfixes ont été trouvés
                break;
              }
            }
            //création de l'objet json à afficher, marker et cercle
            let geoJsonChantier = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [positionY, positionX],
                  },
                  properties: {
                    name: `${unixTimestampToDate(
                      date
                    )}, ( ${positionX}${positionXUnity} : ${positionY}${positionYUnity} ) , ${rayon}${rayonUnity}`,
                  },
                },
                createGeoJSONCircle([positionY, positionX], rayon),
              ],
            };
            //ajout de la couche sur la vue et ajout du geojson à l'objet leaflet geoJson
            this.state.markersList[chantierMarkerKey]
              .addData(geoJsonChantier)
              .addTo(this.state.map_vue);
            //suppression de toutes les couches excepté celle nouvellement créée
            for (let geojsonlayer of removeLayersList) {
              geojsonlayer.remove();
            }
          }
        }
      }
    }

    // Actualiser l'historique des geo-positions en fonction des dernières valeurs
    if (
      this.props.data_objects_list_view !== undefined &&
      this.state.historiqueGeoPositionList.length > 0 &&
      this.state.afficherApresReq10613 &&
      !(
        this.props.attenteReqListe[
          'ATTENTE_REQUETE' +
            glossaire_requetes.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue
        ] === true
      )
    ) {
      for (
        let ind_data_objects = 0;
        ind_data_objects < this.props.data_objects_list_view.length;
        ind_data_objects++
      ) {
        for (let historiqueGeoPositionKey of this.state
          .historiqueGeoPositionList) {
          if (this.state.markersList[historiqueGeoPositionKey] !== undefined) {
            //initialisation de la liste des couches à supprimer
            const removeLayersList = [
              ...this.state.markersList[historiqueGeoPositionKey].getLayers(),
            ];
            //initialisation des listes de données à récupérer pour créer les objets markers et l'objet ligne
            let coords = [];
            let pointsList = [];
            if (
              this.state.markersList[historiqueGeoPositionKey].graph_id ===
              this.props.data_objects_list_view[ind_data_objects].graph_id
            ) {
              for (
                let indValeur = 0;
                indValeur <
                this.props.data_objects_list_view[ind_data_objects].data.data[0]
                  .y.length;
                indValeur++
              ) {
                //ajout des coordonnées pour l'affichage de la ligne
                coords.push([
                  this.props.data_objects_list_view[ind_data_objects].data
                    .data[1].y[indValeur],
                  this.props.data_objects_list_view[ind_data_objects].data
                    .data[0].y[indValeur],
                ]);
                //ajout des objets points à la liste
                pointsList.push({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [
                      this.props.data_objects_list_view[ind_data_objects].data
                        .data[1].y[indValeur],
                      this.props.data_objects_list_view[ind_data_objects].data
                        .data[0].y[indValeur],
                    ],
                  },
                  properties: {
                    name: unixTimestampToDate(
                      this.props.data_objects_list_view[ind_data_objects].data
                        .data[1].x[indValeur]
                    ),
                  },
                });
              }
            }
            //initialisation de l'objet json contenant les points et la ligne
            let json = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: coords,
                  },
                  properties: {
                    prop0: 'value0',
                    prop1: 0.0,
                  },
                },
                ...pointsList,
              ],
            };
            //ajout de l'objet geoJson à l'objet geoJon leaflet
            this.state.markersList[historiqueGeoPositionKey]
              .addData(json)
              .addTo(this.state.map_vue);
            //suppression de toutes les couches excepté celle nouvellement créée
            for (let geojsonlayer of removeLayersList) {
              geojsonlayer.remove();
            }
          }
        }
      }
      if (this.state.markersClusterGroup !== undefined) {
        this.state.markersClusterGroup.refreshClusters();
      }
    }

    // mise à jour de la vue selon update_time
    if (
      this.state.update_time_unactive &&
      this.props.views_list_by_view_id[this.props.view_id] !== undefined &&
      this.props.views_list_by_view_id[this.props.view_id].update_time !==
        undefined &&
      this.props.views_list_by_view_id[this.props.view_id].update_time > 0 &&
      this.props.views_list_by_view_id[this.props.view_id].update_time !== null
    ) {
      this.setState({ update_time_unactive: false }); // création de la fonction de interval de rafraîchissement (une seule fois)
      this.interval = setInterval(() => {
        this.props.reqRecupererVue(this.props.view_id);
        this.props.reqRecupererListeCouchesVue(this.props.view_id);
        this.setState({ afficherApresReq10604: true });
        this.props.reqRecupererListeObjetsAAfficherAssocieVue(
          this.props.view_id
        );
        this.setState({
          afficherApresReq10604: true, //attente de la fin de la req de récupération des couches
          afficherApresReq10613: true, //attente de la fin de la req de récupération des objets
        });
      }, parseInt(this.props.views_list_by_view_id[this.props.view_id].update_time) * 1000);
    }
  }

  componentWillUnmount() {
    // purge tout les graphes plotly
    // loop sur les objets pour unmountComponentAtNode() tout les reactDom créé pour chaque objet
    for (const keyComposant in this.state.objetsARender) {
      if (document.getElementById(keyComposant) !== null) {
        ReactDOM.unmountComponentAtNode(document.getElementById(keyComposant));
      }
    }
    if (
      this.state.map_vue !== undefined &&
      this.state.markersClusterGroup !== undefined
    ) {
      this.state.map_vue.removeLayer(this.state.markersClusterGroup);
    }
    //supprime le addEventListener qui relance l'affichage de la vue après avoir cliqué sur le bouton aggrandisement/rétrécissement
    const mapDynamicDiv = document.getElementById('mapDynamicDiv');
    if (mapDynamicDiv !== null) {
      const popupConteneur = mapDynamicDiv.parentElement;
      // supprime la popup
      if (popupConteneur !== null) {
        let boutonFullScreen;
        //recupère le bouton de plein écran
        if (
          popupConteneur.childNodes[0] !== undefined &&
          popupConteneur.childNodes[0].childNodes[1] !== undefined
        ) {
          boutonFullScreen = popupConteneur.childNodes[0].childNodes[1];
        } else if (
          popupConteneur.parentElement !== null &&
          popupConteneur.parentElement.parentElement !== null
        ) {
          boutonFullScreen =
            popupConteneur.parentElement.parentElement.childNodes[0]
              .childNodes[1];
        }
        if (boutonFullScreen !== undefined) {
          // supprime le listener du bouton de plein écran
          boutonFullScreen.removeEventListener('click', () => {
            this.setState({
              afficherApresReq10604: true,
            });
          });
        }
      }
    }
    clearInterval(this.interval);
    const id_vue_req = this.props.view_id;
    this.props.supprimerVuesLayersObjets();
    //envoi les reqs pour récupérer la liste des objets et la liste des couches de la vue pour la configuration pour la configuration admin
    if (this.props.interfaceAdmin) {
      this.props.reqRecupererListeCouchesVue(id_vue_req);
      this.props.reqRecupererListeObjetsAssocieVue(id_vue_req);
    }
  }

  render() {
    let styleDiv = {
      height: '100%',
      width: '100%',
    };

    if (
      this.props.views_list_by_view_id[this.props.view_id] !== undefined &&
      this.props.views_list_by_view_id[this.props.view_id].view_type !==
        glossaire_type_de_vue.plan_et_carte
    ) {
      styleDiv['zoom'] = 1 / window.devicePixelRatio;
    }
    if (this.props.recupCoord !== undefined) {
      styleDiv['cursor'] = 'crosshair';
    }
    return (
      <div id={'mapDynamicDiv'} style={styleDiv}>
        {this.state.afficherApresReq10604 ||
        this.state.afficherApresReq10613 ? (
          <div className={S.Chargement}>
            <div>{checkMot('chargement_en_cours')}</div>
            <Spinner size={'2px'} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    views_list_by_view_id: state.vue.views_list_by_view_id,
    data_objects_list_view:
      state.vue.views_data_objects_list_view[ownProps.view_id],
    views_layers_list: state.vue.views_layers_list,
    attenteReqListe: state.attenteRequete,
    interfaceAdmin: state.authentifie.interfaceAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reqRecupererObjetAAfficherAssocieVue: (view_id, viewobj_id) =>
      dispatch(
        actions.reqRecupererObjetAAfficherAssocieVue(view_id, viewobj_id)
      ),
    reqRecupererListeObjetsAssocieVue: (idVue) =>
      dispatch(actions.reqRecupererListeObjetsAssocieVue(idVue)),
    reqRecupererListeObjetsAAfficherAssocieVue: (idVue) =>
      dispatch(actions.reqRecupererListeObjetsAAfficherAssocieVue(idVue)),
    reqRecupererListeCouchesVue: (idVue) =>
      dispatch(actions.reqRecupererListeCouchesVue(idVue)),
    reqRecupererVue: (idVue) => dispatch(actions.reqRecupererVue(idVue)),
    supprimerVuesLayersObjets: () =>
      dispatch(actions.supprimerVuesLayersObjets()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffichageVue);

import React, { Component } from 'react';
import BibliothequePlotly from '../BibliothequePlotly/BibliothequePlotly';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { convertiUtsToDate } from '../GrapheXT/GrapheXT';

class TableauMinMaxDate extends Component {
  state = {
    data: [],
    layout: {},
  };

  render() {
    let layout = {};
    let data = [];
    let headers_values = [];
    let headers_unit = [];
    let sensors_names = [];
    let noms_responsables = [];
    let dates_values = [];
    let dates_max = [];
    let values_max = [];
    let dates_min = [];
    let values_min = [];
    if (
      this.props.configuration !== undefined &&
      this.props.donnees !== undefined
    ) {
      layout = {
        font: { size: 14 },
        title: {
          text:
            this.props.configuration.title +
            '<br>' +
            this.props.configuration.subtitle,
          font: {
            // size: 25,
            color: '#3c3935',
          },
        },
        showlegend: true,
        legend: {
          orientation: 'h',
          x: 0,
          y: -0.0,
        },
        ...(this.props.viewobj_json_content === undefined ||
        this.props.viewobj_json_content === null
          ? {}
          : this.props.viewobj_json_content.layout),
      };
      for (let curve of this.props.donnees) {
        let index_sensor_name = sensors_names.indexOf(curve.sensor_name); // index du nom du capteur sinon -1

        let index_prefix =
          curve.prefix === '' ? -1 : headers_values.indexOf(curve.prefix); // index du préfixe sinon -1
        let index_position_in_sensor =
          curve.position_in_sensor === ''
            ? -1
            : headers_values.indexOf(curve.position_in_sensor); // index du préfixe sinon -1

        if (index_sensor_name < 0) {
          // ajoute le sensor name et la date si le sensorname n'est pas dans la liste
          sensors_names.push(curve.sensor_name);
          // ajoute le nom du responsable
          noms_responsables.push(curve.name);

          for (let ind_col = 0; ind_col < headers_values.length; ind_col++)
            values_max[ind_col].push(null);
          for (let ind_col = 0; ind_col < headers_values.length; ind_col++)
            values_min[ind_col].push(null);
        }

        // cas où le sensor_name vient d'être ajouté à la liste
        index_sensor_name =
          index_sensor_name < 0 ? sensors_names.length - 1 : index_sensor_name;
        /*** si le nom de la courbe est aussi une colonne
                 if (index_name < 0 && index_prefix < 0) {
                 */

        // Supposons que curve.x contient les dates et curve.y les valeurs
        let maxIndex = curve.y.indexOf(Math.max(...curve.y)); // Trouve l'indice de la valeur maximale dans curve.y
        let maxValue = curve.y[maxIndex]; // Valeur maximale
        let maxDate = curve.x[maxIndex]; // Date correspondante dans curve.x

        // Supposons que curve.x contient les dates et curve.y les valeurs
        let minIndex = curve.y.indexOf(Math.min(...curve.y)); // Trouve l'indice de la valeur maximale dans curve.y
        let minValue = curve.y[minIndex]; // Valeur maximale
        let minDate = curve.x[minIndex]; // Date correspondante dans curve.x

        if (index_prefix < 0) {
          //si le nom de la courbe et le préfixe n'existe pas
          // ajoute une colonne de null
          values_max.push(new Array(sensors_names.length).fill(null));
          dates_max.push(new Array(sensors_names.length).fill(null));
          values_min.push(new Array(sensors_names.length).fill(null));
          dates_min.push(new Array(sensors_names.length).fill(null));

          values_max[values_max.length - 1][index_sensor_name] = maxValue;
          dates_max[dates_max.length - 1][index_sensor_name] = maxDate;

          values_min[values_min.length - 1][index_sensor_name] = minValue;
          dates_min[dates_min.length - 1][index_sensor_name] = minDate;
          if (curve.prefix !== '') {
            // ajoute le préfixe si elle n'est pas une chaîne vide
            headers_values.push(curve.prefix);
          } else {
            // ajoute la position de la composante sinon
            headers_values.push(curve.position_in_sensor);
          }
          headers_unit.push(curve.engineering_unit);
        } else if (index_prefix > -1) {
          // positionne la valeur dans la ligne du sensor_name si le nom du préfixe existe déjà dans l'entête
          values_max[index_prefix][index_sensor_name] = maxValue;
          dates_max[index_prefix][index_sensor_name] = maxDate;
          values_min[index_prefix][index_sensor_name] = minValue;
          dates_min[index_prefix][index_sensor_name] = minDate;
        } else {
          // positionne la valeur dans la ligne du sensor_name sinon en fonction de la index_position_in_sensor
          values_max[index_position_in_sensor][index_sensor_name] = maxValue;
          dates_max[index_position_in_sensor][index_sensor_name] = maxDate;
          values_min[index_position_in_sensor][index_sensor_name] = minValue;
          dates_min[index_position_in_sensor][index_sensor_name] = minDate;
        }
      }
    }

    let entetes = [[checkMot('nom')]];
    for (const [index, valeur] of headers_values.entries()) {
      entetes.push([
        checkMot('max') + ' ' + valeur + ' [' + checkMot('date') + ']',
      ]);
      entetes.push([
        checkMot('max') + ' ' + valeur + ' [' + headers_unit[index] + ']',
      ]);
    }
    for (const [index, valeur] of headers_values.entries()) {
      entetes.push([
        checkMot('min') + ' ' + valeur + ' [' + checkMot('date') + ']',
      ]);
      entetes.push([
        checkMot('min') + ' ' + valeur + ' [' + headers_unit[index] + ']',
      ]);
    }
    let cells_values = [];
    for (let index = 0; index < dates_max.length; index++) {
      cells_values.push(convertiUtsToDate(dates_max[index]));
      cells_values.push(values_max[index]);
    }
    for (let index = 0; index < dates_min.length; index++) {
      cells_values.push(convertiUtsToDate(dates_min[index]));
      cells_values.push(values_min[index]);
    }
    let headerColor = '#EFB805';
    data.push({
      type: 'table',
      columnwidth: [8, 3, 3, 3, 3, 3, 3],
      header: {
        values: entetes,
        align: ['left', 'center'],
        line: { width: 1, color: '#000000' },
        fill: { color: headerColor },
        font: { family: 'Arial', size: 17, color: 'white' },
      },
      cells: {
        values: [sensors_names, ...cells_values],
        align: ['left', 'center'],
        line: { color: '#000000' },
        font: { family: 'Arial', size: 17, color: ['#506784'] },
        height: 25,
      },
    });

    return (
      <BibliothequePlotly
        data={data}
        layout={{
          ...layout,
          responsive: true,
          ...this.props.viewobj_json_content,
        }}
        donneesGraphe={this.props.donneesGraphe}
        ouvrirPopupModifierIntervalle={
          this.props.switchAfficherModifierIntervalle
        }
        dimensionWidth={this.props.dimensionWidth}
        dimensionHeight={this.props.dimensionHeight}
        viewobj_json_content={this.props.viewobj_json_content}
      />
    );
  }
}

export default TableauMinMaxDate;

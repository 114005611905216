import React, { Component } from 'react';

import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';

import { checkMot } from '../../../../ressources/functionJS/checkMot';

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
class SelectPrivilege extends Component {
  state = {
    personnaliserPrivilegeBool: false,
    privilegesCategorie: 'defaut',
  };
  componentDidMount() {
    //vérifie s'il y a une correspondance avec la table de catégorie de privilèges
    if (
      this.props.listePrivilegesParCategorie !== null &&
      this.props.listePrivilegesParCategorie !== undefined
    ) {
      let correspondanceTrouvee = false;
      let catPrivilege = 'Privilèges personnalisé'; //par défaut la catégorie est personnalisé
      for (let cleCat in this.props.listePrivilegesParCategorie) {
        if (
          arraysEqual(
            this.props.preconfigurerPrivilege,
            this.props.listePrivilegesParCategorie[cleCat]
          )
        ) {
          catPrivilege = cleCat; // si il y a correspondance entre la catégorie et la liste des privilèges de l'utilisateur
          this.setState({
            ...this.state,
            preConfigModifierPrivilegesCategorie: cleCat,
          }); // si il y a correspondance entre la catégorie et la liste des privilèges de l'utilisateur
          correspondanceTrouvee = true;
          break;
        }
      }
      if (!correspondanceTrouvee) {
        //todo: si les privilèges sont personnalisés afficher la liste des privilèges
        this.setState({
          ...this.state,
          preConfigModifierPrivilegesCategorie: catPrivilege,
        });
      }
    }
  }
  gererCategorieSelection = (event) => {
    this.props.gererPrivilege(
      this.props.listePrivilegesParCategorie[event.target.value]
    );
    this.setState({ privilegesCategorie: event.target.value });
  };
  render() {
    const afficheOptionsPrivileges = Object.keys(
      this.props.listePrivilegesParCategorie
    ).map((optionKey) => {
      //todo restreindre la liste des options à ce que l'admin peut créer
      //todo utiliser la liste des privilèges de la bdd en l'important via l'api
      return (
        <option key={optionKey} value={optionKey}>
          {checkMot(optionKey)}
        </option>
      );
    });
    return (
      <>
        {/*formulaire de sélection liste déroulante */}
        <select
          className={[s.custom_select, s.mr_sm_2].join(' ')}
          value={this.state.privilegesCategorie}
          name={this.state.privilegesCategorie}
          onChange={(event) => this.gererCategorieSelection(event)}
          disabled={this.props.desactive}
        >
          <option key={'default'} value={null} unselectable={'on'}>
            {checkMot('selection_categorie_privilege')}
          </option>
          {/*<option key={"default22"} value={null} selected={true}>teste</option>*/}
          {afficheOptionsPrivileges}
        </select>
      </>
    );
  }
}

export default SelectPrivilege;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../services/actions';

import AccesRestreins from '../../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import { confirmeOnClick } from '../../../../ressources/functionJS/confirmeOnClick';
import { checkMot } from '../../../../ressources/functionJS/checkMot';

import { style } from '../../../Vue/ProfilVue/ProfilVue.module.scss';
import { BiEditAlt, RiDeleteBin2Line } from 'react-icons/all';
import { profil, btM, btF, span } from './ProfilGroupeEmail.module.scss';

import FormulaireGroupeEmail from './FormulaireGroupeEmail/FormulaireGroupeEmail';
import { BsFillInfoCircleFill } from 'react-icons/bs';
class ProfilGroupeEmail extends Component {
  state = {
    showModal: false,
    afficheFormulaireModifier: false,
  };
  componentDidUpdate(prevProps) {
    if (this.props.InfoSelect !== prevProps.InfoSelect) {
      this.setState({
        email_group_name: this.props.InfoSelect.email_group_name,
        afficheFormulaireModifier: false,
      });
    }
  }
  gererEmailGroupName = (event) => {
    this.setState({ ...this.state, email_group_name: event.target.value });
  };
  handleOpenModal = () => {
    this.setState({ showModal: true });
  };
  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    //affichage par défaut du profil si aucun groupe de mail n'a été sélectionné
    let profilGroupeEmail = checkMot('selectionne_un_groupe_de_mail');
    if (this.props.InfoSelect !== null) {
      let affichageBouton;
      if (this.state.afficheFormulaireModifier) {
        //formulaire de modification
        affichageBouton = (
          <>
            <button
              className={btM}
              onClick={() =>
                this.setState({
                  ...this.state,
                  afficheFormulaireModifier: false,
                })
              }
            >
              <BiEditAlt size={24} />
              {checkMot('annuler')}
            </button>
          </>
        );
      } else {
        //formulaire de profil
        affichageBouton = (
          <>
            <AccesRestreins
              numPrivilege={privilege.modifier_des_groupes_emails}
            >
              <button
                className={btM}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    afficheFormulaireModifier: true,
                  })
                }
              >
                <BiEditAlt size={24} />
                {checkMot('modifier_groupe_de_mail')}
              </button>
            </AccesRestreins>
            <AccesRestreins
              numPrivilege={privilege.supprimer_des_groupes_emails}
            >
              <AttenteRequete
                request_type={requestType.supprimer_un_groupe_d_email}
                str={checkMot('suppression_en_cour')}
              >
                {/*Bouton supprimer un groupe de mail */}
                <button
                  className={btF}
                  onClick={() =>
                    confirmeOnClick(
                      checkMot(
                        'voulez_vous_vraiment_supprimer_ce_groupe_de_mail'
                      ),
                      () =>
                        this.props.reqSupprimer(
                          parseInt(this.props.InfoSelect.group_email_id)
                        )
                    )
                  }
                >
                  <RiDeleteBin2Line size={24} />
                  {checkMot('supprimer_groupe_de_mail')}
                </button>
              </AttenteRequete>
            </AccesRestreins>
          </>
        );
      }
      profilGroupeEmail = (
        <div className={profil}>
          {/* logo "i"*/}
          <span className={style}>
            <BsFillInfoCircleFill size={24} />
          </span>
          <span className={style}>
            {/*l'id de la vue*/}
            {checkMot('group_email_id')} :{' '}
            {this.props.InfoSelect.group_email_id}
          </span>
          <FormulaireGroupeEmail
            preconfigurerGroupeEmail={this.props.InfoSelect}
            affichageProfil={!this.state.afficheFormulaireModifier}
          />
          {/*Affiche les boutons de contrôles de profil ou modification*/}
          {affichageBouton}
        </div>
      );
    }
    return profilGroupeEmail;
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    reqSupprimer: (idGroupeEmail) =>
      dispatch(actions.reqSupprimerGroupeEmail(idGroupeEmail)),
  };
};

export default connect(null, mapDispatchToProps)(ProfilGroupeEmail);

import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { ListGroupItem } from 'reactstrap';

import AjouterProjetFavori from './AjouterProjetFavori/AjouterProjetFavori';
import SupprimerProjetFavori from './SupprimerProjetFavori/SupprimerProjetFavori';
// import { AiFillFolderOpen, AiFillFolder } from "react-icons/ai";
import * as actions from '../../../../../services/actions';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import S from '../ArborescenceProjets.module.scss';

const DEFAULT_PADDING = 16;
const ICON_SIZE = 0;
const LEVEL_SPACE = 16;

const ListItem = ({
  level = 0,
  hasNodes,
  isOpen,
  label,
  searchTerm,
  openNodes,
  toggleNode,
  matchSearch,
  focused,
  ...props
}) => (
  <ListGroupItem
    key={props.project_id}
    style={{
      paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
      paddingTop: '0px',
      paddingBottom: '0px',
      cursor: 'pointer',
      backgroundColor: '#3C3935',
      boxShadow: focused ? '0px 0px 5px 0px #222' : 'none',
      zIndex: focused ? 999 : 'unset',
      position: 'relative',
      textAlign: 'center',
    }}
  >
    <div className={S.Noeud}>
      <div className={S.identationNoeud}>
        {hasNodes ? ( //affichage d'icône dossier et flèche déroulante si le noeud à des enfants sinon flèche simple
          <span
            onClick={(e) => {
              hasNodes && toggleNode && toggleNode();
              e.stopPropagation();
            }}
          >
            {isOpen ? ( //affichage de l'icône d'ouverture/fermeture
              <span>
                {/*icône d'ouvert*/}
                {/* <FaCaretDown size={20} color="#ffffff"/> */}
                <BiChevronDown size={15} color="#ffffff" />
              </span>
            ) : (
              <span className={S.newstile}>
                {/*icône fermer*/}
                {/* <FaCaretRight size={20} color="#ffffff"/> */}
                <BiChevronRight size={15} color="#ffffff" />
              </span>
            )}
          </span>
        ) : (
          <BiChevronRight size={15} color="#ffffff" />
        )}
        {/*affichage du lien de redirection pour modifier l'url et enregistre la modification dans redux*/}
        {props.url_ext !== undefined && props.url_ext !== '' ? (
          //bouton de lien ext d'affichage
          <span
            className={
              props.project_id_url === props.project_id ? S.itn_actif : S.itn
            }
            onClick={() => {
              window.open(
                props.url_ext +
                  (props.url_ext_show_id === 1 || props.url_ext_show_id === true
                    ? '/' + props.project_id
                    : '')
              );
            }}
          >
            <span>{label}</span>
          </span>
        ) : (
          //bouton d'affichage de lien interne
          <Link
            to={'/' + props.project_id}
            onClick={(e) => {
              hasNodes && toggleNode && toggleNode();
              e.stopPropagation();
              props.enregistrementProjectIdUrl(props.project_id);
            }}
            className={
              props.project_id_url === props.project_id ? S.itn_actif : S.itn
            }
          >
            <span>{label}</span>
          </Link>
        )}
      </div>
      {/*affichage du favori si déjà en favori étoile pleine sinon étoile vide*/}
      {props.favorite_projects_list_id.indexOf(parseInt(props.project_id)) ===
      -1 ? (
        <AjouterProjetFavori project_id={props.project_id} />
      ) : (
        <SupprimerProjetFavori project_id={props.project_id} />
      )}
    </div>
  </ListGroupItem>
);
const mapStateToProps = (state) => {
  return {
    project_id_url: state.interfaceClient.project_id_url,
    favorite_projects_list_id: state.authentifie.favorite_projects_list_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    enregistrementProjectIdUrl: (project_id) =>
      dispatch(actions.enregistrerProjectIdUrl(project_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListItem);

import React from 'react';

import AffichageVue from '../../../../Vue/AffichageVue/AffichageVue';

import * as glossaire_url_objet from '../../../../../ressources/glossaires/glossaire_url_objet';

const DefilerTableauxDeBord = (props) => {
  const [indVue, setIndVue] = React.useState(props.indDiaporama); //initialise l'index à la position de l'id de la vue de la liste des vues favoris
  const [listeVue, setListeVue] = React.useState([]);
  const [uniciteVue, setUniciteVue] = React.useState(props.indDiaporama); //initialise l'index d'unicité à la position de l'id de la vue de la liste des vues favoris

  React.useEffect(() => {
    if (props.afficher_diaporama === true) {
      setListeVue([
        ...Array(uniciteVue),
        <AffichageVue
          view_id={props.liste_tableaux_de_bord_id[indVue]}
          uniciteVue={uniciteVue}
        />,
      ]);

      window.history.pushState(
        'object or string',
        'Title',
        '/' +
          props.project_id_url +
          '-' +
          glossaire_url_objet.diaporama_vue +
          '-' +
          props.liste_tableaux_de_bord_id[indVue]
      );
      props.enregistrerIDObjetAffichage(
        props.liste_tableaux_de_bord_id[indVue]
      );
      setTimeout(() => {
        if (indVue < props.liste_tableaux_de_bord_id.length - 1) {
          setIndVue(indVue + 1);
          setUniciteVue(uniciteVue + 1);
        } else {
          setIndVue(0);
          setListeVue([]);
          setUniciteVue(0);
        }
      }, props.tempsAvantTDBSuivant);
    } else if (props.liste_tableaux_de_bord_id[indVue] === undefined) {
      if (indVue < props.liste_tableaux_de_bord_id.length - 1) {
        setIndVue(indVue + 1);
        setUniciteVue(uniciteVue + 1);
      } else {
        setIndVue(0);
        setListeVue([]);
        setUniciteVue(0);
      }
    }
  }, [uniciteVue]);
  if (props.liste_tableaux_de_bord_id !== undefined) {
    if (props.liste_tableaux_de_bord_id.length === 1) {
      return (
        <AffichageVue
          view_id={props.liste_tableaux_de_bord_id[0]}
          uniciteVue={uniciteVue}
        />
      );
    } else if (props.liste_tableaux_de_bord_id.length > 0) {
      return listeVue.length > 0 && uniciteVue < listeVue.length
        ? listeVue[uniciteVue]
        : null;
    } else {
      return 'Vue impossible à afficher';
    }
  } else {
    return "Vous n'avez pas de tableau de bord favoris, allez dans la partie éditer ses tableaux de bord favoris pour en ajouter";
  }
};
export default DefilerTableauxDeBord;

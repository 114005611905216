import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  graphs_list: [], //todo liste vide de base
  modificationListe: [],

  dates_formats_list: [],
  time_period_units_list: [],
  liste_configurations_graphes: {},
  liste_donnees_graphes: {},
};

const enregistrerListeGraphes = (state, action) => {
  return { ...state, graphs_list: action.graphs_list };
};
const enregistrerGraphe = (state, action) => {
  let newConfig = {
    ...action.configuration_graphe,
  };
  delete newConfig['state'];
  delete newConfig['errors'];
  let newListe_configurations_graphes = {
    ...state.liste_configurations_graphes,
  };
  newListe_configurations_graphes[action.configuration_graphe.graph_id] =
    newConfig;
  return {
    ...state,
    liste_configurations_graphes: newListe_configurations_graphes,
  };
};
const enregistrerDonneesGraphe = (state, action) => {
  let newAffichageGraphe = {
    ...action.donnees_graphe,
  };
  delete newAffichageGraphe['state'];
  delete newAffichageGraphe['errors'];

  let newListe_donnees_graphes = {
    ...state.liste_donnees_graphes,
  };
  newListe_donnees_graphes[action.donnees_graphe.config.graph_id] =
    newAffichageGraphe;
  return { ...state, liste_donnees_graphes: newListe_donnees_graphes };
};
const enregistrerDonneesGrapheAutomatiqueXT = (state, action) => {
  let newAffichageGraphe = {
    ...action.donnees_graphe,
  };
  delete newAffichageGraphe['state'];
  delete newAffichageGraphe['errors'];
  let newListe_donnees_graphes = {
    ...state.liste_donnees_graphes,
    // [action.sensor_id+"+"+action.position_in_sensor]:newAffichageGraphe
    [action.sensor_id + '+' + action.position_in_sensor]: newAffichageGraphe,
  };
  return { ...state, liste_donnees_graphes: newListe_donnees_graphes };
};
const enregistrerListeFormatsDeDates = (state, action) => {
  return { ...state, dates_formats_list: action.dates_formats_list };
};
const enregistrerListeUnitesDeTemps = (state, action) => {
  return { ...state, time_period_units_list: action.time_period_units_list };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_LISTE_GRAPHES:
      return enregistrerListeGraphes(state, action);
    case actionTypes.ENREGISTRER_GRAPHE:
      return enregistrerGraphe(state, action);
    case actionTypes.ENREGISTRER_DONNEES_GRAPHE:
      return enregistrerDonneesGraphe(state, action);
    case actionTypes.ENREGISTRER_DONNEES_GRAPHE_AUTOMATIQUE_XT:
      return enregistrerDonneesGrapheAutomatiqueXT(state, action);
    case actionTypes.ENREGISTRER_LISTE_FORMATS_DE_DATES:
      return enregistrerListeFormatsDeDates(state, action);
    case actionTypes.ENREGISTRER_LISTE_UNITES_DE_TEMPS:
      return enregistrerListeUnitesDeTemps(state, action);
    default:
      return state;
  }
};

export default reducer;

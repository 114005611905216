import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import {
  createTemplateReport,
  getAllReports,
  getPublishedReports,
  getPublishedTemplateReports,
  publishReports,
  updateTemplateReport,
} from '../api';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */
  dispatch(actions.debutAttente(requestId));
  try {
    let response;

    switch (requestId) {
      case requestType.recuperer_la_liste_des_rapports_publies:
        response = await getPublishedReports(payload);
        if (response.data.state) {
          dispatch(enregistrerListeRapportsPublies(response.data));
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_rapports_gabarits:
        response = await getPublishedTemplateReports(payload);
        if (response.data.state) {
          dispatch(enregistrerListeRapportsGabarits(response.data));
          return;
        }
        break;
      case requestType.creer_un_rapport_gabarit:
        response = createTemplateReport(payload);
        if (response.data.state) {
          // success behaviour
          return;
        }
        break;
      case requestType.modifier_un_rapport_gabarit:
        response = updateTemplateReport(payload);
        if (response.data.state) {
          // success behaviour
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_rapports_generes:
        response = await getAllReports(payload);
        if (response.data.state) {
          dispatch(enregistrerListeRapportsGeneres(response.data));
          return;
        }
        break;
      case requestType.publier_un_rapport_genere:
        response = await publishReports(payload);
        if (response.data.state) {
          dispatch(enregistrerListeRapportsGeneres(response.data));
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
    // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqRecupererListeRapportsPublies = (list_project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_rapports_publies, {
      list_project_id,
    });
  };
};
export const reqRecupererListeRapportsGabarits = (list_project_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_rapports_gabarits,
      { list_project_id }
    );
  };
};
export const reqCreerRapportGabarit = (formulaireRapportGabarit) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.creer_un_rapport_gabarit,
      ...formulaireRapportGabarit
    );
  };
};
export const reqModifierRapportGabarit = (formulaireRapportGabarit) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modifier_un_rapport_gabarit,
      ...formulaireRapportGabarit
    );
  };
};
export const reqSupprimerRapportGabarit = (template_report_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_rapport_gabarit, {
      template_report_id,
    });
  };
};
export const reqGenererRapport = (
  template_report_id,
  start_report,
  end_report
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.generer_un_rapport, {
      template_report_id,
      start_report,
      end_report,
    });
  };
};

export const reqRecupererListeRapportsGeneres = (
  list_project_id,
  start_list_generate_report,
  end_list_generate_report
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_rapports_generes, {
      list_project_id,
      start_list_generate_report,
      end_list_generate_report,
    });
  };
};
export const reqPublierRapportGenere = (generate_report_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.publier_un_rapport_genere, {
      generate_report_id,
    });
  };
};
// TODO a double check
export const reqChargerDocumentRapport = (generate_report_id, data_report) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.charger_le_document_rapport, {
      generate_report_id,
      ...data_report,
    });
  };
};
export const reqSupprimerRapportGenere = (generate_report_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_rapport_genere, {
      generate_report_id,
    });
  };
};

const enregistrerListeRapportsPublies = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_PUBLIES,
    publish_reports_list: data.publish_reports_list,
  };
};
const enregistrerListeRapportsGabarits = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_GABARITS,
    templates_reports_list: data.templates_reports_list,
  };
};
const enregistrerListeRapportsGeneres = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_RAPPORTS_GENERES,
    generates_reports_list: data.generates_reports_list,
  };
};

import React, { Component } from 'react';

import Bouton from '../../../UI/Bouton/Bouton';
import AccesRestreins from '../../../../hoc/AccesRestreins/AccesRestreins';
import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as privilege from '../../../../ressources/glossaires/glossaire_privileges';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../../ressources/functionJS/confirmeOnClick';

import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import {
  sas,
  hedes,
  ConteneurInfo,
  ConteneurProfil,
  style,
} from './ProfilRapportGabarit.module.scss';
import FormulaireRapportGabarit from './FormulaireRapportGabarit/FormulaireRapportGabarit';
import * as S from './FormulaireRapportGabarit/FormulaireRapportGabarit.module.scss';
import FormulaireSelectionDateHeureUnix from '../../../Graphe/ProfilGraphe/FormulaireGraphe/FormulaireSelectionDateHeureUnix/FormulaireSelectionDateHeureUnix';
import Collapse from '../../../../hoc/Collapse/Collapse';
import { BiSend, HiDocumentDuplicate } from 'react-icons/all';

import Spinner from 'reactstrap/es/Spinner';

const defaultConfigurationRapportGabarit = {
  // charte graphique
  graphical_charter: {
    page_header: {
      omit_cover_page: 1,
      objects_list: [],
    },
    page_footer: {
      omit_cover_page: 1,
      objects_list: [],
    },
    font_style: {
      family: 'Montserrat',
      style: 'italic',
      weight: 2,
      line_height: 'normal',
      size: 2,
    },
  },
  cover_page: {
    objects_list: [],
  },
  body_page: {
    objects_list: [],
  },
};

class ProfilRapportGabarit extends Component {
  state = {
    afficherModifier: false,
    configurationRapportGabarit: defaultConfigurationRapportGabarit,
    start_report: null,
    end_report: null,
    isLoading: true, // nouvel état pour suivre le chargement
    erreurChargement: '', // nouvel état pour le message d'erreur
  };

  async chargerConfig() {
    const path = this.props.infoRapportGabarit.path_config;
    if (!path) {
      this.setState({
        erreurChargement: 'Le chemin du fichier JSON est vide.',
      });
      return;
    }
    try {
      const res = await fetch(path + '?varget0' + Math.random());
      const json = await res.json();
      this.setState({
        template_report_id: this.props.infoRapportGabarit.template_report_id,
        configurationRapportGabarit: { ...json },
        afficherModifier: false,
        isLoading: false, // Arrêter le chargement
        erreurChargement: '', // Aucune erreur
      });
    } catch (erreur) {
      this.setState({
        erreurChargement: 'Erreur lors du chargement des données JSON.',
      });
    }
  }

  componentDidMount() {
    this.chargerConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    // Contrôler si les propriétés pertinentes ont changé
    const templateIdChanged =
      this.props.infoRapportGabarit.template_report_id !==
      prevProps.infoRapportGabarit.template_report_id;
    const pathConfigChanged =
      this.props.infoRapportGabarit.path_config !==
      prevProps.infoRapportGabarit.path_config;

    if (templateIdChanged || pathConfigChanged) {
      this.chargerConfig();
    }
  }

  //fonction afficher le formulaire de modification du profil du rapport gabarit
  afficherModifierProfil = () => {
    this.setState({ afficherModifier: true });
    this.props.commutateurInfoFull();
  };
  //fonction pour annuler la modification du profil du rapport gabarit
  annulerModificationProfil = () => {
    this.setState({ ...this.state, afficherModifier: false });
  };
  gerer_start_report = (unix) =>
    this.setState({ ...this.state, start_report: unix });
  gerer_end_report = (unix) =>
    this.setState({ ...this.state, end_report: unix });

  render() {
    const formulaire = (
      <div className={[s.col_md_12, ConteneurProfil].join(' ')}>
        <FormulaireRapportGabarit
          preconfigurerRapportGabarit={this.props.infoRapportGabarit}
          preconfigurerPath_file={this.props.infoRapportGabarit.path_file}
          annuler={this.annulerModificationProfil}
          configurationRapportGabarit={this.state.configurationRapportGabarit}
          affichageProfil={!this.state.afficherModifier}
        />
        {/*Début barre d'outil pour formulaire Vue*/}
        {this.state.afficherModifier ? null : (
          <div className={[s.d_flex, s.justify_content_between].join(' ')}>
            <AccesRestreins
              numPrivilege={privilege.modifier_des_rapports_gabarits}
            >
              <div className={sas}>
                {/*Bouton pour passer le formulaire Vue en mode modifiable*/}
                <Bouton cliquer={this.afficherModifierProfil}>
                  <AiFillEdit /> {checkMot('modifier')}
                </Bouton>
              </div>
              <div className={sas}>
                {/*Bouton pour passer le formulaire Vue en mode modifiable*/}
                <Bouton
                  cliquer={() =>
                    confirmeOnClick(
                      checkMot(
                        'voulez_vous_vraiment_dupliquer_ce_rapport_gabarit'
                      ),
                      () =>
                        this.props.reqCreer({
                          project_id: parseInt(
                            this.props.infoRapportGabarit.project_id
                          ),
                          name: this.props.infoRapportGabarit.name + ' bis',
                          time_zone: this.props.infoRapportGabarit.time_zone,
                          email_group_id: parseInt(
                            this.props.infoRapportGabarit.email_group_id
                          ),
                          interval_next_generation: parseInt(
                            this.props.infoRapportGabarit
                              .interval_next_generation
                          ),
                          last_unixtimestamp_run: parseInt(
                            this.props.infoRapportGabarit.last_unixtimestamp_run
                          ),
                          next_unixtimestamp_run: parseInt(
                            this.props.infoRapportGabarit.next_unixtimestamp_run
                          ),
                          mode: parseInt(this.props.infoRapportGabarit.mode),
                          enable: parseInt(
                            this.props.infoRapportGabarit.enable
                          ),
                          number_of_days: parseInt(
                            this.props.infoRapportGabarit.number_of_days
                          ),
                          publication_auto: parseInt(
                            this.props.infoRapportGabarit.publication_auto
                          ),
                          pdf_result: parseInt(
                            this.props.infoRapportGabarit.pdf_result
                          ),
                          json_config: JSON.stringify(
                            this.state.configurationRapportGabarit
                          ),
                        })
                    )
                  }
                >
                  <HiDocumentDuplicate /> {checkMot('dupliquer')}
                </Bouton>
              </div>
            </AccesRestreins>
            <div className={sas}>
              <AccesRestreins
                numPrivilege={privilege.supprimer_des_rapports_gabarits}
              >
                <AttenteRequete
                  request_type={requestType.supprimer_un_rapport_gabarit}
                >
                  <Bouton
                    cliquer={() =>
                      confirmeOnClick(
                        checkMot(
                          'voulez_vous_vraiment_supprimer_ce_rapport_gabarit'
                        ),
                        () =>
                          this.props.reqSupprimer(
                            this.props.infoRapportGabarit.template_report_id
                          )
                      )
                    }
                  >
                    <AiFillDelete /> {checkMot('supprimer')}
                  </Bouton>
                </AttenteRequete>
              </AccesRestreins>
            </div>
          </div>
        )}
      </div>
    );
    const formulaireGenererRapport = (
      <div>
        <Collapse
          showAdvanceParameters={true}
          ExpandText={checkMot('generer_rapport')}
        >
          {/**DEBUT last_unixtimestamp_run next_unixtimestamp_run**/}

          <div className={[s.form_group, S.text_center].join(' ')}>
            <div className={[s.form_row, s.align_items_center].join(' ')}>
              <span className={[S.stil2, s.col_12].join(' ')}>
                {' '}
                {checkMot(
                  'interval_de_temps_sur_lequel_les_rapports_sont_generes_debut_fin'
                )}
              </span>
              <div className={s.col_6}>
                {/**DEBUT last_unixtimestamp_run**/}
                <FormulaireSelectionDateHeureUnix
                  value={this.state.start_report}
                  gereUnixDate={this.gerer_start_report}
                />
                {/**FIN last_unixtimestamp_run**/}
              </div>
              <div className={s.col_6}>
                {/**DEBUT next_unixtimestamp_run**/}
                <FormulaireSelectionDateHeureUnix
                  value={this.state.end_report}
                  gereUnixDate={this.gerer_end_report}
                />
                {/**FIN next_unixtimestamp_run**/}
              </div>
            </div>
          </div>
          {/**FIN last_unixtimestamp_run next_unixtimestamp_run**/}
          <div
            className={[
              s.d_flex,
              s.justify_content_between,
              s.float_right,
            ].join(' ')}
          >
            <div className={sas}>
              <AccesRestreins numPrivilege={privilege.generer_des_rapports}>
                <AttenteRequete request_type={requestType.generer_un_rapport}>
                  <Bouton
                    cliquer={() =>
                      confirmeOnClick(
                        checkMot('voulez_vous_vraiment_generer_ce_rapport'),
                        () =>
                          this.props.reqGenererRapport(
                            this.props.infoRapportGabarit.template_report_id,
                            this.state.start_report,
                            this.state.end_report
                          )
                      )
                    }
                  >
                    <BiSend /> {checkMot('generer_le_rapport')}
                  </Bouton>
                </AttenteRequete>
              </AccesRestreins>
            </div>
          </div>
        </Collapse>
      </div>
    );

    return (
      <div className={ConteneurInfo}>
        {/*entête du profil rapport gabarit*/}
        <div className={[s.card_header, hedes].join(' ')}>
          {/* logo "i"*/}
          <span className={style}>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-info-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
              />
            </svg>
          </span>
          <span className={style}>
            {/*l'id du rapport gabarit*/}
            {checkMot('template_report_id')} :{' '}
            {this.props.infoRapportGabarit.template_report_id}
          </span>
        </div>
        {/*conteneur pour l'affichage général du conteneur info*/}
        {this.state.erreurChargement ? (
          checkMot('erreur_lors_du_chargement')
        ) : this.state.isLoading ? (
          <>
            <Spinner />
            {checkMot('chargement_des_donnees')}
          </>
        ) : (
          formulaire
        )}
        {formulaireGenererRapport}
      </div>
    );
  }
}

export default ProfilRapportGabarit;

import React from 'react';
import { connect } from 'react-redux';
import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import S from './CommutateurLangue.module.scss';
import fr from '../../ressources/flag/fr.png';
import bg from '../../ressources/flag/bg.png';
import zh from '../../ressources/flag/zh.png';
import cs from '../../ressources/flag/cs.png';
import da from '../../ressources/flag/da.png';
import nl from '../../ressources/flag/nl.png';
import en_gb from '../../ressources/flag/en_gb.png';
import en_us from '../../ressources/flag/en_us.png';
import et from '../../ressources/flag/et.png';
import fi from '../../ressources/flag/fi.png';
import de from '../../ressources/flag/de.png';
import el from '../../ressources/flag/el.png';
import hu from '../../ressources/flag/hu.png';
import it from '../../ressources/flag/it.png';
import ja from '../../ressources/flag/ja.png';
import lv from '../../ressources/flag/lv.png';
import lt from '../../ressources/flag/lt.png';
import pl from '../../ressources/flag/pl.png';
import pt_pt from '../../ressources/flag/pt_pt.png';
import pt_br from '../../ressources/flag/pt_br.png';
import ro from '../../ressources/flag/ro.png';
import ru from '../../ressources/flag/ru.png';
import sk from '../../ressources/flag/sk.png';
import sl from '../../ressources/flag/sl.png';
import es from '../../ressources/flag/es.png';
import sv from '../../ressources/flag/sv.png';
import bzh from '../../ressources/flag/drapeau_breton.jpg';
import * as actions from '../../services/actions';
import { checkMot } from '../../ressources/functionJS/checkMot';
import { MenuItem, Select } from '@material-ui/core';

const CommutateurLangue = (props) => {
  const [langueSite, setLangueSite] = React.useState(props.langueSite);
  const handleChange = (event) => {
    setLangueSite(event.target.value);
    props.changeLangueSite(event.target.value);
  };
  const listeLangue = {
    fr: { sigle: 'FR', src: fr, alt: 'France' },
    bg: { sigle: 'BG', src: bg, alt: 'Bulgarian' },
    zh: { sigle: 'ZH', src: zh, alt: 'Chinese (simplified)' },
    cs: { sigle: 'CS', src: cs, alt: 'Czech' },
    da: { sigle: 'DA', src: da, alt: 'Danish' },
    nl: { sigle: 'NL', src: nl, alt: 'Dutch' },
    'en-us': { sigle: 'US', src: en_us, alt: 'English (American)' },
    'en-gb': { sigle: 'GB', src: en_gb, alt: 'English (British)' },
    et: { sigle: 'ET', src: et, alt: 'Estonian' },
    fi: { sigle: 'FI', src: fi, alt: 'Finnish' },
    de: { sigle: 'DE', src: de, alt: 'German' },
    el: { sigle: 'EL', src: el, alt: 'Greek' },
    hu: { sigle: 'HU', src: hu, alt: 'Hungarian' },
    it: { sigle: 'IT', src: it, alt: 'Italian' },
    ja: { sigle: 'JA', src: ja, alt: 'Japanese' },
    lv: { sigle: 'LV', src: lv, alt: 'Latvian' },
    lt: { sigle: 'LT', src: lt, alt: 'Lithuanian' },
    pl: { sigle: 'PL', src: pl, alt: 'Polish' },
    'pt-pt': { sigle: 'PT', src: pt_pt, alt: 'Portuguese' },
    'pt-br': { sigle: 'BR', src: pt_br, alt: 'Portuguese (Brazilian)' },
    ro: { sigle: 'RO', src: ro, alt: 'Romanian' },
    ru: { sigle: 'RU', src: ru, alt: 'Russian' },
    sk: { sigle: 'SK', src: sk, alt: 'Slovak' },
    sl: { sigle: 'SL', src: sl, alt: 'Slovenian' },
    es: { sigle: 'ES', src: es, alt: 'Spanish' },
    sv: { sigle: 'SV', src: sv, alt: 'Swedish' },
    bzh: { sigle: 'BZH', src: bzh, alt: 'Breton' },
  };
  let affichageCommutateurLangue;
  switch (props.typeAffichage) {
    case 'bouton':
      const affichageSigleDrapeau = (value) => (
        <div className={[S.flag].join(' ')}>
          <span className={[s.col_1, S.tex].join(' ')}>
            {listeLangue[value].sigle}
          </span>
          <img
            className={[s.col_3, S.igms].join(' ')}
            src={listeLangue[value].src}
            alt={listeLangue[value].alt}
          />
        </div>
      );
      affichageCommutateurLangue = (
        <Select
          value={langueSite}
          label={checkMot('langue')}
          onChange={handleChange}
          renderValue={affichageSigleDrapeau}
        >
          {Object.keys(listeLangue).map((langueKey) => (
            <MenuItem key={langueKey} value={langueKey}>
              {affichageSigleDrapeau(langueKey)}
            </MenuItem>
          ))}
        </Select>
      );
      break;
    default:
      affichageCommutateurLangue = (
        <div className={S.menus}>
          <span className={S.tex}> {checkMot('langues')} </span>

          {Object.keys(listeLangue).map((langueKey) => (
            <div
              key={langueKey}
              className={S.Bouton}
              onClick={() => props.changeLangueSite(langueKey)}
            >
              <div className={[S.flag, s.row].join(' ')}>
                <span className={[s.col_1, S.tex].join(' ')}>
                  {listeLangue[langueKey].sigle}
                </span>{' '}
                <img
                  className={[s.col_3, S.igms].join(' ')}
                  src={listeLangue[langueKey].src}
                  alt={listeLangue[langueKey].alt}
                />
              </div>
            </div>
          ))}
        </div>
      );
      break;
  }
  return affichageCommutateurLangue;
};

const mapStateToProps = (state) => {
  return {
    langueSite: state.dico.langueSite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLangueSite: (langue) => dispatch(actions.langue(langue)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommutateurLangue);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import AffichageIconeCapteur from '../../../UI/AffichageIconeCapteur/AffichageIconeCapteur';
import GaugesCompass from '../../../UI/widget/GaugesCompass/GaugesCompass';
import LinearGauges from '../../../UI/widget/LinearGauges/LinearGauges';
import ProgresseBar from '../../../UI/widget/ProgresseBar/ProgresseBar';
import PopupObjet from './PopupObjet/PopupObjet';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { jsDictionaryEquals } from '../../../../ressources/functionJS/jsDictionaryEquals';
import { ratioString2PuissanceNiveauZoom } from '../../../../ressources/functionJS/ratioString2PuissanceNiveauZoom';
import { ratioStyleCSS2PuissanceNiveauZoom } from '../../../../ressources/functionJS/ratioStyleCSS2PuissanceNiveauZoom';
import { stringOrEmptyString } from '../../../../ressources/functionJS/stringOrEmptyString';
import * as glossaire_type_objets_vue from '../../../../ressources/glossaires/glossaire_type_objets_vue';
import * as actions from '../../../../services/actions';
import { Spinner } from 'reactstrap';
import * as glossaire_affichage_type_capteur from '../../../../ressources/glossaires/glossaire_affichage_type_capteur';
import * as glossaire_type_export from '../../../../ressources/glossaires/glossaire_type_export';
import { setUrlPathFromDomain } from '../../../../services/utils/utils';
import { formatUnite } from '../../../Graphe/AffichageGraphe/GrapheXT/GrapheXT';
import IframeObjetTypeCapteurXImageDynamique from './IframeObjetTypeCapteurXImageDynamique/IframeObjetTypeCapteurXImageDynamique';
import * as S from './ObjetCapteur.module.scss';
import { getSensorColor } from '../../../../services/utils/utils';

class ObjetCapteur extends Component {
  state = {
    afficherPopup:
      this.props.afficherPopup !== undefined ? this.props.afficherPopup : false,
    viewobj_json_content: null,
    largeurPopup: Math.max(this.props.objetAAfficher.name.length * 9 + 20, 200),
    dateActuel: '',
  };

  componentDidMount() {
    if (this.props.viewobj_json_content !== undefined) {
      this.setState({
        viewobj_json_content: this.props.viewobj_json_content,
      });
    } else if (this.props.objetAAfficher.viewobj_path === undefined) {
      this.setState({
        viewobj_json_content: this.props.viewobj_json_content,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.viewobj_json_content !== undefined &&
      !jsDictionaryEquals(
        this.props.viewobj_json_content,
        prevProps.viewobj_json_content
      ) &&
      !jsDictionaryEquals(
        this.state.viewobj_json_content,
        this.props.viewobj_json_content
      )
    ) {
      //si le json de l'objet est en cours de modification
      this.setState({
        viewobj_json_content: { ...this.props.viewobj_json_content },
      });
    } else if (
      this.props.objetAAfficher.viewobj_path === undefined &&
      this.props.objetAAfficher.viewobj_path !==
        prevProps.objetAAfficher.viewobj_path
    ) {
      this.setState({
        viewobj_json_content: this.props.viewobj_json_content,
      });
    }
    // if(!jsDictionaryEquals(prevProps.listeDernieresDonnees, this.props.listeDernieresDonnees) && this.props.listeDernieresDonnees !== undefined){
    //     if(this.props.listeDernieresDonnees !== undefined){
    //         let newLargeurPopup = this.state.largeurPopup
    //         for (let indLastData = 0; indLastData < this.props.listeDernieresDonnees.length; indLastData++) {
    //             const derniereValeur = this.props.listeDernieresDonnees[indLastData].y[this.props.listeDernieresDonnees[indLastData].y.length - 1];//dernière valeur de la liste de données
    //             let longeurText=(this.props.listeDernieresDonnees[indLastData].prefix + " = " + derniereValeur + " " + this.props.listeDernieresDonnees[indLastData].engineering_unit).length
    //             if (newLargeurPopup<longeurText * 8){
    //                 newLargeurPopup=longeurText * 8
    //             }
    //         }
    //         this.setState({largeurPopup: newLargeurPopup})
    //     }
    // }
  }

  switchAfficherPopup = () => {
    this.setState({ afficherPopup: !this.state.afficherPopup });
  };

  // Méthode pour obtenir la classe CSS en fonction de sensor_color_level
  obtenirClasseCSS(sensor_color_level) {
    const sensorColor = getSensorColor(sensor_color_level);
    return sensorColor.classColor;
  }

  determinerCouleurCapteur(watchdogSeconde) {
    let sensor_color_level = 0;
    const listeDernieresDonnees = this.props.objetAAfficher.data;
    //clé unique de la div de l'objet à afficher

    //arrière plan de icône (couleur du seuil) seuils de couleur: noir, rouge, orange, bleu, vert
    const listeComposantes = this.props.objetAAfficher.metadata.components;

    //initialise début des clés de seuils haut et bas
    const seuilBas = 'alarmlevel_low';
    const seuilHaut = 'alarmlevel_high';
    const uts_present_moins_8h = Date.now() / 1000 - watchdogSeconde;
    //boucle sur les dernières données des composantes
    for (
      let indLastData = 0;
      indLastData < listeDernieresDonnees.length;
      indLastData++
    ) {
      //dernière valeurs de la composante de la liste
      const derniereValeur =
        listeDernieresDonnees[indLastData].y[
          listeDernieresDonnees[indLastData].y.length - 1
        ]; //dernière valeur de la liste de données
      const dernierUTS =
        listeDernieresDonnees[indLastData].x[
          listeDernieresDonnees[indLastData].x.length - 1
        ]; //dernière valeur de la liste de données
      //boucle sur les seuils des composantes
      for (
        let indComposant = 0;
        indComposant < listeComposantes.length;
        indComposant++
      ) {
        //comparaison de la dernière donnée du capteur et du seuil si les position_in_sensor correspondent
        if (
          listeDernieresDonnees[indLastData].position_in_sensor ===
          listeComposantes[indComposant].position_in_sensor
        ) {
          const composantes = listeComposantes[indComposant]; //liste des composantes (avec les seuils)
          //comparaison des seuils et de la dernière données
          if (dernierUTS < uts_present_moins_8h) {
            sensor_color_level = -1;
            break;
          } else if (
            (composantes.start_formula === null &&
              null == composantes.end_formula) ||
            (composantes.start_formula === null &&
              dernierUTS <= composantes.end_formula) ||
            (composantes.start_formula < dernierUTS &&
              null == composantes.end_formula) ||
            (composantes.start_formula < dernierUTS &&
              dernierUTS <= composantes.end_formula)
          ) {
            if (
              sensor_color_level < 4 &&
              composantes[seuilBas + 4] !== undefined &&
              composantes[seuilHaut + 4] !== undefined &&
              ((composantes[seuilBas + 4] !== null &&
                derniereValeur < composantes[seuilBas + 4]) ||
                (composantes[seuilHaut + 4] !== null &&
                  composantes[seuilHaut + 4] < derniereValeur))
            ) {
              sensor_color_level = 4;
            } else if (
              sensor_color_level < 3 &&
              composantes[seuilBas + 3] !== undefined &&
              composantes[seuilHaut + 3] !== undefined &&
              ((composantes[seuilBas + 3] !== null &&
                derniereValeur < composantes[seuilBas + 3]) ||
                (composantes[seuilHaut + 3] !== null &&
                  composantes[seuilHaut + 3] < derniereValeur))
            ) {
              sensor_color_level = 3;
            } else if (
              sensor_color_level < 2 &&
              composantes[seuilBas + 2] !== undefined &&
              composantes[seuilHaut + 2] !== undefined &&
              ((composantes[seuilBas + 2] !== null &&
                derniereValeur < composantes[seuilBas + 2]) ||
                (composantes[seuilHaut + 2] !== null &&
                  composantes[seuilHaut + 2] < derniereValeur))
            ) {
              sensor_color_level = 2;
            } else if (
              sensor_color_level < 1 &&
              composantes[seuilBas + 1] !== undefined &&
              composantes[seuilHaut + 1] !== undefined &&
              ((composantes[seuilBas + 1] !== null &&
                derniereValeur < composantes[seuilBas + 1]) ||
                (composantes[seuilHaut + 1] !== null &&
                  composantes[seuilHaut + 1] < derniereValeur))
            ) {
              sensor_color_level = 1;
            }
          }
        }
      }
      if (sensor_color_level === -1) {
        // cas où une valeur est ancienne de plus de 8h
        break;
      }
    }

    return this.obtenirClasseCSS(sensor_color_level);
  }

  render() {
    let composants = [];
    let dateComposante = 'date invalide';
    let req10614 = () => {};
    if (
      this.props.objetAAfficher.data === undefined ||
      this.props.objetAAfficher.metadata === undefined
    ) {
      //envoie de la requête de récupération de données complémentaires pour de l'objet
      req10614 = () => {
        this.props.reqRecupererObjetAAfficherAssocieVue(
          this.props.view_id,
          this.props.objetAAfficher.viewobj_id
        );
      };
    }

    let stylePopup = {
      directionPopup: 'haut',
    };
    let representation_vue;
    switch (this.props.objetAAfficher.sensor_type_display) {
      case glossaire_affichage_type_capteur.default_display:
        representation_vue = (
          <div
            key={this.props.key}
            className={[this.props.sensor_color_class, S.contourCapteur].join(
              ' '
            )}
            onClick={() => {
              this.switchAfficherPopup();
              req10614();
            }}
          >
            <AffichageIconeCapteur
              alt={this.props.objetAAfficher.icone_path}
              src={this.props.objetAAfficher.icone_path}
              height={this.props.dimensionsIcon}
              width={this.props.dimensionsIcon}
            />
            <PopupObjet
              largeurPopup={this.state.largeurPopup}
              stylePopup={stylePopup}
              afficherPopup={this.state.afficherPopup}
              fermerPopup={this.switchAfficherPopup}
              headerPopupText={this.props.objetAAfficher.name}
            >
              {composants}
            </PopupObjet>
          </div>
        );
        break;
      case glossaire_affichage_type_capteur.notify:
        representation_vue = (
          <div
            key={this.props.key}
            onClick={() => {
              this.switchAfficherPopup();
              req10614();
            }}
          >
            <AffichageIconeCapteur
              alt={this.props.objetAAfficher.icone_path}
              src={this.props.objetAAfficher.icone_path}
              height={this.props.dimensionsIcon}
              width={this.props.dimensionsIcon}
            />
            <span
              className={[
                this.props.sensor_color_class,
                S.contourNotification,
              ].join(' ')}
            />
            <PopupObjet
              largeurPopup={this.state.largeurPopup}
              stylePopup={stylePopup}
              afficherPopup={this.state.afficherPopup}
              fermerPopup={this.switchAfficherPopup}
              headerPopupText={this.props.objetAAfficher.name}
            >
              {composants}
            </PopupObjet>
          </div>
        );
        break;
      default:
        representation_vue = (
          <div
            key={this.props.key}
            className={[this.props.sensor_color_class, S.contourCapteur].join(
              ' '
            )}
            onClick={() => {
              this.switchAfficherPopup();
              req10614();
            }}
          >
            <AffichageIconeCapteur
              alt={this.props.objetAAfficher.icone_path}
              src={this.props.objetAAfficher.icone_path}
              height={this.props.dimensionsIcon}
              width={this.props.dimensionsIcon}
            />
            <PopupObjet
              largeurPopup={this.state.largeurPopup}
              stylePopup={stylePopup}
              afficherPopup={this.state.afficherPopup}
              fermerPopup={this.switchAfficherPopup}
              headerPopupText={this.props.objetAAfficher.name}
            >
              {composants}
            </PopupObjet>
          </div>
        );
        break;
    }

    //composantes de capteur //todo afficher les liens pour afficher les graphes automatiques
    if (
      this.props.objetAAfficher.get_values_onload === 0 ||
      this.props.objetAAfficher.get_metadata_onload === 0
    ) {
      //envoie de la requête de récupération de données complémentaires pour de l'objet
      req10614 = () => {
        this.props.reqRecupererObjetAAfficherAssocieVue(
          this.props.view_id,
          this.props.objetAAfficher.viewobj_id
        );
      };
      if (
        this.props.viewobj_content_list[
          this.props.objetAAfficher.viewobj_id
        ] !== undefined
      ) {
        const listeDernieresDonnees =
          this.props.viewobj_content_list[this.props.objetAAfficher.viewobj_id]
            .data;
        for (
          let indLastData = 0;
          indLastData < listeDernieresDonnees.length;
          indLastData++
        ) {
          const derniereValeur = formatUnite(
            listeDernieresDonnees[indLastData].y[
              listeDernieresDonnees[indLastData].y.length - 1
            ],
            listeDernieresDonnees[indLastData].engineering_unit
          ); //dernière valeur de la liste de données
          const derniereDate =
            listeDernieresDonnees[indLastData].x[
              listeDernieresDonnees[indLastData].x.length - 1
            ]; //dernière valeur de la liste de données
          composants.push(
            <a
              href={''}
              title={derniereDate}
              onClick={(event) => {
                event.preventDefault();
                window.open(
                  `/export_impression_ecran-${glossaire_type_export.graphe_automatique}-${this.props.objetAAfficher.sensor_id}_${listeDernieresDonnees[indLastData].position_in_sensor}`,
                  '_blank',
                  `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,outerWidth=${
                    window.outerWidth / 4
                  },outerHeight=${window.outerHeight / 4}`
                );
              }}
            >
              {`${listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${listeDernieresDonnees[indLastData].engineering_unit} date: ${derniereDate}`}
            </a>
          );
          composants.push(
            <br
              key={`${this.props.objetAAfficher.sensor_id} ${listeDernieresDonnees[indLastData].position_in_sensor} br`}
            />
          );
        }
      } else {
        composants = <Spinner />;
      }
    } else if (this.props.listeDernieresDonnees !== undefined) {
      for (
        let indLastData = 0;
        indLastData < this.props.listeDernieresDonnees.length;
        indLastData++
      ) {
        const derniereValeur = formatUnite(
          this.props.listeDernieresDonnees[indLastData].y[
            this.props.listeDernieresDonnees[indLastData].y.length - 1
          ],
          this.props.listeDernieresDonnees[indLastData].engineering_unit
        ); //dernière valeur de la liste de données
        const derniereDate =
          this.props.listeDernieresDonnees[indLastData].x[
            this.props.listeDernieresDonnees[indLastData].x.length - 1
          ];
        dateComposante = derniereDate;
        //Since JavaScript works in milliseconds, you should convert
        // the time into milliseconds by multiplying it by 1000.
        let date = new Date(derniereDate * 1000);
        // Hours part from the timestamp
        let hours = date.getHours();
        // Minutes part from the timestamp
        let minutes = '0' + date.getMinutes();
        // Seconds part from the timestamp
        let seconds = '0' + date.getSeconds();
        // Will display time in 11:10:22 format
        let formatTime =
          hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        composants.push(
          <a
            href={''}
            title={formatTime}
            onClick={(event) => {
              event.preventDefault();
              window.open(
                `/export_impression_ecran-${glossaire_type_export.graphe_automatique}-${this.props.objetAAfficher.sensor_id} ${this.props.listeDernieresDonnees[indLastData].position_in_sensor}`,
                '_blank',
                `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,width=${window.outerWidth},height=${window.outerHeight}`
              );
            }}
          >
            {`${this.props.listeDernieresDonnees[indLastData].prefix} = ${derniereValeur} ${this.props.listeDernieresDonnees[indLastData].engineering_unit}`}
          </a>
        );
        composants.push(
          <br
            key={`${this.props.objetAAfficher.sensor_id} ${this.props.listeDernieresDonnees[indLastData].position_in_sensor} br`}
          />
        );
      }
    }
    //représentation de l'objet sur la vue
    if (this.props.objetAAfficher !== undefined) {
      if (
        this.state.viewobj_json_content === null &&
        this.props.objetAAfficher.viewobj_path !== undefined &&
        stringOrEmptyString(this.props.objetAAfficher.viewobj_path) !== '' &&
        !this.state.attenteRecupFichierJson &&
        this.props.viewobj_json_content === undefined
      ) {
        // cas où le JSON n'est pas récupéré
        try {
          let cheminURL = setUrlPathFromDomain(
            this.props.objetAAfficher.viewobj_path,
            'https://' +
              document.domain +
              this.props.objetAAfficher.viewobj_path.slice(
                1,
                this.props.objetAAfficher.viewobj_path.length
              )
          );
          fetch(cheminURL + '?varget' + Math.random())
            //todo: modifier le chemin absolu en chemin relatif
            .then((res) => res.json())
            .then((data) => {
              this.setState({
                attenteRecupFichierJson: false,
                viewobj_json_content: data,
              });
            })
            .catch((e) => console.error('error: ', e));
        } catch (e) {
          this.setState({
            attenteRecupFichierJson: false,
            viewobj_json_content: null,
          });
          console.error('error', e);
        }
      } else if (this.state.viewobj_json_content !== null) {
        let sensor_color_class = this.props.sensor_color_class;
        if (this.state.viewobj_json_content.watchdogSeconde !== undefined) {
          sensor_color_class = this.determinerCouleurCapteur(
            this.state.viewobj_json_content.watchdogSeconde
          );
        }
        if (
          this.state.viewobj_json_content.stylePopup !== undefined &&
          this.state.viewobj_json_content.stylePopup !== null
        ) {
          stylePopup = {
            ...this.state.viewobj_json_content.stylePopup,
          };
        }
        let dictionaireDerniereValeur = {};
        let donneeComposante = 'position_in_sensor invalide';
        let maxDonneeComposante = 'position_in_sensor invalide';
        let unite = '';
        let direction = 'position_in_sensor_direction invalide';
        let vitesse = 'position_in_sensor_speed invalide';
        // let uniteDirection = "";
        let uniteVitesse = '';
        if (this.props.listeDernieresDonnees !== undefined) {
          for (
            let indLastData = 0;
            indLastData < this.props.listeDernieresDonnees.length;
            indLastData++
          ) {
            dictionaireDerniereValeur[
              this.props.listeDernieresDonnees[indLastData].position_in_sensor
            ] = {
              derniereValeur: formatUnite(
                this.props.listeDernieresDonnees[indLastData].y[
                  this.props.listeDernieresDonnees[indLastData].y.length - 1
                ],
                this.props.listeDernieresDonnees[indLastData].engineering_unit
              ), //dernière valeur de la liste de données
              derniereDate:
                this.props.listeDernieresDonnees[indLastData].x[
                  this.props.listeDernieresDonnees[indLastData].x.length - 1
                ],
              unite:
                this.props.listeDernieresDonnees[indLastData].engineering_unit,
            };
            if (
              this.props.objetAAfficher.time_prediction_values !== null ||
              this.props.objetAAfficher.time_past_values !== null
            ) {
              // récupère la valeur maximale des données récupérées
              const max = Math.max(
                ...this.props.listeDernieresDonnees[indLastData].y
              );
              dictionaireDerniereValeur[
                this.props.listeDernieresDonnees[indLastData].position_in_sensor
              ]['maxValeur'] = max;
              dictionaireDerniereValeur[
                this.props.listeDernieresDonnees[indLastData].position_in_sensor
              ]['maxDate'] =
                this.props.listeDernieresDonnees[indLastData].x[
                  this.props.listeDernieresDonnees[indLastData].y.indexOf(max)
                ];
            }
          }
        }
        if (
          this.state.viewobj_json_content.position_in_sensor !== undefined &&
          dictionaireDerniereValeur[
            this.state.viewobj_json_content.position_in_sensor
          ]
        ) {
          donneeComposante =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].derniereValeur;
          maxDonneeComposante =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].maxValeur;
          dateComposante =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].derniereDate;
          unite =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].unite;
          donneeComposante = formatUnite(
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].derniereValeur,
            unite
          );
          maxDonneeComposante =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].maxValeur;
          dateComposante =
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor
            ].derniereDate;
        } else if (
          this.state.viewobj_json_content.position_in_sensor_direction !==
            undefined &&
          this.state.viewobj_json_content.position_in_sensor_speed !== undefined
        ) {
          if (
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor_direction
            ] !== undefined
          ) {
            direction =
              dictionaireDerniereValeur[
                this.state.viewobj_json_content.position_in_sensor_direction
              ].derniereValeur;
            // uniteDirection = dictionaireDerniereValeur[this.state.viewobj_json_content.position_in_sensor_direction].unite;
          }
          if (
            dictionaireDerniereValeur[
              this.state.viewobj_json_content.position_in_sensor_speed
            ] !== undefined
          ) {
            vitesse =
              dictionaireDerniereValeur[
                this.state.viewobj_json_content.position_in_sensor_speed
              ].derniereValeur;
            uniteVitesse =
              dictionaireDerniereValeur[
                this.state.viewobj_json_content.position_in_sensor_speed
              ].unite;
          }
        }
        switch (this.state.viewobj_json_content.type_de_representation) {
          case glossaire_type_objets_vue.rectangle_de_composantes:
            representation_vue = (
              <div className={S.centerBullTwo}>
                <div
                  key={this.props.key}
                  className={sensor_color_class}
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.styleMainDiv
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.styleMainDiv,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  {composants}
                </div>
              </div>
            );
            break;
          case glossaire_type_objets_vue.rectangle_de_donnees:
            const afficherUnite =
              this.state.viewobj_json_content.sansUnite === true
                ? ''
                : unite !== ''
                ? ' ' + unite
                : '';
            if (
              this.state.viewobj_json_content.trailingDecimals !== undefined
            ) {
              if (this.state.viewobj_json_content.valeurMax) {
                maxDonneeComposante =
                  this.state.viewobj_json_content.trailingDecimals === 0
                    ? Math.trunc(maxDonneeComposante)
                    : maxDonneeComposante.toFixed(
                        this.state.viewobj_json_content.trailingDecimals
                      );
              } else {
                donneeComposante =
                  this.state.viewobj_json_content.trailingDecimals === 0
                    ? Math.trunc(donneeComposante)
                    : donneeComposante.toFixed(
                        this.state.viewobj_json_content.trailingDecimals
                      );
              }
            }
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  className={sensor_color_class}
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.styleMainDiv
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.styleMainDiv,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  {this.state.viewobj_json_content.valeurMax === true // affiche la valeur max si la clé "valeurMax" du json est à true
                    ? maxDonneeComposante + afficherUnite
                    : donneeComposante + afficherUnite}
                </div>
                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                  derniereDate={dateComposante}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.rectangle_de_couleur:
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  className={sensor_color_class}
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.styleMainDiv
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.styleMainDiv,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                ></div>
                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.GaugesCompass:
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  <GaugesCompass
                    key={this.props.key}
                    className={sensor_color_class}
                    value={direction}
                    units={vitesse + stringOrEmptyString(uniteVitesse)}
                    parametreAffichage={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.parametreAffichage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.parametreAffichage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    ratio={this.props.mapZoom}
                    widget_header={
                      this.state.viewobj_json_content.widget_header
                    }
                    widget_footer={
                      this.state.viewobj_json_content.widget_footer
                    }
                    onClick={() => {
                      // this.switchAfficherPopup();
                      req10614();
                    }}
                  />
                  <div className={S.centerBullTwo}>
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                </div>
              </div>
            );
            break;
          case glossaire_type_objets_vue.LinearGauges:
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  <LinearGauges
                    key={this.props.key}
                    className={sensor_color_class}
                    value={donneeComposante}
                    units={unite}
                    parametreAffichage={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.parametreAffichage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.parametreAffichage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    ratio={this.props.mapZoom}
                    widget_header={
                      this.state.viewobj_json_content.widget_header
                    }
                    widget_footer={
                      this.state.viewobj_json_content.widget_footer
                    }
                    onClick={() => {
                      // this.switchAfficherPopup();
                      req10614();
                    }}
                  />
                  <div className={S.centerBullTwo}>
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                </div>
              </div>
            );
            break;
          case glossaire_type_objets_vue.affichageGif:
            /*
                            const exempleJson = {
                                "type_de_representation":5,
                                "position_in_sensor":1,
                                "type_image": "pompe",
                                "parametreAffichage":
                                    {
                                        "width": "50px",
                                        "height": "50px"
                                    }

                            }
                                                */
            let image_a_afficher;
            switch (this.state.viewobj_json_content.type_image) {
              case 'pompe':
                if (donneeComposante) {
                  image_a_afficher = require('./image_objet/Flexible_impeller_pump_on.gif');
                } else {
                  image_a_afficher = require('./image_objet/Flexible_impeller_pump_off.png');
                }
                break;
              case 'arrow_gif':
                if (donneeComposante) {
                  image_a_afficher = require('./image_objet/arrowbasOn.gif');
                } else {
                  image_a_afficher = require('./image_objet/arrowbasOff.gif');
                }
                break;
              case 'ventilateur':
                if (donneeComposante) {
                  image_a_afficher = require('./image_objet/vent_on.gif');
                } else {
                  image_a_afficher = require('./image_objet/vent_off.png');
                }
                break;
              default:
                image_a_afficher = require('./image_objet/error.gif');
                break;
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={image_a_afficher}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>

                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.ProgresseBar:
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  <ProgresseBar
                    value={donneeComposante}
                    units={unite}
                    ratio={this.props.mapZoom}
                    viewobj_json_content={this.state.viewobj_json_content}
                  />
                  <div className={S.centerBullTwo}>
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                </div>
              </div>
            );
            break;
          case glossaire_type_objets_vue.imageDynamique:
            /*
                            const exempleJson = {
                                "type_de_representation":7,
                                "position_in_sensor":1,
                                "tailleFixe": true,
                                "src_image_on": "http://localhost/argos_frontend/api_argos/image/sensor_type_pic_42.svg",
                                "src_image_off": "https://www.vernee.cc/wp-content/uploads/2022/07/image-GIF.gif",
                                "parametreAffichage":
                                    {
                                        "width": "50px",
                                        "height": "50px"
                                    },
                                "styleImage":
                                    {
                                        "width": "30px",
                                        "height": "30px"
                                    }

                            }
                                                */
            let image_dynamique_a_afficher;
            if (parseInt(donneeComposante) === 1) {
              image_dynamique_a_afficher =
                this.state.viewobj_json_content.src_image_on;
            } else {
              image_dynamique_a_afficher =
                this.state.viewobj_json_content.src_image_off;
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={image_dynamique_a_afficher}
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.styleImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.styleImage,
                            this.props.mapZoom
                          )),
                    }}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>

                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.iframeObjetCapteur:
            /*
                            const exempleJson = {
                                "type_de_representation":8,
                                "ajoutIdCapteur": true,
                                "tailleFixe": true,
                                "src_url": "http://localhost/argos_frontend/api_argos/image/sensor_type_pic_42.svg",
                                "parametreAffichage":
                                    {
                                        "width": "50px",
                                        "height": "50px"
                                    },
                                "styleIframe":
                                    {
                                        "width": "30px",
                                        "height": "30px"
                                    }

                            }
                                                */
            let src_iframe = this.state.viewobj_json_content.src_url;
            if (
              this.state.viewobj_json_content.ajoutIdCapteur !== undefined &&
              this.state.viewobj_json_content.ajoutIdCapteur !== null &&
              this.state.viewobj_json_content.ajoutIdCapteur
            ) {
              src_iframe =
                src_iframe + '/' + this.props.objetAAfficher.sensor_id;
            }

            representation_vue = (
              <div
                key={this.props.key}
                className={[sensor_color_class, S.contourCapteur].join(' ')}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <AffichageIconeCapteur
                  alt={this.props.objetAAfficher.icone_path}
                  src={this.props.objetAAfficher.icone_path}
                  height={this.props.dimensionsIcon}
                  width={this.props.dimensionsIcon}
                />
                <PopupObjet
                  largeurPopup={
                    this.state.viewobj_json_content.mainDivStyle !==
                      undefined &&
                    this.state.viewobj_json_content.mainDivStyle.width !==
                      undefined
                      ? parseInt(
                          this.state.viewobj_json_content.mainDivStyle.width,
                          10
                        )
                      : this.state.largeurPopup
                  }
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                  derniereDate={dateComposante}
                  mainDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.mainDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  headerDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.headerDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  wrapperDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.wrapperDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  contentDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.contentDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipContainerDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content
                          .tipContainerDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.tipDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                >
                  <div
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.divImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.divImage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  >
                    <iframe
                      scrolling="no"
                      src={src_iframe}
                      style={{
                        ...(this.state.viewobj_json_content.tailleFixe === true
                          ? this.state.viewobj_json_content.styleIframe
                          : ratioStyleCSS2PuissanceNiveauZoom(
                              this.state.viewobj_json_content.styleIframe,
                              this.props.mapZoom
                            )),
                      }}
                    ></iframe>
                  </div>
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.iframeObjetCapteurXImageDynamique:
            /*
                            const exempleJson ={
  "type_de_representation": 9,
  "position_in_sensor": 1,
  "tailleFixe": true,
  "src_image_on": "https://argos-v19.itmsol.fr/images/profiles/sensor_types/sensor_type_pic_99.gif",
  "src_image_off": "https://argos-v19.itmsol.fr/images/profiles/sensor_types/sensor_type_pic_105.png",
  "parametreAffichage": {
    "width": "0",
    "height": "0"
  },
  "styleImage": {
    "width": "30px",
    "height": "30px"
  },
  "ajoutIdCapteur": 1,
  "stylePopup": "bas",
  "src_url": "https://api.maintenance.argos-v19.itmsol.fr/electro-vanne",
  "styleIframe": {
    "width": "100%",
    "height": "550px",
    "border": "0",
    "overflow": "hidden"
  },
  "mainDivStyle": {
    "width": "250px",
    "padding": 0
  },
  "wrapperDivStyle": {
    "margin": 0,
    "padding": 0
  },
  "contentDivStyle": {
    "margin": 0,
    "padding": 0
  }

}
                                                */
            let image_dynamique_a_afficher_pour_icon_iframe;
            if (parseInt(donneeComposante) === 1) {
              image_dynamique_a_afficher_pour_icon_iframe =
                this.state.viewobj_json_content.src_image_on;
            } else {
              image_dynamique_a_afficher_pour_icon_iframe =
                this.state.viewobj_json_content.src_image_off;
            }
            let src_iframe_image_dynamique =
              this.state.viewobj_json_content.src_url;
            if (
              this.state.viewobj_json_content.ajoutIdCapteur !== undefined &&
              this.state.viewobj_json_content.ajoutIdCapteur !== null &&
              this.state.viewobj_json_content.ajoutIdCapteur
            ) {
              src_iframe_image_dynamique =
                src_iframe_image_dynamique +
                '/' +
                this.props.objetAAfficher.sensor_id;
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={image_dynamique_a_afficher_pour_icon_iframe}
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.styleImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.styleImage,
                            this.props.mapZoom
                          )),
                    }}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>
                <PopupObjet
                  largeurPopup={
                    this.state.viewobj_json_content.mainDivStyle !==
                      undefined &&
                    this.state.viewobj_json_content.mainDivStyle.width !==
                      undefined
                      ? parseInt(
                          this.state.viewobj_json_content.mainDivStyle.width,
                          10
                        )
                      : this.state.largeurPopup
                  }
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                  derniereDate={dateComposante}
                  mainDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.mainDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  headerDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.headerDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  wrapperDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.wrapperDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  contentDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.contentDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipContainerDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content
                          .tipContainerDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.tipDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                >
                  <div
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.divImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.divImage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  >
                    <iframe
                      scrolling="no"
                      src={src_iframe_image_dynamique}
                      style={{
                        ...(this.state.viewobj_json_content.tailleFixe === true
                          ? this.state.viewobj_json_content.styleIframe
                          : ratioStyleCSS2PuissanceNiveauZoom(
                              this.state.viewobj_json_content.styleIframe,
                              this.props.mapZoom
                            )),
                      }}
                    ></iframe>
                  </div>
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.rectangle_de_date:
            // Convertir le timestamp Unix en objet Date
            const date = new Date(dateComposante * 1000);
            // Configuration par défaut
            const defaultConfig = {
              dateFormat: 'YYYY-MM-DD',
              locale: 'fr-FR',
              // Ajoutez d'autres valeurs par défaut ici
            };

            // Fusionner la configuration JSON avec la configuration par défaut
            const config = {
              ...defaultConfig,
              ...this.state.viewobj_json_content.dateConfig,
            };

            // Formatter la date selon la configuration fusionnée
            const formattedDate = date.toLocaleDateString(config.locale, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              // Ajoutez d'autres options de formatage si nécessaire
              ...this.state.viewobj_json_content.optionDate,
            });
            representation_vue = (
              <div className={S.centerBull}>
                <div
                  key={this.props.key}
                  className={sensor_color_class}
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.styleMainDiv
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.styleMainDiv,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  onClick={() => {
                    this.switchAfficherPopup();
                    req10614();
                  }}
                >
                  {formattedDate}
                </div>
                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                  derniereDate={dateComposante}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.iframeObjetTypeCapteurXImageDynamique:
            /*
                            const exempleJson ={
  "type_de_representation": 11,
  "position_in_sensor": 1,
  "tailleFixe": true,
  "src_image_on": "https://argos-v19.itmsol.fr/images/profiles/sensor_types/sensor_type_pic_99.gif",
  "src_image_off": "https://argos-v19.itmsol.fr/images/profiles/sensor_types/sensor_type_pic_105.png",
  "parametreAffichage": {
    "width": "0",
    "height": "0"
  },
  "styleImage": {
    "width": "30px",
    "height": "30px"
  },
  "ajoutIdCapteur": 1,
  "stylePopup": "bas",
  "src_url": "https://api.maintenance.argos-v19.itmsol.fr/electro-vanne",
  "styleIframe": {
    "width": "100%",
    "height": "550px",
    "border": "0",
    "overflow": "hidden"
  },
  "mainDivStyle": {
    "width": "250px",
    "padding": 0
  },
  "wrapperDivStyle": {
    "margin": 0,
    "padding": 0
  },
  "contentDivStyle": {
    "margin": 0,
    "padding": 0
  }

}
                                                */
            let image_dynamique_type11_a_afficher_pour_icon_iframe;
            if (parseInt(donneeComposante) === 1) {
              image_dynamique_type11_a_afficher_pour_icon_iframe =
                this.state.viewobj_json_content.src_image_on;
            } else {
              image_dynamique_type11_a_afficher_pour_icon_iframe =
                this.state.viewobj_json_content.src_image_off;
            }
            let src_iframe_image_dynamique_type11 =
              this.state.viewobj_json_content.src_url;
            if (
              this.state.viewobj_json_content.ajoutIdCapteur !== undefined &&
              this.state.viewobj_json_content.ajoutIdCapteur !== null &&
              this.state.viewobj_json_content.ajoutIdCapteur
            ) {
              src_iframe_image_dynamique_type11 =
                src_iframe_image_dynamique_type11 +
                '/' +
                this.props.objetAAfficher.metadata.id_type +
                '_' +
                this.props.objetAAfficher.sensor_id;
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={image_dynamique_type11_a_afficher_pour_icon_iframe}
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.styleImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.styleImage,
                            this.props.mapZoom
                          )),
                    }}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>
                <PopupObjet
                  largeurPopup={
                    this.state.viewobj_json_content.mainDivStyle !==
                      undefined &&
                    this.state.viewobj_json_content.mainDivStyle.width !==
                      undefined
                      ? parseInt(
                          this.state.viewobj_json_content.mainDivStyle.width,
                          10
                        )
                      : this.state.largeurPopup
                  }
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                  derniereDate={dateComposante}
                  mainDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.mainDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  headerDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.headerDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  wrapperDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.wrapperDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  contentDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.contentDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipContainerDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content
                          .tipContainerDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                  tipDirectionDivStyle={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.tipDirectionDivStyle
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }}
                >
                  <div
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.divImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.divImage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  >
                    {this.state.afficherPopup && (
                      <IframeObjetTypeCapteurXImageDynamique
                        scrolling="no"
                        src={src_iframe_image_dynamique_type11}
                        style={{
                          ...(this.state.viewobj_json_content.tailleFixe ===
                          true
                            ? this.state.viewobj_json_content.styleIframe
                            : ratioStyleCSS2PuissanceNiveauZoom(
                                this.state.viewobj_json_content.styleIframe,
                                this.props.mapZoom
                              )),
                        }}
                      />
                    )}
                  </div>
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.imageDynamiqueArray:
            /*
                                const exempleJson = {
                                    "type_de_representation":12,
                                    "position_in_sensor":1,
                                    "tailleFixe": true,
                                    "src_images_array": ["https://argos-v19.itmsol.fr/images/views/gauge_environnement_0.png","https://argos-v19.itmsol.fr/images/views/gauge_environnement_1.png","https://argos-v19.itmsol.fr/images/views/gauge_environnement_2.png","https://argos-v19.itmsol.fr/images/views/gauge_environnement.png",],
                                    "values_array": [0,1,2,3],
                                    "parametreAffichage":
                                        {
                                            "width": "50px",
                                            "height": "50px"
                                        },
                                    "styleImage":
                                        {
                                            "width": "30px",
                                            "height": "30px"
                                        }
    
                                }
                                                    */
            let image_dynamique_array_a_afficher;
            let indexValeur =
              this.state.viewobj_json_content.values_array.indexOf(
                parseInt(donneeComposante)
              );

            if (indexValeur >= 0) {
              let indexValeur =
                this.state.viewobj_json_content.values_array.indexOf(
                  parseInt(donneeComposante)
                );
              image_dynamique_array_a_afficher =
                this.state.viewobj_json_content.src_images_array[indexValeur];
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={image_dynamique_array_a_afficher}
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.styleImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.styleImage,
                            this.props.mapZoom
                          )),
                    }}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>

                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                >
                  {composants}
                </PopupObjet>
              </div>
            );
            break;
          case glossaire_type_objets_vue.imageDynamiqueArrayDansPopup:
            /*
                            const exempleJson = {
    "type_de_representation":13,
    "position_in_sensor":1,
    "tailleFixe": true,
    "src_images_array_popup": "https://argos-v19.itmsol.fr/images/views/gauge_environnement.png",
    "src_images_array": [
        "https://argos-v19.itmsol.fr/images/views/gauge_environnement_0.png",
        "https://argos-v19.itmsol.fr/images/views/gauge_environnement_1.png",
        "https://argos-v19.itmsol.fr/images/views/gauge_environnement_2.png",
        "https://argos-v19.itmsol.fr/images/views/gauge_environnement.png"],
    "values_array": [0,1,2,3],
    "parametreAffichage":
        {
            "width": "50px",
            "height": "50px"
        },
    "styleImage":
        {
            "width": "30px",
            "height": "30px"
        }

}
                                                */
            let image_dynamique_array_dans_popup_a_afficher;
            let indexValeur_pour_popup =
              this.state.viewobj_json_content.values_array.indexOf(
                parseInt(donneeComposante)
              );

            if (indexValeur_pour_popup >= 0) {
              image_dynamique_array_dans_popup_a_afficher =
                this.state.viewobj_json_content.src_images_array[
                  indexValeur_pour_popup
                ];
            }
            representation_vue = (
              <div
                className={S.centerBullTwo}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.parametreAffichage
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.parametreAffichage,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                key={this.props.key}
                onClick={() => {
                  this.switchAfficherPopup();
                  req10614();
                }}
              >
                <div
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.divImage
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.divImage,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                >
                  <img
                    src={this.state.viewobj_json_content.src_images_array_popup}
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.styleImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.styleImage,
                            this.props.mapZoom
                          )),
                    }}
                    width={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.width
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.width,
                            this.props.mapZoom
                          )
                    } //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                    height={
                      this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.height
                        : ratioString2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.height,
                            this.props.mapZoom
                          )
                    }
                    alt={checkMot('gif')}
                  />
                </div>

                <PopupObjet
                  largeurPopup={this.state.largeurPopup}
                  stylePopup={stylePopup}
                  afficherPopup={this.state.afficherPopup}
                  fermerPopup={this.switchAfficherPopup}
                  headerPopupText={this.props.objetAAfficher.name}
                >
                  <div
                    style={{
                      ...(this.state.viewobj_json_content.tailleFixe === true
                        ? this.state.viewobj_json_content.divImage
                        : ratioStyleCSS2PuissanceNiveauZoom(
                            this.state.viewobj_json_content.divImage,
                            this.props.mapZoom
                          )),
                    }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  >
                    <img
                      src={image_dynamique_array_dans_popup_a_afficher}
                      width={'100%'} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                      //  height={this.state.viewobj_json_content.tailleFixe === true ? this.state.viewobj_json_content.height : ratioString2PuissanceNiveauZoom(this.state.viewobj_json_content.height, this.props.mapZoom)}
                      alt={checkMot('gif')}
                    />
                  </div>
                </PopupObjet>
              </div>
            );
            break;

          default:
            switch (this.props.objetAAfficher.sensor_type_display) {
              case glossaire_affichage_type_capteur.default_display:
                representation_vue = (
                  <div
                    key={this.props.key}
                    className={[sensor_color_class, S.contourCapteur].join(' ')}
                    onClick={() => {
                      this.switchAfficherPopup();
                      req10614();
                    }}
                  >
                    <AffichageIconeCapteur
                      alt={this.props.objetAAfficher.icone_path}
                      src={this.props.objetAAfficher.icone_path}
                      height={this.props.dimensionsIcon}
                      width={this.props.dimensionsIcon}
                    />
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                      derniereDate={dateComposante}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                );
                break;
              case glossaire_affichage_type_capteur.notify:
                representation_vue = (
                  <div
                    key={this.props.key}
                    onClick={() => {
                      this.switchAfficherPopup();
                      req10614();
                    }}
                  >
                    <AffichageIconeCapteur
                      alt={this.props.objetAAfficher.icone_path}
                      src={this.props.objetAAfficher.icone_path}
                      height={this.props.dimensionsIcon}
                      width={this.props.dimensionsIcon}
                    />
                    <span
                      className={[
                        sensor_color_class,
                        S.contourNotification,
                      ].join(' ')}
                    />
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                      derniereDate={dateComposante}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                );
                break;
              default:
                representation_vue = (
                  <div
                    key={this.props.key}
                    className={[sensor_color_class, S.contourCapteur].join(' ')}
                    onClick={() => {
                      this.switchAfficherPopup();
                      req10614();
                    }}
                  >
                    <AffichageIconeCapteur
                      alt={this.props.objetAAfficher.icone_path}
                      src={this.props.objetAAfficher.icone_path}
                      height={this.props.dimensionsIcon}
                      width={this.props.dimensionsIcon}
                    />
                    <PopupObjet
                      largeurPopup={this.state.largeurPopup}
                      stylePopup={stylePopup}
                      afficherPopup={this.state.afficherPopup}
                      fermerPopup={this.switchAfficherPopup}
                      headerPopupText={this.props.objetAAfficher.name}
                      derniereDate={dateComposante}
                    >
                      {composants}
                    </PopupObjet>
                  </div>
                );
                break;
            }
            break;
        }
      }
    }
    return representation_vue;
  }
}

const mapStateToProps = (state) => {
  return {
    viewobj_content_list: state.vue.viewobj_content_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reqRecupererObjetAAfficherAssocieVue: (view_id, viewobj_id) =>
      dispatch(
        actions.reqRecupererObjetAAfficherAssocieVue(view_id, viewobj_id)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ObjetCapteur);

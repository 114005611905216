import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  alertesListe: [],
};

const supprimerAlerte = (state, action) => {
  let newAlertesListe = [...state.alertesListe];
  newAlertesListe.splice(action.alerteKey, 1);
  return { ...state, alertesListe: newAlertesListe };
};
const ajouterAlerte = (state, action) => {
  let newAlertesListe = [...state.alertesListe];
  newAlertesListe.push({
    typeAlerte: action.typeAlerte,
    titre: action.titre,
    corps: action.corps,
  });
  return { ...state, alertesListe: newAlertesListe };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUPPRIMER_ALERTE:
      return supprimerAlerte(state, action);
    case actionTypes.AJOUTER_ALERTE:
      return ajouterAlerte(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Bouton from '../../../../../UI/Bouton/Bouton';
import AttenteRequete from '../../../../../../hoc/AttenteRequete/AttenteRequete';
import { checkMot } from '../../../../../../ressources/functionJS/checkMot';
import { jsDictionaryEquals } from '../../../../../../ressources/functionJS/jsDictionaryEquals';

import * as actions from '../../../../../../services/actions';
import * as requestType from '../../../../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../../../../ressources/glossaires/glossaire_type_de_bouton';

import * as s from '../../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireConfirmerAlarme.module.scss';

let comfirmeIcone = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1.1"
    viewBox="0 0 16 16"
    height="30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 3c-3.489 0-6.514 2.032-8 5 1.486 2.968 4.511 5 8 5s6.514-2.032 8-5c-1.486-2.968-4.511-5-8-5zM11.945 5.652c0.94 0.6 1.737 1.403 2.335 2.348-0.598 0.946-1.395 1.749-2.335 2.348-1.181 0.753-2.545 1.152-3.944 1.152s-2.763-0.398-3.945-1.152c-0.94-0.6-1.737-1.403-2.335-2.348 0.598-0.946 1.395-1.749 2.335-2.348 0.061-0.039 0.123-0.077 0.185-0.114-0.156 0.427-0.241 0.888-0.241 1.369 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.481-0.085-0.942-0.241-1.369 0.062 0.037 0.124 0.075 0.185 0.114v0zM8 6.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5 0.672-1.5 1.5-1.5 1.5 0.672 1.5 1.5z" />
  </svg>
);
let NonComfirmeIcone = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1.1"
    viewBox="0 0 16 16"
    height="30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.78 0.22c-0.293-0.293-0.768-0.293-1.061 0l-3.159 3.159c-0.812-0.246-1.671-0.378-2.561-0.378-3.489 0-6.514 2.032-8 5 0.643 1.283 1.573 2.391 2.703 3.236l-2.484 2.484c-0.293 0.293-0.293 0.768 0 1.061 0.146 0.146 0.338 0.22 0.53 0.22s0.384-0.073 0.53-0.22l13.5-13.5c0.293-0.293 0.293-0.768 0-1.061zM6.5 5c0.66 0 1.22 0.426 1.421 1.019l-1.902 1.902c-0.592-0.201-1.019-0.761-1.019-1.421 0-0.828 0.672-1.5 1.5-1.5zM1.721 8c0.598-0.946 1.395-1.749 2.335-2.348 0.061-0.039 0.123-0.077 0.185-0.114-0.156 0.427-0.241 0.888-0.241 1.369 0 0.858 0.27 1.652 0.73 2.303l-0.952 0.952c-0.819-0.576-1.519-1.311-2.057-2.162z" />
    <path d="M12 6.906c0-0.424-0.066-0.833-0.189-1.217l-5.028 5.028c0.384 0.123 0.793 0.189 1.217 0.189 2.209 0 4-1.791 4-4z" />
    <path d="M12.969 4.531l-1.084 1.084c0.020 0.012 0.040 0.024 0.059 0.037 0.94 0.6 1.737 1.403 2.335 2.348-0.598 0.946-1.395 1.749-2.335 2.348-1.181 0.753-2.545 1.152-3.944 1.152-0.604 0-1.202-0.074-1.781-0.219l-1.201 1.201c0.933 0.335 1.937 0.518 2.982 0.518 3.489 0 6.514-2.032 8-5-0.703-1.405-1.752-2.6-3.031-3.469z" />
  </svg>
);

class FormulaireConfirmerAlarme extends Component {
  state = {
    //paramètre par défaut de la vue
    acknowledgement_status: 0,
    remark: '',
    image: '',
    alarm_path_image: '',
  };

  componentDidMount() {
    if (
      this.props !== undefined &&
      this.props.preconfigurerAlarme === undefined
    ) {
      this.setState({
        //paramètre par défaut de la vue (voir si dessus)
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigurerAlarme !== undefined
    ) {
      this.setState({
        //pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigurerAlarme,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.preconfigurerAlarme !== undefined &&
      !jsDictionaryEquals(
        prevProps.preconfigurerAlarme,
        this.props.preconfigurerAlarme
      )
    ) {
      this.setState({
        //pré-configuration du formulaire
        acknowledgement_status: 0,
        remark: '',
        image: '',
        ...this.props.preconfigurerAlarme,
      });
    }
  }

  gererConfirmation = () => {
    //mise en forme de l'objet pour l'envoi de la requête API
    let formulaireConfirmationAlarme = {
      //clé du formulaire de la vue de l'api
      alarm_id: parseInt(this.props.preconfigurerAlarme.alarm_id),
      acknowledgement_status: 1,
      remark: this.state.remark,
      image: this.state.image,
    };

    //Envoi la requête de confirmation alarme
    this.props.reqConfirmerAlarme(formulaireConfirmationAlarme);
  };

  gererNePasConfirmer = () => {
    //mise en forme de l'objet pour l'envoi de la requête API
    let formulaireConfirmationAlarme = {
      //clé du formulaire de la vue de l'api
      alarm_id: parseInt(this.props.preconfigurerAlarme.alarm_id),
      acknowledgement_status: 0,
      remark: this.state.remark,
      image: this.state.image,
    };

    //Envoi la requête de confirmation alarme
    this.props.reqConfirmerAlarme(formulaireConfirmationAlarme);
  };
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  affichageFormulaire = (style, label_state, label_name) => {
    /**
     * permet d'afficher un composant input de gestion de la modification
     */

    return (
      <div className={[style, S.style].join(' ')}>
        <span className={[S.stil2].join(' ')}>{label_name}</span>
        <textarea
          className={[s.form_control, S.textarea].join(' ')}
          placeholder={label_name}
          name={label_state}
          value={
            this.props.preconfigurerAlarme !== undefined
              ? this.state[label_state]
              : null
          }
          disabled={this.props.affichageProfil === true}
          onChange={(event) => this.gererEtatDeCibleName(event)}
        />
      </div>
    );
  };
  gererImageImporter = (imageImporter) => {
    this.setState({ image: imageImporter.base64 });
  };

  onValueChange(event) {
    this.setState({
      acknowledgement_status: event.target.value,
    });
  }

  render() {
    return (
      <>
        <div className={[s.row].join(' ')}>
          <div className={S.ConteneurInfo}>
            <div className={[s.container, s.text_left].join(' ')}>
              <form onSubmit={(event) => event.preventDefault()}>
                <div className={s.form_group}>
                  <div className={[s.row, s.align_items_center].join(' ')}>
                    {/**DEBUT remark**/}
                    {this.affichageFormulaire(
                      s.col_12,
                      'remark',
                      checkMot('remarque')
                    )}
                    {/**FIN remark**/}

                    {/*Todo: effacer l'image d'alarme après avoir cliquer sur une autre alarme dans le tableau */}
                    {/*Todo: à dé-commenter après que le back puisse enregistrer l'image*/}
                    {/*<ImporterImage extensionAccepter={".jpg"} gererImageImporter={this.gererImageImporter}/>*/}
                  </div>
                </div>
                {this.props.affichageProfil === true ? null : (
                  <div className={[s.form_group, s.text_lg_right].join(' ')}>
                    <div className={[s.d_flex].join(' ')}>
                      <AttenteRequete
                        request_type={requestType.modifier_une_vue}
                        str={checkMot('confirmation_en_cour')}
                      >
                        {this.state.acknowledgement_status === 1 ? (
                          <Bouton
                            type={type_btn.danger}
                            cliquer={() => this.gererNePasConfirmer()}
                          >
                            {NonComfirmeIcone}
                            {checkMot('ne_pas_confirmer')}
                          </Bouton>
                        ) : (
                          <Bouton
                            type={type_btn.succes}
                            cliquer={() => this.gererConfirmation()}
                          >
                            {comfirmeIcone}
                            {checkMot('confirmer')}
                          </Bouton>
                        )}
                      </AttenteRequete>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqConfirmerAlarme: (formulaireConfirmationAlarme) =>
      dispatch(actions.reqConfirmerAlarme(formulaireConfirmationAlarme)),
  };
};

export default connect(null, mapDispatchToProps)(FormulaireConfirmerAlarme);

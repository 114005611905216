import React, { Component } from 'react';
import Bouton from '../../../../UI/Bouton/Bouton';
import { unixTimestampToDate } from '../../../../../ressources/functionJS/unixTimestampToDate';
import { checkMot } from '../../../../../ressources/functionJS/checkMot';

class SelectionDateForUnixTimestamp extends Component {
  state = {
    defaultDate:
      this.props.defaultValue !== null && this.props.defaultValue !== undefined
        ? unixTimestampToDate(this.props.defaultValue).split(', ')[0].split('/')
        : '',
    defaultTime:
      this.props.defaultValue !== null && this.props.defaultValue !== undefined
        ? unixTimestampToDate(this.props.defaultValue).split(', ')[1].split(':')
        : '',
    date:
      unixTimestampToDate(this.props.value) !== null
        ? unixTimestampToDate(this.props.value)
            .split(', ')[0]
            .split('/')
            .reverse()
            .join('-')
        : null,
    time:
      unixTimestampToDate(this.props.value) !== null
        ? unixTimestampToDate(this.props.value).split(', ')[1]
        : null,
  };
  render() {
    const { onUpdate } = this.props;
    const gereDate = (event) => {
      this.setState({ date: event.target.value });
    };
    const gereTime = (event) => {
      this.setState({ time: event.target.value });
    };
    const envoiDate = () => {
      if (this.state.date !== undefined && this.state.date !== null) {
        const splitDate = this.state.date.split('-');
        const splitHour = this.state.time.split(':');

        //fuseau horaire
        let valeurFuseau = new Date().getTimezoneOffset() / 60;
        //date
        //  les mois sont compris entre 0 and 11 (January to December).
        //  Les heures sont comprises entre 0 et 23 (00h pour minuit et 23h)
        let datum = new Date(
          Date.UTC(
            splitDate[0],
            parseInt(splitDate[1]) - 1,
            splitDate[2],
            parseInt(splitHour[0]) + valeurFuseau,
            splitHour[1],
            '00'
          )
        );
        //conversion en uts
        const timeToUnix = datum.getTime();
        onUpdate(timeToUnix / 1000);
      } else {
        onUpdate(null);
      }
    };
    return [
      <input
        type="date"
        defaultValue={
          this.state.defaultDate[2] +
          '-' +
          this.state.defaultDate[1] +
          '-' +
          this.state.defaultDate[0]
        }
        onChange={(event) => gereDate(event)}
      />,
      <input
        type="time"
        defaultValue={
          this.state.defaultTime[0] + ':' + this.state.defaultTime[1]
        }
        onChange={(event) => gereTime(event)}
      />,
      <Bouton cliquer={() => envoiDate()}>{checkMot('enregistrer')}</Bouton>,
      <Bouton cliquer={() => onUpdate(null)}>{checkMot('envoi_null')}</Bouton>,
    ];
  }
}
export default SelectionDateForUnixTimestamp;

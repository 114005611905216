import React, { Component } from 'react';
import { connect } from 'react-redux';

import { jsDictionaryEquals } from '../../../../../../../../ressources/functionJS/jsDictionaryEquals';
import * as S from './DateRapport.module.scss';
import * as s from '../../../../../../../../ressources/StyleGlobal/StyleGlobal';
import * as actions from '../../../../../../../../services/actions';
import Collapse from '../../../../../../../../hoc/Collapse/Collapse';
import { checkMot } from '../../../../../../../../ressources/functionJS/checkMot';

class DateRapport extends Component {
  state = {
    ...this.props.preconfigureObjet,
  };

  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererEtatDeCibleNameInt = (event) =>
    this.setState({
      ...this.state,
      [event.target.name]: parseInt(event.target.value),
    });

  componentDidMount() {
    if (
      this.props !== undefined &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //paramètre par défaut du rapport gabarit(voir si dessus)
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined
    ) {
      this.setState({
        //pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props !== undefined &&
      prevProps.preconfigureObjet !== this.props.preconfigureObjet &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //todo: paramètre par défaut
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined &&
      !jsDictionaryEquals(
        prevProps.preconfigureObjet,
        this.props.preconfigureObjet
      )
    ) {
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
    if (!jsDictionaryEquals(prevState, this.state)) {
      this.props.gererJson(this.state);
    }
  }

  render() {
    const glossaire_date_rapport = {
      0: checkMot('creation'),
      1: checkMot('debut_intervalle'),
      2: checkMot('fin_intervalle'),
      3: checkMot('publication'),
    };
    return (
      <Collapse
        ExpandText={
          <div className={S.contenuEnTete}>
            {this.props.contenuEnTete}
            <span className={S.titre}>
              <b title={checkMot('type_date')}>
                {glossaire_date_rapport[this.state.type_date]}:{' '}
              </b>
              <span title={checkMot('prefix_date')}>
                {this.state.prefix_date}
              </span>
              <span title={checkMot('format_date')}>
                {this.state.format_date}
              </span>
              <span title={checkMot('suffixe_date')}>
                {this.state.suffixe_date}
              </span>
            </span>
          </div>
        }
      >
        <div className={[s.col_6, S.style].join(' ')}>
          <span className={[S.stil2].join(' ')}>{checkMot('type_date')}</span>
          <select
            onChange={(event) => this.gererEtatDeCibleNameInt(event)}
            defaultValue={this.state.type_date}
            disabled={this.props.affichageProfil === true}
            value={this.state.type_date}
            name={'type_date'}
            className={[s.custom_select].join(' ')}
          >
            <option value={0}>{checkMot('creation')}</option>
            <option value={1}>{checkMot('debut_intervalle')}</option>
            <option value={2}>{checkMot('fin_intervalle')}</option>
            <option value={3}>{checkMot('publication')}</option>
          </select>
        </div>
        {this.props.children}
      </Collapse>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqCreerRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
    reqModifierRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
  };
};

export default connect(null, mapDispatchToProps)(DateRapport);

import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import {
  getFailureRatePerProject,
  getSensorFailureRatePerProject,
  getDailyFailureRate,
} from '../api';
import { affichageRetourErreurs } from '../utils/utils';

export const reqRecupererListeProjetsMaintenance = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_objet_projets_taux_de_pannes, {
      start_interval,
      end_interval,
      types_id_list: types_id_list.map((value) => parseInt(value)),
      criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
      projects_id,
    });
  };
};
export const reqRecupererLeTauxDePannesParJour = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.graphe_taux_de_pannes, {
      start_interval,
      end_interval,
      types_id_list: types_id_list.map((value) => parseInt(value)),
      criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
      projects_id,
    });
  };
};
export const reqRecupererLaListeDesCapteursEnPanne = (
  start_interval,
  end_interval,
  types_id_list,
  criticity_id_list,
  projects_id
) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.liste_des_capteurs_defaillants_par_projet,
      {
        start_interval,
        end_interval,
        types_id_list: types_id_list.map((value) => parseInt(value)),
        criticity_id_list: criticity_id_list.map((value) => parseInt(value)),
        projects_id,
      }
    );
  };
};

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */
  dispatch(actions.debutAttente(requestId));
  try {
    let response;
    switch (requestId) {
      case requestType.recuperer_objet_projets_taux_de_pannes:
        response = await getFailureRatePerProject(payload);
        if (response.data.state) {
          dispatch(enregistrerListeProjetsMaintenance(response.projects_list));
          return;
        }
        break;
      case requestType.graphe_taux_de_pannes:
        response = await getDailyFailureRate(payload);
        if (response.data.state) {
          dispatch(enregistrerListeTauxDePannesParJour(response.projects_list));
          return;
        }
        break;
      case requestType.liste_des_capteurs_defaillants_par_projet:
        response = await getSensorFailureRatePerProject(payload);

        if (response.data.state) {
          dispatch(enregistrerListeCapteursEnPanne(response.sensors_list));
          return;
        }

        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
    // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
    // dispatch(actions.finAttente(requeteAPI.request_type));
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

const enregistrerListeProjetsMaintenance = (projects_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_PROJETS_MAINTENANCE,
    projects_list: projects_list,
  };
};
const enregistrerListeTauxDePannesParJour = (projects_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_TAUX_DE_PANNES_PAR_JOUR,
    donnees_pannes: projects_list,
  };
};
const enregistrerListeCapteursEnPanne = (sensors_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_CAPTEURS_EN_PANNES,
    sensors_list: sensors_list,
  };
};

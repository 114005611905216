import React, { Component } from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';
import { FaDev } from 'react-icons/fa';
import { FiMap, FiMapPin } from 'react-icons/fi';
import { RiContactsBookLine, RiRegisteredLine } from 'react-icons/ri';
import { VscVersions } from 'react-icons/vsc';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import logoItm from '../../../ressources/logoitm.png';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import S from './Info.module.scss';
class Info extends Component {
  state = {};

  render() {
    return (
      <div className={[S.molder, s.row].join(' ')}>
        <div className={S.title}>
          <BsInfoCircleFill size={40} color={'#EEB214'} />{' '}
          {checkMot('info')}{' '}
        </div>
        <div className={[S.img, s.row].join(' ')}>
          <div className={[s.col_4, S.paD].join(' ')}>
            <div className={S.subtitle}>
              <RiRegisteredLine size={25} color={'#a6163f'} />{' '}
              {checkMot('itmsol_monitoring_software')}{' '}
            </div>
            <div className={S.subtitle_T}>
              <VscVersions size={25} color={'#a6163f'} /> {checkMot('argos')}
            </div>

            <div className={S.subtitle_A}>
              {' '}
              <FiMapPin size={25} color={'#a6163f'} />{' '}
              {checkMot("3 rue de l'arrivée")}
            </div>
            <div className={S.subtitle_N}>
              {' '}
              <FiMap size={25} color={'#a6163f'} /> {checkMot('75015, Paris')}
            </div>
            <div className={S.subtitle_A}>
              <RiContactsBookLine size={25} color={'#a6163f'} />{' '}
              {checkMot('Contact : ')}
              <a className={S.lin} href="https://itmsol.fr/contact">
                {checkMot('contact@itmsol.fr')}
              </a>
            </div>

            {/* <div className={S.subtitle_M}> <AiOutlineFileText size={20} color={"#a6163f"}/> <a  onClick={() => window.open("pdf/demo.pdf")}className={S.lien} >{checkMot("mentions_legales")}  </a></div> */}
            <div className={S.dev}>
              <FaDev size={25} color={'#a6163f'} />{' '}
              {checkMot('Développement :')}{' '}
            </div>
            <img alt="logo" src={logoItm} className={S.logoItm} />
          </div>
          <div
            className={[
              s.card,
              s.col_8,
              s.text_left,
              S.perso,
              s.overflow_auto,
            ].join(' ')}
          >
            <div className={s.card_body}>
              <h5 className={s.card_title}>
                {' '}
                Itmsol Monitoring Software ARGOS Monitoring Software V19
              </h5>

              <div className={[s.card_text, s.text_justify].join(' ')}>
                <p></p>
                <p>Mention légale</p>
                ARGOS Monitoring Software V19 Pour votre premier accès, vous
                devez accepter les termes et conditions ci-dessous : End User
                License Agreement for ARGOS Monitoring Software This End User
                License Agreement (EULA) accompanies ARGOS Monitoring Software
                and any upgrades, modified versions, or updates of the ARGOS
                Monitoring Software licensed to you by itmsol. Please read this
                EULA carefully. If you do not accept this agreement, you will
                not be able to use the Software. GRANT OF LICENSE
                <p></p>
                Conditions générales d'utilisation & responsabilité Tous les
                éléments visuels et rédactionnels de ce site Internet sont la
                propriété de ITMSOL, à l'exclusion des éléments émanant d'un
                partenaire d'ITMSOL. Les éléments de conception et développement
                du site restent la propriété de Précurseur. La protection du
                site www.itmsol.fr relève par conséquent des législations
                nationales et transnationales relatives au droit de la propriété
                intellectuelle. Toute reproduction et/ou représentation, en tout
                ou partie, sur un quelconque support, présent ou futur, sont
                interdites, sauf autorisation expresse d'ITMSOL. Le non-respect
                de cette interdiction est susceptible de constituer un acte de
                contrefaçon pouvant engager la responsabilité civile ou pénale
                de tout contrevenant.
                <p></p>
                Les informations contenues dans ce site sont non contractuelles
                et sujettes à modification sans préavis. En aucun cas, ITMSOL ne
                pourrait être tenue responsable de quelques dommages ou perte
                d'exploitation liés directement ou pas à l'utilisation de son
                site www.itmsol.fr
                <p></p>
                <p>
                  ARGOS Monitoring Software is licensed, not sold. itmsol grants
                  the purchaser a non-transferable, non-exclusive license to use
                  ARGOS Monitoring Software.
                </p>
                Propriété intellectuelle Les références commerciales des
                produits ou prestations de ITMSOL, www.argosV19.fr ainsi que
                leurs logos, sont des marques d'ITMSOL. Les logos et marques des
                sociétés citées sur le site www.argosV19.fr sont la propriété
                exclusive de leurs auteurs respectifs.
                <p>
                  RESTRICTIONS a. You may not resell, transfer, rent, or lease
                  ARGOS Monitoring Software to third parties. b. You may not
                  install ARGOS Monitoring Software on any Server, unless you
                  have purchased additional licenses. c. You may not modify,
                  adapt, translate, reverse engineer, decompile, disassemble or
                  otherwise attempt to discover the source code of the Software.
                  d. You may not violate or defeat the internal licensing
                  mechanism used by ARGOS Monitoring Software. NO WARRANTY
                </p>
                Toute représentation, reproduction, et/ou exploitation,
                qu’elle(s) soi(en)t partielle(s) ou totale(s), des marques
                précitées est (sont) interdite(s), sauf accord écrit et
                préalable de leurs titulaires. Conformément à la loi
                "Informatique et libertés" n°78-17 du 6 janvier 1978 modifiée,
                vous disposez d’un droit d’accès, de rectification, d’opposition
                et de suppression des données qui vous concernent. Pour
                l'exercer, adresser-vous à ITMSOL - contact@itmsol.fr
                <p>
                  ARGOS Monitoring Software is being delivered to you AS IS, and
                  itmsol makes no warranty as to its use or performance. ITMSOL
                  AND ITS SUPPLIERS DO NOT AND CANNOT WARRANT THE PERFORMANCE OR
                  RESULTS YOU MAY OBTAIN BY USING THE SOFTWARE OR DOCUMENTATION.
                  ITMSOL AND ITS SUPPLIERS MAKE NO WARRANTIES, EXPRESS OR
                  IMPLIED, AS TO NON-INFRINGEMENT OF THIRD PARTY RIGHTS,
                  MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE. IN NO
                  EVENT WILL ITMSOL OR ITS SUPPLIERS BE LIABLE TO YOU FOR ANY
                  CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, INCLUDING ANY
                  LOST PROFITS OR LOST SAVINGS, EVEN IF AN ITMSOL REPRESENTATIVE
                  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR
                  ANY CLAIM BY ANY THIRD PARTY. ARGOS Monitoring Software is
                  protected by copyright laws and international copyright
                  treaties, as well as other intellectual property laws and
                  treaties.
                </p>
                <p>
                  ITMSOL reserves all rights not expressly granted in this EULA.
                  Règlement Général de Protection des Données RGPD : Le logiciel
                  ARGOS enregistre les informations personnelles nécessaires à
                  son fonctionnement : le nom et le prénom mot de passe des
                  utilisateurs pour gérer leurs privilèges d’accès ainsi que les
                  adresses courriel et numéros de téléphone pour l’envoi
                  d’alarmes. Ces informations sont conservées autant que la
                  durée des contrats plus dix ans. Effective 1 July, 2021.
                  ITMSOL SA. 3, rue de l’arrivée 75015 Paris
                </p>
              </div>
              <a className={[s.btn, S.space, s.btn_primary].join(' ')}>
                {' '}
                <AiOutlineFileText size={20} color={'#fffff'} />{' '}
                <a
                  onClick={() =>
                    window.open(
                      'pdf/Argos Monitoring Software V19 - License.pdf'
                    )
                  }
                  className={S.lien}
                >
                  {checkMot('mentions_legales_pdf')}{' '}
                </a>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;

import React, { Component } from 'react';
import { BsTable } from 'react-icons/bs';
import { connect } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';

import { ImPageBreak, ImPagebreak } from 'react-icons/im';
import { jsDictionaryEquals } from '../../../../../../ressources/functionJS/jsDictionaryEquals';
import { checkMot } from '../../../../../../ressources/functionJS/checkMot';
import * as actions from '../../../../../../services/actions';
import { AiOutlineBarChart } from 'react-icons/ai';
import * as s from '../../../../../../ressources/StyleGlobal/StyleGlobal';
import * as S from './FormulaireListeObjetsRapport.module.scss';
import TexteRapport from './ObjetRapport/TexteRapport/TexteRapport';
import Bouton from '../../../../../UI/Bouton/Bouton';
import * as type_btn from '../../../../../../ressources/glossaires/glossaire_type_de_bouton';
import * as glossaire_type_d_objet_associe_a_un_rapport from '../../../../../../ressources/glossaires/glossaire_type_d_objet_associe_a_un_rapport';
import SectionRapport from './ObjetRapport/SectionRapport/SectionRapport';
import {
  BiReset,
  MdDeleteForever,
  TiArrowSortedDown,
  TiArrowSortedUp,
} from 'react-icons/all';
import { confirmeOnClick } from '../../../../../../ressources/functionJS/confirmeOnClick';
import SousSectionRapport from './ObjetRapport/SousSectionRapport/SousSectionRapport';
import ImageRapport from './ObjetRapport/ImageRapport/ImageRapport';
import DateRapport from './ObjetRapport/DateRapport/DateRapport';
import BoiteRapport from './ObjetRapport/BoiteRapport/BoiteRapport';
import GrapheRapport from './ObjetRapport/GrapheRapport/GrapheRapport';
import { BsViewList } from 'react-icons/bs';
import { ImFilePicture } from 'react-icons/im';
import { BsTextareaT } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import TableauComposantsRapport from './ObjetRapport/TableauCapteursRapport/TableauComposantsRapport';
import TableauAlarmesRapport from './ObjetRapport/TableauAlarmesRapport/TableauAlarmesRapport';
import { BsChevronBarDown, BsChevronBarUp } from 'react-icons/bs';
import VueRapport from './ObjetRapport/VueRapport/VueRapport';

class FormulaireListeObjetsRapport extends Component {
  state = {
    objects_list:
      this.props.objects_list === undefined ? [] : [...this.props.objects_list],
    newObjects_list: [],
  };

  componentDidMount() {
    if (this.props !== undefined && this.props.objects_list === undefined) {
      this.setState({
        //paramètre par défaut du rapport gabarit(voir si dessus)
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.objects_list !== undefined
    ) {
      this.setState({
        //pré-configuration du formulaire
        ...this.state,
        objects_list: [...this.props.objects_list],
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props !== undefined &&
      prevProps.objects_list !== this.props.objects_list &&
      this.props.objects_list === undefined
    ) {
      this.setState({
        //todo: paramètre par défaut
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.objects_list !== undefined &&
      !jsDictionaryEquals(prevProps.objects_list, this.props.objects_list)
    ) {
      //si la liste des objets est modifié
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        objects_list: [...this.props.objects_list],
      });
    }
  }

  gererObjectIndex = (objects_list_index, objet) => {
    let new_objects_list = [...this.state.objects_list];
    new_objects_list[objects_list_index] = { ...objet };
    this.setState({ objects_list: new_objects_list });
    this.props.gererObjectsList(new_objects_list);
  };

  affichageFormulaireObjet = (objects_list_index, object) => {
    /**
     * permet d'afficher un composant input de gestion de la modification
     */
    const boutonSupprimer = (
      <Bouton
        ajoutClass={S.boutonStyle}
        title={checkMot('supprimer_definitivement_l_objet')}
        type={type_btn.danger}
        cliquer={() =>
          confirmeOnClick(
            checkMot('voulez_vous_vraiment_supprimer_cette_objet_rapport'),
            () => {
              let new_objects_list = [...this.state.objects_list];
              new_objects_list.splice(objects_list_index, 1);
              this.setState({ objects_list: new_objects_list });
              this.props.gererObjectsList(new_objects_list);
            }
          )
        }
      >
        <MdDeleteForever size={18} />
      </Bouton>
    );
    const boutonReset = (
      <Bouton
        ajoutClass={S.boutonStyle}
        title={checkMot('reinitialiser_l_objet_avec_les_valeurs_par_defaut')}
        type={type_btn.danger}
        cliquer={() =>
          confirmeOnClick(
            checkMot('voulez_vous_vraiment_reinitialiser_l_objet_rapport') +
              ': ' +
              checkMot(
                Object.keys(glossaire_type_d_objet_associe_a_un_rapport).find(
                  (key) =>
                    glossaire_type_d_objet_associe_a_un_rapport[key] ===
                    object.report_object_type
                )
              ),
            () => {
              let new_objects_list = [...this.state.objects_list];
              new_objects_list[objects_list_index] =
                glossaire_type_d_objet_associe_a_un_rapport.objet_json[
                  object.report_object_type
                ];
              this.setState({ objects_list: new_objects_list });
              this.props.gererObjectsList(new_objects_list);
            }
          )
        }
      >
        <BiReset size={18} />
      </Bouton>
    );
    const boutonHaut = (
      <Bouton
        ajoutClass={S.boutonStyle}
        title={checkMot('deplacer_l_element_au_dessus')}
        type={type_btn.succes}
        desactive={objects_list_index === 0 ? 'disabled' : null}
        cliquer={() => {
          let new_objects_list = [...this.state.objects_list];
          var b = new_objects_list[objects_list_index];
          new_objects_list[objects_list_index] =
            new_objects_list[objects_list_index - 1];
          new_objects_list[objects_list_index - 1] = b;
          this.setState({ objects_list: new_objects_list });
          this.props.gererObjectsList(new_objects_list);
        }}
        dobleClique={() =>
          confirmeOnClick(checkMot('deplacer_l_element_en_premier'), () => {
            let new_objects_list = [...this.state.objects_list];
            var b = new_objects_list[objects_list_index];
            new_objects_list.splice(objects_list_index, 1);
            new_objects_list.unshift(b);
            this.setState({ objects_list: new_objects_list });
            this.props.gererObjectsList(new_objects_list);
          })
        }
      >
        <TiArrowSortedUp size={18} />
      </Bouton>
    );
    const boutonBas = (
      <Bouton
        ajoutClass={S.boutonStyle}
        title={checkMot('deplacer_l_element_en_dessous')}
        type={type_btn.succes}
        desactive={
          objects_list_index === this.state.objects_list.length - 1
            ? 'disabled'
            : null
        }
        cliquer={() => {
          let new_objects_list = [...this.state.objects_list];
          var b = new_objects_list[objects_list_index];
          new_objects_list[objects_list_index] =
            new_objects_list[objects_list_index + 1];
          new_objects_list[objects_list_index + 1] = b;
          this.setState({ objects_list: new_objects_list });
          this.props.gererObjectsList(new_objects_list);
        }}
        dobleClique={() =>
          confirmeOnClick(checkMot('deplacer_l_element_en_dernier'), () => {
            let new_objects_list = [...this.state.objects_list];
            var b = new_objects_list[objects_list_index];
            new_objects_list.splice(objects_list_index, 1);
            new_objects_list.push(b);
            this.setState({ objects_list: new_objects_list });
            this.props.gererObjectsList(new_objects_list);
          })
        }
      >
        <TiArrowSortedDown size={18} />
      </Bouton>
    );
    const boutonHautFirst = (
      <Bouton
        ajoutClass={[S.boutonStyle, S.personalise].join(' ')}
        title={checkMot('deplacer_l_element_au_dessus')}
        type={type_btn.succes}
        desactive={objects_list_index === 0 ? 'disabled' : null}
        cliquer={() => {
          let new_objects_list = [...this.state.objects_list];
          var b = new_objects_list[objects_list_index];
          new_objects_list.splice(objects_list_index, 1);
          new_objects_list.unshift(b);
          this.setState({ objects_list: new_objects_list });
          this.props.gererObjectsList(new_objects_list);
        }}
      >
        <BsChevronBarUp size={18} />
      </Bouton>
    );
    const boutonBasLast = (
      <Bouton
        ajoutClass={[S.boutonStyle, S.personalise].join(' ')}
        title={checkMot('deplacer_l_element_en_dessous')}
        type={type_btn.succes}
        desactive={
          objects_list_index === this.state.objects_list.length - 1
            ? 'disabled'
            : null
        }
        cliquer={() => {
          let new_objects_list = [...this.state.objects_list];
          var b = new_objects_list[objects_list_index];
          new_objects_list.splice(objects_list_index, 1);
          new_objects_list.push(b);
          this.setState({ objects_list: new_objects_list });
          this.props.gererObjectsList(new_objects_list);
        }}
      >
        <BsChevronBarDown size={18} />
      </Bouton>
    );

    //     const place = <input
    //     className={S.newSs}
    //     placeholder={this.state.objects_list.length}
    //     onChange={(event) => this.setState({ objects_list: [...this.state.objects_list, event.target.value]})}
    //   />;
    //     const position = <span>{this.state.objects_list.length}</span>

    let barreOutilsGauge = (
      <div className={S.barreOutil}>
        {boutonHaut}
        {boutonBas}
        {boutonBasLast}
        {boutonHautFirst}
      </div>
    );

    let barreOutilsDroit = (
      <div className={S.barreOutil}>
        {boutonSupprimer}
        {boutonReset}
        {/* <span className={S.marggA}>
           {position}       
            {place}
            </span>, */}
      </div>
    );
    if (this.props.affichageProfil === true) {
      barreOutilsGauge = null;
      barreOutilsDroit = null;
    }
    switch (object.report_object_type) {
      case glossaire_type_d_objet_associe_a_un_rapport.texte:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <TexteRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <BsTextareaT size={20} />
                    </span>
                    <span className={S.nexs}>{checkMot('texte')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </TexteRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.image:
        return (
          //img section---------------------------------------------------------------------------------------------------
          <div className={[s.col_12, S.card].join(' ')}>
            <ImageRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <ImFilePicture size={20} />
                    </span>
                    <span className={S.nexs}>{checkMot('image')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </ImageRapport>
          </div>
          //img section---------------------------------------------------------------------------------------------------
        );
      case glossaire_type_d_objet_associe_a_un_rapport.date:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <DateRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <BiCalendar size={20} />
                    </span>
                    {checkMot('date')}
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </DateRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.section:
        if (
          this.props.typeObjetParent === undefined &&
          this.props.typeObjetParent !==
            glossaire_type_d_objet_associe_a_un_rapport.section &&
          this.props.typeObjetParent !==
            glossaire_type_d_objet_associe_a_un_rapport.sous_section
        ) {
          return (
            <div className={[s.col_12, S.card].join(' ')}>
              <SectionRapport
                project_id={this.props.project_id}
                preconfigureObjet={object}
                affichageProfil={this.props.affichageProfil}
                gererJson={(objet) =>
                  this.gererObjectIndex(objects_list_index, objet)
                }
                contenuEnTete={
                  <div className={S.contenuEnTete}>
                    {barreOutilsGauge}
                    <span className={[S.stil2].join(' ')}>
                      <span className={S.nexs}>
                        {' '}
                        <ImPageBreak size={20} />
                      </span>
                      <span className={S.nexs}>{checkMot('section')}</span>
                    </span>
                    {barreOutilsDroit}
                  </div>
                }
              >
                {/*/!**DEBUT viewobj_json_content**!/{this.affichageFormulaire(object, <span*/}
                {/*className={[S.stil2].join(" ")}>{checkMot("viewobj_json_content")}</span>, (objetjson) => this.gererObjectIndex(objects_list_index, objetjson))}/!**FIN viewobj_json_content**!/*/}
              </SectionRapport>
            </div>
          );
        } else {
          return (
            <div
              className={[s.col_12, S.card].join(' ')}
              title={checkMot(
                'creation_de_section_invalide_dans_une_section_ou_une_sous_section'
              )}
            >
              {boutonSupprimer}
              {/**DEBUT reportobj_json_content**/}
              {this.affichageFormulaire(
                this.state.objects_list[objects_list_index],
                checkMot('creation_de_section_invalide'),
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN reportobj_json_content**/}
            </div>
          );
        }
      case glossaire_type_d_objet_associe_a_un_rapport.sous_section:
        if (
          this.props.typeObjetParent !== undefined &&
          this.props.typeObjetParent ===
            glossaire_type_d_objet_associe_a_un_rapport.section
        ) {
          return (
            <div className={[s.col_12, S.card].join(' ')}>
              <SousSectionRapport
                project_id={this.props.project_id}
                preconfigureObjet={object}
                affichageProfil={this.props.affichageProfil}
                gererJson={(objet) =>
                  this.gererObjectIndex(objects_list_index, objet)
                }
                contenuEnTete={
                  <div className={S.contenuEnTete}>
                    {barreOutilsGauge}
                    <span className={[S.stil2].join(' ')}>
                      <span className={S.nexs}>
                        {' '}
                        <ImPagebreak size={20} />
                      </span>
                      <span className={S.nexs}>{checkMot('sous_section')}</span>
                    </span>
                    {barreOutilsDroit}
                  </div>
                }
              >
                {/*/!**DEBUT viewobj_json_content**!/{this.affichageFormulaire(object, <span*/}
                {/*className={[S.stil2].join(" ")}>{checkMot("viewobj_json_content")}</span>, (objetjson) => this.gererObjectIndex(objects_list_index, objetjson))}/!**FIN viewobj_json_content**!/*/}
              </SousSectionRapport>
            </div>
          );
        } else {
          return (
            <div
              className={[s.col_12, S.card].join(' ')}
              title={checkMot(
                'creation_de_sous_section_invalide_dans_une_sous_section'
              )}
            >
              {boutonSupprimer}
              {/**DEBUT reportobj_json_content**/}
              {this.affichageFormulaire(
                this.state.objects_list[objects_list_index],
                checkMot('creation_de_sous_section_invalide'),
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN reportobj_json_content**/}
            </div>
          );
        }
      case glossaire_type_d_objet_associe_a_un_rapport.graphe:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <GrapheRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <AiOutlineBarChart size={20} />
                    </span>
                    <span className={S.nexs}>{checkMot('graphe')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </GrapheRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.tableau_composant:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <TableauComposantsRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <AiOutlineBarChart size={20} />
                    </span>
                    <span className={S.nexs}>
                      {checkMot('tableau_composant')}
                    </span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </TableauComposantsRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.tableau_alarme:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <TableauAlarmesRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <AiOutlineBarChart size={20} />
                    </span>
                    <span className={S.nexs}>{checkMot('tableau_alarme')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </TableauAlarmesRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.boite:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <BoiteRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <BsViewList size={20} />{' '}
                    </span>
                    <span className={S.nexs}>{checkMot('boite')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/*/!**DEBUT viewobj_json_content**!/{this.affichageFormulaire(object, <span*/}
              {/*className={[S.stil2].join(" ")}>{checkMot("viewobj_json_content")}</span>, (objetjson) => this.gererObjectIndex(objects_list_index, objetjson))}/!**FIN viewobj_json_content**!/*/}
            </BoiteRapport>
          </div>
        );
      case glossaire_type_d_objet_associe_a_un_rapport.vue:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            <VueRapport
              project_id={this.props.project_id}
              preconfigureObjet={object}
              affichageProfil={this.props.affichageProfil}
              gererJson={(objet) =>
                this.gererObjectIndex(objects_list_index, objet)
              }
              contenuEnTete={
                <div className={S.contenuEnTete}>
                  {barreOutilsGauge}
                  <span className={[S.stil2].join(' ')}>
                    <span className={S.nexs}>
                      {' '}
                      <AiOutlineBarChart size={20} />
                    </span>
                    <span className={S.nexs}>{checkMot('vue')}</span>
                  </span>
                  {barreOutilsDroit}
                </div>
              }
            >
              {/**DEBUT viewobj_json_content**/}
              {this.affichageFormulaire(
                object,
                <span className={[S.stil2].join(' ')}>
                  {checkMot('viewobj_json_content')}
                </span>,
                (objetjson) =>
                  this.gererObjectIndex(objects_list_index, objetjson)
              )}
              {/**FIN viewobj_json_content**/}
            </VueRapport>
          </div>
        );
      default:
        return (
          <div className={[s.col_12, S.card].join(' ')}>
            {/**DEBUT reportobj_json_content**/}
            {this.affichageFormulaire(
              this.state.objects_list[objects_list_index],
              <div className={S.contenuEnTete}>
                {barreOutilsGauge}

                <span className={[S.stil2].join(' ')}>
                  <span className={S.nexs}>
                    {' '}
                    <BsTable size={20} />
                  </span>
                  <span className={S.nexs}>
                    {checkMot(
                      Object.keys(
                        glossaire_type_d_objet_associe_a_un_rapport
                      ).find(
                        (key) =>
                          glossaire_type_d_objet_associe_a_un_rapport[key] ===
                          object.report_object_type
                      )
                    )}
                  </span>
                </span>
                {barreOutilsDroit}
              </div>,
              (objetjson) =>
                this.gererObjectIndex(objects_list_index, objetjson)
            )}
            {/**FIN reportobj_json_content**/}
          </div>
        );
    }
  };
  affichageFormulaire = (value, label_name, gererObjectIndex) => {
    let affichageFormulaireJSON;
    if (value.image_base64 !== undefined && value.image_base64.length > 500) {
      const showValue = { ...value, image_base64: "voir l'aperçu ci-dessus" };
      affichageFormulaireJSON = (
        <div className={[s.col_12, S.style].join(' ')}>
          {label_name}
          <textarea
            className={[s.col_12, s.form_control, S.textarea].join(' ')}
            placeholder={label_name}
            value={JSON.stringify(showValue, null, 2)}
            disabled={this.props.affichageProfil === true}
            onChange={(event) =>
              gererObjectIndex(
                JSON.parse(event.target.value) !== undefined
                  ? {
                      ...JSON.parse(event.target.value),
                      image_base64: value.image_base64,
                    }
                  : value
              )
            }
          />
        </div>
      );
    } else {
      affichageFormulaireJSON = (
        <div className={[s.col_12, S.style].join(' ')}>
          {label_name}
          <textarea
            className={[s.col_12, s.form_control, S.textarea].join(' ')}
            placeholder={label_name}
            value={JSON.stringify(value, null, 2)}
            disabled={this.props.affichageProfil === true}
            onChange={(event) =>
              gererObjectIndex(
                JSON.parse(event.target.value) !== undefined
                  ? JSON.parse(event.target.value)
                  : value
              )
            }
          />
        </div>
      );
    }

    return (
      <div>
        {/**DEBUT report_object_type**/}
        <div className={[s.col_12, S.style].join(' ')}>
          <span className={[S.stil2].join(' ')}>
            {checkMot('report_object_type')}
          </span>
          <select
            defaultValue={value.report_object_type}
            onChange={(event) =>
              confirmeOnClick(
                checkMot(
                  'voulez_vous_vraiment_changer_le_type_d_objet_rapport'
                ) +
                  ': ' +
                  checkMot(
                    Object.keys(
                      glossaire_type_d_objet_associe_a_un_rapport
                    ).find(
                      (key) =>
                        glossaire_type_d_objet_associe_a_un_rapport[key] ===
                        parseInt(event.target.value)
                    )
                  ),
                () =>
                  gererObjectIndex(
                    glossaire_type_d_objet_associe_a_un_rapport.objet_json[
                      event.target.value
                    ]
                  )
              )
            }
            disabled={this.props.affichageProfil === true}
            className={[s.custom_select].join(' ')}
          >
            {Object.keys(glossaire_type_d_objet_associe_a_un_rapport).map(
              (key) => {
                if (key !== 'objet_json' && key !== 'boite') {
                  return (
                    <option
                      value={glossaire_type_d_objet_associe_a_un_rapport[key]}
                    >
                      {checkMot(key)}
                    </option>
                  );
                }
              }
            )}
          </select>
        </div>
        {/**FIN report_object_type**/}
        {affichageFormulaireJSON}
      </div>
    );
  };

  render() {
    let formulaire = Object.keys(this.state.objects_list).map((objet, index) =>
      this.affichageFormulaireObjet(index, this.state.objects_list[index])
    );

    return (
      <>
        <div className={s.form_group}>
          <div
            className={[
              s.form_row,
              s.text_left,
              this.props.display === 'horizontal' ? S.horizontal : '',
            ].join(' ')}
          >
            {formulaire}
          </div>
          {this.props.affichageProfil === true ? null : (
            <div className={S.paddin}>
              <Bouton
                type={type_btn.outil}
                desactive={this.props.affichageProfil === true}
                cliquer={() => {
                  let new_objects_list = [...this.state.objects_list];
                  new_objects_list.push({
                    ...glossaire_type_d_objet_associe_a_un_rapport.objet_json[
                      glossaire_type_d_objet_associe_a_un_rapport.texte
                    ],
                  });
                  this.setState({ objects_list: new_objects_list });
                  this.props.gererObjectsList(new_objects_list);
                }}
              >
                <AiOutlinePlus size={15} />
              </Bouton>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqCreerRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
    reqModifierRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
  };
};

export default connect(null, mapDispatchToProps)(FormulaireListeObjetsRapport);

import React, { Component } from 'react';
import * as s from '../../../../../../../ressources/StyleGlobal/StyleGlobal';

import { jsDictionaryEquals } from '../../../../../../../ressources/functionJS/jsDictionaryEquals';
import Bouton from '../../../../../../UI/Bouton/Bouton';

import * as S from './FormulaireCourbe.module.scss';
import { checkMot } from '../../../../../../../ressources/functionJS/checkMot';
import Collapse from '../../../../../../../hoc/Collapse/Collapse';
import { confirmeOnClick } from '../../../../../../../ressources/functionJS/confirmeOnClick';
const decomposeStringMode = (str, modeStr) => {
  if (str !== undefined) {
    const arrayMode = str.split('+');
    return arrayMode.indexOf(modeStr) > -1;
  } else {
    return false;
  }
};
class FormulaireCourbe extends Component {
  state = {
    courbe_name: this.props.preConfigCourbe.name,
    formula: this.props.preConfigCourbe.formula,
    parameters: this.props.preConfigCourbe.parameters,
    show_alarms: this.props.preConfigCourbe.show_alarms,
    yaxis: this.props.preConfigCourbe.yaxis,
    type_courbe: this.props.preConfigCourbe.type,
    //mode: lines ou markers ou text
    lines: decomposeStringMode(this.props.preConfigCourbe.mode, 'lines'),
    markers: decomposeStringMode(this.props.preConfigCourbe.mode, 'markers'),
    text: decomposeStringMode(this.props.preConfigCourbe.mode, 'text'),
    //configuration de line{dash:...,shape...}
    line_dash:
      this.props.preConfigCourbe.line !== undefined
        ? this.props.preConfigCourbe.line.dash
        : '',
    line_shape:
      this.props.preConfigCourbe.line !== undefined
        ? this.props.preConfigCourbe.line.shape
        : '',

    //mise en page de l'affichage
    affichage_details: false,
  };

  componentDidMount() {
    // this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // if(this.props.preconfigurerUniteDeTempsId!==prevProps.preconfigurerUniteDeTempsId){
    //     this.setState({...this.state, uniteDeTempsId:this.props.preconfigurerUniteDeTempsId});
    // }

    if (!jsDictionaryEquals(this.props, prevProps)) {
      //met à jour l'affichage de la courbe si les configurations de graphe changent
      this.setState({
        courbe_name: this.props.preConfigCourbe.name,
        formula: this.props.preConfigCourbe.formula,
        parameters: this.props.preConfigCourbe.parameters,
        show_alarms: this.props.preConfigCourbe.show_alarms,
        yaxis: this.props.preConfigCourbe.yaxis,
        type_courbe: this.props.preConfigCourbe.type,
        //mode: lines ou markers ou text
        lines: decomposeStringMode(this.props.preConfigCourbe.mode, 'lines'),
        markers: decomposeStringMode(
          this.props.preConfigCourbe.mode,
          'markers'
        ),
        text: decomposeStringMode(this.props.preConfigCourbe.mode, 'text'),
        //configuration de line{dash:...,shape...}
        line_dash:
          this.props.preConfigCourbe.line !== undefined
            ? this.props.preConfigCourbe.line.dash
            : '',
        line_shape:
          this.props.preConfigCourbe.line !== undefined
            ? this.props.preConfigCourbe.line.shape
            : '',

        //mise en page de l'affichage
        affichage_details: false,
      });
    }
    if (!jsDictionaryEquals(this.state, prevState)) {
      let newMode = [];
      if (this.state.lines) {
        newMode.push('lines');
      }
      if (this.state.markers) {
        newMode.push('markers');
      }
      if (this.state.text) {
        newMode.push('text');
      }
      this.props.gererConfigCourbe({
        //config générale d'une courbe
        name: this.state.courbe_name, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: nom de la courbe
        show_alarms: parseInt(this.state.show_alarms), //TYPE: int + ValeurParDéfaut: 1 +  DESCRIPTION: affichage des seuils sur le graphe, 1 (affiche les alarmes) ou 0 (masque les alarmes)
        formula: this.state.formula, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
        parameters: this.state.parameters, //TYPE: String + ValeurParDéfaut: "" +  DESCRIPTION: voir le document de syntaxe
        yaxis: this.state.yaxis, //TYPE: String + ValeurParDéfaut: "y1" +  DESCRIPTION: "y1" ou "y2"
        type: this.state.type_courbe, //TYPE: String + ValeurParDéfaut: "scatter" +  DESCRIPTION: "scatter" ou "bar"

        //configuration pour un type de courbe scatter
        mode: newMode.join('+'), //TYPE: String + ValeurParDéfaut: "lines+makers" +  DESCRIPTION: 'lines et/ou markers et/ou text' concaténer avec un "+"

        //configuration pour un mode incluant line
        line: {
          //TYPE: Object + ValeurParDéfaut: {} +  DESCRIPTION: si mode contient "line"
          dash: this.state.line_dash, //TYPE: String + ValeurParDéfaut: "linear" +  DESCRIPTION: 'vh' ou 'hv' ou 'linear' ou 'spline' ou 'hvh' ou 'vhv'
          shape: this.state.line_shape, //TYPE: String + ValeurParDéfaut: "solid" +  DESCRIPTION:  'solid' ou 'dashdot' ou 'dot'
        },
      });
    }
  }
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  gererEtatCheckboxDeCibleName = (changeEvent) => {
    const { name } = changeEvent.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };
  afficherDetail = () =>
    this.setState({
      ...this.state,
      affichage_details: !this.state.affichage_details,
    });
  render() {
    // récupérer liste des UnitesDeTemps avec this.props.listeUnitesDeTemps
    // créer un formulaire pour ajouter un UniteDeTemps à un capteur sous forme de liste déroulante
    const detailsCourbe = (
      <>
        {/**DEBUT parameters**/}
        <div className={[s.row].join(' ')}>
          <div className={s.col_12}>
            <label className={[s.text_left, S.marge]}>
              {checkMot('parameters')}
            </label>
            <input
              type="text"
              pattern="[0-9a-zA-Z._%+-]+"
              className={s.form_control}
              placeholder="parameters"
              name="parameters"
              value={this.state.parameters}
              disabled={this.props.disabled === true}
              // required
              onChange={(event) => this.gererEtatDeCibleName(event)}
            />
          </div>

          <div className={[s.col_12].join(' ')}>
            {/**DEBUT show_alarms**/}
            <label className={[s.text_left, S.marge].join(' ')}>
              {checkMot('seuils')}
            </label>
            <select
              onChange={(event) => this.gererEtatDeCibleName(event)}
              name="show_alarms"
              defaultValue={this.state.show_alarms}
              disabled={this.props.disabled === true}
              value={this.state.show_alarms}
              className={s.custom_select}
            >
              <option value={1}>{checkMot('afficher_les_seuils')}</option>
              <option value={0}>{checkMot('masquer_les_seuils')}</option>
            </select>
            {/**FIN show_alarms**/}
          </div>
          <div className={[s.col_12].join(' ')}>
            {/**DEBUT yaxis**/}
            <label className={[s.text_left, S.marge]}>
              {checkMot('yaxis')}
            </label>
            <select
              onChange={(event) => this.gererEtatDeCibleName(event)}
              name="yaxis"
              defaultValue={this.state.yaxis}
              disabled={this.props.disabled === true}
              value={this.state.yaxis}
              className={s.custom_select}
            >
              <option value="y1">{checkMot('y1')}</option>
              <option value="y2">{checkMot('y2')}</option>
            </select>
            {/**FIN yaxis**/}
          </div>

          <div className={[s.col_12].join(' ')}>
            {/**DEBUT type_courbe**/}
            <label className={[s.text_left, S.marge].join(' ')}>
              {checkMot('type_de_courbe')}
            </label>
            <select
              onChange={(event) => this.gererEtatDeCibleName(event)}
              name="type_courbe"
              defaultValue={this.state.type_courbe}
              disabled={this.props.disabled === true}
              value={this.state.type_courbe}
              className={[s.custom_select, S.marge].join(' ')}
            >
              <option className={S.optios} value="scatter">
                {checkMot('scatter')}
              </option>
              <option className={S.optios} value="bar">
                {checkMot('bar')}
              </option>
            </select>
            {/**FIN type_courbe**/}
          </div>
        </div>

        {
          //configuration du mode de courbe si type==scatter
          this.state.type_courbe === 'scatter' ? (
            <>
              <div className={S.optios}>
                <input
                  name="lines"
                  checked={this.state.lines}
                  type="checkbox"
                  onChange={this.gererEtatCheckboxDeCibleName}
                  disabled={this.props.disabled === true}
                />
                {checkMot('lines')}
              </div>
              <div className={S.optios}>
                <input
                  name="markers"
                  checked={this.state.markers}
                  type="checkbox"
                  onChange={this.gererEtatCheckboxDeCibleName}
                  disabled={this.props.disabled === true}
                />
                {checkMot('markers')}
              </div>
              <div className={S.optios}>
                <input
                  name="text"
                  checked={this.state.text}
                  type="checkbox"
                  onChange={this.gererEtatCheckboxDeCibleName}
                  disabled={this.props.disabled === true}
                />
                {checkMot('text')}
              </div>
              {
                //configuration des lignes de courbe si ligne est présent dans mode: "lines+..."
                this.state.lines ? (
                  <div className={[s.text_left].join(' ')}>
                    {/**DEBUT line_dash**/}
                    <div className={s.col_12}>{checkMot('dash')}</div>
                    <select
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="line_dash"
                      defaultValue={this.state.line_dash}
                      disabled={this.props.disabled === true}
                      value={this.state.line_dash}
                      className={s.custom_select}
                    >
                      <option value="solid">{checkMot('solid')}</option>
                      <option value="dot">{checkMot('dot')}</option>
                      <option value="dash">{checkMot('dash')}</option>
                      <option value="longdash">{checkMot('longdash')}</option>
                      <option value="dashdot">{checkMot('dashdot')}</option>
                      <option value="longdashdot">
                        {checkMot('longdashdot')}
                      </option>
                    </select>
                    {/**FIN line_dash**/}
                    {/**DEBUT line_shape**/}
                    <div className={s.col_12}>{checkMot('shape')}</div>
                    <select
                      onChange={(event) => this.gererEtatDeCibleName(event)}
                      name="line_shape"
                      defaultValue={this.state.line_shape}
                      disabled={this.props.disabled === true}
                      value={this.state.line_shape}
                      className={s.custom_select}
                    >
                      <option value="vh">{checkMot('vh')}</option>
                      <option value="hv">{checkMot('hv')}</option>
                      <option value="linear">{checkMot('linear')}</option>
                      <option value="spline">{checkMot('spline')}</option>
                      <option value="hvh">{checkMot('hvh')}</option>
                      <option value="vhv">{checkMot('vhv')}</option>
                    </select>
                    {/**FIN line_shape**/}
                  </div> //Configuration de line_dash et line_shape
                ) : null
              }
            </>
          ) : null
        }
      </>
    );
    const iconeSupprimer = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-trash"
        viewBox="0 0 16 16"
      >
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path
          fillRule="evenodd"
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
      </svg>
    );
    return (
      <>
        {/*config générale d'une courbe*/}
        {/**DEBUT courbe_name && formula**/}
        <div className={s.row}>
          <div className={[s.col_12, S.sti1].join(' ')}>
            {this.props.disabled ? null : (
              <Bouton
                cliquer={() =>
                  confirmeOnClick(
                    checkMot('voulez_vous_vraiment_supprimer_cette_courbe'),
                    this.props.supprimerCourbe
                  )
                }
              >
                {/*Bouton supprimer courbe*/}
                {iconeSupprimer}
              </Bouton>
            )}
            <div className={s.col_12}>
              <label className={S.text}>{checkMot('nom_courbe')}</label>
              <input
                type="text"
                pattern="[0-9a-zA-Z._%+-]+"
                className={[s.form_control].join(' ')}
                placeholder="courbe_name"
                name="courbe_name"
                value={this.state.courbe_name}
                disabled={this.props.disabled === true}
                // required
                onChange={(event) => this.gererEtatDeCibleName(event)}
              />
            </div>
            <div className={[s.col_12, S.sti1].join(' ')}>
              <label className={S.text}>{checkMot('formula')}</label>
              <input
                type="text"
                pattern="[0-9a-zA-Z._%+-]+"
                className={[s.form_control].join(' ')}
                placeholder="formula"
                name="formula"
                value={this.state.formula}
                disabled={this.props.disabled === true}
                // required
                onChange={(event) => this.gererEtatDeCibleName(event)}
              />
            </div>

            <Collapse ExpandText={checkMot('parametres_avances')}>
              {detailsCourbe}
            </Collapse>
          </div>
          {/**FIN courbe_name && formula**/}

          {/*{this.state.affichage_details?:null}*/}
        </div>
      </>
    );
  }
}

export default FormulaireCourbe;

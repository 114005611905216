import React, { useState } from 'react';
import imageTransparente from './imageTransparente.svg';
import { setUrlPathFromDomain } from '../../../services/utils/utils';

function AffichageIconeCapteur(props) {
  const [afficherImageParDefaut, setAfficherImageParDefaut] = useState(false);
  let cheminImage = setUrlPathFromDomain(props.src, '../../.' + props.src);
  const imageCapteur = (
    <img
      className={props.ajoutClass}
      src={cheminImage}
      onError={() => setAfficherImageParDefaut(true)} //test en local
      alt={props.alt}
      height={props.height}
      width={props.width}
      style={{ borderRadius: '100%' }}
    />
  );
  const imageParDefaut = (
    <img
      className={props.ajoutClass}
      src={imageTransparente}
      alt={props.alt}
      height={props.height}
      width={props.width}
      style={{ borderRadius: '100%' }}
    />
  );
  return afficherImageParDefaut ? imageParDefaut : imageCapteur;
}

export default AffichageIconeCapteur;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Collapse from '../../../../../../../../hoc/Collapse/Collapse';
import { jsDictionaryEquals } from '../../../../../../../../ressources/functionJS/jsDictionaryEquals';
import * as actions from '../../../../../../../../services/actions';
import * as S from './VueRapport.module.scss';

class VueRapport extends Component {
  state = {
    ...this.props.preconfigureObjet,
  };

  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });

  componentDidMount() {
    if (
      this.props !== undefined &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //paramètre par défaut du rapport gabarit(voir si dessus)
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined
    ) {
      this.setState({
        //pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props !== undefined &&
      prevProps.preconfigureObjet !== this.props.preconfigureObjet &&
      this.props.preconfigureObjet === undefined
    ) {
      this.setState({
        //todo: paramètre par défaut
        ...this.state,
      });
    } else if (
      this.props !== undefined &&
      this.props.preconfigureObjet !== undefined &&
      !jsDictionaryEquals(
        prevProps.preconfigureObjet,
        this.props.preconfigureObjet
      )
    ) {
      this.setState({
        //todo: pré-configuration du formulaire
        ...this.state,
        ...this.props.preconfigureObjet,
      });
    }
    if (!jsDictionaryEquals(prevState, this.state)) {
      this.props.gererJson(this.state);
    }
  }

  gererVueId = (view_id) =>
    this.setState({ ...this.state, view_id: parseInt(view_id) });

  render() {
    return (
      <Collapse
        ExpandText={
          <div className={S.contenuEnTete}>
            {this.props.contenuEnTete}
            <span className={S.titre}>{this.state.text}</span>
            <span className={S.bold}>
              {' '}
              ID du vue : {this.props.preconfigureObjet.view_id}{' '}
            </span>
          </div>
        }
      >
        {this.props.children}
      </Collapse>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reqCreerRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqCreerRapportGabarit(formulaireRapportGabarit)),
    reqModifierRapportGabarit: (formulaireRapportGabarit) =>
      dispatch(actions.reqModifierRapportGabarit(formulaireRapportGabarit)),
  };
};

export default connect(null, mapDispatchToProps)(VueRapport);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import ProfilCapteur from '../Capteur/ProfilCapteur/ProfilCapteur';
import ConteneurInformation from '../ConteneurInformation/ConteneurInformation';
import AjouterCapteur from './Ajouter/AjouterCapteur';
import ImporterCapteurCSV from './ImporterCapteurCSV/ImporterCapteurCSV';
import SensorType from './SensorType/SensorType';
import TableauCapteur from './TableauCapteur/TableauCapteur';

import * as actions from '../../services/actions';
import Bouton from '../UI/Bouton/Bouton';
import Popup from '../UI/Popup/Popup';

import AccesRestreins from '../../hoc/AccesRestreins/AccesRestreins';
import { checkMot } from '../../ressources/functionJS/checkMot';
import * as indexConteneurInfo from '../../ressources/glossaires/glossaire_conteneur_info';
import * as privilege from '../../ressources/glossaires/glossaire_privileges';
import * as type_btn from '../../ressources/glossaires/glossaire_type_de_bouton';

import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi';
import { VscCloudUpload, VscListUnordered } from 'react-icons/vsc';
import S from './Capteur.module.scss';

class Capteur extends Component {
  state = {
    parametresSelectione: {},
    montrerInfo: false,
    indexConteneurInfo: null,
    montrerInfoFull: false,
  };
  capteurSelectione = (info) => {
    this.setState({
      ...this.state,
      parametresSelectione: info,
      indexConteneurInfo: indexConteneurInfo.profil,
      montrerInfo: true,
    });
  };
  commutateurInfoCreer = () =>
    this.setState((prevState) => {
      if (prevState.montrerInfo) {
        //ancien état : conteneur info ouvert ; prochain état fermé -> on désactive full info
        return {
          ...this.state,
          montrerInfo: !prevState.montrerInfo,
          montrerInfoFull: false,
        };
      } else {
        return { ...this.state, montrerInfo: !prevState.montrerInfo };
      }
    });
  //fonction switch conteneur info full screen
  commutateurInfoFull = () =>
    this.setState((prevState) => {
      return { ...this.state, montrerInfoFull: !prevState.montrerInfoFull };
    });
  commutateurAnnuler = (event) => {
    event.preventDefault();
    this.setState({
      ...this.state,
      montrerInfo: false,
      indexConteneurInfo: null,
    });
  };
  creerCapteur = () => {
    this.setState({
      ...this.state,
      montrerInfo: true,
      indexConteneurInfo: indexConteneurInfo.ajouterForm,
    });
  };

  render() {
    let styleConteneurCentralGauche = [S.ConteneurCentralGauche];
    let styleConteneurCentralDroite = [S.ConteneurCentralDroite];
    let commutateurClasses = [S.CommutateurInfo];

    if (this.state.montrerInfo && this.state.montrerInfoFull) {
      //conteneur info ouvert & plein écran
      //on met la largeur à 100% du conteneur info
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFull);
      //on réduit à zéro la largeur du conteneur central
      styleConteneurCentralGauche.push(S.ConteneurCentraGaucheVoid);
    } else if (this.state.montrerInfo && !this.state.montrerInfoFull) {
      //conteneur info ouvert & !plein écran
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteOuvert);
    } else {
      //conteneur info fermé
      styleConteneurCentralDroite.push(S.ConteneurCentralDroiteFermer);
      styleConteneurCentralGauche.push(S.ConteneurCentraGaucheFermer);
    }
    return (
      <>
        <div className={[S.ConteneurUtilisateur].join(' ')}>
          <div className={styleConteneurCentralGauche.join(' ')}>
            <TableauCapteur
              selectioneLigne={this.capteurSelectione}
              creer={this.creerCapteur}
            >
              <div className={S.titleModule}> {checkMot('capteurs')} </div>
              <AccesRestreins numPrivilege={privilege.modifier_des_capteurs}>
                <Popup
                  hoverText={checkMot('sensor_type')}
                  typeBoutonAffichage={type_btn.outil}
                  ajoutOverlay={S.Overlay}
                  icone={<VscListUnordered size={15} />}
                >
                  <SensorType />
                </Popup>
                <Bouton
                  hoverText={checkMot('upload')}
                  type={type_btn.outil}
                  rond={true}
                  cliquer={() => {
                    this.setState({
                      ...this.state,
                      montrerInfo: true,
                      indexConteneurInfo: indexConteneurInfo.importation,
                    });
                  }}
                >
                  <VscCloudUpload size={15} />
                </Bouton>

                {/*<Bouton ajoutClass={S.Mbts} cliquer={this.handleOpenModal}>*/}

                {/*</Bouton>*/}
              </AccesRestreins>
            </TableauCapteur>
          </div>
          <div
            className={
              styleConteneurCentralDroite.join(' ') + ' ' + S.separation
            }
          >
            <Bouton
              ajoutClass={[commutateurClasses, S.expandTO].join(' ')}
              rond={true}
              type={type_btn.danger}
              cliquer={this.commutateurInfoCreer}
            >
              {/* <IoIosClose size={30}/> */}
              <BiArrowFromLeft size={15} />
            </Bouton>
            <Bouton
              ajoutClass={S.expand}
              cliquer={this.commutateurInfoFull}
              rond={true}
              type={type_btn.danger}
            >
              <BiArrowFromRight size={15} />
            </Bouton>

            <ConteneurInformation
              selectionInfo={this.state.indexConteneurInfo}
              ajouterForm={
                <AjouterCapteur
                  ajouterAlerte={this.props.ajouterAlerte}
                  annuler={this.commutateurAnnuler}
                  ajouter={this.props.reqAjouter}
                />
              }
              profil={
                <ProfilCapteur
                  infoCapteur={this.state.parametresSelectione}
                  reqModifier={this.props.reqModifier}
                  reqSupprimer={this.props.reqSupprimer}
                  ajouter={this.props.reqAjouter}
                />
              }
              importation={
                <ImporterCapteurCSV
                  reqAjouter={this.props.reqAjouter}
                  reqModifier={this.props.reqModifier}
                  reqImportationCapteursEnLot={
                    this.props.reqImportationCapteursEnLot
                  }
                />
              }
            />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ajouterAlerte: (typeAlerte, titre, corps) =>
      dispatch(actions.ajouterAlerte(typeAlerte, titre, corps)),
    reqAjouter: (formulaireCreerCapteur) =>
      dispatch(actions.reqAjouterCapteur(formulaireCreerCapteur)),
    reqModifier: (formulaireModifierCapteur) =>
      dispatch(actions.reqModifierCapteur(formulaireModifierCapteur)),
    reqSupprimer: (idCapteur) =>
      dispatch(actions.reqSupprimerCapteur(idCapteur)),
    reqImportationCapteursEnLot: (idCapteur) =>
      dispatch(actions.reqImportationCapteursEnLot(idCapteur)),
  };
};

export default connect(null, mapDispatchToProps)(Capteur);

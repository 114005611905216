import { store } from '../../index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import {
  getViewByProjectIds,
  getView,
  updateView,
  deleteView,
  createView,
  addViewLayer,
  getViewLayers,
  getFavoriteDashboards,
  updateLayersOrder,
  deleteLayerFromView,
  getAllObjectsFromView,
  getAllObjectsToDisplayFromView,
  getAdditonalDataOfObject,
  getUserDashboards,
  updateUserFavoriteDashboards,
} from '../api';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  const state = store.getState();
  dispatch(actions.debutAttente(requestId));

  try {
    let response;
    switch (requestId) {
      case requestType.recuperation_de_la_liste_des_vues:
        response = await getViewByProjectIds(payload);
        if (response.data.state) {
          dispatch(enregistrerListeVues(response.data));
          return;
        }
        break;
      case requestType.recuperer_une_vue:
        response = await getView(payload);
        if (response.data.state) {
          dispatch(enregistrerVue(response.data));
          return;
        }
        break;
      case requestType.creer_une_vue:
        response = await createView(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeVues(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.modifier_une_vue:
        response = await updateView(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeVues(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }

        break;
      case requestType.supprimer_une_vue:
        response = await deleteView(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeVues(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_couches_de_vue:
        response = await getViewLayers(payload);
        if (response.data.state) {
          if (
            Array.isArray(response.data.layers_list) &&
            response.data.layers_list !== null
          ) {
            dispatch(
              enregistrerListeCoucheVues(response.data, payload.view_id)
            );
          }
          return;
        }
        break;
      case requestType.ajouter_une_couche_de_vue:
        response = await addViewLayer(payload);
        if (response.data.state) {
          // success behaviour
          return;
        }
        break;
      case requestType.modifier_l_ordre_des_couches:
        response = await updateLayersOrder(payload);
        if (response.data.state) {
          // success behaviours
          return;
        }
        break;
      case requestType.supprimer_une_couche_de_vue:
        response = await deleteLayerFromView(payload);
        if (response.data.state) {
          // success behaviours
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_objets_associe_a_la_vue:
        response = await getAllObjectsFromView(payload);
        if (response.data.state) {
          dispatch(
            enregistrerListeObjetsAssocieVue(response.data, payload.view_id)
          );
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue:
        response = await getAllObjectsToDisplayFromView(payload);
        if (response.data.state) {
          dispatch(viderListeObjetsAAfficherAssocieVue(payload.view_id));
          dispatch(
            enregistrerListeObjetsAAfficherAssocieVue(
              response.data,
              payload.view_id
            )
          );
          return;
        }
        break;
      case requestType.recuperer_les_informations_complementaires_d_un_objet:
        response = await getAdditonalDataOfObject(payload);
        if (response.data.state) {
          dispatch(
            enregistrerObjetAAfficherAssocieVue(
              response.data,
              payload.viewobj_id,
              payload.view_id
            )
          );
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_tableaux_de_bord_favoris:
        response = await getFavoriteDashboards();
        if (response.data.state) {
          dispatch(enregistrerListeVuesFavoris(response.data));
          return;
        }
        break;
      case requestType.recuperer_la_liste_des_tableaux_de_bord_utilisateur:
        response = await getUserDashboards(payload);
        if (response.data.state) {
          dispatch(enregistrerTableauxDeBord(response.data, payload.user_id));
          return;
        }
        break;
      case requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur:
        response = await updateUserFavoriteDashboards(payload);
        if (response.data.state) {
          //success behaviour
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
    // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqRecupererListeVues = (list_project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_de_la_liste_des_vues, {
      list_project_id,
    });
  };
};
export const reqRecupererVue = (view_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_une_vue, { view_id });
  };
};
export const reqCreerVue = (formulaireVue) => {
  return (dispatch) => {
    console.log(formulaireVue);
    requetePost(dispatch, requestType.creer_une_vue, formulaireVue);
  };
};
export const reqAjouterCoucheVue = (view_id, layer) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.ajouter_une_couche_de_vue, {
      view_id,
      layer,
    });
  };
};
export const reqRecupererListeCouchesVue = (view_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_couches_de_vue, {
      view_id,
    });
  };
};
export const reqRecupererListeTableauxDeBordFavoris = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_tableaux_de_bord_favoris
    );
  };
};
export const reqModifierOrdreCouches = (
  view_id,
  layers_position_order_list
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modifier_l_ordre_des_couches, {
      view_id,
      layers_position_order_list,
    });
  };
};
export const reqSupprimerCoucheVue = (layer_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_une_couche_de_vue, {
      layer_id,
    });
  };
};
export const reqModifierVue = (view_id, formulaireVue) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modifier_une_vue, {
      view_id,
      formulaireVue,
    });
  };
};
export const reqSupprimerVue = (view_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_une_vue, { view_id });
  };
};
export const reqRecupererListeObjetsAssocieVue = (view_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_objets_associe_a_la_vue,
      { view_id }
    );
  };
};
export const reqAjouterObjetVue = (view_id, viewobj) => {
  /**
   * requête pour ajouter un objet à une vue
   */
  return (dispatch) => {
    requetePost(dispatch, requestType.ajouter_un_objet_associe_a_la_vue, {
      view_id,
      viewobj,
    });
  };
};
export const reqModifierObjetVue = (view_id, viewobj_id, viewobj) => {
  /**
   * requête pour ajouter un objet à une vue
   */
  return (dispatch) => {
    requetePost(dispatch, requestType.modifier_un_objet_associe_a_la_vue, {
      viewobj_id,
      view_id,
      viewobj,
    });
  };
};
export const reqSupprimerObjetVue = (viewobj_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_objet_associe_a_la_vue, {
      viewobj_id,
    });
  };
};
export const reqRecupererListeObjetsAAfficherAssocieVue = (view_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_objets_a_afficher_associes_a_une_vue,
      { view_id }
    );
  };
};
export const reqRecupererObjetAAfficherAssocieVue = (view_id, viewobj_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_les_informations_complementaires_d_un_objet,
      { view_id, viewobj_id }
    );
  };
};
export const reqRecupererListeTableauxDeBord = (user_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_tableaux_de_bord_utilisateur,
      { user_id }
    );
  };
};
export const reqModifierListeTableauxDeBordFavorisUtilisateur = (
  user_id,
  favorite_views_list
) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modifier_la_liste_des_tableaux_de_bord_favoris_utilisateur,
      { user_id, favorite_views_list }
    );
  };
};

export const supprimerVuesLayersObjets = () => {
  return { type: actionTypes.SUPPRIMER_VUES_LAYERS_OBJETS };
};
const enregistrerListeVues = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_VUES,
    views_list: data.views_list,
  };
};
const enregistrerListeVuesFavoris = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_VUES_FAVORIS,
    favorite_views_list: data.favorite_views_list,
  };
};
const enregistrerVue = (data) => {
  return { type: actionTypes.ENREGISTRER_VUE, view: data.view };
};
const enregistrerListeCoucheVues = (data, view_id) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_COUCHES_VUE,
    layers_list: data.layers_list,
    view_id: view_id,
  };
};
const enregistrerListeObjetsAssocieVue = (data, view_id) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_OBJETS_ASSOCIE_VUE,
    objects_list_view: data.objects_list_view,
    view_id: view_id,
  };
};
export const viderListeObjetsAAfficherAssocieVue = (view_id) => {
  return {
    type: actionTypes.VIDER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE,
    view_id: view_id,
  };
};
const enregistrerListeObjetsAAfficherAssocieVue = (data, view_id) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE,
    data_objects_list_view: data.data_objects_list_view,
    view_id: view_id,
  };
};
const enregistrerObjetAAfficherAssocieVue = (data, viewobj_id, view_id) => {
  return {
    type: actionTypes.ENREGISTRER_OBJET_A_AFFICHER_ASSOCIE_VUE,
    viewobj_content: data.viewobj_content,
    viewobj_id: viewobj_id,
    view_id: view_id,
  };
};
const enregistrerTableauxDeBord = (data, user_id) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_TABLEAUX_DE_BORD,
    views_list: data.views_list,
    favorite_views_list: data.favorite_views_list,
    user_id: user_id,
  };
};

import React from 'react';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';

const checkboxDefault = (props) => {
  const onChecked = () => {
    props.selectRadio(props.children);
  };
  return (
    <>
      <div
        className={[
          s.custom_control,
          s.custom_checkbox,
          s.my_1,
          s.mr_sm_2,
        ].join(' ')}
        key={props.children}
      >
        <input
          checked={props.checked}
          type="checkbox"
          className={s.custom_control_input}
          name={props.name}
          onChange={onChecked}
          value={props.children}
          id={props.children + 'Checkbox'}
        />
        <label
          className={s.custom_control_label}
          htmlFor={props.children + 'Checkbox'}
        >
          {props.children}
        </label>
      </div>
    </>
  );
};

export default checkboxDefault;

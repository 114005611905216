import { store } from '../../index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import {
  getAllGraphs,
  deleteGraph,
  getGraph,
  getGraphData,
  getAutoGraphDataXT,
  updateGraph,
  createGraph,
  getDateFormat,
} from '../api';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  const state = store.getState();
  dispatch(actions.debutAttente(requestId));
  try {
    let response;
    switch (requestId) {
      case requestType.recuperation_de_la_liste_des_graphes:
        response = await getAllGraphs(payload);
        if (response.data.state) {
          dispatch(enregistrerListeGraphes(response.data));
          return;
        }
        break;
      case requestType.recuperer_un_graphe:
        response = await getGraph(payload);
        if (response.data.state) {
          dispatch(enregistrerGraphe(response.data));
          return;
        }
        break;
      case requestType.recuperation_des_donnees_d_un_graphe:
        response = await getGraphData(payload);
        if (response.data.state) {
          dispatch(enregistrerDonneesGraphe(response.data));
          return;
        }
        break;
      case requestType.recuperation_des_donnees_d_un_graphe_automatique_xt:
        response = await getAutoGraphDataXT(payload);
        if (response.data.state) {
          dispatch(
            enregistrerDonneesGrapheAutomatiqueXT(
              response.data,
              payload.sensor_id,
              payload.position_in_sensor
            )
          );
          return;
        }
        break;
      case requestType.supprimer_un_graphe:
        response = await deleteGraph(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeGraphes(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.creer_un_graphe:
        response = await createGraph(payload);
        if (response.data.state) {
          dispatch(
            actions.reqRecupererListeGraphes(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }
        break;
      case requestType.modifier_un_graphe:
        response = await updateGraph(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererGraphe(payload.config.graph_id));
          dispatch(
            actions.reqRecupererListeGraphes(
              state.authentifie.filtre_projects_list_id
            )
          );
          return;
        }

        break;
      case requestType.recuperation_de_la_liste_des_formats_de_dates:
        response = await getDateFormat();
        if (response.data.state) {
          dispatch(
            enregistrerListeFormatsDeDates(response.data.dates_formats_list)
          );
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
    // dispatch(actions.ajouterAlerte("danger", "ApiErreurRequeteTitre", ["ApiErreurRequeteCorps", {request_type: requeteAPI.request_type, messageErreur: err.toString()}]));
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqRecupererListeGraphes = (projects_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_de_la_liste_des_graphes, {
      projects_id,
    });
  };
};

export const reqSupprimerGraphe = (graph_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_graphe, { graph_id });
  };
};

export const reqRecupererGraphe = (graph_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_un_graphe, {
      graph_id,
    });
  };
};
export const reqRecupererDonneesGraphe = (
  graph_id,
  time_period,
  measures_num,
  select_time,
  time_interval
) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_des_donnees_d_un_graphe, {
      graph_id: graph_id,
      time_period,
      measures_num,
      select_time,
      time_interval,
    });
  };
};
export const reqRecupererDonneesGrapheAutomatiqueXT = (
  sensor_id,
  position_in_sensor,
  time_period,
  measures_num,
  select_time,
  time_interval
) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_des_donnees_d_un_graphe_automatique_xt,
      {
        sensor_id,
        position_in_sensor,
        time_period,
        measures_num,
        select_time,
        time_interval,
      }
    );
  };
};
export const reqCreerGraphe = (formulaireGrapheT_X) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.creer_un_graphe, {
      config: {
        ...formulaireGrapheT_X,
      },
    });
  };
};
export const reqModifierGraphe = (formulaireGrapheT_X) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modifier_un_graphe, {
      config: {
        ...formulaireGrapheT_X,
      },
    });
  };
};
export const reqRecupererListeFormatsDeDates = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_de_la_liste_des_formats_de_dates
    );
  };
};
const enregistrerListeGraphes = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_GRAPHES,
    graphs_list: data.graphs_list,
  };
};
const enregistrerGraphe = (data) => {
  return {
    type: actionTypes.ENREGISTRER_GRAPHE,
    configuration_graphe: data.config,
  };
};
const enregistrerDonneesGraphe = (data) => {
  return { type: actionTypes.ENREGISTRER_DONNEES_GRAPHE, donnees_graphe: data };
};
const enregistrerDonneesGrapheAutomatiqueXT = (
  data,
  sensor_id,
  position_in_sensor
) => {
  return {
    type: actionTypes.ENREGISTRER_DONNEES_GRAPHE_AUTOMATIQUE_XT,
    donnees_graphe: data,
    sensor_id: sensor_id,
    position_in_sensor: position_in_sensor,
  };
};
const enregistrerListeFormatsDeDates = (dates_formats_list) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_FORMATS_DE_DATES,
    dates_formats_list: dates_formats_list,
  };
};

import React from 'react';
import logo from '../../ressources/Logo/argos_png_logo_grisN.png';
import classes from './Logo.module.scss';
const Logo = () => {
  return (
    <div className={classes.LogoClasse}>
      <img alt="logo" src={logo} className={[classes.imageLogo].join(' ')} />
    </div>
  );
};

export default Logo;

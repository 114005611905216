import React, { Component } from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import AjouterCouche from './AjouterCouche/AjouterCouche';
import Bouton from '../../../UI/Bouton/Bouton';
import PopupSmall from '../../../UI/PopupSmall/PopupSmall.js';

import AttenteRequete from '../../../../hoc/AttenteRequete/AttenteRequete';

import { checkMot } from '../../../../ressources/functionJS/checkMot';
import { confirmeOnClick } from '../../../../ressources/functionJS/confirmeOnClick';
import * as requestType from '../../../../ressources/glossaires/glossaire_requetes';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import * as actions from '../../../../services/actions';

import { VscSave } from 'react-icons/vsc';
import { AiOutlineClose } from 'react-icons/ai';
import { MdRefresh } from 'react-icons/md';
import { RiFileAddFill } from 'react-icons/ri';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import * as z from './FormulaireCouche.module.scss';
import S from '../../../UI/tableau/Tableau.module.scss';

class FormulaireCouche extends Component {
  state = {
    columns: [
      {
        dataField: 'bonton_supprimer',
        text: checkMot('supprimer'),
        editable: false,
      },
      {
        dataField: 'position',
        text: [
          checkMot('position'),
          <AttenteRequete
            request_type={requestType.supprimer_une_couche_de_vue}
          >
            <Bouton
              ajoutClass={z.especila}
              hoverText={checkMot('enregistrer_le_changement_de_position')}
              type={type_btn.succeSimple}
              cliquer={() =>
                this.props.reqModifierOrdreCouches(
                  this.props.view_id,
                  this.state.position_list
                )
              }
              desactive={false}
            >
              <VscSave size={25} />
            </Bouton>
          </AttenteRequete>,
        ],
        sort: true,
      },
      {
        dataField: 'layer_id',
        text: checkMot('layer_id'),
        sort: true,
        filter: textFilter(),
        editable: false,
      },
      {
        dataField: 'layer_type',
        text: checkMot('layer_type'),
        sort: true,
        editable: false,
      },
      {
        dataField: 'layer_name',
        text: checkMot('layer_name'),
        sort: true,
        editable: false,
      },
      {
        dataField: 'projection_id',
        text: checkMot('projection_id'),
        sort: true,
        editable: false,
      },
      {
        dataField: 'layer_path',
        text: checkMot('layer_path'),
        sort: true,
        editable: false,
      },
      {
        dataField: 'bounds_image',
        text: checkMot('bounds_image'),
        sort: true,
        editable: false,
      },
    ],
    views_layers_list: [],
    position_list: [],
  };

  componentDidMount() {
    if (this.props.view_id !== undefined) {
      //envoie la requête de récupération de couche de la vue si l'id de la vue n'est pas indéfini
      this.props.reqRecupererListeCouchesVue(this.props.view_id);
      if (this.props.views_layers_list[this.props.view_id] !== undefined) {
        //créer la liste de layer ordnées pour la modification si la liste des couches a récupérer existe
        const listeCouches = this.props.views_layers_list[this.props.view_id];
        //initialisation en local de la liste de couches et la liste de la position des couches (pour la modification)
        let newListeCouches = new Array(listeCouches.length);
        let position_list = [];
        for (let indCouche = 0; indCouche < listeCouches.length; indCouche++) {
          //duplique les données
          newListeCouches[indCouche] = {
            ...listeCouches[indCouche],
            bonton_supprimer: (
              <AttenteRequete
                request_type={requestType.supprimer_une_couche_de_vue}
              >
                <Bouton
                  cliquer={() =>
                    confirmeOnClick(
                      checkMot('voulez_vous_vraiment_supprimer_cette_couche'),
                      () =>
                        this.props.reqSupprimerCoucheVue(
                          listeCouches[indCouche].layer_id
                        )
                    )
                  }
                >
                  {checkMot('supprimer')}
                </Bouton>
              </AttenteRequete>
            ),
          };
          //liste à envoyer pour la modification des position des couches
          position_list.push({
            layer_id: parseInt(
              this.props.views_layers_list[this.props.view_id][indCouche]
                .layer_id
            ),
            layer_position: parseInt(
              this.props.views_layers_list[this.props.view_id][indCouche]
                .position
            ),
          });
        }
        this.setState({
          ...this.state,
          views_layers_list: newListeCouches,
          position_list: position_list,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.view_id !== undefined &&
      prevProps.view_id !== this.props.view_id
    ) {
      //envoie la requête de récupération de couche de la vue si l'id de la vue change ou que la liste de couche de vue change
      this.props.reqRecupererListeCouchesVue(this.props.view_id);
    }
    if (
      (this.props.view_id !== undefined &&
        prevProps.view_id !== this.props.view_id) ||
      JSON.stringify(this.props.views_layers_list) !==
        JSON.stringify(prevProps.views_layers_list)
    ) {
      //envoie la requête de récupération de couche de la vue si l'id de la vue change ou que la liste de couche de vue change
      if (this.props.views_layers_list[this.props.view_id] !== undefined) {
        //créer la liste de layer ordnées pour la modification si la liste des couches a récupérer existe
        const listeCouches = this.props.views_layers_list[this.props.view_id];
        //initialisation en local de la liste de couches et la liste de la position des couches (pour la modification)
        let newListeCouches = new Array(listeCouches.length);
        let position_list = [];
        for (let indCouche = 0; indCouche < listeCouches.length; indCouche++) {
          //duplique les données
          newListeCouches[indCouche] = {
            ...listeCouches[indCouche],
            bonton_supprimer: (
              <AttenteRequete
                request_type={requestType.recuperer_la_liste_des_couches_de_vue}
              >
                <Bouton
                  type={type_btn.danger}
                  cliquer={() =>
                    this.props.reqSupprimerCoucheVue(
                      listeCouches[indCouche].layer_id
                    )
                  }
                >
                  <AiOutlineClose />
                </Bouton>
              </AttenteRequete>
            ),
          };
          //liste à envoyer pour la modification des position des couches
          position_list.push({
            layer_id: parseInt(
              this.props.views_layers_list[this.props.view_id][indCouche]
                .layer_id
            ),
            layer_position: parseInt(
              this.props.views_layers_list[this.props.view_id][indCouche]
                .position
            ),
          });
        }
        this.setState({
          ...this.state,
          views_layers_list: newListeCouches,
          position_list: position_list,
        });
      }
    }
  }

  render() {
    const cellEdit = cellEditFactory({
      mode: 'click',
      blurToSave: true,
      // afterSaveCell: (oldValue, newValue, row, column) => {
      //     if(oldValue!==newValue){
      // TODO: changer la couleur de la position qui a changé
      //     }
      // }
      afterSaveCell: (oldValue, newValue, row) => {
        if (oldValue !== newValue) {
          let position_list = [...this.state.position_list];
          for (
            let indCouche = 0;
            indCouche < position_list.length;
            indCouche++
          ) {
            if (position_list[indCouche].layer_id === row.layer_id) {
              position_list[indCouche] = {
                layer_id: parseInt(position_list[indCouche].layer_id),
                layer_position: parseInt(newValue),
              };
            }
          }
          this.setState({ ...this.state, position_list: position_list });
          return newValue;
        }
      },
    });

    let afficherListeCouche;
    if (this.props.views_layers_list[this.props.view_id] !== undefined) {
      afficherListeCouche = (
        <BootstrapTable
          striped
          hover
          keyField={'layer_id'}
          data={this.state.views_layers_list}
          columns={this.state.columns}
          headerClasses={z.center}
          // selectRow={ props.selectionnerLignes }
          classes={S.tableuStil}
          rowStyle={{ padding: '10px', textAlign: 'center' }}
          filter={filterFactory()}
          pagination={paginationFactory()}
          bordered={true}
          cellEdit={cellEdit}
          // cellEdit={ cellEditFactory({ mode: 'dbclick' }) }
        />
      );
    } else {
      afficherListeCouche = (
        <Bouton
          cliquer={() =>
            this.props.reqRecupererListeCouchesVue(this.props.view_id)
          }
        >
          {checkMot('refresh')}
        </Bouton>
      );
    }

    return (
      <>
        <div className={s.row}>
          <div className={[s.col_12, z.marge].join(' ')}>
            <PopupSmall
              typeBoutonAffichage={type_btn.outil}
              icone={<RiFileAddFill />}
              hoverText={checkMot('ajouter_une_couche')}
            >
              <AjouterCouche view_id={this.props.view_id} />
            </PopupSmall>
            <AttenteRequete
              request_type={requestType.recuperer_la_liste_des_couches_de_vue}
            >
              <Bouton
                hoverText={checkMot('refresh')}
                type={type_btn.outil}
                cliquer={() =>
                  this.props.reqRecupererListeCouchesVue(this.props.view_id)
                }
              >
                <MdRefresh />
              </Bouton>
            </AttenteRequete>
          </div>
        </div>
        {/*Affiche la liste des couches sous forme de liste pouvant être supprimé individuellement*/}
        {afficherListeCouche}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //import liste couches d'une vue
    views_layers_list: state.vue.views_layers_list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    reqRecupererListeCouchesVue: (idVue) =>
      dispatch(actions.reqRecupererListeCouchesVue(idVue)),
    reqSupprimerCoucheVue: (idCouche) =>
      dispatch(actions.reqSupprimerCoucheVue(idCouche)),
    reqModifierOrdreCouches: (view_id, layers_position_order_list) =>
      dispatch(
        actions.reqModifierOrdreCouches(view_id, layers_position_order_list)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormulaireCouche);

import React, { Component } from 'react';
import * as S from './ObjetVue.module.scss';
import Bouton from '../../../UI/Bouton/Bouton';
import { stringOrEmptyString } from '../../../../ressources/functionJS/stringOrEmptyString';
import * as glossaire_type_objets_vue from '../../../../ressources/glossaires/glossaire_type_objets_vue';
import * as glossaire_type_export from '../../../../ressources/glossaires/glossaire_type_export';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import { ratioStyleCSS2PuissanceNiveauZoom } from '../../../../ressources/functionJS/ratioStyleCSS2PuissanceNiveauZoom';
import { setUrlPathFromDomain } from '../../../../services/utils/utils';

class ObjetVue extends Component {
  state = {
    viewobj_json_content: null,
  };

  render() {
    // let req10614 = () => {};
    // if (this.props.objetAAfficher.data === undefined || this.props.objetAAfficher.metadata === undefined) {
    //     //envoie de la requête de récupération de données complémentaires pour de l'objet
    //     req10614 = () => {
    //         this.props.reqRecupererObjetAAfficherAssocieVue(this.props.view_id, this.props.objetAAfficher.viewobj_id)
    //     }
    // }
    let representation_vue = (
      <Bouton
        cliquer={() =>
          window.open(
            `/export_impression_ecran-${glossaire_type_export.visualisation}-${this.props.objetAAfficher.view_in_view_id}`,
            '_blank',
            `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
          )
        }
      >
        {this.props.objetAAfficher.name}
      </Bouton>
    );
    //représentation de l'objet sur la vue
    if (this.props.objetAAfficher !== undefined) {
      if (
        this.state.viewobj_json_content === null &&
        this.props.objetAAfficher.viewobj_path !== undefined &&
        stringOrEmptyString(this.props.objetAAfficher.viewobj_path) !== '' &&
        !this.state.attenteRecupFichierJson &&
        this.props.viewobj_json_content === undefined
      ) {
        try {
          let cheminURL = setUrlPathFromDomain(
            this.props.objetAAfficher.viewobj_path,
            'https://' +
              document.domain +
              this.props.objetAAfficher.viewobj_path.slice(
                1,
                this.props.objetAAfficher.viewobj_path.length
              )
          );
          fetch(cheminURL + '?varget' + Math.random())
            //todo: modifier le chemin absolu en chemin relatif
            .then((res) => res.json())
            .then((data) => {
              this.setState({
                attenteRecupFichierJson: false,
                viewobj_json_content: data,
              });
            })
            .catch((e) => console.error('error: ', e));
        } catch (e) {
          this.setState({
            attenteRecupFichierJson: false,
            viewobj_json_content: null,
          });
          console.error('error', e);
        }
      } else if (
        this.state.viewobj_json_content !== null &&
        this.state.viewobj_json_content.type_de_representation !== undefined
      ) {
        switch (this.state.viewobj_json_content.type_de_representation) {
          case glossaire_type_objets_vue.affichgedirectvue:
            representation_vue = (
              <div>
                /!\ Attention chantier en cours problème d'appel en boucle (vue1
                -{'>'} vue1 -{'>'} vue1 ...)
              </div>
            );
            // representation_vue = <div><AffichageVue view_id={this.props.objetAAfficher.view_in_view_id} uniciteVue={checkMot("profil_vue")+Math.random()}/></div>
            break;
          case glossaire_type_objets_vue.bouton_de_redirection:
            representation_vue = (
              <button
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.styleMainDiv
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.styleMainDiv,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                onClick={() =>
                  window.open(
                    `/export_impression_ecran-${glossaire_type_export.visualisation}-${this.props.objetAAfficher.view_in_view_id}`,
                    '_blank',
                    `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                  )
                }
                className={[s.btn, s.btn_sm].join(' ')}
                disabled={this.state.viewobj_json_content.disabled === true}
              >
                {this.state.viewobj_json_content.nom_du_bouton !== undefined
                  ? this.state.viewobj_json_content.nom_du_bouton
                  : this.props.objetAAfficher.name}
              </button>
            );
            break;
          case glossaire_type_objets_vue.bouton_de_redirection_label:
            if (
              this.props.objetAAfficher.view_in_view_id === this.props.view_id
            ) {
              representation_vue = (
                <button
                  className={[s.btn, s.btn_sm, S.bouton_full, S.active].join(
                    ' '
                  )}
                  style={{
                    ...(this.state.viewobj_json_content.tailleFixe === true
                      ? this.state.viewobj_json_content.styleMainBouton
                      : ratioStyleCSS2PuissanceNiveauZoom(
                          this.state.viewobj_json_content.styleMainBouton,
                          this.props.mapZoom
                        )),
                  }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                  disabled={true}
                >
                  <span
                    className={S.rond}
                    style={{
                      ...this.state.viewobj_json_content.styleRondSpan,
                    }}
                  />
                  <span
                    className={[S.text_bouton, S.text_active].join(' ')}
                    style={{
                      ...this.state.viewobj_json_content.styleLabelSpan,
                    }}
                  >
                    {this.state.viewobj_json_content.nom_du_bouton !== undefined
                      ? this.state.viewobj_json_content.nom_du_bouton
                      : this.props.objetAAfficher.name}
                  </span>
                </button>
              );
            } else {
              representation_vue = (
                <Bouton
                  cliquer={() =>
                    window.open(
                      `/export_impression_ecran-${glossaire_type_export.visualisation}-${this.props.objetAAfficher.view_in_view_id}`,
                      '_blank',
                      `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                    )
                  }
                  ajoutClass={[S.bouton_full].join(' ')}
                  style={{
                    ...this.state.viewobj_json_content.styleMainBouton,
                  }}
                >
                  <span
                    className={S.rond}
                    style={{
                      ...this.state.viewobj_json_content.styleRondSpan,
                    }}
                  />
                  <span
                    className={S.text_bouton}
                    style={{
                      ...this.state.viewobj_json_content.styleLabelSpan,
                    }}
                  >
                    {this.state.viewobj_json_content.nom_du_bouton !== undefined
                      ? this.state.viewobj_json_content.nom_du_bouton
                      : this.props.objetAAfficher.name}
                  </span>
                </Bouton>
              );
            }
            break;
          case glossaire_type_objets_vue.bouton_de_redirection_image:
            // {
            //     "type_de_representation": 20003,
            //     "src_img": "https://certbot.eff.org/assets/certbot-logo-1A-6d3526936bd519275528105555f03904956c040da2be6ee981ef4777389a4cd2.svg",
            //     "view_in_view_id": 1,
            //     "tailleFixe": 1,
            //     "disabled": false,
            //     "styleMainDiv": {
            //         "opacity": 1,
            //         "position": "absolute",
            //         "width": "100px",
            //         "height": "100px",
            //         "border": "solid 2px black"
            //     }
            // }
            representation_vue = (
              <img
                alt={this.state.viewobj_json_content.img_alt}
                src={this.state.viewobj_json_content.src_img}
                style={{
                  ...(this.state.viewobj_json_content.tailleFixe === true
                    ? this.state.viewobj_json_content.styleMainDiv
                    : ratioStyleCSS2PuissanceNiveauZoom(
                        this.state.viewobj_json_content.styleMainDiv,
                        this.props.mapZoom
                      )),
                }} //tailleFixe dans le json pour fixe la taille de l'objet peu importe le niveau de zoom
                onClick={() =>
                  window.open(
                    `/export_impression_ecran-${glossaire_type_export.visualisation}-${this.props.objetAAfficher.view_in_view_id}`,
                    '_blank',
                    `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                  )
                }
                className={[s.btn, s.btn_sm].join(' ')}
              />
            );
            break;
          default:
            representation_vue = <div>Défaut</div>;
            break;
        }
      }
    }
    return representation_vue;
  }
}

export default ObjetVue;

import * as actionTypes from '../actions/actionTypes';

// const largeurPopup= 75;
// const hauteurPopup= 80;
// const positionLeft= 10+(100-largeurPopup)/2;
// const positionTop= (100-hauteurPopup)/2;
const largeurPopup = 80;
const hauteurPopup = 100;
const positionLeft = 0;
const positionTop = 0;
export const initialState = {
  width: largeurPopup + '%',
  left: positionLeft + '%',
  height: hauteurPopup + '%',
  top: positionTop + '%',
};

const fermetureMenuGauche = (state) => {
  return { ...state, width: '100%', left: 0 };
};
const ouvertureMenuGauche = (state) => {
  return { ...state, width: largeurPopup + '%', left: positionLeft + '%' };
};
const fermetureEnTete = (state) => {
  return { ...state, height: '100%', top: 0 };
};
const ouvertureEnTete = (state) => {
  return { ...state, height: hauteurPopup + '%', top: positionTop + '%' };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FERMETURE_MENU_GAUCHE:
      return fermetureMenuGauche(state);
    case actionTypes.OUVERTURE_MENU_GAUCHE:
      return ouvertureMenuGauche(state);
    case actionTypes.FERMETURE_EN_TETE:
      return fermetureEnTete(state);
    case actionTypes.OUVERTURE_EN_TETE:
      return ouvertureEnTete(state);
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import S from './GrapheTauxDePannesConteneur.module.scss';
import Style from '../../ArborescenceProjets.module.scss';
import * as type_btn from '../../../../../../ressources/glossaires/glossaire_type_de_bouton';
import * as s from '../../../../../../ressources/StyleGlobal/StyleGlobal';
import Bouton from '../../../../../UI/Bouton/Bouton';

import GrapheTauxDePannes from './GrapheTauxDePannes/GrapheTauxDePannes';

const affichagePetit = '5.9% 0.1% 0% 15.2%';
const affichageGrand = '0% 0% 0% 0%';

class GrapheTauxDePannesConteneur extends Component {
  state = {
    showModal: false,
    overflowX: 'auto',
    overflowY: 'auto',

    inset: this.props.afficherExpand ? affichageGrand : affichagePetit,
    afficherExpand:
      this.props.afficherExpand !== undefined
        ? this.props.afficherExpand
        : false,
  };

  componentDidMount() {
    if (this.props.width === '100%') {
      this.setState({
        inset: '5.9% 0.1% 0% 0%',
      });
    } else {
      this.setState({
        inset: '5.9% 0.1% 0% 15.2%',
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.width !== prevProps.width) {
      if (this.props.width === '100%') {
        this.setState({
          inset: '5.9% 0.1% 0% 0%',
        });
      } else {
        this.setState({
          inset: '5.9% 0.1% 0% 15.2%',
        });
      }
    }
  }

  setShowModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
    if (this.props.eventAuClique instanceof Function) {
      this.props.eventAuClique();
    }
  };

  setExpand = () => {
    this.setState({
      inset: '0% 0% 0% 0%',
      afficherExpand: true,
      overflowX: 'hidden',
    });
    if (document.getElementById(this.props.popup_id) !== null) {
      document.getElementById(this.props.popup_id).requestFullscreen();
    }
  };
  setPetit = () => {
    this.setState({
      inset: affichagePetit,
      afficherExpand: false,
      overflowX: 'auto',
      overflowY: 'auto',
    });
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  };

  render() {
    let Textheader;
    let colorBg = [s.text_right, s.col_1];
    let classpopup = [s.row, s.text_right];
    if (!(this.props.hideHeader === true)) {
      classpopup.push([s.card_header, S.styleB].join(' '));

      Textheader = (
        <span className={[S.headerText, s.col_11].join(' ')}>
          {this.props.headerPopupText}
        </span>
      );
    } else {
      colorBg.push([S.headerTextD].join(' '));
      Textheader = (
        <span className={[S.headerTextD, s.col_11].join(' ')}>
          {this.props.headerPopupText}
        </span>
      );
    }

    let boutonExpand;
    if (this.state.afficherExpand) {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(' ')}
          type={type_btn.Min}
          rond={true}
          cliquer={() => {
            this.setPetit();
            if (document.fullscreenElement !== null) {
              document.exitFullscreen();
            }
          }}
        />
      );
    } else {
      boutonExpand = (
        <Bouton
          ajoutClass={[S.PositionBoutonFermeture, S.BoutonOutil].join(' ')}
          type={type_btn.Max}
          rond={true}
          cliquer={() => {
            this.setExpand();
            if (document.getElementById(this.props.popup_id) !== null) {
              document.getElementById(this.props.popup_id).requestFullscreen();
            }
          }}
        />
      );
    }
    return (
      <>
        {this.props.afficherLien === true ? (
          <a
            href={''}
            onClick={(event) => {
              event.preventDefault();
              this.setShowModal();
            }}
          >
            {this.props.icone}
            {this.props.texte_ouverture}
          </a>
        ) : (
          <Bouton ajoutClass={Style.bouton} cliquer={this.setShowModal}>
            {this.props.icone}
            {this.props.texte_ouverture}
          </Bouton>
        )}
        <Modal
          id={this.props.popup_id}
          isOpen={this.state.showModal}
          onRequestClose={this.setShowModal}
          style={{
            overlay: {
              position: 'absolute',
              zIndex: '1000',

              //    padding: "1px",
              // paddingTop: "50px",
              //    backgroundColor: "white",
              border: 'black 1px solid',
              //    borderRadius: "10px",
              backgroundColor: 'transparent',

              transition: 'width 2s, height 4s',
            },
            content: {
              overflowX: this.state.overflowX,
              overflowY: this.state.overflowY,

              inset: this.state.inset,
              padding: '0px',
              boxShadow: '-5px 0px 5px rgba(0, 0, 0, 0.226)',
            },
          }}
        >
          <div className={classpopup.join(' ')}>
            {Textheader}
            <span className={colorBg.join(' ')}>
              {boutonExpand}
              <Bouton
                ajoutClass={[S.PositionBoutonFermeture].join(' ')}
                type={type_btn.fermer}
                rond={true}
                cliquer={this.setShowModal}
              />
            </span>
          </div>
          {/* <a href="https://www.itmsol.fr" target="_blank" rel="noopener noreferrer">
                    <img className={S.glogo} src={glogo} alt="itmsol"/>
                    </a> */}
          <GrapheTauxDePannes
            start_interval={this.props.start_interval}
            end_interval={this.props.end_interval}
            criticity_id_list={this.props.criticity_id_list}
            types_id_list={this.props.types_id_list}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToprops = (state) => {
  return {
    left: state.popup.left,
    width: state.popup.width,
    top: state.popup.top,
    height: state.popup.height,
  };
};

export default connect(mapStateToprops)(GrapheTauxDePannesConteneur);

import React, { useEffect, useRef } from 'react';

const IframeObjetTypeCapteurXImageDynamique = ({ src, style }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    let login_name;
    let login_password;
    try {
      // Récupérer et parser les données
      const storedAuth = localStorage.getItem('auth');
      const auth = JSON.parse(storedAuth);

      if (auth && auth.login_name && auth.login_password) {
        login_name = auth.login_name;
        login_password = auth.login_password;
      } else {
        throw new Error('Authentification non trouvée ou incomplète');
      }
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des données d'authentification:",
        error
      );
    }
    const sendAuthIdToIframe = () => {
      const message = {
        type: 'auth',
        login_name: login_name,
        login_password: login_password,
      };
      iframeRef.current.contentWindow.postMessage(message, '*');
    };

    // L'événement onLoad du composant iframe lui-même pour une meilleure spécificité
    const iframeElement = iframeRef.current;
    if (iframeElement) {
      iframeElement.addEventListener('load', sendAuthIdToIframe);
      return () => {
        iframeElement.removeEventListener('load', sendAuthIdToIframe);
      };
    }
  }, [src]);

  return (
    <iframe
      ref={iframeRef}
      src={src}
      title="Child Frame"
      onLoad={() => console.error('Iframe loaded')}
      style={style}
    />
  );
};

export default IframeObjetTypeCapteurXImageDynamique;

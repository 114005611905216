/**
 * date: 19/07/2021
 * version: v1
 * description: glossaire des type de représentation visuelle des objets de la vue
 */

//type de représentation d'objet capteur
export const rectangle_de_composantes = 0;
export const rectangle_de_donnees = 1;
export const rectangle_de_couleur = 2;
export const GaugesCompass = 3;
export const LinearGauges = 4;
export const affichageGif = 5;
export const ProgresseBar = 6;
export const imageDynamique = 7;
export const iframeObjetCapteur = 8;
export const iframeObjetCapteurXImageDynamique = 9;
export const rectangle_de_date = 10;
export const iframeObjetTypeCapteurXImageDynamique = 11;
export const imageDynamiqueArray = 12;
export const imageDynamiqueArrayDansPopup = 13;

//type de représentation d'objet graphe
export const affichgedirectgraphe = 10000;
export const diagrammeCirculaire = 10001;
export const grapheAvancementDetaille = 10002;
export const graphesAvancementJourSemaineMois = 10003;
export const historique_positions_x_y = 10004;
export const bouton_de_redirection_graphe = 10005;

//type de représentation d'objet vue
export const affichgedirectvue = 20000;
export const bouton_de_redirection = 20001;
export const bouton_de_redirection_label = 20002;
export const bouton_de_redirection_image = 20003;

//type de représentation d'objet texte
export const bouton_lien_audio = 30000;
export const bouton_lien_video = 30001;
export const bouton_lien_image = 30002;
export const iframe = 30003;
export const texte_div = 30004;

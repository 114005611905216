import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './FormulaireSelectionComposantesGraphes.module.scss';
import Checkbox from '../ListePrivilegesDetaillees/Checkbox/Checkbox';
import { AiOutlineClear } from 'react-icons/ai';

class FormulaireSelectionComposantes extends Component {
  state = {
    positions_in_sensor_list: [],
    preconfigurerPositionsInSensorList: [],
    isSelected: false,
    inputValue: '',
  };

  componentDidMount() {
    if (this.props.preconfigurerPositionsInSensorList !== undefined) {
      this.setState({
        ...this.state,
        positions_in_sensor_list: this.props.preconfigurerPositionsInSensorList,
      });
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (this.props.preconfigurerPositionsInSensorList !== prevProps.preconfigurerPositionsInSensorList) {
  //         this.setState({...this.state, positions_in_sensor_list: this.props.preconfigurerPositionsInSensorList});
  //     }
  // }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.preconfigurerPositionsInSensorList !==
      prevProps.preconfigurerPositionsInSensorList
    ) {
      if (Array.isArray(this.props.preconfigurerPositionsInSensorList)) {
        let flattenedPositions = [].concat.apply(
          [],
          this.props.preconfigurerPositionsInSensorList
        );
        this.setState({
          ...this.state,
          positions_in_sensor_list: flattenedPositions,
        });
      } else {
        // Se preconfigurerPositionsInSensorList não for um array, você pode definir o estado como um array vazio ou lidar com isso de outra forma adequada para o seu caso
        this.setState({ ...this.state, positions_in_sensor_list: [] });
      }
    }
  }

  switchCheckbox = (position_in_sensor) => {
    // On affiche dans la console l'état actuel des positions dans la liste du capteur et la position sélectionnée

    // On crée une nouvelle copie de la liste des positions dans le capteur
    let newPositions_in_sensor_list = [...this.state.positions_in_sensor_list];

    // On s'assure que position_in_sensor est toujours un tableau
    position_in_sensor = [].concat(position_in_sensor);

    // On cherche l'index de la première occurrence de position_in_sensor dans la liste
    let ind_position = newPositions_in_sensor_list.indexOf(
      position_in_sensor[0]
    );
    if (ind_position > -1) {
      // Si l'élément est dans la liste, on le retire
      newPositions_in_sensor_list.splice(ind_position, 1);
    } else {
      // Si l'élément n'est pas dans la liste, on l'ajoute
      newPositions_in_sensor_list.push(...position_in_sensor);
    }

    // On met à jour la liste des positions dans le capteur en utilisant la fonction parente
    this.props.gererPositionsInSensorList(newPositions_in_sensor_list);
    if (
      this.state.positions_in_sensor_list.some(
        (pos) => pos === position_in_sensor[0]
      )
    ) {
      this.setState((prevState) => ({
        positions_in_sensor_list: prevState.positions_in_sensor_list.filter(
          (pos) => pos !== position_in_sensor[0]
        ),
        isSelected: false,
      }));
    } else {
      // De lo contrario, selecciona el checkbox y establece isSelected en true
      this.setState((prevState) => ({
        positions_in_sensor_list: [
          ...prevState.positions_in_sensor_list,
          ...position_in_sensor,
        ],
        isSelected: true,
      }));
    }
  };

  handleInputChange(event) {
    const value = event.target.value;
    const position = value.split(':')[0].trim();

    // Verifica si el valor coincide con alguna de las opciones del datalist
    const matchingOption = this.props.listeComposantes.find(
      (composante) =>
        composante.position_in_sensor + ': ' + composante.prefix === value
    );

    if (matchingOption) {
      this.switchCheckbox([position]);
    }
    this.setState({ inputValue: event.target.value });
  }

  clearInput() {
    this.setState({ inputValue: '' });
    this.props.gererPositionsInSensorList([]);
  }

  render() {
    // récupérer liste des capteurs avec this.props.listeCapteurs
    // créer un formulaire pour ajouter un capteur à l'objet vue sous forme de liste déroulante via objet datalist
    //     let select_boxs_list = [];
    //     let full_list = []

    //     for (let indComposante = 0; indComposante < this.props.listeComposantes.length; indComposante++) {
    //     const composante = this.props.listeComposantes[indComposante]
    //     if (full_list.indexOf(composante.position_in_sensor)<0){
    //         full_list.push(composante.position_in_sensor)
    //         select_boxs_list.push(
    //             <div className={S.inputContainer} key={composante.component_id}>
    //                 <input className={S.customInput}
    //                     // Nous utiliserons la méthode some pour vérifier si l'un des éléments du tableau est présent dans positions_in_sensor_list
    //                     checked={this.state.positions_in_sensor_list.some(pos => pos === composante.position_in_sensor)}
    //                     type="checkbox"
    //                     disabled={this.props.disable || (this.state.isSelected && !this.state.positions_in_sensor_list.some(pos => pos === composante.position_in_sensor))}
    //                     onClick={()=>this.switchCheckbox([composante.position_in_sensor])}
    //                     id={composante.component_id + "Checkbox"}
    //                 />
    //                 <label htmlFor={composante.component_id + "Checkbox"}>
    //                     {composante.position_in_sensor + ': ' + composante.prefix}
    //                 </label>
    //             </div>)
    //     }
    // }

    //     return (
    //         <div className={[s.col_12, S.style].join(" ")}>
    //             {/* <button onClick={()=>this.props.gererPositionsInSensorList(full_list)}>{checkMot("ajouter_tout")}</button>
    //             <button onClick={()=>this.props.gererPositionsInSensorList([])}>{checkMot("retirer_tout")}</button> */}
    //             {select_boxs_list}
    //         </div>
    //     );
    // }
    let optionsList = [];
    let full_list = [];

    for (
      let indComposante = 0;
      indComposante < this.props.listeComposantes.length;
      indComposante++
    ) {
      const composante = this.props.listeComposantes[indComposante];
      if (full_list.indexOf(composante.position_in_sensor) < 0) {
        full_list.push(composante.position_in_sensor);
        optionsList.push(
          <option
            key={composante.component_id}
            value={composante.position_in_sensor + ': ' + composante.prefix}
          />
        );
      }
    }

    // En tu render o return:
    return (
      <div className={[S.inputContainer].join(' ')}>
        <input
          className={[s.col_11, S.customInput].join(' ')}
          type="text"
          list="sensorList"
          value={this.state.inputValue}
          placeholder={checkMot('selectionner_composante')}
          onChange={this.handleInputChange.bind(this)}
          // Puedes agregar otros atributos o eventos si los necesitas
        />
        <datalist id="sensorList">{optionsList}</datalist>
        <button
          className={[s.col_1, S.btnS].join(' ')}
          onClick={this.clearInput.bind(this)}
        >
          <AiOutlineClear size={18} />
        </button>
      </div>
    );
  }
}

export default FormulaireSelectionComposantes;

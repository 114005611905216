import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  projects_list: [], //todo liste vide de base
  donnees_pannes: [], //todo liste vide de base
  sensors_list: [], //todo liste vide de base
  dico_des_pannes_par_jour: {},
};

const enregistrerListeProjetsMaintenance = (state, action) => {
  return { ...state, projects_list: action.projects_list };
};
const enregistrerListeTauxDePannesParJour = (state, action) => {
  return { ...state, donnees_pannes: action.donnees_pannes };
};
const enregistrerListeCapteursEnPanne = (state, action) => {
  return { ...state, sensors_list: action.sensors_list };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_LISTE_PROJETS_MAINTENANCE:
      return enregistrerListeProjetsMaintenance(state, action);
    case actionTypes.ENREGISTRER_LISTE_TAUX_DE_PANNES_PAR_JOUR:
      return enregistrerListeTauxDePannesParJour(state, action);
    case actionTypes.ENREGISTRER_LISTE_CAPTEURS_EN_PANNES:
      return enregistrerListeCapteursEnPanne(state, action);
    default:
      return state;
  }
};

export default reducer;

import { store } from '../../index';
import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import {
  createAlarmTemplate,
  createAlarmXY,
  createGroupMail,
  deleteAlarmTemplate,
  deleteAlarmXY,
  deleteGroupMail,
  getAllAlarmsWithIdProject,
  getAllAlarmsXYWithIdProject,
  getAllAlarmTemplatesWithIdProject,
  getConfirmationAlarm,
  getListMailGroupsWithIdProject,
  updateAlarmTemplate,
  updateAlarmXY,
  updateGroupMail,
} from '../api';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */

  const state = store.getState();
  dispatch(actions.debutAttente(requestId));
  let response;
  try {
    switch (requestId) {
      case requestType.recuperer_la_liste_des_alarmes:
        response = await getAllAlarmsWithIdProject(payload.list_project_id);
        if (response.data.state) {
          dispatch(enregistrerListeAlarmes(response.data));
          return;
        }
        break;
      case requestType.confirmer_une_alarme:
        await getConfirmationAlarm(payload);
        dispatch(
          actions.reqRecupererListeAlarmes([
            state.interfaceClient.project_id_url,
          ])
        );
        return;
      case requestType.recuperer_la_liste_des_groupes_d_email:
        response = await getListMailGroupsWithIdProject(payload);
        if (response.data.state) {
          dispatch(enregistrerListeEmailsGroupes(response.data));
        }
        break;
      case requestType.creer_un_groupe_d_email:
        await createGroupMail(payload);
        dispatch(
          actions.reqListeGroupesEmails(
            state.authentifie.filtre_projects_list_id
          )
        );
        break;
      case requestType.modifier_un_groupe_d_email:
        await updateGroupMail(payload);
        dispatch(
          actions.reqListeGroupesEmails(
            state.authentifie.filtre_projects_list_id
          )
        );
        break;
      case requestType.supprimer_un_groupe_d_email:
        await deleteGroupMail(payload);
        dispatch(
          actions.reqListeGroupesEmails(
            state.authentifie.filtre_projects_list_id
          )
        );
        break;
      case requestType.creer_un_gabarit:
        await createAlarmTemplate(payload);
        dispatch(
          actions.reqListeGabarit(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.modifier_un_gabarit:
        await updateAlarmTemplate(payload);
        dispatch(
          actions.reqListeGabarit(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.supprimer_un_gabarit:
        await deleteAlarmTemplate(payload);
        dispatch(
          actions.reqListeGabarit(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.recuperer_la_liste_des_gabarits:
        response = await getAllAlarmTemplatesWithIdProject(payload);
        if (response.data.state) {
          dispatch(enregistrerListeGabarits(response.data));
        }
        break;
      case requestType.configurer_des_alarmes_xy:
        await createAlarmXY(payload);
        dispatch(
          actions.reqListeAlarmesXY(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.modifier_des_alarmes_xy:
        await updateAlarmXY(payload);
        dispatch(
          actions.reqListeAlarmesXY(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.supprimer_une_configuration_d_alarme_xy:
        await deleteAlarmXY(payload);
        dispatch(
          actions.reqListeAlarmesXY(state.authentifie.filtre_projects_list_id)
        );
        break;
      case requestType.recuperer_la_liste_des_configurations_des_alarmes_xy:
        response = await getAllAlarmsXYWithIdProject(payload);
        dispatch(enregistrerListeAlarmesXY(response.data));
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqRecupererListeAlarmes = (list_project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_alarmes, {
      list_project_id,
    });
  };
};
export const reqConfirmerAlarme = (formulaireConfirmationAlarme) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.confirmer_une_alarme, {
      formulaireConfirmationAlarme,
    });
  };
};
export const reqListeGroupesEmails = (list_project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_groupes_d_email, {
      list_project_id,
    });
  };
};
export const reqCreerGroupeEmail = (formulaireGroupeEmail) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.creer_un_groupe_d_email,
      formulaireGroupeEmail
    );
  };
};
export const reqModifierGroupeEmail = (formulaireGroupeEmail) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modifier_un_groupe_d_email,
      formulaireGroupeEmail
    );
  };
};
export const reqSupprimerGroupeEmail = (group_email_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_groupe_d_email, {
      group_email_id,
    });
  };
};
export const reqCreerGabarit = (formulaireGabarit) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.creer_un_gabarit, formulaireGabarit);
  };
};
export const reqModifierGabarit = (formulaireGabarit) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modifier_un_gabarit, formulaireGabarit);
  };
};
export const reqSupprimerGabarit = (template_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_gabarit, {
      template_id,
    });
  };
};
export const reqListeGabarit = (list_project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperer_la_liste_des_gabarits, {
      list_project_id,
    });
  };
};

export const reqCreerAlarmesXY = (formulaireAlarmesXY) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.configurer_des_alarmes_xy,
      formulaireAlarmesXY
    );
  };
};
export const reqModifierAlarmesXY = (formulaireAlarmesXY) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.modifier_des_alarmes_xy,
      formulaireAlarmesXY
    );
  };
};
export const reqSupprimerAlarmesXY = (alarm_xy_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_une_configuration_d_alarme_xy, {
      alarm_xy_id,
    });
  };
};
export const reqListeAlarmesXY = (list_project_id) => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperer_la_liste_des_configurations_des_alarmes_xy,
      {
        list_project_id,
      }
    );
  };
};

const enregistrerListeAlarmes = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_ALARMES,
    alarms_list: data.alarms_list,
  };
};
const enregistrerListeEmailsGroupes = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_EMAILS_GROUPS,
    list_emails_groups: data.list_emails_groups,
  };
};
const enregistrerListeGabarits = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_GABARITS,
    list_templates: data.list_templates,
  };
};
const enregistrerListeAlarmesXY = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_ALARMES_XY,
    list_alarms_xy: data.list_alarms_xy,
  };
};

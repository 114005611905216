import React, { Component } from 'react';
import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import { connect } from 'react-redux';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import * as S from './FormulaireSelectionTimeZone.module.scss';
import { glossaire_time_zone } from '../../../ressources/glossaires/glossaire_time_zones';

class FormulaireSelectionTimeZone extends Component {
  state = {
    time_zone: null,
    preconfigurerTimeZone: null,
  };
  componentDidMount() {
    if (this.props.preconfigurerTimeZone !== undefined) {
      this.setState({
        ...this.state,
        time_zone: this.props.preconfigurerTimeZone,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.preconfigurerTimeZone !== prevProps.preconfigurerTimeZone) {
      this.setState({
        ...this.state,
        time_zone: this.props.preconfigurerTimeZone,
      });
    } else if (prevState.time_zone !== this.state.time_zone) {
      this.props.gererTimeZone(this.state.time_zone);
    }
  }
  gererEtatDeCibleName = (event) =>
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  render() {
    // récupérer liste des time_zones avec this.props.listetime_zones
    // créer un formulaire pour ajouter un parent à un time_zone sous forme de liste déroulante
    return (
      <>
        <span className={[S.stil2].join(' ')}>{checkMot('time_zone')}</span>
        <input
          list="timezones"
          value={this.state.time_zone}
          onChange={(event) => this.gererEtatDeCibleName(event)}
          name="time_zone"
          className={S.text_general}
        />

        <datalist id="timezones">
          <option disabled={true} selected={true}>
            Selecione um fuso horário
          </option>
          {glossaire_time_zone.map((timezone) => (
            <option
              value={timezone}
              selected={this.state.time_zone === timezone}
            >
              {timezone}
            </option>
          ))}
        </datalist>
        {/* <select onChange={(event) => this.gererEtatDeCibleName(event)}
                        defaultValue={this.state.time_zone}
                        disabled={this.props.affichageProfil === true}
                        value={this.state.time_zone}
                        name={"time_zone"}
                        className={[s.custom_select].join(" ")}>
                    <option value={"Europe/Paris"}>Europe/Paris</option>
                    <option value={"Europe"}>Europe</option>
                    <option value={"Paris"}>Paris</option>
                    {
                        [
                            "Europe",
                            "America",
                            "Asia",
                            "Pacific",
                            "US",
                            "Africa",
                            "Australia",
                            "SystemV",
                            "Chile",
                            "Antarctica",
                            "Indian",
                            "Atlantic",
                            "Canada",
                            "Brazil",
                            "Mexico",
                            "Etc"
                        ].sort().map(categorie =>
                            <optgroup label={checkMot(categorie)}>
                                {
                                    glossaire_time_zone.sort().map(timezone => {
                                            if (timezone.startsWith(categorie)) {
                                                return <option selected={this.state.time_zone}
                                                               value={timezone}>{timezone.slice(categorie.length+1,timezone.length)}</option>
                                            }
                                        }
                                    )
                                }
                            </optgroup>
                        )
                    }

                    <optgroup label={checkMot("autre")}>
                        {
                            glossaire_time_zone.map(timezone => {
                                    if (  !timezone.startsWith("Europe")
                                        &&!timezone.startsWith("America")
                                        &&!timezone.startsWith("Asia")
                                        &&!timezone.startsWith("Pacific")
                                        &&!timezone.startsWith("US")
                                        &&!timezone.startsWith("Africa")
                                        &&!timezone.startsWith("Australia")
                                        &&!timezone.startsWith("SystemV")
                                        &&!timezone.startsWith("Chile")
                                        &&!timezone.startsWith("Antarctica")
                                        &&!timezone.startsWith("Indian")
                                        &&!timezone.startsWith("Atlantic")
                                        &&!timezone.startsWith("Canada")
                                        &&!timezone.startsWith("Brazil")
                                        &&!timezone.startsWith("Mexico")
                                        &&!timezone.startsWith("Etc")) {
                                        return <option selected={this.state.time_zone}
                                                       value={timezone}>{timezone}</option>
                                    }
                                }
                            )
                        }
                    </optgroup>
                </select> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    listetime_zones: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(FormulaireSelectionTimeZone);

import React from 'react';
import S from '../../ExportImpressionEcran/ExportImpressionEcran.module.scss';
import * as glossaire_type_de_graphe from '../../../ressources/glossaires/glossaire_type_de_graphe';
import GrapheXT from './GrapheXT/GrapheXT';
import GrapheXY from './GrapheXY/GrapheXY';
import GrapheAvancementDetaille from './GrapheAvancementDetaille/GrapheAvancementDetaille';
import GraphesAvancementJourSemaineMois from './GraphesAvancementJourSemaineMois/GraphesAvancementJourSemaineMois';
import { checkMot } from '../../../ressources/functionJS/checkMot';
import Bouton from '../../UI/Bouton/Bouton';
import GraphesMachinePosition from './GraphesMachinePosition/GraphesMachinePosition';
import Graphes4 from './Graphes4/Graphes4';
import Graphes2 from './Graphes2/Graphes2';
import GrapheGeoposition from './GrapheGeoposition/GrapheGeoposition';
import TableauDernieresValeursEtTotauxResponsable from './TableauDernieresValeursEtTotauxResponsable/TableauDernieresValeursEtTotauxResponsable';
import TableauDeltaDesBornesEtTotaux from './TableauDeltaDesBornesEtTotaux/TableauDeltaDesBornesEtTotaux';
import TableauDernieresValeursEtTotauxRapport from './TableauDernieresValeursEtTotauxRapport/TableauDernieresValeursEtTotauxRapport';
import TableauDeltaDesBornesEtTotauxRapport from './TableauDeltaDesBornesEtTotauxRapport/TableauDeltaDesBornesEtTotauxRapport';
import TableauDernieresValeursEtTotaux from './TableauDernieresValeursEtTotaux/TableauDernieresValeursEtTotaux';
import TableauMinMaxDate from './TableauMinMaxDate/TableauMinMaxDate';
import TableauAbsoluteMinMaxDate from './TableauAbsoluteMinMaxDate/TableauAbsoluteMinMaxDate';
import GrapheCirculaire from './GrapheCirculaire/GrapheCirculaire';
import DiagrammeSankey from './DiagrammeSankey/DiagrammeSankey';
import GaugeDemiCercle from './GaugeDemiCercle/GaugeDemiCercle';
let juste_une_fois_mimoun = true;
const SwitchGraphe = (props) => {
  let afficheGraphe;

  if (
    props.donneesGraphe !== undefined &&
    props.donneesGraphe.config !== undefined
  ) {
    let boolAfficherGraphe = true;
    for (
      let indSetDonnees = 0;
      indSetDonnees < props.donneesGraphe.length;
      indSetDonnees
    ) {
      if (
        props.donneesGraphe[indSetDonnees].t !== undefined &&
        props.donneesGraphe[indSetDonnees].t !== null &&
        props.donneesGraphe[indSetDonnees].t.length > 0
      ) {
        boolAfficherGraphe = false;
        break;
      }
    }
    if (boolAfficherGraphe) {
      switch (props.donneesGraphe.config.graph_type_id) {
        case glossaire_type_de_graphe.graphe_x_t:
          afficheGraphe = (
            <GrapheXT
              graphDiv={glossaire_type_de_graphe.graphe_x_t}
              {...props}
              seuils={
                props.donneesGraphe.alarms_levels !== undefined
                  ? props.donneesGraphe.alarms_levels
                  : []
              }
            />
          );
          break;
        case glossaire_type_de_graphe.graphe_x_y:
          afficheGraphe = (
            <GrapheXY
              graphDiv={glossaire_type_de_graphe.graphe_x_y}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphe_x_y_t:
          afficheGraphe = (
            <GrapheXY
              graphDiv={glossaire_type_de_graphe.graphe_x_y_t}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphe_avancement_detaille:
          afficheGraphe = (
            <GrapheAvancementDetaille
              graphDiv={glossaire_type_de_graphe.graphe_avancement_detaille}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphes_avancement_jour_semaine_mois:
          afficheGraphe = (
            <GraphesAvancementJourSemaineMois
              graphDiv={
                glossaire_type_de_graphe.graphes_avancement_jour_semaine_mois
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphes_machine_position:
          afficheGraphe = (
            <GraphesMachinePosition
              graphDiv={glossaire_type_de_graphe.graphes_machine_position}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphes_2:
          afficheGraphe = (
            <Graphes2
              graphDiv={glossaire_type_de_graphe.graphes_2}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphes_3:
          afficheGraphe = (
            <Graphes4
              graphDiv={glossaire_type_de_graphe.graphes_3}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphes_4:
          afficheGraphe = (
            <Graphes4
              graphDiv={glossaire_type_de_graphe.graphes_4}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphe_geoposition:
          afficheGraphe = (
            <GrapheGeoposition
              graphDiv={glossaire_type_de_graphe.graphe_geoposition}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_rapport:
          if (
            props.reqRecupererDonneesGrapheAvecNDerniereValeurs instanceof
              Function &&
            props.donnees[0].x.length === 0 &&
            juste_une_fois_mimoun &&
            !props.attenteReq_recuperation_des_donnees_d_un_graphe
          ) {
            props.reqRecupererDonneesGrapheAvecNDerniereValeurs(
              props.configuration.graph_id,
              1
            );
            juste_une_fois_mimoun = false;
          }
          afficheGraphe = (
            <TableauDernieresValeursEtTotauxRapport
              graphDiv={
                glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_rapport
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux_rapport:
          afficheGraphe = (
            <TableauDeltaDesBornesEtTotauxRapport
              graphDiv={
                glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux_rapport
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_responsable:
          if (
            props.reqRecupererDonneesGrapheAvecNDerniereValeurs instanceof
              Function &&
            props.donnees[0].x.length === 0 &&
            juste_une_fois_mimoun &&
            !props.attenteReq_recuperation_des_donnees_d_un_graphe
          ) {
            props.reqRecupererDonneesGrapheAvecNDerniereValeurs(
              props.configuration.graph_id,
              1
            );
            juste_une_fois_mimoun = false;
          }
          afficheGraphe = (
            <TableauDernieresValeursEtTotauxResponsable
              graphDiv={
                glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux_responsable
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux:
          afficheGraphe = (
            <TableauDeltaDesBornesEtTotaux
              graphDiv={
                glossaire_type_de_graphe.tableau_delta_des_bornes_et_totaux
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux:
          afficheGraphe = (
            <TableauDernieresValeursEtTotaux
              graphDiv={
                glossaire_type_de_graphe.tableau_dernieres_valeurs_et_totaux
              }
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_min_max_date:
          afficheGraphe = (
            <TableauMinMaxDate
              graphDiv={glossaire_type_de_graphe.tableau_min_max_date}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.tableau_absolute_min_max_date:
          afficheGraphe = (
            <TableauAbsoluteMinMaxDate
              graphDiv={glossaire_type_de_graphe.tableau_absolute_min_max_date}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.graphe_circulaire:
          afficheGraphe = (
            <GrapheCirculaire
              graphDiv={glossaire_type_de_graphe.graphe_circulaire}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.diagramme_sankey:
          afficheGraphe = (
            <DiagrammeSankey
              graphDiv={glossaire_type_de_graphe.graphe_circulaire}
              {...props}
            />
          );
          break;
        case glossaire_type_de_graphe.gauge_demi_cercle:
          afficheGraphe = (
            <GaugeDemiCercle
              graphDiv={glossaire_type_de_graphe.gauge_demi_cercle}
              {...props}
            />
          );
          break;
        default:
          afficheGraphe = <div>{checkMot('type_de_graphe_inconnu')}</div>;
          break;
      }
    } else {
      afficheGraphe = 'Erreur données API non conforme.';
    }
  } else {
    afficheGraphe = (
      <Bouton
        ajoutClass={S.style}
        cliquer={() => {
          props.reqRecupererDonneesGraphe(props.graph_id);
        }}
      >
        {checkMot('refresh')}
      </Bouton>
    );
  }
  return afficheGraphe;
};

export default SwitchGraphe;

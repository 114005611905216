import { store } from '../../index';

export const checkMot = (str) => {
  /**
   * choix de la langue:
   */

  const state = store.getState();
  if (state.dico.dico_langues[str] !== undefined) {
    if (state.dico.dico_langues[str][state.dico.langueSite] !== undefined) {
      // false, but the key exists!
      return state.dico.dico_langues[str][state.dico.langueSite];
    } else {
      console.warn(str, " n'existe pas en", state.dico.langueSite);
      return state.dico.dico_langues[str]['fr'];
    }
  } else {
    console.warn(
      '/!\\',
      str,
      " n'existe pas dans le dictionnaire de langues. /!\\"
    );
    return str;
  }
};

import React, { Component } from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { connect } from 'react-redux';
import Bouton from '../../../UI/Bouton/Bouton';
import Tableau from '../../../UI/tableau/Tableau';
import { checkMot } from '../../../../ressources/functionJS/checkMot';
import * as type_btn from '../../../../ressources/glossaires/glossaire_type_de_bouton';
import * as S from './TableauProjetsTauxCriticite.module.scss';
import { VscNewFile, VscSearch } from 'react-icons/vsc';
import { convertiUtsToDateHour } from '../../../InterfaceClient/Tabs/Alarmes/TableauAlarmesClient/TableauAlarmesClient';
class TableauProjetsTauxCriticite extends Component {
  state = {
    columns: [
      {
        dataField: 'detectionTimestamp',
        text: [
          checkMot('detection_timestamp'),
          <Bouton
            key={'detection_timestamp'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'detectionTimestamp',
                checkMot('detection_timestamp')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'timestampLastValue',
        text: [
          checkMot('timestamp_last_value'),
          <Bouton
            key={'timestamp_last_value'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre(
                'timestampLastValue',
                checkMot('timestamp_last_value')
              )
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          return convertiUtsToDateHour(cell);
        },
      },
      {
        dataField: 'component',
        text: [
          'Composante(s)',
          <Bouton
            key={'component'}
            type={type_btn.lupeSearch}
            cliquer={() => undefined}
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
        formatter: (cell) => {
          const formattedCell = Array.from(cell);
          let displayCell = '';
          formattedCell.forEach((element) => {
            displayCell += `${element} | `;
          });
          displayCell = displayCell.slice(0, -2);
          return displayCell;
        },
      },
      {
        dataField: 'projectName',
        text: [
          checkMot('project_name'),
          <Bouton
            key={'project_name'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('projectName', checkMot('project_name'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'sensorName',
        text: [
          checkMot('sensor_name'),
          <Bouton
            key={'sensor_name'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensorName', checkMot('sensor_name'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'sensorType',
        text: [
          checkMot('sensor_type'),
          <Bouton
            key={'sensor_type'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensorType', checkMot('sensor_type'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
      {
        dataField: 'sensorGroup',
        text: [
          checkMot('sensor_group'),
          <Bouton
            key={'sensor_group'}
            type={type_btn.lupeSearch}
            cliquer={() =>
              this.afficherFiltre('sensorGroup', checkMot('sensor_group'))
            }
          >
            <VscSearch size={10} />
          </Bouton>,
        ],
        sort: true,
      },
    ],
    selectRow: {
      // paramètre d'affichage des lignes du tableau
      mode: 'radio',
      clickToSelect: true,
      hideSelectAll: true,
      hideSelectColumn: true,
      style: () => {
        //style après sélection
        return {
          background: '#eeb214',
          color: 'black',
        };
      },
      // onSelect: (row) => {//fonction de récupération des données de la ligne après sélection de la ligne
      //     return this.props.projetSelectione(row);
      // }
    },
    iconeCreer: <VscNewFile size={15} />,
  };

  afficherFiltre = (dataField, placeholder) => {
    const newCol = this.state.columns.map((col) => {
      if (col.dataField === dataField) {
        return {
          ...col,
          filter: textFilter({
            placeholder: placeholder,
          }),
        };
      } else {
        return col;
      }
    });
    this.setState({
      ...this.state,
      columns: [...newCol],
    });
  };

  render() {
    let liste_tableaux_de_bord_favoris = [];
    if (this.props.sensorList?.length > 0) {
      for (let objetProject of this.props.sensorList) {
        //récupère la liste des alarmes_xy (utiliser la requête api) en fonction de la liste de projets sélectionné dans le filtre
        if (this.props.filtre_projects_list_id.length > 0) {
          // envoie la requête de recupération de alarme si la liste de projets n'est pas vide

          liste_tableaux_de_bord_favoris.push({
            ...objetProject,
          });
        }
      }
    }

    return (
      <>
        <Tableau
          cleLigne={'id'}
          creer={this.props.creer}
          iconeCreer={this.state.iconeCreer}
          // donnees={this.props.sensors_list_maintenance}
          donnees={liste_tableaux_de_bord_favoris}
          colonnes={this.state.columns}
          selectionnerLignes={this.state.selectRow}
        >
          <span className={S.refreshForm}>{this.props.children}</span>
        </Tableau>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeRapportsGabarits: state.rapport.templates_reports_list,
    filtre_projects_list_id: state.authentifie.filtre_projects_list_id,
    project_id_url: state.interfaceClient.project_id_url,
    projects_list: state.authentifie.projects_list,
  };
};

export default connect(mapStateToProps)(TableauProjetsTauxCriticite);

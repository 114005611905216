import React, { Component } from 'react';
import * as S from './FormulaireSaisieDuree.module.scss';
import { checkMot } from '../../../ressources/functionJS/checkMot';

import * as s from '../../../ressources/StyleGlobal/StyleGlobal';
import * as glossaire_pattern from '../../../ressources/glossaires/glossaire_pattern';

export const TIME_UNITS = {
  secondes: 1,
  minutes: 60,
  heures: 3600,
  jours: 86400,
  semaines: 604800,
};
export const uniteAffichageDuree = (preconfigurerDuree) => {
  let selectedUnit = 'secondes';

  for (const unit in TIME_UNITS) {
    if (parseFloat(preconfigurerDuree) / TIME_UNITS[unit] > 1) {
      selectedUnit = unit;
    }
  }
  return selectedUnit;
};
class FormulaireSaisieDuree extends Component {
  state = {
    secondes: this.props.preconfigurerDuree,
    selectedUnit: 'jours',
  };

  componentDidMount() {
    this.updateUnitAndValue(this.props.preconfigurerDuree);
  }

  componentDidUpdate(prevProps) {
    if (this.props.preconfigurerDuree !== prevProps.preconfigurerDuree) {
      this.updateUnitAndValue(this.props.preconfigurerDuree);
    }
  }

  updateUnitAndValue = (preconfigurerDuree) => {
    const preconfigDureeFloat = parseFloat(preconfigurerDuree);
    const selectedUnit = uniteAffichageDuree(preconfigDureeFloat);
    const valeur = preconfigDureeFloat / TIME_UNITS[selectedUnit];

    this.setState({
      secondes: preconfigurerDuree,
      selectedUnit,
      [selectedUnit]: valeur,
    });
  };

  handleUnitChange = (event) => {
    const selectedUnit = event.target.value;
    const newInterval =
      parseFloat(this.state.secondes) / TIME_UNITS[selectedUnit];
    this.setState({ [selectedUnit]: newInterval, selectedUnit });
  };

  handleTimeUnitChange = (event) => {
    const { selectedUnit } = this.state;
    const newValue = event.target.value;
    const newInterval = parseFloat(newValue) * TIME_UNITS[selectedUnit];

    this.setState({ [selectedUnit]: newValue, secondes: newInterval });
    if (!isNaN(newInterval)) {
      this.props.gererDuree(newInterval);
    }
  };

  render() {
    const { selectedUnit } = this.state;
    return (
      <>
        <div className={S.conteneur_input}>
          <input
            className={[s.form_control, s.col_7].join(' ')}
            pattern={glossaire_pattern.float}
            placeholder={checkMot(selectedUnit)}
            disabled={this.props.affichageProfil === true}
            type="number"
            value={this.state[selectedUnit]}
            onChange={this.handleTimeUnitChange}
          />
          <select
            className={[s.custom_select, s.col_5, S.text_geral].join(' ')}
            disabled={this.props.affichageProfil === true}
            defaultChecked={selectedUnit}
            value={selectedUnit}
            onChange={this.handleUnitChange}
          >
            <option value="secondes">{checkMot('secondes')}</option>
            <option value="minutes">{checkMot('minutes')}</option>
            <option value="heures">{checkMot('heures')}</option>
            <option value="jours">{checkMot('jours')}</option>
            <option value="semaines">{checkMot('semaines')}</option>
          </select>
        </div>
      </>
    );
  }
}

export default FormulaireSaisieDuree;

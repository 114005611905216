import { store } from '../../index';

export const checkPhrase = (cle_tractuction, str) => {
  /**
   * choix de la langue:
   */

  const state = store.getState();
  if (
    state.dico.dico_langues[cle_tractuction] !== undefined &&
    state.dico.dico_langues[cle_tractuction]
  ) {
    if (
      state.dico.dico_langues[cle_tractuction][state.dico.langueSite] !==
        undefined &&
      state.dico.dico_langues[cle_tractuction][state.dico.langueSite] instanceof
        Function
    ) {
      // false, but the key exists!
      return state.dico.dico_langues[cle_tractuction][state.dico.langueSite](
        str
      );
    } else {
      console.warn(cle_tractuction, " n'existe pas en", state.dico.langueSite);
      return state.dico.dico_langues[cle_tractuction]['fr'](str);
    }
  } else {
    console.warn(
      '/!\\',
      cle_tractuction,
      " n'existe pas dans le dictionnaire de langues. /!\\"
    );
    return cle_tractuction;
  }
};

import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../services/actions';
import { checkMot } from '../../ressources/functionJS/checkMot';
import * as s from '../../ressources/StyleGlobal/StyleGlobal';
import * as S from './Accueil.module.scss';
import logo from '../../ressources/itmlogo.png';
import { MdPerson, AiFillPhone, MdEmail } from 'react-icons/all';
import { IoIosContacts } from 'react-icons/io';

class Accueil extends Component {
  render() {
    return (
      <div className={S.container}>
        <div className={[S.bienvenue].join(' ')}>
          {checkMot('bienvenue')} <b>{this.props.prenom} </b>
          {checkMot('sur_la_platforme_argos')}{' '}
        </div>
        <a href="https://itmsol.fr/">
          <div className={S.logo}>
            <img alt="logo" src={logo} className="" />
          </div>
        </a>
        <div className={[S.contact, s.row].join(' ')}>
          <div className={[S.titles, s.col_12].join(' ')}>
            <span className={S.icon}>
              <IoIosContacts size={30} />
            </span>
            {checkMot('contacts_itmsol')}
          </div>
          <div className={[S.car, s.card].join(' ')}>
            <div className={s.card_header}>{checkMot('direction')} :</div>
            <div className={s.card_body}>
              <div className={S.lin}>
                <MdPerson size={20} color={'#EEB214'} /> M Eric Gastine
              </div>
              <div className={S.lin}>
                <AiFillPhone size={20} color={'#EEB214'} />
                <a> 07 61 01 77 50</a>
              </div>
              <div className={S.lin}>
                <MdEmail size={20} color={'#EEB214'} />
                <a href="mailto:eric.gastine@itmsol.fr">
                  {' '}
                  eric.gastine@itmsol.fr
                </a>
              </div>
            </div>
          </div>
          <div className={[S.car, s.card].join(' ')}>
            <div className={s.card_header}>{checkMot('operations')} :</div>
            <div className={s.card_body}>
              <div className={S.lin}>
                <MdPerson size={20} color={'#EEB214'} />M Xavier LELONG
              </div>
              <div className={S.lin}>
                <AiFillPhone size={20} color={'#EEB214'} />
                <a> 06 21 43 68 97</a>
              </div>
              <div className={S.lin}>
                <MdEmail size={20} color={'#EEB214'} />
                <a href="mailto:xavier.lelong@itmsol.fr">
                  {' '}
                  xavier.lelong@itmsol.fr
                </a>
              </div>
            </div>
          </div>
          <div className={[S.car, s.card].join(' ')}>
            <div className={s.card_header}>{checkMot('maintenance')} :</div>
            <div className={s.card_body}>
              <div className={S.lin}>
                <MdPerson size={20} color={'#EEB214'} />M Maxance Lu
              </div>
              <div className={S.lin}>
                <AiFillPhone size={20} color={'#EEB214'} />
                <a> 06 68 09 23 94</a>
              </div>
              <div className={S.lin}>
                <MdEmail size={20} color={'#EEB214'} />
                <a href="mailto:maxance.lu@itmsol.fr"> maxance.lu@itmsol.fr</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    nom: state.authentifie.lastname,
    prenom: state.authentifie.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    supprimerAlerte: (alerteKey) => dispatch(actions.supprimer(alerteKey)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);

import React, { Component } from 'react';
import * as localeDictionary from 'plotly.js/lib/locales/fr';
import BibliothequePlotly from '../BibliothequePlotly/BibliothequePlotly';

class GaugeDemiCercle extends Component {
  /**
   *
   * @return {JSX.Element}
   */
  state = {
    afficherRangeSlider: true,
    afficherModeBar: false,
  };

  render() {
    let value;
    let unit;
    let label;
    let color;
    let max_value;
    let min_value;

    for (
      let index_courbe = 0;
      index_courbe < this.props.donnees.length;
      index_courbe++
    ) {
      let courbe = this.props.donnees[index_courbe];
      if (courbe.y.length > 0) {
        if (!max_value) {
          max_value = courbe.y[0];
        }
        if (!min_value) {
          min_value = courbe.y[0];
        }
        for (let valeur of courbe.y) {
          if (valeur > max_value) {
            max_value = valeur;
          }
          if (valeur < min_value) {
            min_value = valeur;
          }
        }
        //todo si la courbe correspond à la config
        if (courbe.yaxis === 'y1') {
          value = courbe.y[courbe.y.length - 1];
          unit = courbe.engineering_unit;
          label = courbe.name;
          color = courbe.color;
        }
      }
    }
    let jsonLayout = {};
    let styleData = {};
    let styleDataGauge = {};
    let styleDataGaugeAxis = {};
    let styleDataGaugeAxisRange = {};
    if (this.props.viewobj_json_content !== undefined) {
      if (this.props.viewobj_json_content.layout !== undefined) {
        jsonLayout = this.props.viewobj_json_content.layout;
      }
      if (this.props.viewobj_json_content.styleData !== undefined) {
        styleData = this.props.viewobj_json_content.styleData;
        if (this.props.viewobj_json_content.styleData.gauge !== undefined) {
          styleDataGauge = { ...styleData.gauge };
          if (
            this.props.viewobj_json_content.styleData.gauge.axis !== undefined
          ) {
            styleDataGaugeAxis = { ...styleData.gauge.axis };
            if (
              this.props.viewobj_json_content.styleData.gauge.axis.range !==
                undefined &&
              this.props.viewobj_json_content.styleData.gauge.axis.range
                .length === 2
            ) {
              styleDataGaugeAxisRange = styleData.gauge.axis.range;
            }
          }
        }
      }
    }
    let data = [
      {
        type: 'indicator',
        mode: 'gauge+number',
        value: value,
        title: {
          text:
            this.props.configuration.title +
            '<br>' +
            this.props.configuration.subtitle,
          align: 'left',
          font: { size: 24 },
        },
        number: {
          font: { size: 120 },
          suffix: ' ' + unit,
        },
        ...styleData,
        gauge: {
          bar: {
            color: color,
            // line: {width: 20},
            thickness: 1,
          },
          borderwidth: 2,
          bordercolor: 'gray',
          ...styleDataGauge,
          axis: {
            tickwidth: 1,
            tickcolor: 'gray',
            tickfont: { size: 40 },
            ...styleDataGaugeAxis,
            range: [
              styleDataGaugeAxisRange[0] !== null
                ? styleDataGaugeAxisRange[0]
                : min_value, //si la première valeur est null alors la valeur min est pris en compte
              styleDataGaugeAxisRange[1] !== null
                ? styleDataGaugeAxisRange[1]
                : max_value, //si la deuxième valeur est null alors la valeur max est pris en compte
            ],
          },
        },
      },
    ];

    return (
      <BibliothequePlotly
        layout={{
          responsive: true,
          width:
            this.props.dimensionWidth !== undefined
              ? this.props.dimensionWidth
              : window.innerWidth * 0.75, //150
          height:
            this.props.dimensionHeight !== undefined
              ? this.props.dimensionHeight
              : window.innerHeight * 0.75, //165
          font: { color: 'gray', family: 'Opensans', fontSize: 150 },
          margin: {
            t: 130,
            b: 130,
            l: 130,
            r: 130,
          },
          ...jsonLayout,
        }}
        donneesGraphe={this.props.donneesGraphe}
        ouvrirPopupModifierIntervalle={
          this.props.switchAfficherModifierIntervalle
        }
        dimensionWidth={this.props.dimensionWidth}
        dimensionHeight={this.props.dimensionHeight}
        viewobj_json_content={this.props.viewobj_json_content}
        data={data}
        config={{
          locales: { fr: localeDictionary },
          locale: 'fr',
          displayModeBar:
            this.props.viewobj_json_content === undefined ||
            this.props.viewobj_json_content === null
              ? true
              : this.props.viewobj_json_content.length === 0 ||
                  this.props.viewobj_json_content.displayModeBar === true
                ? true
                : false,
          displaylogo: false,
          modeBarButtonsToRemove: [''],
        }}
      />
    );
  }
}

export default GaugeDemiCercle;

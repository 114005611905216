import React, { useEffect, useState } from 'react';
import { IoIosHelpCircleOutline } from 'react-icons/io';

const HoverButton = ({ imageUrl, customStyle, customClass, texte }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [loadedImage, setLoadedImage] = useState(null);

  useEffect(() => {
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => setLoadedImage(image);
  }, [imageUrl]);

  return (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={customStyle}
    >
      <IoIosHelpCircleOutline size={25} />
      {isHovering && texte && <div className={`${customClass}`}>{texte}</div>}
      {isHovering && loadedImage && (
        <>
          <img src={loadedImage.src} className={`${customClass}`} alt="" />
        </>
      )}
    </span>
  );
};

export default HoverButton;

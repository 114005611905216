import React, { Component } from 'react';
import Bouton from '../../../UI/Bouton/Bouton';
import { stringOrEmptyString } from '../../../../ressources/functionJS/stringOrEmptyString';
import * as glossaire_type_objets_vue from '../../../../ressources/glossaires/glossaire_type_objets_vue';
import * as s from '../../../../ressources/StyleGlobal/StyleGlobal';
import { GiCctvCamera, GiSpeaker, IoMdImages } from 'react-icons/all';
import Iframe from 'react-iframe';
import { setUrlPathFromDomain } from '../../../../services/utils/utils';

class ObjetTexte extends Component {
  state = {
    viewobj_json_content: null,
  };

  render() {
    let representation_vue = <Bouton>{this.props.objetAAfficher.name}</Bouton>;
    //représentation de l'objet sur la vue
    if (this.props.objetAAfficher !== undefined) {
      if (
        this.state.viewobj_json_content === null &&
        this.props.objetAAfficher.viewobj_path !== undefined &&
        stringOrEmptyString(this.props.objetAAfficher.viewobj_path) !== '' &&
        !this.state.attenteRecupFichierJson &&
        this.props.viewobj_json_content === undefined
      ) {
        try {
          let cheminURL = setUrlPathFromDomain(
            this.props.objetAAfficher.viewobj_path,
            'https://' +
              document.domain +
              this.props.objetAAfficher.viewobj_path.slice(
                1,
                this.props.objetAAfficher.viewobj_path.length
              )
          );
          fetch(cheminURL + '?varget' + Math.random())
            //todo: modifier le chemin absolu en chemin relatif
            .then((res) => res.json())
            .then((data) => {
              this.setState({
                attenteRecupFichierJson: false,
                viewobj_json_content: data,
              });
            })
            .catch((e) => console.error('error: ', e));
        } catch (e) {
          this.setState({
            attenteRecupFichierJson: false,
            viewobj_json_content: null,
          });
          console.error('error', e);
        }
      } else if (
        this.state.viewobj_json_content !== null &&
        this.state.viewobj_json_content.type_de_representation !== undefined
      ) {
        switch (this.state.viewobj_json_content.type_de_representation) {
          case glossaire_type_objets_vue.bouton_lien_audio:
            representation_vue = (
              <button
                onClick={() =>
                  window.open(
                    this.state.viewobj_json_content.lien_fichier !== undefined
                      ? this.state.viewobj_json_content.lien_fichier
                      : this.props.objetAAfficher.name,
                    '_blank',
                    `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                  )
                }
                className={[s.btn, s.btn_sm].join(' ')}
                style={{
                  ...this.state.viewobj_json_content.styleMainDiv,
                }}
                disabled={this.state.viewobj_json_content.disabled === true}
              >
                <GiSpeaker />
              </button>
            );
            break;
          case glossaire_type_objets_vue.bouton_lien_video:
            representation_vue = (
              <button
                onClick={() =>
                  window.open(
                    this.state.viewobj_json_content.lien_fichier !== undefined
                      ? this.state.viewobj_json_content.lien_fichier
                      : this.props.objetAAfficher.name,
                    '_blank',
                    `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                  )
                }
                className={[s.btn, s.btn_sm].join(' ')}
                style={{
                  ...this.state.viewobj_json_content.styleMainDiv,
                }}
                disabled={this.state.viewobj_json_content.disabled === true}
              >
                <GiCctvCamera />
              </button>
            );
            break;
          case glossaire_type_objets_vue.bouton_lien_image:
            representation_vue = (
              <button
                onClick={() =>
                  window.open(
                    this.state.viewobj_json_content.lien_fichier !== undefined
                      ? this.state.viewobj_json_content.lien_fichier
                      : this.props.objetAAfficher.name,
                    '_blank',
                    `toolbar=no,scrollbars=yes,dependent=yes,top=0,left=0,innerWidth=${window.screen.availWidth},innerHeight=${window.screen.availHeight}`
                  )
                }
                className={[s.btn, s.btn_sm].join(' ')}
                style={{
                  ...this.state.viewobj_json_content.styleMainDiv,
                }}
                disabled={this.state.viewobj_json_content.disabled === true}
              >
                {this.state.viewobj_json_content.lien_bouton_image ===
                undefined ? (
                  <IoMdImages />
                ) : (
                  <img
                    alt={'lien bouton'}
                    src={this.state.viewobj_json_content.lien_bouton_image}
                    style={{
                      ...this.state.viewobj_json_content.styleBoutonImage,
                    }}
                  />
                )}
              </button>
            );
            break;
          case glossaire_type_objets_vue.iframe:
            representation_vue = (
              <Iframe
                url={this.state.viewobj_json_content.url}
                id=""
                className=""
                styles={{ ...this.state.viewobj_json_content.iframe_style }}
                allow="fullscreen"
                display="block"
                frameborder="0"
                width="700"
                height="500"
                // sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                position="relative"
                {...this.state.viewobj_json_content.iframe_props}
              />
            );
            break;
          case glossaire_type_objets_vue.texte_div:
            representation_vue = (
              <div
                id=""
                style={{
                  ...this.state.viewobj_json_content.main_style,
                }}
                {...this.state.viewobj_json_content.texte_div_props}
              >
                {this.state.viewobj_json_content.texte}
              </div>
            );
            break;
          default:
            representation_vue = <div>Défaut</div>;
            break;
        }
      }
    }
    return representation_vue;
  }
}

export default ObjetTexte;

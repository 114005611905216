import * as requestType from '../../ressources/glossaires/glossaire_requetes';
import * as actionTypes from './actionTypes';
import * as actions from './index';
import {
  addImageToProject,
  addProjectToFavorite,
  createProject,
  deleteProject,
  getFavortiesProject,
  getProjects,
  removeProjectfromFavorite,
  updateProject,
} from '../api';
import { affichageRetourErreurs } from '../utils/utils';

const requetePost = async (dispatch, requestId, payload) => {
  /**
   * gère l'envoi du type de requête et du retour de l'api
   * @param dispatch - remplace la fonction 'return' et permet de faire des requêtes asynchrone et réaliser des actions sur le réducteur
   * @param requeteAPI - permet de rendre la requête à l'API
   * @return rien - mais dispatch pour modifier le reducteur et utilise des fonctions alarme
   */
  dispatch(actions.debutAttente(requestId));
  console.log('requetePost:', dispatch, requestId, payload);
  try {
    let response;
    switch (requestId) {
      case requestType.recuperation_de_la_liste_des_projets:
        response = await getProjects(payload);
        if (response.data.state) {
          dispatch(enregistrerListeProjets(response.data));
          return;
        }
        break;
      case requestType.creation_d_un_projet:
        console.log('payload', payload);
        response = await createProject(payload);
        console.log("I'm here requestType.creation_d_un_projet");
        console.log('payload', payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeProjets());
          return;
        }
        break;
      case requestType.modification_d_un_projet:
        response = await updateProject(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeProjets());
          return;
        }
        break;
      case requestType.supprimer_un_projet:
        response = await deleteProject(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeProjets());
          return;
        }
        break;
      case requestType.ajouter_une_image_de_projet:
        response = await addImageToProject(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeProjets());
          return;
        }
        break;
      case requestType.rajouter_un_projet_en_favori:
        response = await addProjectToFavorite(payload);
        if (response.data.state) {
          if (response.project_id > 0) {
            dispatch(actions.reqRecupererListeProjetsFavoris());
          }
          return;
        }
        break;
      case requestType.supprimer_un_projet_des_favoris:
        response = await removeProjectfromFavorite(payload);
        if (response.data.state) {
          dispatch(actions.reqRecupererListeProjetsFavoris());
          return;
        }
        break;
      case requestType.recuperation_de_la_liste_des_projets_des_favoris:
        response = await getFavortiesProject(payload);
        if (response.data.state) {
          dispatch(enregistrerListeProjetsFavoris(response.data));
          return;
        }
        break;
      default:
        break;
    }
    affichageRetourErreurs(
      dispatch,
      `Erreur dans la requête ${requestId}`,
      response
    );
  } catch (err) {
    console.error('Error API', requestId, ':', err);
  } finally {
    dispatch(actions.finAttente(requestId));
  }
};

export const reqRecupererListeProjets = () => {
  return (dispatch) => {
    requetePost(dispatch, requestType.recuperation_de_la_liste_des_projets);
  };
};

export const reqAjouterProjet = (infoNouveauProjet) => {
  return (dispatch) => {
    console.log('reqAjouterProjet');
    requetePost(dispatch, requestType.creation_d_un_projet, {
      ...infoNouveauProjet,
    });
  };
};
export const reqModifierProjet = (infoModifierProjet) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.modification_d_un_projet, {
      ...infoModifierProjet,
    });
  };
};
export const reqSupprimerProjet = (project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_projet, {
      project_id,
    });
  };
};
export const reqAjouterImageProjet = (project_id, image) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.ajouter_une_image_de_projet, {
      project_id,
      image,
    });
  };
};

export const reqAjouterProjetFavori = (project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.rajouter_un_projet_en_favori, {
      project_id,
    });
  };
};
export const reqSupprimerProjetFavori = (project_id) => {
  return (dispatch) => {
    requetePost(dispatch, requestType.supprimer_un_projet_des_favoris, {
      project_id,
    });
  };
};
export const reqRecupererListeProjetsFavoris = () => {
  return (dispatch) => {
    requetePost(
      dispatch,
      requestType.recuperation_de_la_liste_des_projets_des_favoris
    );
  };
};

const enregistrerListeProjets = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_PROJETS,
    projects_list: data.projects_list,
  };
};
const enregistrerListeProjetsFavoris = (data) => {
  return {
    type: actionTypes.ENREGISTRER_LISTE_PROJETS_FAVORIS,
    favorite_projects_list_id: data.favorite_projects_list_id,
  };
};

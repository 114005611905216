import React from 'react';
import { connect } from 'react-redux';
import { ListGroup } from 'reactstrap';
import TreeMenu from 'react-simple-tree-menu';

import ListItem from './AffichageNoeud/AffichageNoeud';

import '../../../../../node_modules/react-simple-tree-menu/dist/main.css';
import * as actions from '../../../../services/actions';
import { checkMot } from '../../../../ressources/functionJS/checkMot';

/*
Deux objets :
- la liste initiale des projets
- l'arbre contenant les noeuds (projets) de l'arborescence

Les projets sont structurellement proche de ce que l'on récupère dans la base de donnée.
Les noeuds sont structurellement proche de ce qui est attendu pour construire l'arborescence graphique.
*/

/*
Algo :
parcours de la liste des projets pour rechercher les roots
 -> pour chaque projet vérifier que son parent_id est null ou n'est pas dans la liste | O(N²) ; N nombre de projet
pour chaque root, rechercher les enfants (possibilité de faire par récurrence à ce moment pour descendre directement) | O((N+1)²) ; (N+1) nombre projet non root
 -> attacher l'enfant au parent et récurrence pour la recherche de sous enfant sur l'enfant
*/

/*
create_node
Crée un noeud à partir d'un projet

Paramètre
project: objet de type projet à convertir en noeud

Retourne le projet sous forme de noeud (node)
*/
function create_node(project) {
  if (project == null) {
    return null;
  }

  return {
    key: project.project_id,
    label: project.project_name,
    parent_id: project.parent_id,
    url_ext: project.url_ext,
    url_ext_show_id: project.url_ext_show_id,

    nodes: [],
  };
}

/*
add_nodes_children

Paramètre
node: objet de type noeud pour lequel nous souhaitons ajouter des noeuds enfants
children_list: liste d'objet de type noeud, les enfants à ajouter

Retourne un objet de type noeud, le noeud parent entrant (node)
*/
function add_nodes_children(node, children_list) {
  if (node == null) {
    return null;
  }

  if (children_list == null) {
    return node;
  }

  for (let ind0 = 0; ind0 < children_list.length; ind0++) {
    node.nodes.push(children_list[ind0]);
  }
  return node;
}

//test des fonctions projet/noeud
/*
console.clear();
let noeud_test = create_node(proj_l[0]);

*/

/*
recurrent_function
Cette fonction intègre le noeud parent et la liste des projets à assigner à un parent.
Elle a pour objectif d'ajouter au noeud parent ses enfants.
De manière récurcive, la fonction descend dans l'arborescence et fais remonter les noeuds complétés.

Paramètre
parent_node: objet de type noeud, le noeud parent pour lequel nous souhaitons assigner les noeuds enfants
proj_l: liste d'objet de type projet, ces projets sont à assigner à un parent

Retourne le noeud parent complété (parent_node)
*/
function recurrent_function(parent_node, proj_l) {
  //recherche des enfants récur
  for (let ind0 = 0; ind0 < proj_l.length; ind0++) {
    if (proj_l[ind0].parent_id === parent_node.key) {
      //le projet selectionné est un enfant

      //création d'un noeud à partir du projet enfant
      let node_child = create_node(proj_l[ind0]);

      //recherche des sous enfants
      node_child = recurrent_function(node_child, proj_l);

      //attribution au nom parent
      parent_node = add_nodes_children(parent_node, [node_child]);
    }
  }

  // on fait remonter le noeud
  return parent_node;
}

const arborescenceProjets = (props) => {
  /*done: afficher l'arborescence des projets de l'utilisateur connecté*/
  /*     1) récupérer la liste des informations de projets de l'utilisateur connecté depuis le reducteur de auth via le connect() de redux */
  /*     2) avec une boucle créer la structure d'arborescence adéquate pour l'insérer dans le composant d'arborescence*/
  /*     3) créer le composant d'arborescence et insérer la structure de l'arborescence précédemment créée*/

  //liste des projets sur lequel nous allons travailler
  let proj_l = [
    ...(props.listeProjets !== null && props.listeProjets === undefined
      ? []
      : props.listeProjets),
  ];

  // console.clear();

  //création de l'arbre : liste contenant les noeuds root (ces noeuds pourraient être eux-même attachés à un noeud principal unique mais ça n'a pas d'intêret pour nous)
  let arbre = [];

  //Recherche des roots
  for (let ind0 = proj_l.length - 1; ind0 >= 0; ind0--) {
    if (proj_l[ind0].parent_id == null) {
      //ajout à la liste des noeuds root
      //array_root.push(proj_l[ind0].id);

      //ajout du noeud à l'arbre
      arbre.push(create_node(proj_l[ind0]));

      //suppression de la liste initiale
      proj_l.splice(ind0, 1);
    } else {
      let flag_root = true;
      //recherche du parent dans la liste
      if (props.listeProjets !== null) {
        for (let ind1 = 0; ind1 < props.listeProjets.length; ind1++) {
          if (props.listeProjets[ind1].project_id === proj_l[ind0].parent_id) {
            //le parent existe dans la liste : ce n'est pas un noeud
            flag_root = false;
            break;
          }
        }
      }
      //ceci est un noeud car le flag est inchangé
      if (flag_root) {
        //ajout du noeud à l'arbre
        arbre.push(create_node(proj_l[ind0]));

        //suppression de la liste initiale
        proj_l.splice(ind0, 1);
      }
    }
  }
  //liste des roots dans l'arbre
  //
  //liste des projets restant à classifier
  //pour chaque noeud root de l'arbre : recherche des enfants par récurrence
  for (let ind2 = 0; ind2 < arbre.length; ind2++) {
    arbre[ind2] = recurrent_function(arbre[ind2], proj_l);
  }

  function trierArborescence(arborescence) {
    arborescence.sort(function (a, b) {
      return a.label.localeCompare(b.label);
    });
    arborescence.forEach(function (noeud) {
      if (noeud.nodes.length > 0) {
        trierArborescence(noeud.nodes);
      }
    });
  }

  trierArborescence(arbre);
  //arborescence

  props.enregistrerArborescenceProjets(arbre);
  return (
    <>
      <TreeMenu
        data={arbre}
        onClickItem={({ key, label, ...props }) => {
          this.navigate(props.url); // user defined prop
        }}
        debounceTime={125}
      >
        {({ search, items }) => (
          <>
            {props.afficherBarreDeRecherche ? (
              <input
                onChange={(e) => search(e.target.value)}
                placeholder={checkMot('recherche')}
              />
            ) : (
              search('')
            )}
            <ListGroup>
              {items.map((value, index) => (
                <ListItem
                  project_id={
                    items[index].key.split('/')[
                      items[index].key.split('/').length - 1
                    ]
                  }
                  {...value}
                />
              ))}
            </ListGroup>
          </>
        )}
      </TreeMenu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    listeFavoris: state.authentifie.favorite_projects_list_id,
    listeProjets: state.authentifie.projects_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    enregistrerArborescenceProjets: (project_id) =>
      dispatch(actions.enregistrerArborescenceProjets(project_id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(arborescenceProjets);

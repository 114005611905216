import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  publish_reports_list: [], //todo liste vide de base
  templates_reports_list: [],
  liste_configurations_rapports_gabarits: {},
  generates_reports_list: [],

  list_templates: [],
  list_alarms_xy: [],
  modificationListe: [],
};

const enregistrerListeRapportsPublies = (state, action) => {
  return { ...state, publish_reports_list: action.publish_reports_list };
};
const enregistrerListeRapportsGabarits = (state, action) => {
  return { ...state, templates_reports_list: action.templates_reports_list };
};
const enregistrerRapportGabarit = (state, action) => {
  let newConfig = {
    ...action.configuration_rapport_gabarit,
  };
  delete newConfig['state'];
  delete newConfig['errors'];
  let newListe_configurations_rapports_gabarits = {
    ...state.liste_configurations_rapports_gabarits,
  };
  newListe_configurations_rapports_gabarits[
    action.configuration_rapport_gabarit.template_report_id
  ] = newConfig;
  return {
    ...state,
    liste_configurations_rapports_gabarits:
      newListe_configurations_rapports_gabarits,
  };
};
const enregistrerListeRapportsGeneres = (state, action) => {
  return { ...state, generates_reports_list: action.generates_reports_list };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_LISTE_RAPPORTS_PUBLIES:
      return enregistrerListeRapportsPublies(state, action);
    case actionTypes.ENREGISTRER_LISTE_RAPPORTS_GABARITS:
      return enregistrerListeRapportsGabarits(state, action);
    case actionTypes.ENREGISTRER_RAPPORT_GABARIT:
      return enregistrerRapportGabarit(state, action);
    case actionTypes.ENREGISTRER_LISTE_RAPPORTS_GENERES:
      return enregistrerListeRapportsGeneres(state, action);
    default:
      return state;
  }
};

export default reducer;

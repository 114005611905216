import * as actionTypes from '../actions/actionTypes';

export const initialState = {
  views_list: [], //todo liste vide de base
  views_list_by_view_id: {}, //todo liste vide de base
  modificationListe: [],
  views_layers_list: {},
  views_objects_lists_list: {}, //todo liste vide de base
  viewobj_content_list: {}, //todo liste vide de base
  views_data_objects_list_view: {}, //todo liste vide de base
  favorite_views_list: [], //todo liste vide de base
  dashbords_list_by_user: {}, //todo liste vide de base
};

const enregistrerListeCoucheVues = (state, action) => {
  let newLayers_list = {
    ...state.views_layers_list,
  };
  newLayers_list[parseInt(action.view_id)] = action.layers_list;
  return { ...state, views_layers_list: newLayers_list };
};
const enregistrerListeObjetsAssocieVue = (state, action) => {
  let newObjects_lists_list = {
    ...state.views_objects_lists_list,
  };
  newObjects_lists_list[parseInt(action.view_id)] = action.objects_list_view;
  return { ...state, views_objects_lists_list: newObjects_lists_list };
};
const viderListeObjetsAAfficherAssocieVue = (state, action) => {
  let newData_objects_list_view = {
    ...state.views_data_objects_list_view,
  };
  delete newData_objects_list_view[parseInt(action.view_id)];
  return { ...state, views_data_objects_list_view: newData_objects_list_view };
};
const enregistrerListeObjetsAAfficherAssocieVue = (state, action) => {
  let newData_objects_list_view = {
    ...state.views_data_objects_list_view,
  };
  newData_objects_list_view[parseInt(action.view_id)] =
    action.data_objects_list_view;
  return { ...state, views_data_objects_list_view: newData_objects_list_view };
};
const enregistrerObjetAAfficherAssocieVue = (state, action) => {
  let newViewobj_content_list = {
    ...state.viewobj_content_list,
  };
  newViewobj_content_list[action.viewobj_id] = {
    // ...action.viewobj_content,
    ...action.viewobj_content[0],

    get_metadata_onload: 1,
    get_values_onload: 1,
  };
  return { ...state, viewobj_content_list: newViewobj_content_list };
};
const enregistrerListeVues = (state, action) => {
  let newViews_list_by_view_id = {
    ...state.views_list_by_view_id,
  };
  for (let indView = 0; indView < action.views_list.length; indView++) {
    newViews_list_by_view_id[parseInt(action.views_list[indView].view_id)] = {
      ...action.views_list[indView],
    };
  }
  return {
    ...state,
    views_list: action.views_list,
    views_list_by_view_id: newViews_list_by_view_id,
  };
};
const enregistrerVue = (state, action) => {
  let newViews_list_by_view_id = {
    ...state.views_list_by_view_id,
  };
  newViews_list_by_view_id[parseInt(action.view.view_id)] = { ...action.view };
  return { ...state, views_list_by_view_id: newViews_list_by_view_id };
};
const enregistrerTableauxDeBord = (state, action) => {
  let newDashbords_list_by_user = {
    ...state.dashbords_list_by_user,
  };
  if (action.favorite_views_list !== undefined) {
    newDashbords_list_by_user[parseInt(action.user_id)] = {
      views_list: [...action.views_list],
      favorite_views_list: [...action.favorite_views_list],
    };
  } else {
    newDashbords_list_by_user[parseInt(action.user_id)] = {
      views_list: [...action.views_list],
    };
  }

  return { ...state, dashbords_list_by_user: newDashbords_list_by_user };
};
const enregistrerListeVuesFavoris = (state, action) => {
  return { ...state, favorite_views_list: [...action.favorite_views_list] };
};
const supprimerVuesLayersObjets = (state) => {
  return {
    ...state,
    // views_list: [],
    // views_list_by_view_id: {},
    modificationListe: [],
    views_layers_list: {},
    views_objects_lists_list: {},
    viewobj_content_list: {},
    views_data_objects_list_view: {},
    dashbords_list_by_user: {},
  };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENREGISTRER_LISTE_VUES:
      return enregistrerListeVues(state, action);
    case actionTypes.ENREGISTRER_VUE:
      return enregistrerVue(state, action);
    case actionTypes.ENREGISTRER_LISTE_COUCHES_VUE:
      return enregistrerListeCoucheVues(state, action);
    case actionTypes.ENREGISTRER_LISTE_OBJETS_ASSOCIE_VUE:
      return enregistrerListeObjetsAssocieVue(state, action);
    case actionTypes.VIDER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE:
      return viderListeObjetsAAfficherAssocieVue(state, action);
    case actionTypes.ENREGISTRER_LISTE_OBJETS_A_AFFICHER_ASSOCIE_VUE:
      return enregistrerListeObjetsAAfficherAssocieVue(state, action);
    case actionTypes.ENREGISTRER_OBJET_A_AFFICHER_ASSOCIE_VUE:
      return enregistrerObjetAAfficherAssocieVue(state, action);
    case actionTypes.ENREGISTRER_LISTE_VUES_FAVORIS:
      return enregistrerListeVuesFavoris(state, action);
    case actionTypes.ENREGISTRER_LISTE_TABLEAUX_DE_BORD:
      return enregistrerTableauxDeBord(state, action);
    case actionTypes.SUPPRIMER_VUES_LAYERS_OBJETS:
      return supprimerVuesLayersObjets(state, action);
    default:
      return state;
  }
};

export default reducer;
